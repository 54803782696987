
import api from "../axiosConfig";
import {
  NOTIFICATION_FIND_FAIL,
  NOTIFICATION_FIND_REQUEST,
  NOTIFICATION_FIND_RESET,
  NOTIFICATION_FIND_SUCCESS,
} from "../constants/NotificationConstants";

export const InternalFindNotification =
  () => async (dispatch: any, getState: any) => {
    dispatch({ type: NOTIFICATION_FIND_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(
        `/api/invoice/find-notification/${token?._id}`,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        },
      );
      return dispatch({ type: NOTIFICATION_FIND_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: NOTIFICATION_FIND_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetNotificationCount = () => ({
  type: NOTIFICATION_FIND_RESET,
});
