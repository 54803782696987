import { useEffect } from "react";

// EmailForm.tsx
const SendEmailModel = ({
  handleChange,
  formData,
  errorEmail,
  handleSendEmail,
  closeModal,
  setErrorEmail,
  sendEmailErrors,
}: any) => {
  useEffect(() => {
    if (formData.errors?.ToErrorMessage === "") {
      setErrorEmail("");
    }
  }, [formData.errors?.ToErrorMessage]);
  return (
    <div>
      <div
        className="bg-[#008065] text-xl font-semibold text-white flex justify-center items-center h-16"
        style={{ fontFamily: "Poppins" }}
      >
        Send An Email
      </div>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-left  sm:mt-0 sm:text-left  w-full">
            <label
              style={{
                fontFamily: "poppins",
              }}
            >
              To
            </label>
            <div className="">
              <input
                type="email"
                name="To"
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  borderColor:
                    formData.errors?.ToErrorMessage || errorEmail?.To
                      ? "#FF0000"
                      : "#008065",
                }}
                className={
                  "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                }
                value={formData?.To}
                onChange={(e) => handleChange(e)}
              />
              {errorEmail?.To && (
                <span
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                    color: "#ff0000",
                  }}
                  className="text-red-400 text-xs"
                >
                  {errorEmail?.To}
                </span>
              )}
              {formData.errors?.ToErrorMessage && (
                <span
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                    color: "#ff0000",
                  }}
                  className="text-red-400 text-xs"
                >
                  {formData.errors.ToErrorMessage}
                </span>
              )}
            </div>
            <div
              style={{
                fontFamily: "poppins",
                marginBottom: "-0.5rem", // Decreased margin bottom
                marginTop: "1rem", // Increased margin top
              }}
            >
              <label>Subject</label>
            </div>
            <div className="">
              <input
                type="email"
                name="Subject"
                className="w-full rounded-[7px] h-9 xs:w-1/4 mt-2 border-2 border-[#008065] focus:outline-none px-2 "
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                }}
                value={formData.Subject}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div
              style={{
                fontFamily: "poppins",
                marginTop: "1rem", // Increased margin top
              }}
            >
              <label>Message</label>
            </div>
            <textarea
              name="Message"
              id="message"
              value={formData.Message}
              onChange={(e) => handleChange(e)}
              className="block p-2.5 w-full text-sm text-gray-900 border-2 border-[#008065] rounded-lg  focus:ring-blue-500 focus:border-blue-500   dark:text-white "
              placeholder="Write your thoughts here..."
            ></textarea>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-white  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          // ref={cancelButtonRef}
          onClick={handleSendEmail}
        >
          send
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SendEmailModel;
