import React, { useEffect, useState } from "react";

// import { useLocation } from "next/navigation";
import { useLocation } from "react-router-dom";
interface Props {
  image?: string;
  imageUrl1?: string;
  text?: string;
  content?: string;
  name?: string;
}

const Banner = ({ image, imageUrl1, text, content, name }: Props) => {
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [text1, settext] = useState(text);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoice, setInvoice] = useState("Simple Invoice");

  const [imageSrc, setImageSrc] = useState(image);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageUrl, setImage] = useState(imageUrl1);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [content1, setcontent1] = useState(content);

  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const pathParts = location.pathname.split("/").filter((part) => part);
    let name = pathParts[pathParts.length - 1] || "";

    if (name.endsWith(".html")) {
      name = name.slice(0, -5);
    }

    name = name
      .replace("free-", "")
      .replace(/-/g, " ")
      .replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());

    setInvoice(name);
  }, [location.pathname]);

  useEffect(() => {
    if (image && imageUrl1 && text) {
      setImageSrc(image);

      setImage(imageUrl1);
      settext(text);
      setcontent1(content);
    }
  }, [image, imageUrl1, text, content]);

  useEffect(() => {
    const navbarMenu = document.getElementById("menu");
    const burgerMenu = document.getElementById("burger");
    const overlayMenu = document.querySelector(".overlay");
    const darkSwitch = document.getElementById("switch");

    const handleBurgerClick = () => {
      if (burgerMenu && navbarMenu) {
        burgerMenu.classList.toggle("is-active");
        navbarMenu.classList.toggle("is-active");
      }
    };

    if (burgerMenu) {
      burgerMenu.addEventListener("click", handleBurgerClick);
    }

    const handleLinkClick = () => {
      if (burgerMenu && navbarMenu) {
        burgerMenu.classList.remove("is-active");
        navbarMenu.classList.remove("is-active");
      }
    };

    const menuLinks = document.querySelectorAll(".menu-link");
    menuLinks.forEach((link) => {
      link.addEventListener("click", handleLinkClick);
    });

    const handleResize = () => {
      if (window.innerWidth >= 992) {
        if (navbarMenu?.classList.contains("is-active")) {
          navbarMenu.classList.remove("is-active");
          overlayMenu?.classList.remove("is-active");
        }
      }
    };

    window.addEventListener("resize", handleResize);

    const handleDarkSwitchClick = () => {
      document.documentElement.classList.toggle("darkmode");
      document.body.classList.toggle("darkmode");
    };

    if (darkSwitch) {
      darkSwitch.addEventListener("click", handleDarkSwitchClick);
    }

    return () => {
      if (burgerMenu) {
        burgerMenu.removeEventListener("click", handleBurgerClick);
      }
      menuLinks.forEach((link) => {
        link.removeEventListener("click", handleLinkClick);
      });
      window.removeEventListener("resize", handleResize);
      if (darkSwitch) {
        darkSwitch.removeEventListener("click", handleDarkSwitchClick);
      }
    };
  }, []);

  return (
    <main className="main ">
      {/* <Carousel onClick={handleChildClick} /> */}

      <>
        {windowWidth >= 768 ? (
          <div
            rel="preload"
            className="2xl:py-14 w-full  2xl:mt-0   bg-center bg-cover xl:bg-center xl:bg-cover lg:bg-center lg:bg-cover 2xl:flex items-center xl:flex items-center lg:flex items-center md:flex items-left  md:block hidden md:items-left "
            style={{
              backgroundImage: `url(${imageSrc})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "100%",
            }}
          >
            <section className="relative z-8 text-white ml-2">
              <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 items-center">
                <div className="banner-inner md:text-center lg:text-left">
                  <h1
                    // className="2xl:text-4xl xl:text-4xl 2xl:w-full lg:w-full md:w-1/2 lg:text-4xl md:text-4xl   text-4xl text-green-500 2xl:font-extrabold xl:font-extrabold lg:font-extrabold md:font-extrabold font-extrabold md:text-left"
                    className="2xl:text-4xl xl:text-4xl 2xl:w-full lg:w-full md:w-1/2 lg:text-4xl md:text-4xl   text-4xl  2xl:font-extrabold xl:font-extrabold lg:font-extrabold md:font-extrabold font-extrabold text-green-500 2xl:mt-2 xl:mt-2 lg:mt-2 md:mt-2 mt-2 md:text-left  "
                    style={{
                      fontFamily: "poppins",
                      textShadow: "1px 1px 1px  rgba(0, 0, 0, 0)",
                    }}
                  >
                    {`${name}`}
                  </h1>
                  <h2
                    className=" 2xl:text-2xl xl:text-2xl lg:text-2xl 2xl:w-full lg:w-full md:w-1/2 lg:text-2xl md:text-2xl text-2xl text-green-500 2xl:mt-2 xl:mt-4 lg:mt-2 md:mt-2 mt-2 md:text-left pb-1 "
                    style={{
                      fontFamily: "poppins",
                      textShadow: "1px 1px 1px  rgba(0, 0, 0, 0)",
                    }}
                  >
                    {`${text}`}
                  </h2>
                  <h3
                    className=" text-black md:mt-3 pb-1 md:text-left 2xl:w-full lg:w-full  md:w-1/2 md:text-sm xl:font-bold lg:text-sm md:font-bold xl:text-md"
                    style={{ fontFamily: "poppins" }}
                  >
                    {`${content}`}
                  </h3>
                  <p
                    className=" md:text-md 2xl:text-lg flex flex-wrap text-green-500 pb-5 "
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                  >
                    Download
                    <span
                      className="ml-2 "
                      style={{
                        fontSize: "12px",
                        marginTop: "3px",
                        marginRight: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        height="18px"
                        width="18px"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75v6a2.25 2.25 0 002.25 2.25h10.5A2.25 2.25 0 0019.5 18.75v-6M7.5 9l4.5 4.5m0 0L16.5 9m-4.5 4.5V3"
                        />
                      </svg>
                    </span>
                    | Email
                    <span
                      className="ml-2"
                      style={{
                        fontSize: "12px",
                        marginTop: "4px",
                        marginRight: "5px",
                      }}
                    >
                      <svg
                        fill="green"
                        height="16px"
                        width="16px"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 64 64"
                        enableBackground="new 0 0 64 64"
                        // xml:space="preserve"
                        strokeWidth="2"
                        stroke="currentColor"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          
                          <path
                            id="Mail"
                            d="M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6 h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017 L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003 L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14 C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401 c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981 l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    | Print
                    <span
                      className="ml-2"
                      style={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <svg
                        fill="green"
                        height="16px"
                        width="16px"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <g id="SVGRepo_iconCarrier">
                          <g>
                            <g>
                              <path d="M488.747,245.202h-62.473V120.094c0.005-0.139,0.021-0.275,0.021-0.414c0-3.087-1.339-5.861-3.466-7.773L336.945,26.05 c-0.33-0.33-0.69-0.63-1.062-0.914c-0.083-0.063-0.164-0.125-0.249-0.186c-0.372-0.266-0.758-0.515-1.164-0.732 c-0.028-0.015-0.057-0.026-0.086-0.041c-0.383-0.201-0.784-0.372-1.193-0.525c-0.083-0.03-0.164-0.062-0.248-0.091 c-0.436-0.149-0.882-0.278-1.342-0.37c-0.018-0.004-0.037-0.005-0.055-0.008c-0.418-0.08-0.845-0.13-1.279-0.159 c-0.122-0.008-0.245-0.015-0.368-0.019c-0.116-0.004-0.229-0.018-0.346-0.018H96.157c-5.771,0-10.449,4.678-10.449,10.449V245.2 h-62.46C10.428,245.202,0,255.631,0,268.45v29.65v133.452c0,31.683,25.784,57.46,57.477,57.46h397.047 c31.693,0,57.477-25.777,57.477-57.46V298.1v-29.65C512,255.631,501.569,245.202,488.747,245.202z M340.005,58.657l50.589,50.573 h-50.589V58.657z M106.606,43.886h212.501v75.794c0,5.771,4.678,10.449,10.449,10.449h75.82v115.073h-298.77V43.886z M491.102,431.553c0,20.16-16.409,36.562-36.579,36.562H57.477c-20.17,0-36.579-16.402-36.579-36.562V308.549h470.204V431.553z M491.103,287.652H20.898V268.45c0-1.297,1.054-2.352,2.35-2.352h72.91h319.668h72.922c1.299,0,2.355,1.055,2.355,2.352V287.652z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </p>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div
            className="  w-screen  md:hidden block bg-cover "
            style={{
              backgroundImage: `url(${imageUrl1})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "100%",
            }}
          >
            <section className="relative z-8 text-white">
              <div className="  grid grid-cols-1 lg:grid-cols-2 items-center">
                <div className="banner-inner text-center lg:text-left h-80">
                  <h1
                    className="text-xl text-green-500 font-black mt-1 "
                    style={{
                      fontFamily: "poppins, serif",
                      textShadow: "2px 2px 4px  rgba(0, 0, 0, 0)",
                    }}
                  >
                    {`${name}`}
                  </h1>
                  <h2
                    className=" text-md font-black text-green-500 "
                    style={{
                      fontFamily: "poppins, serif",
                      textShadow: "2px 2px 4px  rgba(0, 0, 0, 0)",
                    }}
                  >
                    
                    {`${text}`}
                  </h2>
                  <h3
                    className="text-black flex flex-wrap font-bold  item-center pb-1"
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                  >
                    {`${content}`}
                  </h3>
                  <div className=" mt-0">
                    <p
                      className="text-green-500 font-bold flex flex-wrap justify-center items-center"
                      style={{
                        fontFamily: "poppins",
                        fontSize: "12px",
                      }}
                    >
                      Download
                      <span
                        className="ml-1 mr-2"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          height="12px"
                          width="12px"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75v6a2.25 2.25 0 002.25 2.25h10.5A2.25 2.25 0 0019.5 18.75v-6M7.5 9l4.5 4.5m0 0L16.5 9m-4.5 4.5V3"
                          />
                        </svg>
                      </span>
                      | Email
                      <span
                        className="ml-1 mr-2"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <svg
                          fill="green"
                          height="12px"
                          width="12px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 64 64"
                          enableBackground="new 0 0 64 64"
                          // xml:space="preserve"
                          strokeWidth="2"
                          stroke="currentColor"
                        >
                          <g id="SVGRepo_iconCarrier">
                            
                            <path
                              id="Mail"
                              d="M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6 h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017 L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003 L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14 C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401 c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981 l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z"
                            ></path>
                          </g>
                        </svg>
                      </span>
                      | Print
                      <span
                        className="ml-1 mr-2"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <svg
                          fill="green"
                          height="12px"
                          width="12px"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          strokeWidth="2"
                          stroke="currentColor"
                          enableBackground="new 0 0 64 64"
                        >
                          <g id="SVGRepo_iconCarrier">
                            <path d="M488.747,245.202h-62.473V120.094c0.005-0.139,0.021-0.275,0.021-0.414c0-3.087-1.339-5.861-3.466-7.773L336.945,26.05 c-0.33-0.33-0.69-0.63-1.062-0.914c-0.083-0.063-0.164-0.125-0.249-0.186c-0.372-0.266-0.758-0.515-1.164-0.732 c-0.028-0.015-0.057-0.026-0.086-0.041c-0.383-0.201-0.784-0.372-1.193-0.525c-0.083-0.03-0.164-0.062-0.248-0.091 c-0.436-0.149-0.882-0.278-1.342-0.37c-0.018-0.004-0.037-0.005-0.055-0.008c-0.418-0.08-0.845-0.13-1.279-0.159 c-0.122-0.008-0.245-0.015-0.368-0.019c-0.116-0.004-0.229-0.018-0.346-0.018H96.157c-5.771,0-10.449,4.678-10.449,10.449V245.2 h-62.46C10.428,245.202,0,255.631,0,268.45v29.65v133.452c0,31.683,25.784,57.46,57.477,57.46h397.047 c31.693,0,57.477-25.777,57.477-57.46V298.1v-29.65C512,255.631,501.569,245.202,488.747,245.202z M340.005,58.657l50.589,50.573 h-50.589V58.657z M106.606,43.886h212.501v75.794c0,5.771,4.678,10.449,10.449,10.449h75.82v115.073h-298.77V43.886z M491.102,431.553c0,20.16-16.409,36.562-36.579,36.562H57.477c-20.17,0-36.579-16.402-36.579-36.562V308.549h470.204V431.553z M491.103,287.652H20.898V268.45c0-1.297,1.054-2.352,2.35-2.352h72.91h319.668h72.922c1.299,0,2.355,1.055,2.355,2.352V287.652z"></path>
                          </g>
                        </svg>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </>
    </main>
  );
};

export default Banner;
