import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chroma from "chroma-js";
import { useEffect, useState } from "react";
const ColorScheme = ({
   handleColorChange,
   setColorPicker,
   clientList,
}) => {
   const [selectedColor, setSelectedColor] = useState(); // Default to black
   const [darkColor, setDarkColor] = useState("#000000");
   const [mediumColor, setMediumColor] = useState("#3B3B3B");
   const [lightColor, setLightColor] = useState("#777777");
   const [deepLightColor, setDeepLightColor] = useState("#B9B9B9");
   const palette = [
      "#000000", // Black
      "#0000FF", // Blue
      "#9400D3", // Dark Violet
      "#00FF00", // Green
      "#F4CE14", // Yellow
      "#4B0082", // Indigo
      "#FF0000", // Red
      "#FF7F00", // Orange
      "#F7418F", // Pink
      "#59D5E0", // Light Blue
      "#A9A9A9",
      "#F08080",
   ];
   const handleColorBoxClick = (color, event) => {
      event.preventDefault();
      handleColorBoxClick1(color);
   };

   const handleColorBoxClick1 = (color) => {
      // if (selectedColor !== color) {
      setSelectedColor(color);
      const scale = chroma.scale([color, "white"]).mode("lab");
      setDarkColor(scale(0).hex());
      setMediumColor(scale(0.25).hex());
      setLightColor(scale(0.5).hex());
      setDeepLightColor(scale(0.75).hex());
      // }
      handleColorChange({ target: { value: color } });
   };

   // useEffect(() => {
   //    handleColorBoxClick1(clientList);
   // }, [clientList, ColourEmpty]);

   useEffect(() => {
      handleColorBoxClick1(clientList);
   }, [clientList]);

   useEffect(() => {
      let colorArray = [darkColor, mediumColor, lightColor, deepLightColor];
      setColorPicker(colorArray);
   }, [darkColor, mediumColor, lightColor, deepLightColor]);

   return (
      <div>
         <div className="w-full">
            <div
               style={{ fontFamily: "poppins", fontSize: "14px" }}
               className="flex justify-center mx-auto "
            >
               <div
                  className="grid grid-cols-4  xl:grid-cols-4"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
               >
                  {palette.map((color, index) => (
                     <button
                        key={index}
                        className="w-8 h-8 lg:w-6 lg:h-6 xl:w-[1.90rem] xl:h-[1.90rem]"
                        style={{
                           backgroundColor: color,
                           // width: "30px",
                           // height: "30px",
                           marginRight: "10px",
                           marginBottom: "10px",
                           border:
                              selectedColor?.toUpperCase() === color
                                 ? "2px solid black"
                                 : "none",
                           borderRadius: "5px",
                           cursor: "pointer",
                           position: "relative",
                        }}
                        onClick={(event) => handleColorBoxClick(color, event)}
                     >
                        {selectedColor?.toUpperCase() === color && (
                           <FontAwesomeIcon
                              icon={faCheck}
                              style={{
                                 position: "absolute",
                                 top: "50%",
                                 left: "50%",
                                 transform: "translate(-50%, -50%)",
                                 color: "white",
                              }}
                           />
                        )}
                     </button>
                  ))}
               </div>
            </div>
            <div style={{ marginTop: "10px" }} className="flex justify-center ">
               <div
                  className="w-8 h-8 lg:w-6 lg:h-6 xl:w-[1.90rem] xl:h-[1.90rem]"
                  style={{
                     // width: "30px",
                     // height: "30px",
                     backgroundColor: darkColor,
                     borderRadius: "5px",
                     marginRight: "10px",
                     border: "1px solid black",
                  }}
               ></div>
               <div
                  className="w-8 h-8 lg:w-6 lg:h-6 xl:w-[1.90rem] xl:h-[1.90rem]"
                  style={{
                     // width: "30px",
                     // height: "30px",
                     backgroundColor: mediumColor,
                     borderRadius: "5px",
                     marginRight: "10px",
                     border: "1px solid black",
                  }}
               ></div>
               <div
                  className="w-8 h-8 lg:w-6 lg:h-6 xl:w-[1.90rem] xl:h-[1.90rem]"
                  style={{
                     // width: "30px",
                     // height: "30px",
                     backgroundColor: lightColor,
                     borderRadius: "5px",
                     marginRight: "10px",
                     border: "1px solid black",
                  }}
               ></div>
               <div
                  className="w-8 h-8 lg:w-6 lg:h-6 xl:w-[1.90rem] xl:h-[1.90rem]"
                  style={{
                     // width: "30px",
                     // height: "30px",
                     backgroundColor: deepLightColor,
                     borderRadius: "5px",
                     marginRight: "10px",
                     border: "1px solid black",
                  }}
               ></div>
            </div>
         </div>
      </div>
   );
};

export default ColorScheme;
