export const APPSETTING_POST_REQUEST = "APPSETTING_POST_REQUEST ";
export const APPSETTING_POST_SUCCESS = "APPSETTING_POST_SUCCESS";
export const APPSETTING_POST_FAIL = "APPSETTING_POST_FAIL";
export const APPSETTING_POST_REST = "APPSETTING_POST_REST";

export const APPSETTING_GET_REQUEST = "APPSETTING_GET_REQUEST";
export const APPSETTING_GET_SUCCESS = "APPSETTING_GET_SUCCESS";
export const APPSETTING_GET_FAIL = "APPSETTING_GET_FAIL";
export const APPSETTING_GET_REST = "APPSETTING_GET_REST";

export const APPSETTING_UPDATE_REQUEST = "APPSETTING_UPDATE_REQUEST";
export const APPSETTING_UPDATE_SUCCESS = "APPSETTING_UPDATE_SUCCESS";
export const APPSETTING_UPDATE_FAIL = "APPSETTING_UPDATE_FAIL";
export const APPSETTING_UPDATE_REST = "APPSETTING_UPDATE_REST";

export const APPSETTING_DELETE_REQUEST = "APPSETTING_DELETE_REQUEST";
export const APPSETTING_DELETE_SUCCESS = "APPSETTING_DELETE_SUCCESS";
export const APPSETTING_DELETE_FAIL = "APPSETTING_DELETE_FAIL";
export const APPSETTING_DELETE_REST = "APPSETTING_DELETE_REST";

export const SIGNATURE_SAVE_REQUEST = "SIGNATURE_SAVE_REQUEST";
export const SIGNATURE_SAVE_SUCCESS = "SIGNATURE_SAVE_SUCCESS";
export const SIGNATURE_SAVE_FAIL = "SIGNATURE_SAVE_FAIL";
export const SIGNATURE_SAVE_RESET = "SIGNATURE_SAVE_RESET";

export const SIGNATURE_GET_REQUEST = "SIGNATURE_GET_REQUEST";
export const SIGNATURE_GET_SUCCESS = "SIGNATURE_GET_SUCCESS";
export const SIGNATURE_GET_FAIL = "SIGNATURE_GET_FAIL";
export const SIGNATURE_GET_RESET = "SIGNATURE_GET_RESET";

export const SIGNATURE_DELETE_REQUEST = "SIGNATURE_DELETE_REQUEST";
export const SIGNATURE_DELETE_SUCCESS = "SIGNATURE_DELETE_SUCCESS";
export const SIGNATURE_DELETE_FAIL = "SIGNATURE_DELETE_FAIL";
export const SIGNATURE_DELETE_RESET = "SIGNATURE_DELETE_RESET";

export const SIGNATURE_UPDATE_REQUEST = "SIGNATURE_UPDATE_REQUEST";
export const SIGNATURE_UPDATE_SUCCESS = "SIGNATURE_UPDATE_SUCCESS";
export const SIGNATURE_UPDATE_FAIL = "SIGNATURE_UPDATE_FAIL";
export const SIGNATURE_UPDATE_RESET = "SIGNATURE_UPDATE_RESET";


export const INVOICESETTING_POST_REQUEST = "INVOICESETTING_POST_REQUEST ";
export const INVOICESETTING_POST_SUCCESS = "INVOICESETTING_POST_SUCCESS";
export const INVOICESETTING_POST_FAIL = "INVOICESETTING_POST_FAIL";
export const INVOICESETTING_POST_REST = "INVOICESETTING_POST_REST";

export const INVOICESETTING__GET_REQUEST = "INVOICESETTING__GET_REQUEST";
export const INVOICESETTING__GET_SUCCESS = "INVOICESETTING__GET_SUCCESS";
export const INVOICESETTING__GET_FAIL = "INVOICESETTING__GET_FAIL";
export const INVOICESETTING__GET_RESET = "INVOICESETTING__GET_RESET";

export const INVOICESETTING_UPDATE_REQUEST = "INVOICESETTING_UPDATE_REQUEST";
export const INVOICESETTING_UPDATE_SUCCESS = "INVOICESETTING_UPDATE_SUCCESS";
export const INVOICESETTING_UPDATE_FAIL = "INVOICESETTING_UPDATE_FAIL";
export const INVOICESETTING_UPDATE_RESET = "INVOICESETTING_UPDATE_RESET";

export const INVOICESETTING_DELETE_REQUEST = "INVOICESETTING_DELETE_REQUEST";
export const INVOICESETTING_DELETE_SUCCESS = "INVOICESETTING_DELETE_SUCCESS";
export const INVOICESETTING_DELETE_FAIL = "INVOICESETTING_DELETE_FAIL";
export const INVOICESETTING_DELETE_RESET = "INVOICESETTING_DELETE_RESET";

export const SERVICESETTING_POST_REQUEST = "SERVICESETTING_POST_REQUEST ";
export const SERVICESETTING_POST_SUCCESS = "SERVICESETTING_POST_SUCCESS";
export const SERVICESETTING_POST_FAIL = "SERVICESETTING_POST_FAIL";
export const SERVICESETTING_POST_REST = "SERVICESETTING_POST_REST";

export const SERVICESETTING__GET_REQUEST = "SERVICESETTING__GET_REQUEST";
export const SERVICESETTING__GET_SUCCESS = "SERVICESETTING__GET_SUCCESS";
export const SERVICESETTING__GET_FAIL = "SERVICESETTING__GET_FAIL";
export const SERVICESETTING__GET_RESET = "SERVICESETTING__GET_RESET";

export const SERVICESETTING_UPDATE_REQUEST = "SERVICESETTING_UPDATE_REQUEST";
export const SERVICESETTING_UPDATE_SUCCESS = "SERVICESETTING_UPDATE_SUCCESS";
export const SERVICESETTING_UPDATE_FAIL = "SERVICESETTING_UPDATE_FAIL";
export const SERVICESETTING_UPDATE_RESET = "SERVICESETTING_UPDATE_RESET";

export const SERVICESETTING_DELETE_REQUEST = "SERVICESETTING_DELETE_REQUEST";
export const SERVICESETTING_DELETE_SUCCESS = "SERVICESETTING_DELETE_SUCCESS";
export const SERVICESETTING_DELETE_FAIL = "SERVICESETTING_DELETE_FAIL";
export const SERVICESETTING_DELETE_RESET = "SERVICESETTING_DELETE_RESET";
