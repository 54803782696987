import api from "../axiosConfig";
import {
  COMPANY_DELETE_FAIL,
  COMPANY_DELETE_REQUEST,
  COMPANY_DELETE_SUCCESS,
  COMPANY_GET_FAIL,
  COMPANY_GET_REQUEST,
  COMPANY_GET_SUCCESS,
  COMPANY_POST_FAIL,
  COMPANY_POST_REQUEST,
  COMPANY_POST_SUCCESS,
  COMPANY_UPDATE_FAIL,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
} from "../constants/companyConstants";

export const CompanySave = (company: any) => async (dispatch: any, getState: any) => {
  // company.user_id = environment.user_id;
  dispatch({ type: COMPANY_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    company.user_id = token._id;
    const { data } = await api.post(
      `/api/company/save`,
      company,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: COMPANY_POST_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: COMPANY_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const companyAll = () => async (dispatch: any, getState: any) => {
  dispatch({ type: COMPANY_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(
      `/api/company/all/${token?._id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: COMPANY_GET_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: COMPANY_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const companyDelete = (id: string) => async (dispatch: any, getState: any) => {
  dispatch({ type: COMPANY_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.delete(
      `/api/company/delete/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );

    return dispatch({ type: COMPANY_DELETE_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: COMPANY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CompanyUpdate = (id: any, updatedet: any) => async (dispatch: any, getState: any) => {

  dispatch({ type: COMPANY_UPDATE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.put(`/api/company/companyupdate/${id}`, updatedet, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: COMPANY_UPDATE_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: COMPANY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};