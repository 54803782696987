import api from "../axiosConfig";
import { CHEQUE_MODAL_POST_FAIL, CHEQUE_MODAL_POST_REQUEST, CHEQUE_MODAL_POST_SUCCESS, CHEQUE_NEW_POST_FAIL, CHEQUE_NEW_POST_REQUEST, CHEQUE_NEW_POST_SUCCESS } from "../constants/chequeConstants";
export const ChequeFindAll = (id:any) => async (dispatch: any, getState: any) => {
    dispatch({ type: CHEQUE_NEW_POST_REQUEST });
  
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/Cheque/find-all/${token?._id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: CHEQUE_NEW_POST_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: CHEQUE_NEW_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const chequeModalSave = (prod: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: CHEQUE_MODAL_POST_REQUEST});
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.post(
        `/api/cheque/chequeSave`,
        prod,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        }
      );
      return dispatch({ type: CHEQUE_MODAL_POST_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: CHEQUE_MODAL_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };