import {
  DASHBOARD_INTERNAL_INVOICE_GET_COUNT_FAIL,
  DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REQUEST,
  DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REST,
  DASHBOARD_INTERNAL_INVOICE_GET_COUNT_SUCCESS,
  INTERNAL_INVOICE_FIND_ALL_FAIL,
  INTERNAL_INVOICE_FIND_ALL_REQUEST,
  INTERNAL_INVOICE_FIND_ALL_REST,
  INTERNAL_INVOICE_FIND_ALL_SUCCESS,
} from "../constants/InternalInvoiceConstants";

export const salesListsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case INTERNAL_INVOICE_FIND_ALL_REQUEST:
      return { loading: true };
    case INTERNAL_INVOICE_FIND_ALL_SUCCESS:
      return { loading: false, Sales: action.payload };
    case INTERNAL_INVOICE_FIND_ALL_FAIL:
      return { loading: false, error: action.payload };
    case INTERNAL_INVOICE_FIND_ALL_REST:
      return {};
    default:
      return state;
  }
};

export const getCountsListsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REQUEST:
      return { loading: true };
    case DASHBOARD_INTERNAL_INVOICE_GET_COUNT_SUCCESS:
      return { loading: false, getCount: action.payload };
    case DASHBOARD_INTERNAL_INVOICE_GET_COUNT_FAIL:
      return { loading: false, error: action.payload };
    case DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REST:
      return {};
    default:
      return state;
  }
};
