export const QUOTATION_PAGE_POST_REQUEST = 'QUOTATION_PAGE_POST_REQUEST';
export const QUOTATION_PAGE_POST_SUCCESS = 'QUOTATION_PAGE_POST_SUCCESS';
export const QUOTATION_PAGE_POST_FAIL = 'QUOTATION_PAGE_POST_FAIL';
export const QUOTATION_PAGE_POST_REST = 'QUOTATION_PAGE_POST_REST';

export const QUOTATION_PAGE_FIND_ALL_REQUEST = 'QUOTATION_PAGE_FIND_ALL_REQUEST';
export const QUOTATION_PAGE_FIND_ALL_SUCCESS = 'QUOTATION_PAGE_FIND_ALL_SUCCESS';
export const QUOTATION_PAGE_FIND_ALL_FAIL = 'QUOTATION_PAGE_FIND_ALL_FAIL';
export const QUOTATION_PAGE_FIND_ALL_REST = 'QUOTATION_PAGE_FIND_ALL_REST';

export const  QUOTATION_FIND_ONE_REQUEST = ' QUOTATION_FIND_ONE_REQUEST';
export const  QUOTATION_FIND_ONE_SUCCESS = ' QUOTATION_FIND_ONE_SUCCESS';
export const  QUOTATION_FIND_ONE_FAIL = ' QUOTATION_FIND_ONE_FAIL';
export const  QUOTATION_FIND_ONE_REST = ' QUOTATION_FIND_ONE_REST';

export const  QUOTATION_UPDATE_REQUEST = 'QUOTATION_UPDATE_REQUEST';
export const  QUOTATION_UPDATE_SUCCESS = 'QUOTATION_UPDATE_SUCCESS';
export const  QUOTATION_UPDATE_FAIL = 'QUOTATION_UPDATE_FAIL';
export const  QUOTATION_UPDATE_REST = 'QUOTATION_UPDATE_REST';

export const  QUOTATION_DELETE_REQUEST = 'QUOTATION_DELETE_REQUEST';
export const  QUOTATION_DELETE_SUCCESS = 'QUOTATION_DELETE_SUCCESS';
export const  QUOTATION_DELETE_FAIL = 'QUOTATION_DELETE_FAIL';
export const  QUOTATION_DELETE_REST = 'QUOTATION_DELETE_REST';


