export const PAYMENT_POST_REQUEST = "PAYMENT_POST_REQUEST";
export const PAYMENT_POST_SUCCESS = "PAYMENT_POST_SUCCESS";
export const PAYMENT_POST_FAIL = "PAYMENT_POST_FAIL";
export const PAYMENT_POST_RESET = "PAYMENT_POST_RESET";

export const PAYMENT_GET_REQUEST = "PAYMENT_GET_REQUEST";
export const PAYMENT_GET_SUCCESS = "PAYMENT_GET_SUCCESS";
export const PAYMENT_GET_FAIL = "PAYMENT_GET_FAIL";
export const PAYMENT_GET_RESET = "PAYMENT_GET_RESET";

export const PAYMENT_UPDATE_REQUEST = "PAYMENT_UPDATE_REQUEST";
export const PAYMENT_UPDATE_SUCCESS = "PAYMENT_UPDATE_SUCCESS";
export const PAYMENT_UPDATE_FAIL = "PAYMENT_UPDATE_FAIL";
export const PAYMENT_UPDATE_RESET = "PAYMENT_UPDATE_RESET";

export const PAYMENT_DELETE_REQUEST = "PAYMENT_DELETE_REQUEST";
export const PAYMENT_DELETE_SUCCESS = "PAYMENT_DELETE_SUCCESS";
export const PAYMENT_DELETE_FAIL = "PAYMENT_DELETE_FAIL";
export const PAYMENT_DELETE_RESET = "PAYMENT_DELETE_RESET";

export const PAYMENT_PAGE_POST_REQUEST = "PAYMENT_PAGE_POST_REQUEST";
export const PAYMENT_PAGE_POST_SUCCESS = "PAYMENT_PAGE_POST_SUCCESS";
export const PAYMENT_PAGE_POST_FAIL = "PAYMENT_PAGE_POST_FAIL";
export const PAYMENT_PAGE_POST_RESET = "PAYMENT_PAGE_POST_RESET";
