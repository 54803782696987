/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signin } from "../../redux/actions/userAction";

function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);




  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const usersignin = useSelector((state: any) => state.userSignin);
  const { userInfo } = usersignin;

  const localStorages: any = sessionStorage.getItem("userInfo");
  const token = JSON.parse(localStorages as any);

  function redirectToLogin() {
    navigateTo("/login");
  }

  useEffect(() => {
    if (!userInfo || !token) {
      redirectToLogin();
    } else {
      navigateTo("/app/home");
    }
  }, [userInfo]);

  const onSubmit = (data: any) => {
    let sendValue: any = {
      email: data.email,
      password: data.password.trim(),
    };
    dispatch(signin(sendValue) as any).then((res: any) => {
      if (res && res.type !== "USER_SIGNIN_FAIL") {

        if (res.payload.token?.userRoleName === "Superadmin") {
          navigateTo("/app/AdminDashboard");
        } else if (res.payload.token.userRoleName === "Admin") {
          navigateTo("/app/home");
        } else {
          navigateTo("/login");
        }
      } else {
        setIsErrorVisible(true)
      }
    });
  };

  const registerscreen = () => {
    navigateTo("/register");
  };

  return (
    <section className=" flex items-center justify-center 2xl:mt-16">
      <div className="  flex rounded-2xl  ">
        <div className="w-full   lg:block hidden mt-3 ">
          <img
            src="images/inv-image.webp"
            className="rounded-2xl  w-full  "
            alt="page img"
          />
        </div>
        <div className="w-full lg:w-full rounded-lg lg:rounded-l-none mt-4">
          <div className=" flex flex-col items-center justify-center relative">
            <div className="flex flex-col bg-[#008065] text-center shadow-md px-4 sm:px-6 md:px-10 lg:px-10 py-16 h-full  w-full max-w-md rounded-t">
              <span
                style={{ fontFamily: "poppins" }}
                className="text-[#FFF] text-center text-xl font-bold mb-6 py-1 place-self-center"
              >
                Login
              </span>
            </div>
            <div className="flex flex-col shadow-lg shadow-gray-400 px-4 sm:px-6 md:px-10 lg:px-10 py-8   w-full max-w-md rounded-b ">
              <div className="flex flex-col bg-white  -mt-24 shadow-lg shadow-gray-400  px-4 sm:px-6 md:px-10 lg:px-10 py-8 rounded-md w-full max-w-md">
                {isErrorVisible ? (
                  <p className="text-base text-center text-[#FF0000] font-semibold ">
                    Invalid Email or Password
                  </p>
                ) : ""}
                <div className="pt-1">
                  <form action="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col mb-6 ">
                      <label className="mb-1 text-xs sm:text-sm tracking-wide font-semibold text-[#008065]">
                        E-mail Address
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          {...register("email", {
                            required: "Email is required .",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: errors?.email ? "" : "#008065",
                          }}
                          className={
                            `${errors?.email ? "border-[#FF0000]" : ""} ` +
                            "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                          }
                          placeholder="Enter Email Address"
                        />
                      </div>
                      {errors.email && (
                        <p
                          role="alert"
                          className="text-xs mt-1 text-left text-[#FF0000]"
                        >
                          {errors.email.message as any}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <label className="mb-1 text-xs sm:text-sm tracking-wide font-semibold text-[#008065]">
                        Password
                      </label>
                      <div className="relative">
                        <input
                          {...register("password", {
                            required: "password Is Required",
                          })}
                          type={isPasswordVisible ? "text" : "password"}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: errors?.password ? "" : "#008065",
                          }}
                          className={
                            `${errors?.password ? "border-[#FF0000]" : ""} ` +
                            "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                          }
                          placeholder="Password"
                        />
                        <span
                          onClick={togglePasswordVisibility}
                          className="absolute inset-y-0 end-0 grid cursor-pointer place-content-center px-4"
                        >
                          {isPasswordVisible ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 text-gray-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              className="h-4 w-4 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      {errors?.password && (
                        <p className="text-[#FF0000] text-xs">
                          {errors?.password?.message as any}
                        </p>
                      )}
                    </div>
                    <div className="flex items-center justify-between mt-2 mb-4">
                      <div className="inline-flex items-center font-bold text-[#008065] text-sm cursor-pointer">
                        <input {...register("checkbox", {})} type="checkbox" />
                        <span className="px-1">Remember me</span>
                      </div>
                      <div
                        onClick={() => navigateTo("/forgetPassword")}
                        className="text-sm text-[#008065] inline-block hover:underline hover:cursor-pointer transition duration-200 font-semibold"
                      >
                        Forget password?
                      </div>
                    </div>
                    <div className="flex w-full ">
                      <button
                        type="submit"
                        className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#008065]  rounded py-2 w-full transition duration-150 ease-in"
                      >
                        <span className="mr-2 uppercase">LOGIN</span>
                      </button>
                    </div>
                    <div
                      onClick={registerscreen}
                      className="flex justify-center font-bold text-[#008065] text-md cursor-pointer mt-2"
                    >
                      <span className="">
                        You don't have an account?
                        <span className="text-slate-600">Sign Up</span>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
