import api from "../axiosConfig";
import { PRODUCT_DEACTIVE_FAIL, PRODUCT_DEACTIVE_REQUEST, PRODUCT_DEACTIVE_SUCCESS, PRODUCT_DELETE_FAIL, PRODUCT_DELETE_REQUEST, PRODUCT_DELETE_SUCCESS, PRODUCT_GET_FAIL, PRODUCT_GET_REQUEST, PRODUCT_GET_SUCCESS, PRODUCT_POST_FAIL, PRODUCT_POST_REQUEST, PRODUCT_POST_SUCCESS, PRODUCT_UPDATE_FAIL, PRODUCT_UPDATE_REQUEST, PRODUCT_UPDATE_SUCCESS } from "../constants/productConstants";



export const Products = (prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PRODUCT_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.post(`/api/product/save`, prod, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PRODUCT_POST_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PRODUCT_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ------------------------------------Bulk add------------------------------------
export const createBulkProducts = (prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PRODUCT_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.post(`/api/bulkproduct/save`, prod, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PRODUCT_POST_SUCCESS, payload: data });

  } catch (error: any) {
    return dispatch({
      type: PRODUCT_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateBulkProducts = (prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PRODUCT_UPDATE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.put(`/api/bulkproduct/update`, prod, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });

    return dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ProductsGet = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PRODUCT_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.get(`/api/product/all/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PRODUCT_GET_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PRODUCT_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ProductsUpdate = (id: any, prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PRODUCT_UPDATE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.put(`/api/product/put/${id}`, prod, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ProductsDelete = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PRODUCT_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.delete(`/api/products/delete/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PRODUCT_DELETE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ProductsDeactive = (id: any, prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PRODUCT_DEACTIVE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.put(`/api/product/putDeactive/${id}`, prod, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PRODUCT_DEACTIVE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PRODUCT_DEACTIVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};