import { UPIPAYMENT_DELETE_FAIL, UPIPAYMENT_DELETE_REQUEST, UPIPAYMENT_DELETE_RESET, UPIPAYMENT_DELETE_SUCCESS, UPIPAYMENT_GET_FAIL, UPIPAYMENT_GET_ONE_FAIL, UPIPAYMENT_GET_ONE_REQUEST, UPIPAYMENT_GET_ONE_RESET, UPIPAYMENT_GET_ONE_SUCCESS, UPIPAYMENT_GET_REQUEST, UPIPAYMENT_GET_RESET, UPIPAYMENT_GET_SUCCESS, UPIPAYMENT_POST_FAIL, UPIPAYMENT_POST_REQUEST, UPIPAYMENT_POST_RESET, UPIPAYMENT_POST_SUCCESS, UPIPAYMENT_UPDATE_FAIL, UPIPAYMENT_UPDATE_REQUEST, UPIPAYMENT_UPDATE_RESET, UPIPAYMENT_UPDATE_SUCCESS } from "../constants/upiPaymentConstants";

const initialSaveState = {
    upiPaymentPost: null,
};

const initialGetState = {
    upiPaymentGet: [],
};

const initialUpdateState = {
    upiPaymentGet: [],
};

const initialDeleteState = {
    upiPaymentGet: [],
};

export const upiPaymentPostReducer = (state = initialSaveState, action: any) => {
    switch (action.type) {
        case UPIPAYMENT_POST_REQUEST:
            return { loading: true };
        case UPIPAYMENT_POST_SUCCESS:
            return { loading: false, company: action.payload };
        case UPIPAYMENT_POST_FAIL:
            return { loading: false, error: action.payload };
        case UPIPAYMENT_POST_RESET:
            return {};
        default:
            return state;
    }
}

export const upiPaymentGetReducer = (state = initialGetState, action: any) => {
    switch (action.type) {
        case UPIPAYMENT_GET_REQUEST:
            return { loading: true };
        case UPIPAYMENT_GET_SUCCESS:
            return { loading: false, company: action.payload };
        case UPIPAYMENT_GET_FAIL:
            return { loading: false, error: action.payload };
        case UPIPAYMENT_GET_RESET:
            return {};
        default:
            return state;
    }
}

export const GetOneUpiDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case UPIPAYMENT_GET_ONE_REQUEST:
            return { loading: true };
        case UPIPAYMENT_GET_ONE_SUCCESS:
            return { loading: false, upiDetail: action.payload };
        case UPIPAYMENT_GET_ONE_FAIL:
            return { loading: false, error: action.payload };
        case UPIPAYMENT_GET_ONE_RESET:
            return {};
        default:
            return state;
    }
};

export const upiPaymentUpdateReducer = (state = initialUpdateState, action: any) => {
    switch (action.type) {
        case UPIPAYMENT_UPDATE_REQUEST:
            return { loading: true };
        case UPIPAYMENT_UPDATE_SUCCESS:
            return { loading: false, company: action.payload };
        case UPIPAYMENT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case UPIPAYMENT_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}

export const upiPaymentDeleteReducer = (state = initialDeleteState, action: any) => {
    switch (action.type) {
        case UPIPAYMENT_DELETE_REQUEST:
            return { loading: true };
        case UPIPAYMENT_DELETE_SUCCESS:
            return { loading: false, company: action.payload };
        case UPIPAYMENT_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case UPIPAYMENT_DELETE_RESET:
            return {};
        default:
            return state;
    }
}