import api from "../axiosConfig";
import { USERS_PROFILE_GET_FAIL, USERS_PROFILE_GET_REQUEST, USERS_PROFILE_GET_SUCCESS, USERS_PROFILE_POST_FAIL, USERS_PROFILE_POST_REQUEST, USERS_PROFILE_POST_SUCCESS } from "../constants/userProfileConstats";

export const userProfilesPost = (postValue: any) => async (dispatch: any, getState: any) => {

    dispatch({ type: USERS_PROFILE_POST_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();
        // const { data } = await Axios.post(`/api/userprofile/save`, postValue, {
        //     headers: { Authorization: `Bearer ${token?.tocken}` },
        // });
        const { data } = await api.post(`/api/createuser/save`, postValue, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: USERS_PROFILE_POST_SUCCESS, payload: data });
    } catch (error: any) {

        return dispatch({
            type: USERS_PROFILE_POST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const userProfileAll = (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: USERS_PROFILE_GET_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();
        const { data } = await api.get(`/api/userlist/all/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: USERS_PROFILE_GET_SUCCESS, payload: data });
    } catch (error: any) {
        return dispatch({
            type: USERS_PROFILE_GET_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};




