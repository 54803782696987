import { STOCK_GET_FAIL, STOCK_GET_REQUEST, STOCK_GET_RESET, STOCK_GET_SUCCESS, STOCK_PURCHASE_GET_FAIL, STOCK_PURCHASE_GET_REQUEST, STOCK_PURCHASE_GET_RESET, STOCK_PURCHASE_GET_SUCCESS } from "../constants/stockConstants";

export const userStockGetReducer = (state = {}, action: any) => {
    switch (action.type) {
        case STOCK_GET_REQUEST:
            return { loading: true };
        case STOCK_GET_SUCCESS:
            return { loading: false, stocklist: action.payload };
        case STOCK_GET_FAIL:
            return { loading: false, error: action.payload };
        case STOCK_GET_RESET:
            return {};
        default:
            return state;
    }
};

export const userStockPurchaseGetReducer = (state = {}, action: any) => {
    switch (action.type) {
        case STOCK_PURCHASE_GET_REQUEST:
            return { loading: true };
        case STOCK_PURCHASE_GET_SUCCESS:
            return { loading: false, stocklist: action.payload };
        case STOCK_PURCHASE_GET_FAIL:
            return { loading: false, error: action.payload };
        case STOCK_PURCHASE_GET_RESET:
            return {};
        default:
            return state;
    }
};