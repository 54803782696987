/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import DashboardLayout from '../layouts/Dashboard/Dashboard'

export default function AuthenticatedRoutes() {
  const usersignin = useSelector((state: any) => state.userSignin)
  const { userInfo } = usersignin
  const localStorages: any = sessionStorage.getItem('userInfo')
  const token = JSON.parse(localStorages as any);

  const navigateTo = useNavigate()


  function redirectToLogin() {
    navigateTo("/login");
  }

  useEffect(() => {
    if (!userInfo || !token) {
      redirectToLogin()
    }
  }, [userInfo])

  return (
    <div>
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </div>
  )
}
