export const PURCHASE_NEW_POST_REQUEST = "PURCHASE_NEW_POST_REQUEST ";
export const PURCHASE_NEW_POST_SUCCESS = "PURCHASE_NEW_POST_SUCCESS";
export const PURCHASE_NEW_POST_FAIL = "PURCHASE_NEW_POST_FAIL";
export const PURCHASE_NEW_POST_RESET = "PURCHASE_NEW_POST_RESET";

export const PURCHASE_INVOICE_FIND_ALL_REQUEST = 'INTERNAL_INVOICE_FIND_ALL_REQUEST';
export const PURCHASE_INVOICE_FIND_ALL_SUCCESS = 'INTERNAL_INVOICE_FIND_ALL_SUCCESS';
export const PURCHASE_INVOICE_FIND_ALL_FAIL = 'INTERNAL_INVOICE_FIND_ALL_FAIL';
export const PURCHASE_INVOICE_FIND_ALL_REST = 'INTERNAL_INVOICE_FIND_ALL_REST';

export const PURCHASE_INVOICE_GET_COUNT_REQUEST = 'PURCHASE_INVOICE_GET_COUNT_REQUEST';
export const PURCHASE_INVOICE_GET_COUNT_SUCCESS = 'PURCHASE_INVOICE_GET_COUNT_SUCCESS';
export const PURCHASE_INVOICE_GET_COUNT_FAIL = 'PURCHASE_INVOICE_GET_COUNT_FAIL';
export const PURCHASE_INVOICE_GET_COUNT_REST = 'PURCHASE_INVOICE_GET_COUNT_REST';

export const PURCHASENEW_INVOICE_FIND_ONE_REQUEST = 'PURCHASENEW_INVOICE_FIND_ONE_REQUEST';
export const PURCHASENEW_INVOICE_FIND_ONE_SUCCESS = 'PURCHASENEW_INVOICE_FIND_ONE_SUCCESS';
export const PURCHASENEW_INVOICE_FIND_ONE_FAIL = 'PURCHASENEW_INVOICE_FIND_ONE_FAIL';
export const PURCHASENEW_INVOICE_FIND_ONE_REST = 'PURCHASENEW_INVOICE_FIND_ONE_REST';

export const PURCHASENEW_INVOICE_UPDATE_REQUEST = 'PURCHASENEW_INVOICE_UPDATE_REQUEST';
export const PURCHASENEW_INVOICE_UPDATE_SUCCESS = 'PURCHASENEW_INVOICE_UPDATE_SUCCESS';
export const PURCHASENEW_INVOICE_UPDATE_FAIL = 'PURCHASENEW_INVOICE_UPDATE_FAIL';
export const PURCHASENEW_INVOICE_UPDATE_REST = 'PURCHASENEW_INVOICE_UPDATE_REST';

export const PURCHASE_INVOICE_DELETE_REQUEST = 'PURCHASE_INVOICE_DELETE_REQUEST';
export const PURCHASE_INVOICE_DELETE_SUCCESS = 'PURCHASE_INVOICE_DELETE_SUCCESS';
export const PURCHASE_INVOICE_DELETE_FAIL = 'PURCHASE_INVOICE_DELETE_FAIL';
export const PURCHASE_INVOICE_DELETE_RESET = 'PURCHASE_INVOICE_DELETE_RESET';