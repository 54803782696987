export const PURCHASE_POST_REQUEST = 'PURCHASE_POST_REQUEST ';
export const PURCHASE_POST_SUCCESS = 'PURCHASE_POST_SUCCESS';
export const PURCHASE_POST_FAIL = 'PURCHASE_POST_FAIL';
export const PURCHASE_POST_RESET = 'PURCHASE_POST_RESET';

export const PURCHASE_GET_REQUEST = 'PURCHASE_GET_REQUEST';
export const PURCHASE_GET_SUCCESS = 'PURCHASE_GET_SUCCESS';
export const PURCHASE_GET_FAIL = 'PURCHASE_GET_FAIL';
export const PURCHASE_GET_RESET = 'PURCHASE_GET_RESET';

export const PURCHASE_UPDATE_REQUEST = 'PURCHASE_UPDATE_REQUEST';
export const PURCHASE_UPDATE_SUCCESS = 'PURCHASE_UPDATE_SUCCESS';
export const PURCHASE_UPDATE_FAIL = 'PURCHASE_UPDATE_FAIL';
export const PURCHASE_UPDATE_RESET = 'PURCHASE_UPDATE_RESET';

export const PURCHASE_DELETE_REQUEST = 'PURCHASE_DELETE_REQUEST';
export const PURCHASE_DELETE_SUCCESS = 'PURCHASE_DELETE_SUCCESS';
export const PURCHASE_DELETE_FAIL = 'PURCHASE_DELETE_FAIL';
export const PURCHASE_DELETE_RESET = 'PURCHASE_DELETE_RESET';