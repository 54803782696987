import { CLIENT_GET_FAIL, CLIENT_GET_REQUEST, CLIENT_GET_REST, CLIENT_GET_SUCCESS } from "../constants/clientConstants";

export const clientgetReducer = (state = {}, action: any) => {
    switch (action.type) {
        case CLIENT_GET_REQUEST:
            return { loading: true };
        case CLIENT_GET_SUCCESS:
            return { loading: false, clientgetlist: action.payload };
        case CLIENT_GET_FAIL:
            return { loading: false, error: action.payload };
        case CLIENT_GET_REST:
            return {};
        default:
            return state;
    }
};