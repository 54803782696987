export const PURCHASE_FIXED_ASSET_POST_REQUEST = "PURCHASE_FIXED_ASSET_POST_REQUEST";
export const PURCHASE_FIXED_ASSET_POST_SUCCESS = "PURCHASE_FIXED_ASSET_POST_SUCCESS";
export const PURCHASE_FIXED_ASSET_POST_FAIL = "PURCHASE_FIXED_ASSET_POST_FAIL";
export const PURCHASE_FIXED_ASSET_POST_RESET = "PURCHASE_FIXED_ASSET_POST_RESET";


export const PURCHASE_FIXED_ASSET_FIND_ALL_REQUEST = 'PURCHASE_FIXED_ASSET_FIND_ALL_REQUEST';
export const PURCHASE_FIXED_ASSET_FIND_ALL_SUCCESS = 'PURCHASE_FIXED_ASSET_FIND_ALL_SUCCESS';
export const PURCHASE_FIXED_ASSET_FIND_ALL_FAIL = 'PURCHASE_FIXED_ASSET_FIND_ALL_FAIL';
export const PURCHASE_FIXED_ASSET_FIND_ALL_REST = 'PURCHASE_FIXED_ASSET_FIND_ALL_REST';

export const PURCHASE_FIXED_ASSET_DELETE_REQUEST = 'PURCHASE_FIXED_ASSET_DELETE_REQUEST';
export const PURCHASE_FIXED_ASSET_DELETE_SUCCESS = 'PURCHASE_FIXED_ASSET_DELETE_SUCCESS';
export const PURCHASE_FIXED_ASSET_DELETE_FAIL = 'PURCHASE_FIXED_ASSET_DELETE_FAIL';
export const PURCHASE_FIXED_ASSET_DELETE_RESET = 'PURCHASE_FIXED_ASSET_DELETE_RESET';

export const PURCHASE_FIXED_ASSET_UPDATE_REQUEST = 'PURCHASE_FIXED_ASSET_UPDATE_REQUEST';
export const PURCHASE_FIXED_ASSET_UPDATE_SUCCESS = 'PURCHASE_FIXED_ASSET_UPDATE_SUCCESS';
export const PURCHASE_FIXED_ASSET_UPDATE_FAIL = 'PURCHASE_FIXED_ASSET_UPDATE_FAIL';
export const PURCHASE_FIXED_ASSET_UPDATE_REST = 'PURCHASE_FIXED_ASSET_UPDATE_REST';

export const PURCHASE_FIXED_ASSET_FIND_ONE_REQUEST = 'PURCHASE_FIXED_ASSET_FIND_ONE_REQUEST';
export const PURCHASE_FIXED_ASSET_FIND_ONE_SUCCESS = 'PURCHASE_FIXED_ASSET_FIND_ONE_SUCCESS';
export const PURCHASE_FIXED_ASSET_FIND_ONE_FAIL = 'PURCHASE_FIXED_ASSET_FIND_ONE_FAIL';
export const PURCHASE_FIXED_ASSET_FIND_ONE_REST = 'PURCHASE_FIXED_ASSET_FIND_ONE_REST';