import UploadProducts from './UploadProducts'

export default function ProductsBulkUpload() {
    return (

        <>
            <fieldset className="mx-3 mb-4 rounded-lg">
                <legend className="p-4 font-bold text-[#045545] text-lg ml-2">
                    Products Bulk Upload
                </legend>
                <UploadProducts />

            </fieldset>
        </>
    )
}
