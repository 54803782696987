import {
  REGISTER_GET_FAIL,
  REGISTER_GET_REQUEST,
   REGISTER_GET_RESET,
   REGISTER_GET_SUCCESS,
   USERS_GET_FAIL,
   USERS_GET_REQUEST,
   USERS_GET_RESET,
   USERS_GET_SUCCESS,
   USERS_PAGES_POST_FAIL,
   USERS_PAGES_POST_REQUEST,
   USERS_PAGES_POST_RESET,
   USERS_PAGES_POST_SUCCESS,
   USER_REGISTEROUT,
   USER_REGISTER_FAIL,
   USER_REGISTER_REQUEST,
   USER_REGISTER_SUCCESS,
   USER_SIGNIN_FAIL,
   USER_SIGNIN_REQUEST,
   USER_SIGNIN_SUCCESS,
   USER_SIGNOUT,
} from "../constants/userConstants";

export const userRegisterReducer = (state = {}, action: any) => {
   switch (action.type) {
      case USER_REGISTER_REQUEST:
         return { loading: true };
      case USER_REGISTER_SUCCESS:
         return { loading: false, userInfo: action.payload };
      case USER_REGISTER_FAIL:
         return { loading: false, error: action.payload };
      case USER_REGISTEROUT:
         return {};
      default:
         return state;
   }
};

export const userSigninReducer = (state = {}, action: any) => {
   switch (action.type) {
      case USER_SIGNIN_REQUEST:
         return { loading: true };
      case USER_SIGNIN_SUCCESS:
         return { loading: false, userInfo: action.payload };
      case USER_SIGNIN_FAIL:
         return { loading: false, error: action.payload };
      case USER_SIGNOUT:
         return {};
      default:
         return state;
   }
};

export const userGetDataReducer = (state = {}, action: any) => {
   switch (action.type) {
      case USERS_PAGES_POST_REQUEST:
         return { loading: true };
      case USERS_PAGES_POST_SUCCESS:
         return { loading: false, userdata: action.payload };
      case USERS_PAGES_POST_FAIL:
         return { loading: false, error: action.payload };
      case USERS_PAGES_POST_RESET:
         return {};
      default:
         return state;
   }
};

export const userUpdateOne = (state = {}, action: any) => {
   switch (action.type) {
      case REGISTER_GET_REQUEST:
         return { loading: true };
      case REGISTER_GET_SUCCESS:
         return { loading: false, userdata: action.payload };
      case REGISTER_GET_FAIL:
         return { loading: false, error: action.payload };
      case REGISTER_GET_RESET:
         return {};
      default:
         return state;
   }
};

export const userGetReducer = (state = {}, action: any) => {
   switch (action.type) {
      case USERS_GET_REQUEST:
         return { loading: true };
      case USERS_GET_SUCCESS:
         return { loading: false, userGetdata: action.payload };
      case USERS_GET_FAIL:
         return { loading: false, error: action.payload };
      case USERS_GET_RESET:
         return {};
      default:
         return state;
   }
};
