
export const FONTFAMILY_POST_REQUEST = "FONTFAMILY_POST_REQUEST";
export const FONTFAMILY_POST_SUCCESS = "FONTFAMILY_POST_SUCCESS";
export const FONTFAMILY_POST_FAIL = "FONTFAMILY_POST_FAIL";
export const FONTFAMILY_POST_RESET = "FONTFAMILY_POST_RESET";

export const FONTFAMILY_GET_REQUEST = "FONTFAMILY_GET_REQUEST";
export const FONTFAMILY_GET_SUCCESS = "FONTFAMILY_GET_SUCCESS";
export const FONTFAMILY_GET_FAIL = "FONTFAMILY_GET_FAIL";
export const FONTFAMILY_GET_RESET = "FONTFAMILY_GET_RESET";

export const FONTFAMILY_UPDATE_REQUEST = "FONTFAMILY_UPDATE_REQUEST";
export const FONTFAMILY_UPDATE_SUCCESS = "FONTFAMILY_UPDATE_SUCCESS";
export const FONTFAMILY_UPDATE_FAIL = "FONTFAMILY_UPDATE_FAIL";
export const FONTFAMILY_UPDATE_RESET = "FONTFAMILY_UPDATE_RESET";

export const FONTFAMILY_DELETE_REQUEST = "FONTFAMILY_DELETE_REQUEST";
export const FONTFAMILY_DELETE_SUCCESS = "FONTFAMILY_DELETE_SUCCESS";
export const FONTFAMILY_DELETE_FAIL = "FONTFAMILY_DELETE_FAIL";
export const FONTFAMILY_DELETE_RESET = "FONTFAMILY_DELETE_RESET";