import React, { Fragment, useEffect, useState } from "react";
import DashboardWidgets from '../dashboards/DashboardWidgets';
import DashboardDiagrams from '../dashboards/DashboardDiagrams';
import { Transition } from "@headlessui/react";

const DashboardHome: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState<string | { range: string; startDate: string; endDate: string }>('Last 1 month');
  console.log("selectedRange------------>", selectedRange)
  const [radioValue, setRadioValue] = useState('Last 1 month');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const toggleModal = () => {
    if (!isOpen) {
      setRadioValue(typeof selectedRange === 'string' ? selectedRange : selectedRange.range);
    }
    setIsOpen(!isOpen);
  };
  console.log("Selected range:", selectedRange);
  const handleApply = () => {
    if (radioValue === "Custom" && startDate && endDate) {
      setSelectedRange({ range: radioValue, startDate: startDate, endDate: endDate });
    } else {
      setSelectedRange(radioValue);
    }
    setIsOpen(false);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event.target.value);
  };

  useEffect(() => {

  }, [selectedRange]);

  return (
    <div className="bg-[#E1E8E7] h-full relative">
      <div className="flex justify-between items-center p-4">
        <div className="text-2xl font-bold text-[#045545] mx-2">
          Dashboard
        </div>

        <div className="relative">
          <button onClick={toggleModal} className="flex items-center px-4 py-2 bg-[#008065] text-white font-semibold rounded-md">
            <span>{typeof selectedRange === 'string' ? selectedRange : `${selectedRange.startDate} - ${selectedRange.endDate}`}</span>
            <svg className="w-4 h-4 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {isOpen && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
              <Transition
                show={isOpen}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                  <div className="p-4">
                    <h3 className="font-semibold text-lg mb-2">Date range</h3>
                    <ul className="space-y-2">
                      {['Today', 'Last 7 days', 'Last 1 month', 'Last 3 months', 'Last 6 months', 'Last 12 months', 'Last 16 months', 'Custom']?.map((option) => (
                        <li key={option}>
                          <input
                            type="radio"
                            name="range"
                            checked={radioValue === option}
                            value={option}
                            onChange={handleRadioChange}
                          />
                          <span className="text-lg ml-2">{option}</span>
                        </li>
                      ))}
                    </ul>
                    {radioValue === "Custom" && (
                      <div className="items-center mt-4">
                        <div>
                          <label className="block text-sm">Start date</label>
                          <input
                            type="date"
                            className="w-full border rounded-md p-1 text-sm"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                        <div>
                          <label className="block text-sm">End date</label>
                          <input
                            type="date"
                            className="w-full border rounded-md p-1 text-sm"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex justify-end space-x-2 mt-4">
                      <button
                        type="button"
                        className="px-4 py-2 bg-red-500 text-white rounded-md"
                        onClick={toggleModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="px-4 py-2 bg-[#008065] text-white rounded-md"
                        onClick={handleApply}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </Transition>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full pr-4 mb-4 sm:mb-1">
          <DashboardWidgets
            selectedRange={
              typeof selectedRange === "string"
                ? ({ range: selectedRange })
                : ({ range: "Custom", startDate: selectedRange.startDate, endDate: selectedRange.endDate })
            }
          />
          <div className="mt-1">
            <DashboardDiagrams
              selectedRange={
                typeof selectedRange === "string"
                  ? { range: selectedRange }
                  : { range: "Custom", startDate: selectedRange.startDate, endDate: selectedRange.endDate }
              }
            />
          </div>
        </div>
        <div className="w-full lg:w-2/6 pl-4 pr-4 sm:pl-4 sm:pr-2">
          {/* Additional components or placeholders can go here */}
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
