/* eslint-disable eqeqeq */
import ClearIcon from "@mui/icons-material/Clear";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CompantTable from "../../Tables/CompantTable";
import { environment } from "../../environment/environment";
import {
  CompanySave,
  CompanyUpdate,
  companyAll,
  companyDelete,
} from "../../redux/actions/companyAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";

interface FieldErrors {
  postal_code?: string;
  company_mobile?: string;
  pan_no?: string;
  gst?: string;
}

interface props {
  openTab: number;
}



const CompanyTab = ({ openTab }: props) => {
  const localStorages = sessionStorage.getItem("userInfo");
  const auth = JSON.parse(localStorages as any);
  const email = auth?.token?.email;
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: "",
      company_address: "",
      city: "",
      state: "",
      postal_code: "",
      company_mobile: "",
      company_email: email,
      pan_no: "",
      gst: "",
    },
  });

  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState([] as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState(null as any);
  const inputRef2 = useRef(null as any);


  const fetchData = () => {
    dispatch(companyAll() as any).then((res: any) => {
      if (res) {
        if (res?.payload?.length > 0) {
          setValue("company_name", res.payload[0]?.company_name);
          setValue("company_address", res.payload[0]?.company_address);
          setValue("city", res.payload[0]?.city);
          setValue("state", res.payload[0]?.state);
          setValue("postal_code", res.payload[0]?.postal_code);
          setValue("company_mobile", res.payload[0]?.company_mobile);
          setValue("pan_no", res.payload[0]?.pan_no);
          setValue("gst", res.payload[0]?.gst);
          setCompanyList(res.payload);
        }

        setFormData({
          ...formData,
          companyimg: res.payload[0]?.companyimg,
        });
      }
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editId, setEditId] = useState<string | undefined>(null as any);
  useEffect(() => {
    handleCancelClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTab]);
  const [formData, setFormData] = useState({
    companyimg: " ",
    company_name: " ",
    company_address: " ",
    city: " ",
    state: " ",
    postal_code: " ",
    company_mobile: " ",
    company_email: email,
    pan_no: " ",
    gst: " ",
  });

  // ***************************cancel button section*****************************
  const handleCancelClick = () => {
    if (editId || companyList.length === 0) {
      reset()
      setFieldErrors({})
      setValue("company_name", companyList[0]?.company_name);
      setValue("company_address", companyList[0]?.company_address);
      setValue("city", companyList[0]?.city);
      setValue("state", companyList[0]?.state);
      setValue("postal_code", companyList[0]?.postal_code);
      setValue("company_mobile", companyList[0]?.company_mobile);
      setValue("pan_no", companyList[0]?.pan_no);
      setValue("gst", companyList[0]?.gst);
      setFormData({
        ...formData,
        companyimg: companyList[0]?.companyimg,
      });

      setEditId("");
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        setFormData({ ...formData, companyimg: base64Data });
        if (inputRef2.current) {
          inputRef2.current.value = '';
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const ClearFile = (e: any) => {
    e.preventDefault();
    formData.companyimg = "";
    setFormData({ ...formData });
    inputRef2.current.value = "";
  };

  const handleEditSection = (data: any) => {
    setFormData({
      companyimg: data.companyimg,
      company_name: data.company_name,
      company_address: data.company_address,
      city: data.city,
      state: data.state,
      postal_code: data.postal_code,
      company_mobile: data.company_mobile,
      company_email: data.company_email,
      pan_no: data.pan_no,
      gst: data.gst,
    });
    setEditId(data?._id?.$oid);
  };

  const handleDeleteSection = (companyDetails: any) => {
    setShowConfirmation(true);
    setUpdateId(companyDetails._id.$oid);
    setEditId("");
  };

  const handleConfirmdelete = () => {
    dispatch(companyDelete(updateId) as any).then((response: any) => {
      if (response) {
        setCompanyList(
          companyList.filter((company: any) => company._id.$oid !== updateId),
        );
        fetchData();
        reset();
        setUpdateId(null);
        setShowConfirmation(false);
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };
  const onSubmit = (data: any) => {
    let Company = {
      companyimg: formData.companyimg ? formData.companyimg : "",
      company_name: data.company_name,
      company_address: data.company_address,
      city: data.city,
      state: data.state,
      postal_code: data.postal_code,
      company_mobile: data.company_mobile,
      company_email: email,
      pan_no: data.pan_no,
      gst: data.gst,
      user_id: environment.user_id,
    };
    if (
      !fieldErrors.postal_code &&
      !fieldErrors.pan_no &&
      !fieldErrors.company_mobile &&
      !fieldErrors.gst
    ) {
      if (editId) {
        dispatch(CompanyUpdate(editId, Company) as any).then((res: any) => {
          setFormData({
            companyimg: companyList.companyimg,
            company_name: companyList.company_name,
            company_address: companyList.company_address,
            city: companyList.city,
            state: companyList.state,
            postal_code: companyList.postal_code,
            company_mobile: companyList.company_mobile,
            company_email: email,
            pan_no: companyList.pan_no,
            gst: companyList.gst,
          });
          fetchData();
          reset();
          setEditId("");
          toast.success("Update SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        });
      } else {
        dispatch(CompanySave(Company) as any).then((res: any) => {
          if (res && res.type === "COMPANY_POST_SUCCESS") {
            fetchData();
            reset();
            toast.success("Saved SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
          }
        });
      }
    }
  };

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const fieldName = name as keyof FieldErrors;

    /*********  validation logic  ************/
    let error = "";
    if (fieldName === "postal_code" && !value.match(/^[0-9]{6}$/) && value) {
      error = "Invalid postal code";
    } else if (
      fieldName === "company_mobile" &&
      !value.match(/^[0-9]{10}$/) &&
      value
    ) {
      error = "Invalid Mobile No";
    } else if (
      fieldName === "pan_no" &&
      value &&
      !value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
    ) {
      setValue("pan_no", value.toUpperCase());
      error = "Invalid PAN number";
    } else if (
      fieldName === "gst" &&
      !value.match(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      ) &&
      value
    ) {
      setValue("gst", value.toUpperCase());
      error = "Invalid GST number";
    }

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleTrimStart = (event: any) => {
    const { name, value } = event.target;
    if (name == "company_name") {
      setValue('company_name', value.trimStart());
    }
    if (name == "company_address") {
      setValue('company_address', value.trimStart());
    }
    if (name == "city") {
      setValue('city', value.trimStart());
    }
    if (name == "state") {
      setValue('state', value.trimStart());
    }

  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="mx-3 mb-4 rounded-lg">
          <legend className="p-4  font-bold text-[#045545]  text-lg    ml-2">
            Company
          </legend>

          <div className="rounded-xl  py-3 mb-3  bg-[#E1E8E7]">
            <div className="flex w-full  flex-col sm:flex-row px-4">
              <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2    ">
                <div className="flex flex-col mb-6 ">
                  <div className="relative 2xl:w-full xl:w-full lg:w-full md:w-9/12">
                    <label
                      style={{
                        fontFamily: "poppins",
                        height: "120px",
                        overflow: "hidden",
                      }}
                      className="object-cover   pb-5 flex flex-col items-center px-1 cursor-pointer hover:bg-blue "
                    >
                      <input
                        disabled={
                          editId || companyList?.length === 0 ? false : true
                        }
                        type="file"
                        accept="image/*"
                        ref={inputRef2}
                        className="hidden"
                        onChange={(e) => handleFileChange(e)}
                      />
                      {formData.companyimg ? (
                        <>
                          <div>
                            <ClearIcon
                              sx={{ marginLeft: "10rem" }}
                              onClick={(e) => {
                                if (editId || companyList?.length === 0) {
                                  ClearFile(e);
                                }
                              }}
                            />
                          </div>

                          <img
                            className="w-[150px] h-[95px] inline-block shrink-0 rounded-lg "
                            alt="logo"
                            style={{ pointerEvents: "none" }}
                            src={formData.companyimg}
                          />
                        </>
                      ) : (
                        <>
                          <div
                            className="object-cover   pb-5 flex flex-col items-center px-1 bg-white text-blue rounded-lg shadow-md tracking-wide border border-blue cursor-pointer hover:bg-blue "
                          >
                            <svg
                              className="w-10 h-10 mt-4"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span className="mt-1 text-base leading-normal">
                              Add a Business Logo
                            </span>
                          </div>

                        </>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      marginTop: "-3.5px",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span>Company Name</span>
                  </label>
                  <div className="relative">
                    <input
                      readOnly={
                        editId || companyList?.length === 0 ? false : true
                      }
                      type="text"
                      placeholder="Company Name"
                      className={`${errors?.company_name ? "border-rose-600" : ""
                        } border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(editId || companyList?.length === 0 ? false : true)
                          ? "cursor-not-allowed"
                          : ""
                        }`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("company_name", {
                        required: "Company Name is required",
                        onChange: handleTrimStart,
                      })}

                    />
                    {errors?.company_name && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {errors?.company_name?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    Company Address
                  </label>
                  <div className="relative">
                    <input
                      readOnly={
                        editId || companyList?.length === 0 ? false : true
                      }
                      type="text"
                      placeholder=" Address (Optional)"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(editId || companyList?.length === 0 ? false : true)
                        ? "cursor-not-allowed"
                        : ""
                        }`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("company_address", {
                        onChange: handleTrimStart,
                      })}

                    />
                  </div>
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    City
                  </label>
                  <div className="relative">
                    <input
                      readOnly={
                        editId || companyList?.length === 0 ? false : true
                      }
                      type="text"
                      placeholder="City (Optional)"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(editId || companyList?.length === 0 ? false : true)
                        ? "cursor-not-allowed"
                        : ""
                        }`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("city", {
                        onChange: handleTrimStart,
                      })}

                    />
                  </div>
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    State
                  </label>
                  <div className="relative">
                    <input
                      readOnly={
                        editId || companyList?.length === 0 ? false : true
                      }
                      placeholder="State (Optional)"
                      type="text"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full  ${(editId || companyList?.length === 0 ? false : true)
                        ? "cursor-not-allowed"
                        : ""
                        }`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("state", {
                        onChange: handleTrimStart,
                      })}

                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    Postal Code
                  </label>
                  <div className="relative">
                    <input
                      readOnly={
                        editId || companyList?.length === 0 ? false : true
                      }
                      placeholder="Postal Code (Optional)"
                      className={`${fieldErrors?.postal_code ? "border-rose-600" : ""
                        }
                        border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(editId || companyList?.length === 0 ? false : true)
                          ? "cursor-not-allowed"
                          : ""
                        }`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      type=""
                      maxLength={6}
                      {...register("postal_code")}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        setValue("postal_code", value);
                        handleInputChange(e);
                      }}
                    />
                    {fieldErrors.postal_code && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {fieldErrors.postal_code}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    Mobile No
                  </label>
                  <div className="relative">
                    <input
                      readOnly={
                        editId || companyList?.length === 0 ? false : true
                      }
                      type="text"
                      placeholder="Mobile (Optional)"
                      className={`${fieldErrors?.company_mobile ? "border-rose-600" : ""
                        }  border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(editId || companyList?.length === 0 ? false : true)
                          ? "cursor-not-allowed"
                          : ""
                        }`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      maxLength={10}
                      {...register("company_mobile")}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ''); // Replace non-numeric characters
                        setValue("company_mobile", value); // Update the form state with the cleaned value
                        handleInputChange(e);
                      }}
                    />
                    {fieldErrors.company_mobile && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {fieldErrors.company_mobile}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    Company E-Mail <span className="text-red-600">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Email (Optional)"
                      readOnly={true}
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(editId || companyList?.length === 0 ? false : true)
                        ? "cursor-not-allowed"
                        : ""
                        }`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("company_email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                        required: "Company email is required",
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    PAN No
                  </label>
                  <div className="relative">
                    <input
                      readOnly={
                        editId || companyList?.length === 0 ? false : true
                      }
                      type="text"
                      placeholder="Pan No (Optional)"
                      className={`${fieldErrors?.pan_no ? "border-rose-600" : ""
                        } border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(editId || companyList?.length === 0 ? false : true)
                          ? "cursor-not-allowed"
                          : ""
                        }`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("pan_no")}
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                        handleInputChange(e);
                      }}
                    />
                    {fieldErrors.pan_no && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {fieldErrors.pan_no}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    GST
                  </label>
                  <div className="relative">
                    <input
                      readOnly={
                        editId || companyList?.length === 0 ? false : true
                      }
                      type="text"
                      placeholder="GST (Optional)"
                      className={`${fieldErrors?.gst ? "border-rose-600" : ""
                        } border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(editId || companyList?.length === 0 ? false : true)
                          ? "cursor-not-allowed"
                          : ""
                        }`}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("gst")}
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                        handleInputChange(e);
                      }}
                    />
                    {fieldErrors.gst && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {fieldErrors.gst}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col  ">
                  <div className="relative"></div>
                </div>
              </div>
              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded">
                  <button
                    type="button"
                    onClick={handleCancelClick}
                    className={`${companyList?.length > 0 && !editId
                      ? "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] opacity-50  cursor-not-allowed disabled"
                      : "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                      }`}
                    style={{
                      backgroundColor: "#008065",
                      fontSize: "14px",
                      fontFamily: "poppins",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40">
                  <button
                    type="submit"
                    className={`${companyList?.length > 0 && !editId
                      ? "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] opacity-50  cursor-not-allowed disabled"
                      : "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                      }`}
                    style={{
                      backgroundColor: "#008065",
                      fontSize: "14px",
                      fontFamily: "poppins",
                    }}
                  >
                    {editId ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>

      <CompantTable
        companyList={companyList}
        handleEditSection={handleEditSection}
        handleDeleteSection={handleDeleteSection}
      />
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirmdelete}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default CompanyTab;
