
import { FONTFAMILY_GET_FAIL, FONTFAMILY_GET_REQUEST, FONTFAMILY_GET_RESET, FONTFAMILY_GET_SUCCESS, FONTFAMILY_POST_FAIL, FONTFAMILY_POST_REQUEST, FONTFAMILY_POST_RESET, FONTFAMILY_POST_SUCCESS } from "../constants/FontFamilyConstants";


const initialSaveState = {
    FontPost: null,
};

export const fontPostReducer = (state = initialSaveState, action: any) => {
    switch (action.type) {
        case FONTFAMILY_POST_REQUEST:
            return { loading: true };
        case FONTFAMILY_POST_SUCCESS:
            return { loading: false, Fontfamily: action.payload };
        case FONTFAMILY_POST_FAIL:
            return { loading: false, error: action.payload };
        case FONTFAMILY_POST_RESET:
            return {};
        default:
            return state;
    }
}

export const GetFontDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case FONTFAMILY_GET_REQUEST:
            return { loading: true };
        case FONTFAMILY_GET_SUCCESS:
            return { loading: false, fontDetail: action.payload };
        case FONTFAMILY_GET_FAIL:
            return { loading: false, error: action.payload };
        case FONTFAMILY_GET_RESET:
            return {};
        default:
            return state;
    }
};