import api from "../axiosConfig";
import {
    PAYMENTRECEIVE_LIST_FAIL,
    PAYMENTRECEIVE_LIST_REQUEST,
    PAYMENTRECEIVE_LIST_SUCCESS,
    PAYMENTRECEIVE_DELETE_FAIL,
    PAYMENTRECEIVE_DELETE_REQUEST,
    PAYMENTRECEIVE_DELETE_SUCCESS,
    PAYMENTRECEIVE_POST_FAIL,
    PAYMENTRECEIVE_POST_REQUEST,
    PAYMENTRECEIVE_POST_SUCCESS,
    PAYMENTRECEIVE_EDIT_FINDONE_REQUEST,
    PAYMENTRECEIVE_EDIT_FINDONE_SUCCESS,
    PAYMENTRECEIVE_EDIT_FINDONE_FAIL,
    PAYMENTRECEIVE_UPDATE_REQUEST,
    PAYMENTRECEIVE_UPDATE_SUCCESS,
    PAYMENTRECEIVE_UPDATE_FAIL,
} from "../constants/paymentReceiveConstants";

export const paymentReceivePost = (value: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: PAYMENTRECEIVE_POST_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();
        const { data } = await api.post(`/api/paymentreceive/save`, value, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: PAYMENTRECEIVE_POST_SUCCESS, payload: data });
    } catch (error: any) {

        return dispatch({
            type: PAYMENTRECEIVE_POST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


export const paymentReceiveGetList =
    () => async (dispatch: any, getState: any) => {
        dispatch({ type: PAYMENTRECEIVE_LIST_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.get(`/api/paymentreceiveget/${token?._id}`);

            return dispatch({ type: PAYMENTRECEIVE_LIST_SUCCESS, payload: data });
        } catch (error: any) {
            dispatch({
                type: PAYMENTRECEIVE_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };



export const paymentReceiveDelete = (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: PAYMENTRECEIVE_DELETE_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();

        const { data } = await api.delete(`/api/paymentreceivedelete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: PAYMENTRECEIVE_DELETE_SUCCESS, payload: data });

    } catch (error: any) {
        dispatch({
            type: PAYMENTRECEIVE_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const paymentReceiveEditFindOne =
    (id: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: PAYMENTRECEIVE_EDIT_FINDONE_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.get(`/api/paymentreceiveeditfindone/${id}`, {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            });
            return dispatch({
                type: PAYMENTRECEIVE_EDIT_FINDONE_SUCCESS,
                payload: data,
            });
        } catch (error: any) {
            dispatch({
                type: PAYMENTRECEIVE_EDIT_FINDONE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const paymentReceiveUpdate =
    (id: any, paymentReceiveUpdatedata: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: PAYMENTRECEIVE_UPDATE_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.put(
                `/api/paymentreceiveupdate/put/${id}`,
                paymentReceiveUpdatedata,
                {
                    headers: { Authorization: `Bearer ${token?.tocken}` },
                },
            );
            return dispatch({ type: PAYMENTRECEIVE_UPDATE_SUCCESS, payload: data });
        } catch (error: any) {
            dispatch({
                type: PAYMENTRECEIVE_UPDATE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };
