import { ErrorOutlineSharp } from "@mui/icons-material";
import { Container, CssBaseline, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";

const Paper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  minHeight: "80vh",
  alignItems: "center",
  justifyContent: "center",
}));

export default function ErrorPage(props: any) {

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper>
        <div>
          <ErrorOutlineSharp fontSize="large" />
        </div>
        <Typography component="h1" variant="h5">
          Not Found
        </Typography>
      </Paper>
    </Container>
  );
}
