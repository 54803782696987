/* eslint-disable eqeqeq */
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "../pages/commen/ConfirmationPopup";
import { fixedAssetAll, fixedAssetDelete, getPurchaseFAandFixedAsset } from "../redux/actions/fixedAssetsAction";
import { purchaseFixedAssetDelete } from "../redux/actions/purchaseFixedAssetAction";
import { salesFixedAssetDelete } from "../redux/actions/salesFixedAssetAction";


const FixedAssetsReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [deleteid, setDeleteId] = useState([] as any);
    const [purchaseFAdeleteid, purchaseFASetDeleteId] = useState([] as any);
    const [salesFAdeleteid, setSalesFADeleteId] = useState([] as any);
    const [fixedAssetDetails, setFixedAssetDetails] = useState([] as any);
    const [hasPurchaseFA, setHasPurchaseFA] = useState(false);
    const [hasSalesFA, setHasSalesFA] = useState(false);
    const [assetList, setAssetList] = useState([] as any);
    const fetchData = () => {
        dispatch(fixedAssetAll() as any).then((response: any) => {
            const data = Array.isArray(response?.payload) ? response.payload : [];
            setFixedAssetDetails(data);
        });
    };
    const [deleteInfo, setDeleteInfo] = useState<{ transactionType: string; deleteId: string | null }>({
        transactionType: "",
        deleteId: null,
    });

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // *******************sort*********************
    useEffect(() => {
        const purchaseFA = assetList.some(
            (item: any) => item.transactionType === "Purchase FA"
        );
        setHasPurchaseFA(purchaseFA)
        const SalesFA = assetList.some(
            (item: any) => item.transactionType === "Sales FA"
        );
        setHasSalesFA(SalesFA)

    }, [assetList]);
    const handleChange = (data: any) => {
        dispatch(getPurchaseFAandFixedAsset(data) as any).then((res: any) => {
            if (res) {
                setAssetList(res.payload);
            }
        });

    }

    const handleCreateInvoice = () => {
        navigate("/app/fixedassets");
    };


    const handleEdit = (params: any) => {
        if (params.transactionType === "Purchase FA") {
            navigate(`/app/purchaseFixedAssetentry/${params._id.$oid}`);
        } else if (params.transactionType === "Opening Assets") {
            navigate(`/app/fixedassets/${params._id.$oid}`);
        } else if (params.transactionType === "Sales FA") {
            navigate(`/app/salesFixedAssetPage/${params._id.$oid}`);
        }


    };

    const rows = assetList?.map((item: any, index: any) => ({
        id: uuidv4(),
        sl_No: index + 1,
        ...item,
    }));


    const handleDelete = (params: any) => {
        if (params.transactionType === "Purchase FA") {
            const purchaseFA = assetList.some(
                (item: any) => item.transactionType === params.transactionType
            );
            setHasPurchaseFA(purchaseFA)
            setHasSalesFA(false)
            setShowConfirmation(true);
            purchaseFASetDeleteId(params?._id.$oid);
            setDeleteInfo({
                transactionType: params.transactionType,
                deleteId: params.asset_id,
            });
        } else if (params.transactionType === "Sales FA") {
            const SalesFA = assetList.some(
                (item: any) => item.transactionType === params.transactionType
            );

            setHasSalesFA(SalesFA)
            setHasPurchaseFA(false)
            setShowConfirmation(true);
            setSalesFADeleteId(params?._id.$oid);
            setDeleteInfo({
                transactionType: params.transactionType,
                deleteId: params._id.$oid,
            });
        } else if (params.transactionType === "Opening Assets" && !hasPurchaseFA && !hasSalesFA) {
            setShowConfirmation(true);
            setDeleteId(params?._id.$oid);
            setDeleteInfo({
                transactionType: params.transactionType,
                deleteId: params._id.$oid,
            });
        } else {
            alert("Cannot delete Opening Assets when Purchase FA Assets exist.");
        }
    };

    const handleConfirm = () => {
        if (deleteInfo.transactionType === "Opening Assets") {
            dispatch(fixedAssetDelete(deleteid) as any).then((res: any) => {
                if (res) {
                    fetchData();
                    setDeleteId(null);
                    setShowConfirmation(false);
                }
            });
        } else if (deleteInfo.transactionType === "Purchase FA") {
            dispatch(purchaseFixedAssetDelete(purchaseFAdeleteid) as any).then((res: any) => {
                if (res) {
                    purchaseFASetDeleteId(null);
                    setShowConfirmation(false);
                }
            });
        } else if (deleteInfo.transactionType === "Sales FA") {
            dispatch(salesFixedAssetDelete(salesFAdeleteid) as any).then((res: any) => {
                if (res) {
                    setSalesFADeleteId(null);
                    setShowConfirmation(false);
                }
            });
        }
    };
    const handleCancel = () => {
        setShowConfirmation(false);
    };




    const columns: GridColDef[] = [
        {
            field: "date",
            headerName: "Date",
            width: 160,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.date ? params.row.date : "N/A"}</>
            ),
        },
        {
            field: "transactionType",
            headerName: "Txn Type",
            width: 160,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.transactionType ? params.row.transactionType : "N/A"}</>
            ),
        },
        {
            field: "party",
            headerName: "Party",
            width: 160,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.toatalAmount ? params.row.toatalAmount : "N/A"}</>
            ),
        },
        {
            field: "paymentType",
            headerName: "Payment Type",
            width: 160,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.paymentType ? params.row.paymentType : "N/A"}</>
            ),
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 160,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.amount ? params.row.amount : "N/A"}</>
            ),
        },

        {
            field: "action",
            headerName: "Actions",
            editable: false,
            width: 160,
            renderCell: (params: any) => (
                <>
                    <IconButton aria-label="edit"
                        onClick={() => handleEdit(params.row)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5 cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                        </svg>
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(params.row)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5  cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                        </svg>
                    </IconButton>
                </>
            ),
        },
    ];



    return (
        <div className="flex flex-row">
            <div className="bg-[white] h-screen w-full py-3 ">
                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3  text-[#008065] ">
                                    Fixed Assets
                                </th>
                                <th scope="col" className="px-6 py-3  text-[#008065] ">
                                    Current Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {fixedAssetDetails.map((item: any, index: any) => (
                                <tr key={index}
                                    onClick={() => handleChange(item._id.$oid)} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {item.asset_name}
                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {item.totalAmount}
                                    </th>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>

                <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                    <div className="p-4  font-bold text-[#008065]  ml-2">Fixed Assets - Report</div>
                    <div className="p-4  font-bold text-[#008065]  ml-2">
                        <button
                            type="button"
                            className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                            style={{ backgroundColor: "#008065" }}
                            onClick={handleCreateInvoice}
                        >
                            <FiPlusCircle className="h-5 w-5" />
                            <span className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`} >Add Fixed Assets</span>
                        </button></div>


                </div>
                <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 ml-3 w-[86%] md:w-[93%] lg:w-[97%] xl:w-[96.5%] 2xl:w-[96.6%]">
                    <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row">
                    </div>
                    <Box
                        sx={{
                            height: 400,
                            width: "100%",
                            overflowX: "auto",
                            overflowY: "auto",
                        }}
                    >
                        <DataGrid
                            autoHeight
                            sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: 500,
                                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                                {
                                    backgroundColor: "#fff",
                                    color: "#008060 !important",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                },
                                "& .MuiDataGrid-checkboxInput": {
                                    color: "#008060 !important",
                                },
                                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                    fontWeight: 600,
                                    fontSize: "14px",
                                },
                                ".MuiDataGrid-cell.MuiDataGrid-cell--textRight": {
                                    fontWeight: 600,
                                    fontSize: "14px",
                                },
                                "@media (min-width: 2555px)": {
                                    ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
                                    {
                                        minWidth: "265px !important",
                                    },
                                },
                            }}
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 25, 50]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </Box>
                </div>
                {showConfirmation && (
                    <ConfirmationPopup
                        message="Are you sure you want to proceed?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )}
            </div>
        </div >

    );
};

export default FixedAssetsReport;
