import api from "../axiosConfig";
import { SALES_FIXED_ASSET_DELETE_FAIL, SALES_FIXED_ASSET_DELETE_REQUEST, SALES_FIXED_ASSET_DELETE_SUCCESS, SALES_FIXED_ASSET_FIND_ALL_FAIL, SALES_FIXED_ASSET_FIND_ALL_REQUEST, SALES_FIXED_ASSET_FIND_ALL_SUCCESS, SALES_FIXED_ASSET_FIND_ONE_FAIL, SALES_FIXED_ASSET_FIND_ONE_REQUEST, SALES_FIXED_ASSET_FIND_ONE_SUCCESS, SALES_FIXED_ASSET_POST_FAIL, SALES_FIXED_ASSET_POST_REQUEST, SALES_FIXED_ASSET_POST_SUCCESS, SALES_FIXED_ASSET_UPDATE_FAIL, SALES_FIXED_ASSET_UPDATE_REQUEST, SALES_FIXED_ASSET_UPDATE_SUCCESS } from "../constants/salesFixedAssetConstants";


export const SalesFixedAssetSave = (prod: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: SALES_FIXED_ASSET_POST_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.post(
        `/api/salesFixedAsset/salesFixedAssetSave`,
        prod,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        }
      );
      return dispatch({ type: SALES_FIXED_ASSET_POST_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: SALES_FIXED_ASSET_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }


  export const SalesFixedAssetGetList =
    () => async (dispatch: any, getState: any) => {
        dispatch({ type: SALES_FIXED_ASSET_FIND_ALL_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.get(`/api/salesFixedget/${token?._id}`);

            return dispatch({ type: SALES_FIXED_ASSET_FIND_ALL_SUCCESS, payload: data });
        } catch (error: any) {
            dispatch({
                type: SALES_FIXED_ASSET_FIND_ALL_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

    export const salesFixedAssetDelete = (id: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: SALES_FIXED_ASSET_DELETE_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
    
            const { data } = await api.delete(`/api/salesFixedAssetDelete/${id}`, {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            });
            return dispatch({ type: SALES_FIXED_ASSET_DELETE_SUCCESS, payload: data });
    
        } catch (error: any) {
            dispatch({
                type: SALES_FIXED_ASSET_DELETE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

    export const salesFixedAssetFindOne =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: SALES_FIXED_ASSET_FIND_ONE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/salesFixedAsset/salesFixedAssetfind-one/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({
        type: SALES_FIXED_ASSET_FIND_ONE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: SALES_FIXED_ASSET_FIND_ONE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
    

  export const salesFixedAssetUpdate =
  (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: SALES_FIXED_ASSET_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/salesFixedAsset/salesFixedAssetput/${id}`,
        externalInvoice,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        },
      );
      return dispatch({ type: SALES_FIXED_ASSET_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: SALES_FIXED_ASSET_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };