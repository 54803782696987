import { toWords } from 'number-to-words';
import NumberFormat from "../commen/NumberFormat";

const PrintQuotationService = ({ ServiceInvoice, componentRef, }: any) => {


    const totalAmount = ServiceInvoice.toatalAmount;
    const roundedtotalAmount = Math.round(totalAmount);
    const totalAmountInWords = Number.isFinite(roundedtotalAmount) ? toWords(roundedtotalAmount) : 'Invalid amount';
    return (
        <div ref={componentRef}>
            <div style={{ breakBefore: "page" }}></div>
            {/*   image and clien information                */}
            <div className="grid grid-cols-6">
                {ServiceInvoice.imageData ? (
                    <div className="col-start-1 col-end-2">
                        <div className=" font-title  sm:text-left ">
                            <label
                                style={{ fontFamily: "poppins" }}
                                className="   items-start  text-blue      "
                            >
                                <input type="file" accept="image/*" className="hidden" />
                                <img
                                    className=" pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 "
                                    src={ServiceInvoice.imageData}
                                    alt="logonew "
                                />
                            </label>
                        </div>
                    </div>
                ) : null}
                <div className="col-start-2 col-span-4 ">
                    <div className=" text-center  pt-7 text-4xl ">
                        {ServiceInvoice.companyName}
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center">
                <div className=" text-center">
                    <div className="mt-1">
                        <span>
                            {ServiceInvoice.companyAddress ? (
                                <span>Address: {ServiceInvoice.companyAddress},</span>) : null}
                            {ServiceInvoice.companyCity ? (
                                <span>{ServiceInvoice.companyCity},</span>
                            ) : null}

                            {ServiceInvoice.companyState ? (
                                <span>{ServiceInvoice.companyState}-</span>) : null}
                            {ServiceInvoice.companyPostalCode ? (
                                <span>{ServiceInvoice.companyPostalCode},</span>) : null}
                            {ServiceInvoice.companygst ? (
                                <span> Gstn:
                                    {ServiceInvoice.companygst}</span>) : null}

                        </span>
                    </div>
                    <div>
                        <span>
                            {ServiceInvoice.companyPan ? (
                                <span>PAN: {ServiceInvoice.companyPan},</span>
                            ) : null}
                            {ServiceInvoice.companyEmail ? (
                                <span>Email: <span className="lowercase">{ServiceInvoice.companyEmail},</span></span>) : null} {ServiceInvoice.companyMobile ? (<span>Mobile: {ServiceInvoice.companyMobile},</span>) : null}
                        </span>
                    </div>
                </div>
            </div>
            <div className="border "></div>
            {/*        *******************   invoice name                */}

            <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
                <div className="justify-center flex flex-row relative ">
                    <input
                        readOnly
                        className="w-11/12 rounded-[7px] text-center xs:w-1/4  focus:outline-none truncate"
                        autoComplete="nope"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "34px",
                            fontWeight: 700,
                        }}
                        id="input_focus"
                        value={ServiceInvoice.invoicehead}
                    />
                    {/* </h1> */}
                </div>
                {/*     client details           */}
                <div className=" px-8 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
                    <div className="flex-1 md:mx-2">
                        <div className="flex flex-col sm:flex-row pt-3  justify-between">
                            <div className="">
                                <div className="flex flex-row  items-center mb-2 ">
                                    <div
                                        className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                        style={{
                                            color: "#008065",
                                            fontFamily: "poppins",

                                            fontWeight: 700,
                                        }}
                                    >
                                        Client Details (Shipped To)
                                    </div>
                                </div>
                                {ServiceInvoice.clientName ? (
                                    <>

                                        <div
                                            className="flex justify-between"
                                            style={{ maxWidth: "500px", width: "325px" }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                    verticalAlign: "top",
                                                }}
                                                className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                Name
                                            </div>
                                            <div className="">
                                                <input
                                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                                    readOnly
                                                    value={ServiceInvoice.clientName}
                                                    style={{
                                                        fontFamily: "poppins",

                                                        fontWeight: 400,
                                                        width: "210px",
                                                        verticalAlign: "top",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                {/* {ServiceInvoice.clientAddress ? (
                                    <>
                                        <div
                                            className="flex justify-between"
                                            style={{ maxWidth: "500px", width: "325px" }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                    verticalAlign: "top",
                                                }}
                                                className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                Address
                                            </div>
                                            <div className="">
                                                <input
                                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                                    readOnly
                                                    value={ServiceInvoice.clientAddress}
                                                    style={{
                                                        fontFamily: "poppins",

                                                        fontWeight: 400,
                                                        width: "210px",
                                                        verticalAlign: "top",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null} */}
                                {/* {ServiceInvoice.clientCity ? (
                                    <>
                                        <div
                                            className="flex justify-between"
                                            style={{ maxWidth: "500px", width: "325px" }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                    verticalAlign: "top",
                                                }}
                                                className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                City
                                            </div>
                                            <div className="">
                                                <input
                                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                                    readOnly
                                                    value={ServiceInvoice.clientCity}
                                                    style={{
                                                        fontFamily: "poppins",

                                                        fontWeight: 400,
                                                        width: "210px",
                                                        verticalAlign: "top",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null} */}
                                {/* {ServiceInvoice.clientState ? (
                                    <>
                                        <div
                                            className="flex justify-between "
                                            style={{ maxWidth: "500px", width: "325px" }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                    verticalAlign: "top",
                                                }}
                                                className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                State
                                            </div>
                                            <div className="">
                                                <input
                                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                                    readOnly
                                                    value={ServiceInvoice.clientState}
                                                    style={{
                                                        fontFamily: "poppins",

                                                        fontWeight: 400,
                                                        width: "210px",
                                                        verticalAlign: "top",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null} */}
                                {/* {ServiceInvoice.clientPostalCode ? (
                                    <>
                                        <div
                                            className="flex justify-between "
                                            style={{ maxWidth: "500px", width: "325px" }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                    verticalAlign: "top",
                                                }}
                                                className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                Postal Code
                                            </div>
                                            <div className="">
                                                <input
                                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                                    readOnly
                                                    value={ServiceInvoice.clientPostalCode}
                                                    style={{
                                                        fontFamily: "poppins",

                                                        fontWeight: 400,
                                                        width: "210px",
                                                        verticalAlign: "top",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null} */}
                                {ServiceInvoice.clientMobile ? (
                                    <>
                                        <div
                                            className="flex justify-between "
                                            style={{ maxWidth: "500px", width: "325px" }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                    verticalAlign: "top",
                                                }}
                                                className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                Mobile
                                            </div>
                                            <div className="">
                                                <input
                                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                                    readOnly
                                                    value={ServiceInvoice.clientMobile}
                                                    style={{
                                                        fontFamily: "poppins",

                                                        fontWeight: 400,
                                                        width: "210px",
                                                        verticalAlign: "top",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {ServiceInvoice.clientEmail ? (
                                    <>
                                        <div
                                            className="flex justify-between "
                                            style={{ maxWidth: "500px", width: "325px" }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                    verticalAlign: "top",
                                                }}
                                                className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                Email
                                            </div>
                                            <div className="w-full">
                                                <input
                                                    className="focus:outline-none text-xs sm:pl-20 lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-sm "
                                                    readOnly
                                                    value={ServiceInvoice?.clientEmail}
                                                    style={{
                                                        fontFamily: "poppins",

                                                        fontWeight: 400,
                                                        width: "500px",
                                                        verticalAlign: "top",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {ServiceInvoice.clientPan ? (
                                    <>

                                        <div
                                            className="flex justify-between "
                                            style={{ maxWidth: "500px", width: "325px" }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                    verticalAlign: "top",
                                                }}
                                                className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                PAN Number
                                            </div>
                                            <div className="">
                                                <input
                                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                                    readOnly
                                                    value={ServiceInvoice.clientPan}
                                                    style={{
                                                        fontFamily: "poppins",

                                                        fontWeight: 400,
                                                        width: "210px",
                                                        verticalAlign: "top",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {ServiceInvoice.clientgst ? (
                                    <>
                                        <div
                                            className="flex justify-between "
                                            style={{ maxWidth: "500px", width: "325px" }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                    verticalAlign: "top",
                                                }}
                                                className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                            >
                                                GST
                                            </div>
                                            <div className="">
                                                <input
                                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                                    readOnly
                                                    value={ServiceInvoice.clientgst}
                                                    style={{
                                                        fontFamily: "poppins",

                                                        fontWeight: 400,
                                                        width: "210px",
                                                        verticalAlign: "top",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>

                            <div className="">
                                <div className="flex flex-row items-center justify-between mb-2 mr-8">
                                    <div
                                        style={{
                                            color: "#008065",
                                            fontFamily: "poppins",

                                            fontWeight: 700,
                                        }}
                                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    >
                                        Quotation No
                                    </div>
                                    <div className="font-title flex-1 text-right ">
                                        <input
                                            readOnly
                                            value={ServiceInvoice.invoiceNo}
                                            style={{
                                                fontFamily: "poppins",
                                            }}
                                            className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full  block focus:outline-none "
                                        />
                                    </div>
                                    <div></div>
                                </div>
                                {ServiceInvoice.invoicedate ? (
                                    <>
                                        <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                                            <div
                                                style={{
                                                    color: "#008065",
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                }}
                                                className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                            >
                                                Quotation Date
                                            </div>
                                            <div className="font-title flex-1 text-right">
                                                <input
                                                    readOnly
                                                    value={ServiceInvoice.invoicedate}
                                                    style={{
                                                        fontFamily: "poppins",
                                                    }}
                                                    className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full   focus:outline-none "
                                                ></input>
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                ) : null}
                                {ServiceInvoice.poNumber ? (
                                    <>
                                        <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                                            <div
                                                style={{
                                                    color: "#008065",
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                }}
                                                className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                            >
                                                PO No
                                            </div>
                                            <div className="font-title flex-1 text-right">
                                                <input
                                                    readOnly
                                                    value={ServiceInvoice.poNumber}
                                                    style={{
                                                        fontFamily: "poppins",
                                                    }}
                                                    className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full   focus:outline-none "
                                                ></input>
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                ) : null}
                                {ServiceInvoice.poDate ? (
                                    <>
                                        <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                                            <div
                                                style={{
                                                    color: "#008065",
                                                    fontFamily: "poppins",

                                                    fontWeight: 700,
                                                }}
                                                className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                            >
                                                PO Date
                                            </div>
                                            <div className="font-title flex-1 text-right">
                                                <input
                                                    readOnly
                                                    value={ServiceInvoice.poDate}
                                                    style={{
                                                        fontFamily: "poppins",
                                                    }}
                                                    className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full   focus:outline-none "
                                                ></input>
                                            </div>
                                            <div></div>
                                        </div>
                                    </>
                                ) : null}

                            </div>

                        </div>
                    </div>
                </div>




                {/********************************** * subtotal *******************************/}
                <div className="px-8 mt-3">
                    <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white bg-[#008065]">
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className=" sm:w-1/12  text-left "
                        >
                            <span className="inline-block">S No</span>
                        </div>
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className=" sm:w-6/12 text-center sm:pr-11 "
                        >
                            <span className="inline-block">Products</span>
                        </div>
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className="w-40 sm:w-1/4 text-right pr-8 "
                        >
                            <span className="inline-block ">Hsn</span>
                        </div>
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className=" w-full sm:w-1/4 text-center  mr-18      "
                        >
                            Price
                        </div>

                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className="w-full sm:w-1/4 text-center sm:text-center   "
                        >
                            Qty
                        </div>
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className=" w-full sm:w-1/4 text-right 2xl:text-right   "
                        >
                            Total
                        </div>
                    </div>
                </div>
                {ServiceInvoice?.product?.map((product: any, index: number) => {
                    return (
                        <div
                            key={index}
                            className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row  xl:flex-row flex-col rounded-lg w-full  2xl:px-6 xl:px-6  md:px-7 px-4  py-1 items-center relative text-sm"
                        >
                            <span className="inline-block w-2/12  mb-1 sm:mb-0   2xl:px-3  xl:px-3 lg:px-3 md:px-3">
                                <input
                                    readOnly
                                    value={index + 1}
                                    style={{
                                        fontFamily: "poppins",
                                        fontWeight: 400,
                                    }}
                                    className="font-title  block w-full 2xl:text-left  2xl:ml-3 xl:ml-2 md:ml-3  xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                />
                            </span>
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontWeight: 700,
                                }}
                                className=" w-full sm:w-1/4 text-right   flex flex-row sm:block "
                            >
                                <span
                                    style={{
                                        marginBottom: "5px",
                                    }}
                                    className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                >
                                    Service
                                </span>
                                <span className="inline-block w-full  mb-1 sm:mb-0   2xl:px-3  xl:px-3 lg:px-3 md:px-3">
                                    <input
                                        readOnly
                                        value={product.service_name}
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 400,
                                        }}
                                        className="font-title  block w-full  2xl:ml-3 xl:ml-2 md:ml-3    sm:ml-7  text-left focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    />
                                </span>
                            </div>
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontWeight: 700,
                                }}
                                className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                            >
                                <span
                                    style={{
                                        marginBottom: "5px",
                                    }}
                                    className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                >
                                    HsnNo
                                </span>
                                <span className="inline-block mb-1 sm:mb-0 ">
                                    <input
                                        readOnly
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 400,
                                        }}
                                        value={product.hsn ? product.hsn : "N/A"}
                                        className="font-title  block text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    />
                                </span>
                            </div>
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontWeight: 700,
                                }}
                                className=" w-full sm:w-1/4 text-left   flex flex-row sm:block "
                            >
                                <span
                                    style={{
                                        marginBottom: "5px",
                                    }}
                                    className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                >
                                    Price
                                </span>
                                <span className="inline-block  mb-1 sm:mb-0 ">
                                    <input
                                        style={{
                                            fontFamily: "poppins",
                                            marginTop: "",
                                            fontWeight: 400,
                                        }}
                                        readOnly
                                        value={product.rate}
                                        type={"number"}
                                        className="font-title text-right pr-8 block w-full  "
                                    />
                                </span>
                            </div>
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontWeight: 700,
                                }}
                                className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                            >
                                <span
                                    style={{
                                        marginBottom: "5px",
                                    }}
                                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                >
                                    Quantity
                                </span>
                                <span className="inline-block  w-full mb-1 sm:mb-0">
                                    <input
                                        readOnly
                                        value={product.qty}
                                        type={"number"}
                                        style={{
                                            fontFamily: "poppins",
                                            marginTop: "",
                                            fontWeight: 400,
                                        }}
                                        className="font-title text-right pr-14 block w-full  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    />
                                </span>
                            </div>

                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                }}
                                className=" w-full sm:w-1/4 text-right  flex flex-row sm:block"
                            >
                                <span
                                    style={{
                                        marginBottom: "5px",
                                    }}
                                    className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                >
                                    Total
                                </span>
                                <span className="font-block w-1/2 sm:w-full  md:w-full  lg:w-full lg:pr-4 md:pr-4  sm:pr-7  lg:pr-4  xl:pr-5 ">
                                    <NumberFormat value={product.amount}></NumberFormat>
                                </span>
                            </div>
                        </div>
                    );
                })}
                {ServiceInvoice.subtotal ? (
                    <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                        >
                            subtotal
                        </div>

                        <div
                            className="font-block w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            <NumberFormat
                                value={ServiceInvoice.subtotal}

                            />
                        </div>
                    </div>
                ) : null}
                {ServiceInvoice?.tax
                    ?.sort((a: any, b: any) => {
                        const order = ['Discount', 'GST', 'CGST', 'SGST', 'IGST', 'OTHERS'];
                        return order.indexOf(a.taxName) - order.indexOf(b.taxName);
                    })
                    .map((tax: any, index: number) => {
                        if (tax.taxAmount !== '0.00' && tax.taxName !== "GST") {


                        return (
                            <div key={index}>

                                <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                                    <div
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                        className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                                    >
                                        {tax.taxName}
                                        {tax.taxName !== "Extra Fees" ? <span className="ml-2">@</span> : ''}
                                        {tax.taxName !== "Extra Fees" ? tax.taxPersent : ''}
                                        {tax.taxName !== "Extra Fees" ? (<span>%</span>) : ""}
                                    </div>

                                    <div
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                        }}
                                        className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1">
                                        <NumberFormat
                                            value={tax.taxAmount}

                                        />
                                    </div>
                                </div>


                            </div>
                        );
                    }
                    })}

                {/* ******************************TAX VALUES**************/}

                {ServiceInvoice.toatalAmount ? (
                    <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                        >
                            Total
                        </div>

                        <div style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1">
                            <NumberFormat
                                value={ServiceInvoice.toatalAmount}


                            />
                        </div>
                    </div>
                ) : null}

                {/*************************************Amount In Words *********************/}
                <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">

                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className="font-title w-1/2 sm:w-1/4 text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 whitespace-nowrap capitalize"
                    >
                        Rupees {totalAmountInWords} only.

                    </div>
                </div>

                {/************************************* bank details *********************/}

                {ServiceInvoice.bankNme ? (
                    <>
                        <div
                            className="ml-5 text-#008065"
                            style={{
                                color: "#008065",
                                fontFamily: "poppins",
                                fontWeight: 700,
                            }}
                        >
                            BANK DETAILS:
                        </div>
                        <div className="grid grid-cols-3 ">
                            <div className="ml-10">

                                <div
                                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 700,
                                    }}
                                >
                                    BankName
                                </div>
                            </div>
                            <div className="col-span-2 ...">
                                <input
                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                    readOnly
                                    value={ServiceInvoice.bankNme}
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 400,
                                        width: "210px",
                                        verticalAlign: "top",
                                    }}
                                />
                            </div>
                            <div className="ml-10">

                                <div
                                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 700,
                                    }}
                                >
                                    Account HolderName:
                                </div>
                            </div>
                            <div className="col-span-2 ...">
                                <input
                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                    readOnly
                                    value={ServiceInvoice?.accounHolder}
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 400,
                                        width: "210px",
                                        verticalAlign: "top",
                                    }}
                                />
                            </div>
                            <div className="ml-10">

                                <div
                                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 700,
                                    }}
                                >
                                    Account Number:
                                </div>
                            </div>
                            <div className="col-span-2 ...">
                                <input
                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                    readOnly
                                    value={ServiceInvoice.accounNumber}
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 400,
                                        width: "210px",
                                        verticalAlign: "top",
                                    }}
                                />
                            </div>
                            <div className="ml-10">

                                <div
                                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 700,
                                    }}
                                >
                                    IFSC code:
                                </div>
                            </div>
                            <div className="col-span-2 ...">
                                <input
                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                    readOnly
                                    value={ServiceInvoice.ifscCode}
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 400,
                                        width: "210px",
                                        verticalAlign: "top",
                                    }}
                                />
                            </div>
                        </div>
                    </>
                ) : null}

                {/***********************************UPI PAY*****************************************/}

                {ServiceInvoice.upiType ? (
                    <>
                        <div
                            className="ml-5"
                            style={{
                                color: "#008065",
                                fontFamily: "poppins",

                                fontWeight: 700,
                            }}
                        >
                            UPI PAYMENT:
                        </div>
                        <div className="grid grid-cols-3 ">
                            <div className="ml-10">

                                <div
                                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 700,
                                    }}
                                >
                                    UPI PAY
                                </div>
                            </div>
                            <div className="col-span-2 ...">
                                <input
                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                    readOnly
                                    value={ServiceInvoice.upiType}
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 400,
                                        width: "210px",
                                        verticalAlign: "top",
                                    }}
                                />
                            </div>
                            <div className="ml-10">

                                <div
                                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 700,
                                    }}
                                >
                                    UPI NUMBER
                                </div>
                            </div>
                            <div className="col-span-2 ...">
                                <input
                                    className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                                    readOnly
                                    value={ServiceInvoice.upiNumber}
                                    style={{
                                        fontFamily: "poppins",

                                        fontWeight: 400,
                                        width: "210px",
                                        verticalAlign: "top",
                                    }}
                                />
                            </div>
                        </div>
                    </>
                ) : null}

                {/**************************  term and condition ******************/}
                {ServiceInvoice.termsanddcon ? (
                    <>
                        <div
                            className="flex justify-between  ml-5 mr-5 "
                            style={{
                                color: "#008065",
                                fontFamily: "poppins",

                                fontWeight: 700,
                            }}
                        >
                            <label className="  text-base text-#008065 font-semibold">
                                Terms and Conditions:
                            </label>
                        </div>
                        <div className="mx-8 ">
                            <p className="sm:text-wrap lg:text-wrap md:text-wrap text-sm break-words break-all mr-4">

                                {ServiceInvoice.termsanddcon}
                            </p>
                        </div>
                    </>
                ) : null}
                {/**********************************Terms*********************************/}
                {ServiceInvoice.notes ? (
                    <>
                        <div
                            className="flex justify-between  ml-5 mr-5 "
                            style={{
                                color: "#008065",
                                fontFamily: "poppins",

                                fontWeight: 700,
                            }}
                        >
                            <label className="  text-base text-#008065 font-semibold">
                                Notes:
                            </label>
                        </div>
                        <div className="mx-8 ">
                            <p className="sm:text-wrap lg:text-wrap md:text-wrap text-sm break-words break-all mr-4">

                                {ServiceInvoice.notes}
                            </p>
                        </div>
                    </>
                ) : null}
                {/********************************signature*******************************/}
                {(ServiceInvoice?.signature || ServiceInvoice?.seal) ? (
                    <div className="flex flex-row justify-between">
                        <div className={ServiceInvoice?.signPostion === "R" ? "lg:order-2 xl:order-2 2xl:order-2 md:order-2 flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 w-1/4 2xl:w-1/3 items-left pl-8 justify-center w-full h-40 rounded-lg mt-2  " : "flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-left pl-8 w-full h-32  rounded-lg mt-2"}>
                            {ServiceInvoice?.signature && (<p className="font-semibold text-[#008065] pt-2">Signature:</p>)}
                            <div className="relative">
                                {ServiceInvoice?.signature ? (
                                    <>
                                        <img
                                            className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                                            alt="sign"
                                            src={
                                                ServiceInvoice?.signature
                                                    ? ServiceInvoice?.signature
                                                    : ServiceInvoice?.signature
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={ServiceInvoice?.sealPostion === "L" ? "lg:order-1 xl:order-1 2xl:order-1 md:order-1 flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/4 2xl:w-1/3 items-center pr-8 justify-center w-full h-40  mt-2" : " flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center pr-8 justify-center w-full h-32 mt-2 "}>
                            {ServiceInvoice?.seal && (<p className="font-semibold text-[#008065] pt-2">Seal:</p>)}
                            <div className="relative">
                                <label
                                    style={{ fontFamily: "poppins" }}
                                    className="object-cover  pb-2 flex flex-col items-center px-4    "
                                >
                                    {ServiceInvoice?.seal ? (
                                        <>
                                            <img
                                                className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                                                alt="sign"
                                                src={ServiceInvoice?.seal ? ServiceInvoice?.seal : ServiceInvoice?.seal}
                                            />
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </label>
                            </div>
                        </div>
                    </div>

                ) : null}
            </div>
        </div>
    )
}

export default PrintQuotationService
