import { CASHINHAND_POST_FAIL, CASHINHAND_POST_REQUEST, CASHINHAND_POST_RESET, CASHINHAND_POST_SUCCESS } from "../constants/CashInHandConstants";


export const GetCashInHandReducer = (state = {}, action: any) => {
    switch (action.type) {
        case CASHINHAND_POST_REQUEST:
            return { loading: true };
        case CASHINHAND_POST_SUCCESS:
            return { loading: false, cashList: action.payload };
        case CASHINHAND_POST_FAIL:
            return { loading: false, error: action.payload };
        case CASHINHAND_POST_RESET:
            return {};
        default:
            return state;
    }
};