import api from "../axiosConfig";
import {
  PAYMENT_DELETE_FAIL,
  PAYMENT_DELETE_REQUEST,
  PAYMENT_DELETE_SUCCESS,
  PAYMENT_GET_FAIL,
  PAYMENT_GET_REQUEST,
  PAYMENT_GET_SUCCESS,
  PAYMENT_PAGE_POST_FAIL,
  PAYMENT_PAGE_POST_REQUEST,
  PAYMENT_PAGE_POST_SUCCESS,
  PAYMENT_POST_FAIL,
  PAYMENT_POST_REQUEST,
  PAYMENT_POST_SUCCESS,
  PAYMENT_UPDATE_FAIL,
  PAYMENT_UPDATE_REQUEST,
  PAYMENT_UPDATE_SUCCESS,
} from "../constants/PaymentConstants";

export const PaymentSave = (pay: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PAYMENT_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.post(
      `/api/pay/save`,
      pay,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: PAYMENT_POST_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: PAYMENT_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const paymentGet = () => async (dispatch: any, getState: any) => {
  dispatch({ type: PAYMENT_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(`/api/pay/all`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PAYMENT_GET_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: PAYMENT_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const paymentUpdate =
  (id: any, payment: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: PAYMENT_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/pay/update/${id}`,
        payment,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        }
      );
      return dispatch({ type: PAYMENT_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: PAYMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const paymentDelete = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PAYMENT_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.delete(
      `/api/pay/delete/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: PAYMENT_DELETE_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: PAYMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const paymentpageInvoice = (paymentPage: any) => async (dispatch: any) => {
  dispatch({ type: PAYMENT_PAGE_POST_REQUEST });
  try {
    const { data } = await api.post(`/api/paymentpage/save`, paymentPage);
    return dispatch({ type: PAYMENT_PAGE_POST_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PAYMENT_PAGE_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
