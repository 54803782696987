import React, { useEffect, useState } from "react";
interface NumberFormatProps {
  value: number;
  setCurrency: any;
  root: any;
  currentCountryName: any;
}
const CurrenyFormat: React.FC<NumberFormatProps> = ({
  value,
  setCurrency,
  root,
  currentCountryName,
}: any) => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("₹");
  // const currency = typeof window !== 'undefined' ? sessionStorage.getItem("countryCode") : null;
  const currency = currentCountryName ? currentCountryName : null;
  // const currencyData = currency ? JSON.parse(currency) : null;
  const currencyData = currency ? currency : null;
  const currencySymbolMap: Record<string, string> = {
    AFN: "؋",
    EUR: "€",
    ALL: "L",
    DZD: "د.ج",
    USD: "$",
    AOA: "Kz",
    XCD: "$",
    ARS: "$",
    AMD: "֏",
    AWG: "ƒ",
    AUD: "$",
    AZN: "₼",
    BSD: "$",
    BHD: ".د.ب",
    BDT: "৳",
    BBD: "$",
    BYN: "Br",
    BZD: "$",
    XOF: "Fr",
    BMD: "$",
    BTN: "Nu.",
    BOB: "Bs.",
    BWP: "P",
    BRL: "R$",
    BND: "$",
    BGN: "лв",
    BIF: "Fr",
    KHR: "៛",
    XAF: "Fr",
    CAD: "$",
    CVE: "Esc",
    KYD: "$",
    CLP: "$",
    CNY: "¥",
    COP: "$",
    KMF: "Fr",
    CKD: "$",
    CRC: "₡",
    CUC: "$",
    ANG: "ƒ",
    CZK: "Kč",
    DKK: "kr",
    DJF: "Fr",
    DOP: "$",
    CDF: "FC",
    EGP: "£",
    ERN: "Nfk",
    SZL: "L",
    ETB: "Br",
    FKP: "£",
    FJD: "$",
    XPF: "₣",
    GMD: "D",
    GEL: "₾",
    GHS: "₵",
    GIP: "£",
    GTQ: "Q",
    GBP: "£",
    GNF: "Fr",
    GYD: "$",
    HTG: "G",
    HNL: "L",
    HKD: "$",
    HUF: "Ft",
    ISK: "kr",
    IDR: "Rp",
    IRR: "﷼",
    IQD: "ع.د",
    ILS: "₪",
    JMD: "$",
    JPY: "¥",
    JOD: "د.ا",
    KZT: "₸",
    KES: "Sh",
    INR: "₹",
    KWD: "د.ك",
    KGS: "с",
    LAK: "₭",
    OMR: "ر.ع.",
    LBP: "ل.ل",
    LSL: "L",
    LRD: "$",
    LYD: "ل.د",
    CHF: "Fr",
    MOP: "P",
    MGA: "Ar",
    MWK: "MK",
    MYR: "RM",
    MVR: ".ރ",
    MRU: "UM",
    MUR: "₨",
    MXN: "$",
    MDL: "L",
    MNT: "₮",
    MAD: "د.م.",
    MZN: "MT",
    MMK: "Ks",
    NAD: "$",
    NPR: "₨",
    NZD: "$",
    NIO: "C$",
    NGN: "₦",
    KPW: "₩",
    MKD: "den",
    NOK: "kr",
    PKR: "₨",
    PAB: "B/.",
    PGK: "K",
    PYG: "₲",
    PEN: "S/ ",
    PHP: "₱",
    PLN: "zł",
    QAR: "ر.ق",
    RON: "lei",
    RUB: "₽",
    RWF: "Fr",
    WST: "T",
    STN: "Db",
    SAR: "ر.س",
    RSD: "дин.",
    SCR: "₨",
    SLL: "Le",
    SGD: "$",
    SBD: "$",
    SOS: "Sh",
    ZAR: "R",
    SHP: "£",
    KRW: "₩",
    SSP: "£",
    LKR: "Rs  රු",
    SRD: "$",
    SEK: "kr",
    SYP: "£",
    TWD: "$",
    TJS: "ЅМ",
    TZS: "Sh",
    THB: "฿",
    TOP: "T$",
    TTD: "$",
    TND: "د.ت",
    TRY: "₺",
    TMT: "m",
    UGX: "Sh",
    UAH: "₴",
    AED: "د.إ",
    UYU: "$",
    UZS: "so'm",
    VUV: "Vt",
    VES: "Bs.S.",
    VND: "₫",
    YER: "﷼",
    ZMW: "ZK",
    ZWL: "$",
    PAS: "E£",
  };
  const countryToCurrencyMap: Record<string, string> = {
    Afghanistan: "AFN",
    Albania: "ALL",
    Algeria: "DZD",
    Andorra: "EUR",
    Angola: "AOA",
    Argentina: "ARS",
    Armenia: "AMD",
    Australia: "AUD",
    Austria: "EUR",
    Azerbaijan: "AZN",
    Bahamas: "BSD",
    Bahrain: "BHD",
    Bangladesh: "BDT",
    Barbados: "BBD",
    Belarus: "BYN",
    Belgium: "EUR",
    Belize: "BZD",
    Benin: "XOF",
    Bhutan: "BTN",
    Bolivia: "BOB",
    Bosnia_and_Herzegovina: "BAM",
    Botswana: "BWP",
    Brazil: "BRL",
    Brunei: "BND",
    Bulgaria: "BGN",
    Burkina_Faso: "XOF",
    Burundi: "BIF",
    Cambodia: "KHR",
    Cameroon: "XAF",
    Canada: "CAD",
    Cape_Verde: "CVE",
    Central_African_Republic: "XAF",
    Chad: "XAF",
    Chile: "CLP",
    China: "CNY",
    Colombia: "COP",
    Comoros: "KMF",
    Congo: "CDF",
    Costa_Rica: "CRC",
    Croatia: "EUR",
    Cuba: "USD",
    Cyprus: "EUR",
    Czech_Republic: "CZK",
    Denmark: "DKK",
    Djibouti: "DJF",
    Dominica: "XCD",
    Dominican_Republic: "DOP",
    East_Timor: "USD",
    Ecuador: "USD",
    Egypt: "EGP",
    El_Salvador: "USD",
    Equatorial_Guinea: "XAF",
    Eritrea: "ERN",
    Estonia: "EUR",
    Eswatini: "SZL",
    Ethiopia: "ETB",
    Fiji: "FJD",
    Finland: "EUR",
    France: "EUR",
    Gabon: "XAF",
    Gambia: "GMD",
    Georgia: "GEL",
    Germany: "EUR",
    Ghana: "GHS",
    Greece: "EUR",
    Grenada: "XCD",
    Guatemala: "GTQ",
    Guinea: "GNF",
    Guinea_Bissau: "XOF",
    Guyana: "GYD",
    Haiti: "HTG",
    Honduras: "HNL",
    Hong_Kong: "HKD",
    Hungary: "HUF",
    Iceland: "ISK",
    India: "INR",
    Indonesia: "IDR",
    Iran: "IRR",
    Iraq: "IQD",
    Ireland: "EUR",
    Israel: "ILS",
    Italy: "EUR",
    Jamaica: "JMD",
    Japan: "JPY",
    Jordan: "JOD",
    Kazakhstan: "KZT",
    Kenya: "KES",
    Kiribati: "AUD",
    Kosovo: "EUR",
    Kuwait: "KWD",
    Kyrgyzstan: "KGS",
    Laos: "LAK",
    Latvia: "EUR",
    Lebanon: "LBP",
    Lesotho: "LSL",
    Liberia: "LRD",
    Libya: "LYD",
    Liechtenstein: "CHF",
    Lithuania: "EUR",
    Luxembourg: "EUR",
    Macao: "MOP",
    Macedonia: "MKD",
    Madagascar: "MGA",
    Malawi: "MWK",
    Malaysia: "MYR",
    Maldives: "MVR",
    Mali: "XOF",
    Malta: "EUR",
    Marshall_Islands: "USD",
    Mauritania: "MRU",
    Mauritius: "MUR",
    Mexico: "MXN",
    Micronesia: "USD",
    Moldova: "MDL",
    Monaco: "EUR",
    Mongolia: "MNT",
    Montenegro: "EUR",
    Morocco: "MAD",
    Mozambique: "MZN",
    Myanmar: "MMK",
    Namibia: "NAD",
    Nauru: "AUD",
    Nepal: "NPR",
    Netherlands: "EUR",
    New_Zealand: "NZD",
    Nicaragua: "NIO",
    Niger: "XOF",
    Nigeria: "NGN",
    North_Korea: "KPW",
    Norway: "NOK",
    Oman: "OMR",
    Pakistan: "PKR",
    Palau: "USD",
    Panama: "PAB",
    Papua_New_Guinea: "PGK",
    Paraguay: "PYG",
    Peru: "PEN",
    Philippines: "PHP",
    Poland: "PLN",
    Portugal: "EUR",
    Qatar: "QAR",
    Romania: "RON",
    Russia: "RUB",
    Rwanda: "RWF",
    Saint_Kitts_and_Nevis: "XCD",
    Saint_Lucia: "XCD",
    Saint_Vincent_and_the_Grenadines: "XCD",
    Samoa: "WST",
    San_Marino: "EUR",
    Sao_Tome_and_Principe: "STN",
    Saudi_Arabia: "SAR",
    Senegal: "XOF",
    Serbia: "RSD",
    Seychelles: "SCR",
    Sierra_Leone: "SLL",
    Singapore: "SGD",
    Slovakia: "EUR",
    Slovenia: "EUR",
    Solomon_Islands: "SBD",
    Somalia: "SOS",
    South_Africa: "ZAR",
    South_Korea: "KRW",
    South_Sudan: "SSP",
    Spain: "EUR",
    Sri_Lanka: "LKR",
    Sudan: "SDG",
    Suriname: "SRD",
    Sweden: "SEK",
    Switzerland: "CHF",
    Syria: "SYP",
    Taiwan: "TWD",
    Tajikistan: "TJS",
    Tanzania: "TZS",
    Thailand: "THB",
    Togo: "XOF",
    Tonga: "TOP",
    Trinidad_and_Tobago: "TTD",
    Tunisia: "TND",
    Turkey: "TRY",
    Turkmenistan: "TMT",
    Tuvalu: "AUD",
    Uganda: "UGX",
    Ukraine: "UAH",
    United_Arab_Emirates: "AED",
    United_Kingdom: "GBP",
    United_States: "USD",
    Uruguay: "UYU",
    Uzbekistan: "UZS",
    Vanuatu: "VUV",
    Vatican: "EUR",
    Venezuela: "VES",
    Vietnam: "VND",
    Yemen: "YER",
    Zambia: "ZMW",
    Zimbabwe: "ZWL",
    "Åland Islands": "EUR",
    "American Samoa": "USD",
    Anguilla: "USD",
    "Antigua and Barbuda": "USD",
    Aruba: "AWG",
    Bermuda: "USD",
    "British Indian Ocean Territory": "USD",
    "British Virgin Islands": "USD",
    "Burkina Faso": "DJF",
    "Cape Verde": "CVE",
    "Caribbean Netherlands": "USD",
    "Cayman Islands": "USD",
    "Central African Republic": "XOF",
    "Costa Rica": "CRC",
    Curaçao: "ANG",
    Czechia: "CZK",
    "Dominican Republic": "USD",
    "DR Congo": "CDF",
    "El Salvador": "USD",
    "Equatorial Guinea": "XAF",
    "Falkland Islands": "SSP",
    "Faroe Islands": "ISK",
    "French Guiana": "EUR",
    "French Polynesia": "XPF",
    "French Southern and Antarctic Lands": "EUR",
    Gibraltar: "FKP",
    Greenland: "DKK",
    Guadeloupe: "EUR",
    Guam: "USD",
    Guernsey: "EGP",
    "Hong Kong": "AUD",
    "Isle of Man": "EGP",
    "Ivory Coast": "KMF",
    Jersey: "GBP",
    Macau: "BWP",
    "Marshall Islands": "MXN",
    Martinique: "EUR",
    Mayotte: "EUR",
    Montserrat: "MXN",
    "New Caledonia": "XPF",
    "New Zealand": "NZD",
    Niue: "NAD",
    "Norfolk Island": "NAD",
    "North Korea": "KPW",
    "North Macedonia": "MKD",
    "Northern Mariana Islands": "NZD",
    Palestine: "PAS",
    "Papua New Guinea": "PGK",
    "Pitcairn Islands": "ARS",
    "Puerto Rico": "USD",
    "Republic of the Congo": "BIF",
    Réunion: "EUR",
    "Saint Barthélemy": "EUR",
    "Saint Helena, Ascension and Tristan da Cunha": "SYP",
    "Saint Kitts and Nevis": "SBD",
    "San Marino": "EUR",
    "São Tomé and Príncipe": "STN",
    "Saudi Arabia": "YER",
    "Sierra Leone": "SLL",
    "Sint Maarten": "ANG",
    "Solomon Islands": "SGD",
    "South Africa": "ZAR",
    "South Georgia": "SYP",
    "South Korea": "KRW",
    "South Sudan": "SYP",
    "Sri Lanka": "LKR",
    "Svalbard and Jan Mayen": "SEK",
    "Timor-Leste": "TTD",
    Tokelau: "TTD",
    "Trinidad and Tobago": "TTD",
    "Turks and Caicos Islands": "TTD",
    "United Arab Emirates": "AED",
    "United Kingdom": "FKP",
    "United States": "USD",
    "United States Minor Outlying Islands": "USD",
    "United States Virgin Islands": "USD",
    "Vatican City": "EUR",
    "Wallis and Futuna": "XPF",
    "Western Sahara": "MAD",
  };
  const fetchData = () => {
    if (!currencyData) return;
    const country = currencyData;
    const currencyCode = countryToCurrencyMap[country];
    const symbol = currencySymbolMap[currencyCode] ?? "₹";
    if (root !== "Preview") {
      setCurrency(symbol);
    }

    setCurrencySymbol(symbol);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyData]);
  const formattedValue = new Intl.NumberFormat("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return (
    <span>
      {currencySymbol} {formattedValue}
    </span>
  );
};
export default CurrenyFormat;
