import {
  APPSETTING_GET_FAIL,
  APPSETTING_GET_REQUEST,
  APPSETTING_GET_REST,
  APPSETTING_GET_SUCCESS,
  APPSETTING_POST_FAIL,
  APPSETTING_POST_REQUEST,
  APPSETTING_POST_REST,
  APPSETTING_POST_SUCCESS,
  SIGNATURE_GET_FAIL,
  SIGNATURE_GET_REQUEST,
  SIGNATURE_GET_RESET,
  SIGNATURE_GET_SUCCESS,
} from "../constants/appSettingConstants";

export const userAppSettingPostReducer = (state = {}, action: any) => {
  switch (action.type) {
    case APPSETTING_POST_REQUEST:
      return { loading: true };
    case APPSETTING_POST_SUCCESS:
      return { loading: false, prods: action.payload };
    case APPSETTING_POST_FAIL:
      return { loading: false, error: action.payload };
    case APPSETTING_POST_REST:
      return {};
    default:
      return state;
  }
};

export const userAppSettingGetReducer = (state = {}, action: any) => {
  switch (action.type) {
    case APPSETTING_GET_REQUEST:
      return { loading: true };
    case APPSETTING_GET_SUCCESS:
      return { loading: false, productlist: action.payload };
    case APPSETTING_GET_FAIL:
      return { loading: false, error: action.payload };
    case APPSETTING_GET_REST:
      return {};
    default:
      return state;
  }
};
export const userAppSignGetReducer = (state = {}, action: any) => {
  switch (action.type) {
    case SIGNATURE_GET_REQUEST:
      return { loading: true };
    case SIGNATURE_GET_SUCCESS:
      return { loading: false, signaturelist: action.payload };
    case SIGNATURE_GET_FAIL:
      return { loading: false, error: action.payload };
    case SIGNATURE_GET_RESET:
      return {};
    default:
      return state;
  }
};
