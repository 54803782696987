import NumberFormat from "../../commen/NumberFormat";
import React from 'react';

function GstSection({ TaxAllList, formData, }: any) {

  return (
    <>
      {TaxAllList?.map((tasx: any, index: number) => (
        <React.Fragment key={tasx._id || index}>
          {tasx.discountStatus && (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 items-center">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/3 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                DISCOUNT<span className="ml-2">@</span>
                {tasx.discountPercentage}%
              </div>
              <div className="font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1">
                <NumberFormat
                  value={
                    formData?.subtotal * (tasx.discountPercentage / 100)
                      ? formData?.subtotal * (tasx.discountPercentage / 100)
                      : 0
                  }
                />
              </div>
            </div>
          )}
          {tasx.cgstStatus && (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 items-center">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                CGST<span className="ml-2">@</span>
                {tasx.cgstPercentage}%
              </div>
              <div className="font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1">
                <NumberFormat
                  value={
                    (formData?.subtotal -
                      formData?.subtotal * (tasx.discountPercentage / 100)) *
                      (tasx.cgstPercentage / 100)
                      ? (formData?.subtotal -
                        formData?.subtotal *
                        (tasx.discountPercentage / 100)) *
                      (tasx.cgstPercentage / 100)
                      : 0
                  }
                />
              </div>
            </div>
          )}
          {tasx.sgstStatus && (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 items-center">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                SGST<span className="ml-2">@</span>
                {tasx.sgstPercentage}%
              </div>
              <div className="font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1">
                <NumberFormat
                  value={
                    (formData?.subtotal -
                      formData?.subtotal * (tasx.discountPercentage / 100)) *
                      (tasx.sgstPercentage / 100)
                      ? (formData?.subtotal -
                        formData?.subtotal *
                        (tasx.discountPercentage / 100)) *
                      (tasx.sgstPercentage / 100)
                      : 0
                  }
                />
              </div>
            </div>
          )}
          {tasx.igstStatus && (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                IGST<span className="ml-2">@</span>
                {tasx.igstPercentage}%
              </div>
              <div className="font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1">
                <NumberFormat
                  value={
                    (formData?.subtotal -
                      formData?.subtotal * (tasx.discountPercentage / 100)) *
                      (tasx.igstPercentage / 100)
                      ? (formData?.subtotal -
                        formData?.subtotal *
                        (tasx.discountPercentage / 100)) *
                      (tasx.igstPercentage / 100)
                      : 0
                  }
                />
              </div>
            </div>
          )}
          {tasx.vatStatus && (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                VAT<span className="ml-2">@</span>
                {tasx.vatPercentage}%
              </div>
              <div className="font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1">
                <NumberFormat
                  value={
                    (formData?.subtotal -
                      formData?.subtotal * (tasx.discountPercentage / 100)) *
                      (tasx.vatPercentage / 100)
                      ? (formData?.subtotal -
                        formData?.subtotal *
                        (tasx.discountPercentage / 100)) *
                      (tasx.vatPercentage / 100)
                      : 0
                  }
                />
              </div>
            </div>
          )}
          {tasx.othersStatus && (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                OTHERS<span className="ml-2">@</span>
                {tasx.othersPercentage}%
              </div>
              <div className="font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1">
                <NumberFormat
                  value={
                    (formData?.subtotal -
                      formData?.subtotal * (tasx.discountPercentage / 100)) *
                      (tasx.othersPercentage / 100)
                      ? (formData?.subtotal -
                        formData?.subtotal *
                        (tasx.discountPercentage / 100)) *
                      (tasx.othersPercentage / 100)
                      : 0
                  }
                />
              </div>
            </div>
          )}
          {tasx.extraFeesStatus && (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                EXTRA FEES
              </div>
              <div className="font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1">
                <NumberFormat value={Number(tasx.extraFees)} />
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export default GstSection;
