import api from "../axiosConfig";
import { STOCK_GET_FAIL, STOCK_GET_REQUEST, STOCK_GET_SUCCESS, STOCK_PURCHASE_GET_FAIL, STOCK_PURCHASE_GET_REQUEST, STOCK_PURCHASE_GET_SUCCESS } from "../constants/stockConstants";

export const stockGet = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: STOCK_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.get(`/api/stock/all/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: STOCK_GET_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: STOCK_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const stockPurchaseGet = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: STOCK_PURCHASE_GET_REQUEST});
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.get(`/api/stockPurchase/all/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: STOCK_PURCHASE_GET_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: STOCK_PURCHASE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};




