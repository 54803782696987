export const SERVICESETTING_POST_REQUEST = "SERVICESETTING_POST_REQUEST ";
export const SERVICESETTING_POST_SUCCESS = "SERVICESETTING_POST_SUCCESS";
export const SERVICESETTING_POST_FAIL = "SERVICESETTING_POST_FAIL";
export const SERVICESETTING_POST_REST = "SERVICESETTING_POST_REST";

export const SERVICESETTING_GET_REQUEST = "SERVICESETTING_GET_REQUEST";
export const SERVICESETTING_GET_SUCCESS = "SERVICESETTING_GET_SUCCESS";
export const SERVICESETTING_GET_FAIL = "SERVICESETTING_GET_FAIL";
export const SERVICESETTING_GET_REST = "SERVICESETTING_GET_REST";

export const SERVICESETTING_UPDATE_REQUEST = "SERVICESETTING_UPDATE_REQUEST";
export const SERVICESETTING_UPDATE_SUCCESS = "SERVICESETTING_UPDATE_SUCCESS";
export const SERVICESETTING_UPDATE_FAIL = "SERVICESETTING_UPDATE_FAIL";
export const SERVICESETTING_UPDATE_REST = "SERVICESETTING_UPDATE_REST";

export const SERVICESETTING_DELETE_REQUEST = "SERVICESETTING_DELETE_REQUEST";
export const SERVICESETTING_DELETE_SUCCESS = "SERVICESETTING_DELETE_SUCCESS";
export const SERVICESETTING_DELETE_FAIL = "SERVICESETTING_DELETE_FAIL";
export const SERVICESETTING_DELETE_REST = "SERVICESETTING_DELETE_REST";