import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const FeedbackPage = () => {
  const [selectedOption, setSelectedOption] = useState(null as any);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      const file = event.target.files[0];
      if (file) {
        setFileName(file.name);
      } else {
        setFileName("");
      }
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      feedback: "",
      comments: "",
      fileuploadFeedback: "",
    },
  });

  const usersignin = useSelector((state: any) => state.userSignin);
  const { userInfo } = usersignin;

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("feedback", data.feedback);
    formData.append("comments", data.comments);
    formData.append("userEmail", userInfo?.token?.email);
    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    try {
      const response = await axios
        .post("/api/internalfeedback/save", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res: any) => {
          if (res) {
            reset();
            setFileName("");
            toast.success(
              "Thanks For Your Feedback!. We'll Review Your Feedback Shortly",
              {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 800,
              },
            );
          }
        });
    } catch (error) {
      console.error("There was an error uploading the file!", error);
    }
  };
  const handleCancel = () => {
    reset();
    setFileName("");
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="mx-3 mb-4 rounded-lg">
          <legend className="p-4 font-bold text-[#045545] text-lg ml-2">
            Feedback
          </legend>
          <div className="rounded-xl px-3 mx-2">
            <div>
              <label className="block mb-2 text-sm font-semibold text-[#008065] mt-2">
                Name:
              </label>
              <div className="">
                <input
                  type="text"
                  {...register("name", {
                    required: "name is required",
                  })}
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                  }}
                  className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full lg:w-1/4"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-semibold text-[#008065] mt-2">
                Please provide your feedback:
              </label>
            </div>
            <div
              className="flex-col sm:flex-row md:flex lg:flex"
              style={{ alignItems: "center" }}
            >
              <input
                className="mr-1"
                type="checkbox"
                id="feedback"
                value="Excellent"
                {...register("feedback", {
                  required: "feedback is required",
                })}
                checked={selectedOption === "Excellent"}
                onChange={() => setSelectedOption("Excellent")}
              />
              <label
                className="mr-2"
                style={{ fontFamily: "poppins", fontSize: "16px" }}
                htmlFor="feedback5"
              >
                Excellent
              </label>
              <br />
              <input
                className="mr-1"
                type="checkbox"
                id="feedback"
                value="Very Good"
                {...register("feedback", {
                  required: "feedback is required",
                })}
                checked={selectedOption === "Very_Good"}
                onChange={() => setSelectedOption("Very_Good")}
              />
              <label
                className="mr-2"
                style={{ fontFamily: "poppins", fontSize: "16px" }}
                htmlFor="feedback4"
              >
                Very Good
              </label>
              <br />
              <input
                className="mr-1"
                type="checkbox"
                id="feedback"
                value="Good"
                {...register("feedback", {
                  required: "feedback is required",
                })}
                checked={selectedOption === "Good"}
                onChange={() => setSelectedOption("Good")}
              />
              <label
                className="mr-2"
                style={{ fontFamily: "poppins", fontSize: "16px" }}
                htmlFor="feedback3"
              >
                Good
              </label>
              <br />
              <input
                className="mr-1"
                type="checkbox"
                id="feedback"
                value="Average"
                {...register("feedback", {
                  required: "feedback is required",
                })}
                checked={selectedOption === "Average"}
                onChange={() => setSelectedOption("Average")}
              />
              <label
                className="mr-2"
                style={{ fontFamily: "poppins", fontSize: "16px" }}
                htmlFor="feedback2"
              >
                Average
              </label>
              <br />
              <input
                className="mr-1"
                type="checkbox"
                id="feedback"
                value="Poor"
                {...register("feedback", {
                  required: "feedback is required",
                })}
                checked={selectedOption === "Poor"}
                onChange={() => setSelectedOption("Poor")}
              />
              <label
                className="mr-2"
                style={{ fontFamily: "poppins", fontSize: "16px" }}
                htmlFor="feedback1"
              >
                Poor
              </label>
              <br />
              <br />
            </div>
            <div>
              {!selectedOption && (
                <p
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                    color: "#ff0000",
                  }}
                  className="text-red-400 text-xs"
                >
                  {errors?.feedback?.message}
                </p>
              )}
            </div>
            <label className="block mb-2 text-sm font-semibold text-[#008065] mt-2">
              Add Comments:
            </label>
            <textarea
              id="message"
              {...register("comments", {})}
              className="block p-2.5 w-full text-sm text-gray-900 border-2 border-[#008065] rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:text-white"
              placeholder="Write your thoughts here..."
              style={{ resize: "none", height: "9rem" }}
            ></textarea>

            <label
              className="block  text-sm font-semibold text-[#008065] mt-4 mb-2"
              style={{ fontFamily: "poppins", fontSize: "14px" }}
            >
              Please Upload Your File :
            </label>
            <div className="file-upload">
              <label
                title="Click to upload"
                htmlFor="button2"
                className="w-full md:w-2/4 lg:w-2/6 xl:w-1/6 cursor-pointer flex items-center justify-center gap-4 px-4 py-4 border border-[#008065] border-11px rounded-2xl"
              >
                <input
                  type="file"
                  id="button2"
                  className="hidden"
                  {...register("fileuploadFeedback")}
                  onChange={handleFileChange}
                />
                <div className="w-max relative">
                  <svg
                    className="w-6 h-6 "
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"></path>
                  </svg>
                </div>
                <div className="relative">
                  <span
                    className="block text-base font-semibold relative text-[#008065] group-hover:text-[#008065] whitespace-nowrap"
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                  >
                    Upload a File
                  </span>
                </div>
              </label>

              <span
                className="block text-base font-semibold relative text-[#008065] group-hover:text-[#008065] whitespace-nowrap"
                style={{ fontFamily: "poppins", fontSize: "14px" }}
              >
                {fileName ? `File: ${fileName}` : "No File Choosen."}
              </span>
            </div>
            <div className="flex w-full flex-col py-1 sm:flex-row">
              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 mt-5">
                <div className="flex flex-col w-40 rounded">
                  <button
                    type="reset"
                    className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm h-8 block bg-[#008065]"
                    onClick={handleCancel}
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 md:px-2 px-0 mt-5">
                <div className="flex flex-col w-40">
                  <button
                    className="rounded-xl font-bold text-white flex flex-row items-center bg-[#008065] justify-center text-sm h-8 block"
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default FeedbackPage;
