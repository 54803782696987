import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const ColorSettingTable = ({ clientList, deleteId, edit }: any) => {
  const rows = [{ id: 1, color: "", color_name: "", action: "" }];

  const columns: GridColDef[] = [
    { field: "sno", headerName: "S No", width: 150 },
    {
      field: "color",
      headerName: "Color",
      flex: 2,
      minWidth: 150,
      renderCell: () => (
        <div>
          <span
            className="w-[50px] h-[50px] inline-block shrink-0 rounded-2xl "
            style={{
              backgroundColor: clientList,
            }}
          ></span>
        </div>
      ),
    },
    {
      field: "color_name",
      headerName: "Color Name",
      flex: 2,
      minWidth: 150,
      renderCell: () => (
        <>
          <span>{clientList}</span>
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 2,
      minWidth: 150,
      renderCell: () => (
        <>
          <IconButton aria-label="edit" onClick={() => edit(clientList)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => {
              deleteId(clientList);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5  cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row"></div>
        <div
          className="bg-[#F1F7F6] p-[8px] m-[5px]"
          style={{ borderRadius: "8px" }}
        >
          <Box sx={{ height: "auto", width: "100%" }}>
            <DataGrid
autoHeight
              sx={{
                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                  {
                    color: "rgb(0, 128, 101)",
                    fontWeight: 600,
                    fontSize: "14px",
                  },
                ".MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical.css-1b9e9gy":
                  { overflowY: "hidden" },
                ".MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor.css-wop1k0-MuiDataGrid-footerContainer":
                  {
                    display: "none",
                  },
                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                  fontWeight: 600,
                  fontSize: "14px",
                },
                "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
              }}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ColorSettingTable;
