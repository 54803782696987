import React, { useEffect, useState } from "react";
import AppTable from "../../Tables/AppTable";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  AppSettingDelete,
  AppSettingGet,
  AppSettingSave,
  AppSettingUpdate,
} from "../../redux/actions/appSettingsAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";
import { toast } from "react-toastify";

const AppTab = () => {
  const dispatch = useDispatch();
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cgstPercentage: "",
      sgstPercentage: "",
      igstPercentage: "",
      vatPercentage: "",
      othersPercentage: "",
      discountPercentage: "",
      extraFees: "",
    },
  });
  const [AppSettingList, setAppSettingList] = useState([] as any);
  const [updateId, setUpdateId] = useState(null as any);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [CgstChecked, setCgstChecked] = useState(true);
  const [SgstChecked, setSgstChecked] = useState(true);
  const [IgstChecked, setIgstChecked] = useState(false);
  const [VATChecked, setVATChecked] = useState(false);
  const [OthersChecked, setOthersChecked] = useState(false);
  const [DiscountChecked, setDiscountChecked] = useState(false);
  const [ExtraFeesChecked, setExtraFeesChecked] = useState(false);

  const handleCheckboxChange = () => {
    setCgstChecked(!CgstChecked);
    setSgstChecked(!SgstChecked);
    setIgstChecked(false);
    setValue(`cgstPercentage`, "");
    setValue(`sgstPercentage`, "");
    setValue(`igstPercentage`, "");
  };
  const handleCheckboxChange2 = () => {
    setSgstChecked(!SgstChecked);
    setCgstChecked(!CgstChecked);
    setIgstChecked(false);
    setValue(`cgstPercentage`, "");
    setValue(`sgstPercentage`, "");
    setValue(`igstPercentage`, "");
  };
  const handleCheckboxChange3 = () => {
    setIgstChecked(!IgstChecked);
    setSgstChecked(false);
    setCgstChecked(false);
    setValue(`igstPercentage`, "");
    setValue(`cgstPercentage`, "");
    setValue(`sgstPercentage`, "");
  };
  const handleCheckboxChange4 = () => {
    setVATChecked(!VATChecked);
    setValue(`vatPercentage`, "");
  };
  const handleCheckboxChange5 = () => {
    setOthersChecked(!OthersChecked);
    setValue(`othersPercentage`, "");
  };

  const handleCheckboxChange6 = () => {
    setDiscountChecked(!DiscountChecked);
    setValue(`discountPercentage`, "");
  };

  const handleCheckboxChange7 = () => {
    setExtraFeesChecked(!ExtraFeesChecked);
    setValue(`extraFees`, "");
  };

  // ***************************cancel button section*****************************
  const resetProduct = () => {
    let defaultValues = {
      cgstPercentage: "",
      sgstPercentage: "",
      igstPercentage: "",
      vatPercentage: "",
      othersPercentage: "",
      discountPercentage: "",
      extraFees: "",
    };
    setUpdateId(null);
    setCgstChecked(true)
    setSgstChecked(true)
    setIgstChecked(false)
    setVATChecked(false)
    setOthersChecked(false)
    setDiscountChecked(false)
    setExtraFeesChecked(false)
    reset({
      ...defaultValues,
    });
  };

  const onSubmit = (data: any) => {
    let appSettingData = {
      cgstPercentage: data?.cgstPercentage,
      cgstStatus: !data.cgstPercentage ? false : CgstChecked,
      sgstPercentage: data?.sgstPercentage,
      sgstStatus: !data.sgstPercentage ? false : SgstChecked,
      igstPercentage: data?.igstPercentage,
      igstStatus: !data.igstPercentage ? false : IgstChecked,
      vatPercentage: data?.vatPercentage,
      vatStatus: !data?.vatPercentage ? false : VATChecked,
      othersPercentage: data?.othersPercentage,
      othersStatus: !data?.othersPercentage ? false : OthersChecked,
      discountPercentage: data?.discountPercentage,
      discountStatus: !data?.discountPercentage ? false : DiscountChecked,
      extraFees: data?.extraFees,
      extraFeesStatus: !data?.extraFees ? false : ExtraFeesChecked,
      user_id: token?._id,
    };
    if (AppSettingList?.length > 0) {
      dispatch(
        AppSettingUpdate(AppSettingList[0]._id?.$oid, appSettingData) as any,
      ).then((res: any) => {
        if (res?.payload) {
          fetchData();
          reset();
          setValue(`cgstPercentage`, "");
          setValue(`sgstPercentage`, "");
          setValue(`igstPercentage`, "");
          setValue(`vatPercentage`, "");
          setValue(`othersPercentage`, "");
          setValue(`discountPercentage`, "");
          setValue(`extraFees`, "");
          setUpdateId(null);
          toast.success("Update SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        }

      });
    } else {
      // if (data?.cgstPercentage) {
      dispatch(AppSettingSave(appSettingData) as any).then((res: any) => {
        if (res) {
          fetchData();
          reset();
          toast.success("Saved SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        }
      });
      // }

    }
  };

  const handleEdit = (item: any) => {
    setUpdateId(item?._id?.$oid);
    setCgstChecked(item?.cgstStatus);
    setSgstChecked(item?.sgstStatus);
    setIgstChecked(item?.igstStatus);
    setVATChecked(item?.vatStatus);
    setOthersChecked(item?.othersStatus);
    setDiscountChecked(item?.discountStatus);
    setExtraFeesChecked(item?.extraFeesStatus);
    let value = {
      cgstPercentage: item?.cgstPercentage,
      sgstPercentage: item?.sgstPercentage,
      igstPercentage: item?.igstPercentage,
      vatPercentage: item?.vatPercentage,
      othersPercentage: item?.othersPercentage,
      discountPercentage: item?.discountPercentage,
      extraFees: item?.extraFees,
    };
    reset({
      ...value,
    });
    // setUpdate(true);
  };
  const handleDelete = (data: any) => {
    setShowConfirmation(true);
    setUpdateId(data._id?.$oid);
  };

  const handleConfirm = () => {
    dispatch(AppSettingDelete(updateId) as any).then((res: any) => {
      if (res?.type === 'APPSETTING_DELETE_SUCCESS') {
        resetProduct();
        setShowConfirmation(false);
        reset();
        fetchData();
        setUpdateId(null);
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const fetchData = () => {
    dispatch(AppSettingGet(token?._id) as any).then((res: any) => {
      if (res) {
        setAppSettingList(res.payload);
      }
    });
  };
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (AppSettingList) {
      setCgstChecked(
        AppSettingList[0]?.cgstStatus === undefined
          ? CgstChecked
          : AppSettingList[0]?.cgstStatus,
      );
      setSgstChecked(
        AppSettingList[0]?.sgstStatus === undefined
          ? SgstChecked
          : AppSettingList[0]?.sgstStatus,
      );
      setIgstChecked(AppSettingList[0]?.igstStatus);
      setVATChecked(AppSettingList[0]?.vatStatus);
      setOthersChecked(AppSettingList[0]?.othersStatus);
      setDiscountChecked(AppSettingList[0]?.discountStatus);
      setExtraFeesChecked(AppSettingList[0]?.extraFeesStatus);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppSettingList]);

  return (
    <div>
      <div>
        <div className="bg-[#E1E8E7] h-full" style={{ fontFamily: "poppins" }}>
          <div className="text-2xl font-bold text-[#045545]  text-lg   ml-2">
            App Settings
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset className="mx-2 mb-4 rounded-lg">
                <legend className="p-4  font-bold text-[#008065]  ml-2">
                  App Settings
                </legend>
                <div className="rounded-xl  py-3 mb-3  bg-[#E1E8E7]">
                  <div className="flex w-full flex-col px-4 sm:flex-row">
                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                      <div className="flex flex-col mb-6 ">
                        <label
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#008065",
                          }}
                        >
                          CGST(%)
                        </label>
                        <div className="relative flex">
                          <div className="relative m-2">
                            <input
                              type="checkbox"
                              name="GST"
                              placeholder="GST"
                              checked={CgstChecked}
                              onChange={handleCheckboxChange}
                              className={
                                "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            />
                          </div>

                          <div className="relative">
                            <input
                              type="text"
                              placeholder="CGST"
                              readOnly={!CgstChecked}
                              {...register("cgstPercentage", {
                                pattern: {
                                  value: /^(?:\d*\.\d+|\d+)$/,
                                  message:
                                    "Enter a valid CGST Percentage",
                                },
                                onChange: (e) => {
                                  let { value } = e.target;
                                  value = value.replace(/[^0-9.]/g, '');
                                  if (parseFloat(value) > 14) {
                                    value = '0';
                                  }
                                  setValue("cgstPercentage", value);
                                  setValue("sgstPercentage", value);

                                },
                              })}
                              className={
                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                              }}
                            />
                            {errors?.cgstPercentage && (
                              <p className="text-red-600 font-semibold text-xs">
                                {errors?.cgstPercentage?.message}
                              </p>
                            )}
                          </div>
                          
                        </div>
                        
                      </div>
                    </div>

                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                      <div className="flex flex-col mb-6 ">
                        <label
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#008065",
                          }}
                        >
                          SGST(%)
                        </label>
                        <div className="relative flex">
                          <div className="relative m-2">
                            <input
                              type="checkbox"
                              name="GST"
                              checked={SgstChecked}
                              onChange={handleCheckboxChange2}
                              placeholder="GST"
                              className={
                                "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            />
                          </div>
                          <div className="relative">
                            <input
                              type="text"
                              readOnly={!SgstChecked}
                              {...register("sgstPercentage", {
                                pattern: {
                                  value: /^(?:\d*\.\d+|\d+)$/,
                                  message:
                                    "Enter a valid SGST Percentage",
                                },
                                onChange: (e) => {
                                  let { value } = e.target;
                                  value = value.replace(/[^0-9.]/g, '');
                                  if (parseFloat(value) > 14) {
                                    value = "0"
                                  }
                                  setValue("sgstPercentage", value);
                                  setValue("cgstPercentage", value);

                                }
                              })}
                              placeholder="SGST"
                              className={
                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                              }}
                            />
                            {errors?.sgstPercentage && (
                              <p className="text-red-600 font-semibold text-xs">
                                {errors?.sgstPercentage?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                      <div className="flex flex-col mb-6 ">
                        <label
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#008065",
                          }}
                        >
                          IGST(%)
                        </label>
                        <div className="relative flex">
                          <div className="relative m-2">
                            <input
                              type="checkbox"
                              name="GST"
                              checked={IgstChecked}
                              onChange={handleCheckboxChange3}
                              placeholder="GST"
                              className={
                                "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            />
                          </div>
                          <div className="relative">
                            <input
                              type="text"
                              readOnly={!IgstChecked}
                              {...register("igstPercentage", {
                                pattern: {
                                  value: /^(?:\d*\.\d+|\d+)$/,
                                  message:
                                    "Enter a valid IGST Percentage",
                                },
                                onChange: (e) => {
                                  let { value } = e.target;
                                  value = value.replace(/[^0-9.]/g, '')
                                  if (parseFloat(value) > 100) {
                                    value = "0"
                                  }
                                  setValue("igstPercentage", value);
                                }
                              })}
                              placeholder="IGST"
                              className={
                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                              }}
                            />
                            {errors?.igstPercentage && (
                              <p className="text-red-600 font-semibold text-xs">
                                {errors?.igstPercentage?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                      <div className="flex flex-col mb-6 ">
                        <label
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#008065",
                          }}
                        >
                          VAT(%)
                        </label>
                        <div className="relative flex">
                          <div className="relative m-2">
                            <input
                              type="checkbox"
                              name="GST"
                              checked={VATChecked}
                              onChange={handleCheckboxChange4}
                              placeholder="GST"
                              className={
                                "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            />
                          </div>
                          <div className="relative">
                            <input
                              type="text"
                              // name="VAT"
                              readOnly={!VATChecked}
                              {...register("vatPercentage", {
                                pattern: {
                                  value: /^(?:\d*\.\d+|\d+)$/,
                                  message:
                                    "Enter a valid VAT Percentage",
                                },
                                onChange: (e) => {
                                  let { value } = e.target;
                                  value = value.replace(/[^0-9.]/g, '');
                                  if (parseFloat(value) > 100) {
                                    value = "0"
                                  }
                                  setValue("vatPercentage", value);
                                }
                              })}
                              placeholder="VAT"
                              className={
                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                              }}
                            />
                            {errors?.vatPercentage && (
                              <p className="text-red-600 font-semibold text-xs">
                                {errors?.vatPercentage?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full flex-col px-4  sm:flex-row">
                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                      <div className="flex flex-col mb-6 ">
                        <label
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#008065",
                          }}
                        >
                          OTHERS(%)
                        </label>
                        <div className="relative flex">
                          <div className="relative m-2">
                            <input
                              type="checkbox"
                              name="GST"
                              checked={OthersChecked}
                              onChange={handleCheckboxChange5}
                              placeholder="GST"
                              className={
                                "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700 "
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            />
                          </div>
                          <div className="relative">
                            <input
                              type="text"
                              // name="OTHERS"
                              placeholder="OTHERS"
                              {...register("othersPercentage", {
                                pattern: {
                                  value: /^(?:\d*\.\d+|\d+)$/,
                                  message:
                                    "Enter a valid Others Percentage",
                                },
                                onChange: (e) => {
                                  let { value } = e.target;
                                  value = value.replace(/[^0-9.]/g, '');
                                  if (parseFloat(value) > 100) {
                                    value = "0"
                                  }
                                  setValue("othersPercentage", value);
                                }
                              })}
                              readOnly={!OthersChecked}
                              className={
                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                              }}
                            />
                            {errors?.othersPercentage && (
                              <p className="text-red-600 font-semibold text-xs">
                                {errors?.othersPercentage?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                      <div className="flex flex-col mb-6 ">
                        <label
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#008065",
                          }}
                        >
                          DISCOUNT(%)
                        </label>
                        <div className="relative flex">
                          <div className="relative m-2">
                            <input
                              type="checkbox"
                              name="GST"
                              checked={DiscountChecked}
                              onChange={handleCheckboxChange6}
                              placeholder="GST"
                              className={
                                "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700 "
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            />
                          </div>
                          <div className="relative">
                            <input
                              type="text"
                              placeholder="DISCOUNT"
                              {...register("discountPercentage", {
                                pattern: {
                                  value: /^(?:\d*\.\d+|\d+)$/,
                                  message:
                                    "Enter a valid Discount Percentage",
                                },
                                onChange: (e) => {
                                  let { value } = e.target;
                                  if (parseFloat(value) > 100) {
                                    value = "0"
                                  }
                                  setValue("discountPercentage", value);
                                }
                              })}
                              readOnly={!DiscountChecked}
                              className={
                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                              }}
                            />
                            {errors?.discountPercentage && (
                              <p className="text-red-600 font-semibold text-xs">
                                {errors?.discountPercentage?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                      <div className="flex flex-col mb-6 ">
                        <label
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#008065",
                          }}
                        >
                          EXTRA FEES
                        </label>
                        <div className="relative flex">
                          <div className="relative m-2">
                            <input
                              type="checkbox"
                              name="GST"
                              checked={ExtraFeesChecked}
                              onChange={handleCheckboxChange7}
                              placeholder="GST"
                              className={
                                "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700 "
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            />
                          </div>
                          <div className="relative">
                            <input
                              type="text"
                              placeholder="EXTRAFEES"
                              {...register("extraFees", {
                                pattern: {
                                  value: /^(?:\d*\.\d+|\d+)$/,
                                  message:
                                    "Enter a valid ExtraFees Percentage",
                                },
                                onChange: (e) => {
                                  let { value } = e.target;
                                  value = value.replace(/[^0-9.]/g, '');
                                  if (parseFloat(value) > 100) {
                                    value = "0"
                                  }
                                  setValue("extraFees", value);
                                }
                              })}
                              readOnly={!ExtraFeesChecked}
                              className={
                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                              }
                              style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                  </div>
                  <div className="flex w-full flex-col sm:flex-row pl-4">
                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                      <div className="flex flex-col w-44 rounded">
                        <button
                          type="button"
                          className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                          style={{
                            backgroundColor: "#008065",
                            fontSize: "14px",
                            fontFamily: "poppins",
                          }}
                          onClick={resetProduct}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                      <div className="flex flex-col w-44 ">
                        <button
                          type="submit"
                          // className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                          style={{
                            backgroundColor: "#008065",
                            fontSize: "14px",
                            fontFamily: "poppins",
                          }}
                          className={`${AppSettingList?.length > 0 && !updateId
                            ? "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] opacity-50  cursor-not-allowed disabled"
                            : "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                            }`}
                          disabled={AppSettingList?.length > 0 && !updateId ? true : false}
                        >
                          {updateId ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <AppTable
          AppSettingList={AppSettingList}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
        {showConfirmation && (
          <ConfirmationPopup
            message="Are you sure you want to proceed?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </div>
    </div>
  );
};

export default AppTab;
