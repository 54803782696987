
import { FiPlusCircle } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import SalesFixedAssetTable from "./SalesFixedAssetTable";

const SalesFixedAssetTab = () => {
    
    const navigate = useNavigate();

    // *******************sort*********************


    const handleAddSalesFixedAsset = () => {
        navigate("/app/salesFixedAssetPage");
    };

  return (
    <div>
    <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
        <div className="p-4  font-bold text-[#008065]  ml-2"> Sales Fixed Asset - Report</div>
        <div className="p-4  font-bold text-[#008065]  ml-2">
            <button
                type="button"
                className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                style={{ backgroundColor: "#008065" }}
                onClick={handleAddSalesFixedAsset}
            >
                <FiPlusCircle className="h-5 w-5" />
                <span className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`} >Add Sales Fixed Asset </span>
            </button></div>


    </div>
    <div className="mt-1">
        <SalesFixedAssetTable />
    </div>


</div>
  )
}

export default SalesFixedAssetTab
