/* eslint-disable eqeqeq */
import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  upiPaymentAll,
  upiPaymentDelete,
  upiPaymentSave,
  upiPaymentUpdate,
} from "../../redux/actions/upiPaymentAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";

interface FieldErrors {
  upi_number?: string;
}

const UPIPaymentPage = () => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [upiPaymentList, setUpiPaymentList] = useState([] as any);
  const [searchValue, setSearchValue] = useState([] as any);
  const [upiId, setUpiId] = useState(null as any);
  const fetchData = () => {
    dispatch(upiPaymentAll() as any).then((res: any) => {
      setUpiPaymentList(res.payload);
      setSearchValue(res.payload);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const handleInputChange = (e: any) => {
    setValue("upi_number", e.target.value.trim());
    const { name, value } = e.target;
    const fieldName = name as keyof FieldErrors;
    let error = "";
    if (
      fieldName === "upi_number" &&
      !value.match(/^[a-zA-Z0-9.-]+@[a-zA-Z]+$/) &&
      value
    ) {
      error = "Invalid UPI number format";
    }
    setFieldErrors((prevErrors: any) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const onSubmit = (data: any) => {
    const duplicate = upiPaymentList.some(
      (payment: any) =>
        payment.upi_number === data.upi_number && payment._id.$oid !== upiId
    );
    if (duplicate) {
      toast.error("UPI number already exists", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 800,
      });
      return;
    }

    if (upiId) {
      dispatch(upiPaymentUpdate(upiId, data) as any).then((res: any) => {
        // eslint-disable-next-line eqeqeq
        if (res.type == "UPIPAYMENT_UPDATE_SUCCESS") {
          fetchData();
          setUpiId("");
          let value = {
            upi_pay: "",
            defaultUpiInfo: "",
            upi_number: "",
          };
          reset({
            ...value,
          });
        }
        toast.success("Update SuccessFully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
      });
    } else {
      dispatch(upiPaymentSave(data) as any).then((res: any) => {
        if (res.type == "UPIPAYMENT_POST_SUCCESS") {
          fetchData();
          reset();
        }
        toast.success("Saved SuccessFully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
      });
    }
  };

  const editUpiPayment = (upi: any) => {
    setUpiId(upi._id.$oid);

    let value = {
      upi_pay: upi.upi_pay,
      defaultUpiInfo: upi.defaultUpiInfo,
      upi_number: upi.upi_number,
    };
    reset({
      ...value,
    });
  };

  // const deleteUpi = (item:any) => {
  //   setShowConfirmation(true);
  //   setUpiId(item._id?.$oid);

  // };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    let data = [...searchValue];
    data = data?.filter((value: any) =>
      value?.upi_pay?.toLowerCase().includes(query?.toLowerCase())
    );
    setUpiPaymentList(data);
  };

  const deleteUpi = (data: any) => {
    setShowConfirmation(true);
    setUpiId(data._id?.$oid);
  };

  const handleConfirm = () => {
    dispatch(upiPaymentDelete(upiId) as any).then((res: any) => {
      if (res) {
        fetchData();
        setUpiId(null);
        setShowConfirmation(false);
        let value = {
          upi_pay: "",
          defaultUpiInfo: "",
          upi_number: "",
        };
        reset({
          ...value,
        });
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  // *********************sorted*********************
  const [sortOrder, setSortOrder] = useState("asc");
 
  const handleSortChange = (newSortOrder: string) => {
    const sortedData = [...searchValue].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.upi_pay.toLowerCase().localeCompare(b.upi_pay.toLowerCase());
      } else if (newSortOrder === "desc") {
        return b.upi_pay.toLowerCase().localeCompare(a.upi_pay.toLowerCase());
      } else {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
    });
    setUpiPaymentList(sortedData);
    setSortOrder(newSortOrder);
  };

  const handleCancelClick = () => {
    setUpiId(false);
    let value = {
      upi_pay: "",
      defaultUpiInfo: "",
      upi_number: "",
    };
    reset({
      ...value,
    });
    setFieldErrors({});
    // reset();
  };
  const rows = upiPaymentList.map((item: any, index: any) => ({
    id: uuidv4(),
    sno: index + 1,
    ...item,
  }));

  const getRowClassName = (params: any) => {
    return params.row.defaultUpiInfo ? "green-background" : "";
  };

  const columns: GridColDef[] = [
    { field: "sno", headerName: "S No", width: 150 },
    { field: "upi_pay", headerName: "UPI Pay", flex: 2, minWidth: 150 },
    { field: "upi_number", headerName: "UPI Number", flex: 2, minWidth: 150 },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => editUpiPayment(params.row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => {
              deleteUpi(params.row);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5  cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className=' className="bg-[#E1E8E7]'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="mx-3 mb-4 rounded-lg">
            <legend className="p-4  font-bold text-[#008065]  text-lg   ml-2">
              UPI Payment Info
            </legend>
            <div className="rounded-xl px-2 ">
              <div className="flex ml-6 gap-2">
                <div className="">
                  <input
                    type="checkbox"
                    placeholder="defaultUpiInfo"
                    className={
                      "border-2 focus:outline-none mx-2 rounded-[7px] px-2 h-5 w-5 accent-green-700"
                    }
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                    {...register("defaultUpiInfo")}
                  />
                </div>
                <div className="text-[#008065] font-semibold">
                  <label>Default UPI Payment Info</label>
                </div>
              </div>
              <div className="flex w-full flex-col sm:flex-row ">
                <div className="w-full ">
                  <div className="text-gray-700 md:flex md:items-center mx-6 p-2">
                    <div className="mb-1 md:mb-0 md:w-1/3">
                      <label
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                        className="text-[#008065] required"
                      >
                        <span>UPI PAY</span>
                      </label>
                    </div>

                    <div className="relative mt-0">
                      <select
                        defaultValue={""}
                        className={`block lg:w-72 md:w-72 xl:w-96 2xl:w-96 w-full p-2 text-sm text-gray-900 border rounded-lg focus:outline-none 
                         ${
                           errors.upi_pay
                             ? "border-red-500"
                             : "border-[#008065]"
                         } 
                         bg-gray-50 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        {...register("upi_pay", {
                          required: "Upi Pay is required",
                        })}
                      >
                        <option value="" disabled  hidden>
                          Select UPI Pay
                        </option>
                        <option>PayTM</option>
                        <option>Phone Pe</option>
                        <option>Google Pay</option>
                        <option>BHIM</option>
                        <option>iMobile Pay</option>
                        <option>Bharat Pe</option>
                        <option>BHIM Baroda Pay</option>
                        <option>BHIM Axis Pay</option>
                        <option>BHIM SBI Pay</option>
                        <option>YONO SBI</option>
                        <option>PayZapp</option>
                        <option>NIYO Global</option>
                        <option>Bajaj Markets</option>
                      </select>
                      {errors.upi_pay && (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {"Upi Pay is required"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="text-gray-700 md:flex md:items-center mx-6 p-2">
                    <div className="mb-1 md:mb-0 md:w-1/3 ">
                      <label
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                        className="text-[#008065] required"
                      >
                        <span>UPI NUMBER</span>
                      </label>
                    </div>
                    <div className="relative md:w-2/3 md:flex-grow">
                      <input
                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-48 md:w-72 lg:w-72 xl:w-96 2xl:w-96
                        ${
                          errors.upi_number || fieldErrors.upi_number
                            ? "border-red-500"
                            : "border-[#008065]"
                        } `}
                        {...register("upi_number", {
                          required: "UPI number is required",
                          // onChange: (e) => {
                          //   setValue("upi_number", e.target.value.trim())
                          // },
                          onChange: (e) => handleInputChange(e),
                          pattern: {
                            value: /^[a-zA-Z0-9.-]+@[a-zA-Z]+$/,
                            message: "Invalid UPI number format",
                          },
                        })}
                        type="text"
                        placeholder="Enter a UPI NO..."
                      />
                      {fieldErrors.upi_number ? (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {fieldErrors.upi_number}
                        </p>
                      ) : errors.upi_number ? (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {"UPI number is required"}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-xl px-3 mb-3">
              <div className="flex w-full flex-col sm:flex-row pl-5">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col  mb-4 "></div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col  mb-4 "> </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2">
                  <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                    <button
                      type="button"
                      className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                      style={{
                        backgroundColor: "#008065",
                        fontFamily: "poppins",
                        fontSize: "14px",
                      }}
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                    <button
                      // onClick={(e: any) => handleSubmit(e)}
                      className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                      style={{
                        backgroundColor: "#008065",
                        fontFamily: "poppins",
                        fontSize: "14px",
                      }}
                    >
                      {upiId ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>

        <div>
          <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
            <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
              <div className="flex items-center">
                <div className="relative flex mb-2">
                  <input
                    onChange={(e) => onSearch(e)}
                    type="text"
                    id="simple-search"
                    className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                    placeholder="Search"
                    required
                  />
                  <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="block mb-2  ">
                <div className="relative flex items-center ">
                  <select
                    defaultValue={""}
                    id="sortDropdown"
                    className="block w-[11rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
                    onChange={(e) => handleSortChange(e.target.value)}
                  >
                    <option value="" disabled  hidden>
                      Sort By
                    </option>
                    <option value="asc">UPI Name A to Z</option>
                    <option value="desc">UPI Name Z to A</option>
                    {/* Add other sorting options if needed */}
                  </select>
                </div>
              </div>
            </div>

            <div
              className="bg-[#F1F7F6]"
              style={{ borderRadius: "8px" }}
            >
              <Box
                sx={{
                  height: "45vh",
                  width: "100%",
                  overflowX: "auto",
                  overflowY: "hidden",
                }}
              >
                <DataGrid
autoHeight
                  sx={{
                    ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                      {
                        color: "rgb(0, 128, 101)",
                        fontWeight: 600,
                        fontSize: "14px",
                      },
                    ".MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical.css-1b9e9gy":
                      { overflowY: "hidden" },
                    ".green-background": {
                      backgroundColor: "rgb(193, 225, 193)",
                    },
                    ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                      fontWeight: 600,
                      fontSize: "14px",
                    },
                    "& .MuiDataGrid-checkboxInput": {
                      color: "#008060 !important",
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  checkboxSelection
                  getRowClassName={getRowClassName}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25, 50]}
                  disableRowSelectionOnClick
                />
              </Box>
            </div>
          </div>
        </div>
      </div>
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default UPIPaymentPage;
