import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { registerAll } from "../../redux/actions/userAction";
import { InternalFindAll } from "../../redux/actions/InternalInvoiceAction";
import { serviceFindAll } from "../../redux/actions/invoiceServiceActions";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

type SelectedRange =
	| { range: string }
	| { range: string; startDate: string; endDate: string };

interface DashboardLineDiaProps {
	selectedRange: SelectedRange;
}

const DashboardLineDia: React.FC<DashboardLineDiaProps> = ({
	selectedRange,
}) => {
	const dispatch = useDispatch();
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;

	const [invoiceTable, setInvoiceTable] = useState([] as any);
	const [serviceInvoiceTable, setServiceInvoiceTable] = useState([] as any);
	const [graphdata, setGraphData] = useState({ labels: [], datasets: [] });

	const fetchData = useCallback(() => {
		dispatch(registerAll(token?._id) as any);
		dispatch(InternalFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setInvoiceTable(response.payload);
			}
		});
		dispatch(serviceFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setServiceInvoiceTable(response.payload);
			}
		});
	}, [dispatch, token]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const mergedArray = useMemo(
		() => invoiceTable.concat(serviceInvoiceTable),
		[invoiceTable, serviceInvoiceTable]
	);
	useEffect(() => {
		let data: any = { labels: [], datasets: [] };

		if (selectedRange.range === "Last 1 month") {
			const today = new Date();
			const endDate = new Date(today);
			endDate.setHours(23, 59, 59, 999);
			const lastMonthStart = new Date(today);
			lastMonthStart.setDate(today.getDate() - 29);

			const dailyCounts: { [key: string]: number } = {};

			mergedArray.forEach((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				if (invoiceDate >= lastMonthStart && invoiceDate <= endDate) {
					const dateKey = invoiceDate.toISOString().split("T")[0];
					dailyCounts[dateKey] = (dailyCounts[dateKey] || 0) + 1;
				}
			});

			const labels: string[] = [];
			const counts: number[] = [];
			let currentDate = new Date(lastMonthStart);

			while (currentDate <= endDate) {
				const dateKey = currentDate.toISOString().split("T")[0];
				labels.push(
					currentDate.toLocaleString("default", {
						day: "numeric",
						month: "short",
					})
				);
				counts.push(dailyCounts[dateKey] || 0);
				currentDate.setDate(currentDate.getDate() + 1);
			}

			data = {
				labels,
				datasets: [
					{
						label: "Invoice Count (Last 1 Month)",
						data: counts,
						borderColor: "rgba(75,192,192,1)",
						backgroundColor: "rgba(75,192,192,0.2)",
						fill: true,
					},
				],
			};
		} else if (selectedRange.range === "Today") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);

			const todayInvoiceCount = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= todayStart && invoiceDate < todayEnd;
			}).length;

			const yesterdayStart = new Date(todayStart);
			yesterdayStart.setDate(todayStart.getDate() - 1);

			// const yesterdayInvoiceCount = mergedArray.filter((invoice: any) => {
			//   const invoiceDate = new Date(
			//     invoice.creation_date?.$date
			//   );
			//   return invoiceDate >= yesterdayStart && invoiceDate < todayStart;
			// }).length;

			data = {
				labels: ["Today"],
				datasets: [
					{
						label: "Invoice Count",
						data: [todayInvoiceCount],
						borderColor: "rgba(75,192,192,1)",
						backgroundColor: "rgba(75,192,192,0.2)",
						fill: true,
					},
				],
			};
		} else if (selectedRange.range === "Last 7 days") {
			const today = new Date();
			const last7Days = Array.from({ length: 7 }, (_, i) => {
				const day = new Date(today);
				day.setDate(today.getDate() - i);
				return day;
			});

			const dailyCounts = Array(7).fill(0);

			mergedArray.forEach((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				last7Days.forEach((day, index) => {
					if (
						invoiceDate.getDate() === day.getDate() &&
						invoiceDate.getMonth() === day.getMonth() &&
						invoiceDate.getFullYear() === day.getFullYear()
					) {
						dailyCounts[index] += 1;
					}
				});
			});

			data = {
				labels: last7Days.reverse()?.map((day) => day.toLocaleDateString()),
				datasets: [
					{
						label: "Invoice Count (Last 7 Days)",
						data: dailyCounts.reverse(),
						borderColor: "rgba(75,192,192,1)",
						backgroundColor: "rgba(75,192,192,0.2)",
						fill: true,
					},
				],
			};
		} else if (selectedRange.range === "Last 3 months") {
			const today = new Date();
			const last3Months = Array.from({ length: 3 }, (_, i) => {
				return new Date(today.getFullYear(), today.getMonth() - i, 1);
			});

			const monthlyCounts = Array(3).fill(0);

			mergedArray.forEach((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				last3Months.forEach((month, index) => {
					if (
						invoiceDate.getMonth() === month.getMonth() &&
						invoiceDate.getFullYear() === month.getFullYear()
					) {
						monthlyCounts[index] += 1;
					}
				});
			});

			data = {
				labels: last3Months
					.reverse()
					?.map((month) =>
						month.toLocaleString("default", { month: "long", year: "numeric" })
					),
				datasets: [
					{
						label: "Invoice Count (Last 3 Months)",
						data: monthlyCounts.reverse(),
						borderColor: "rgba(75,192,192,1)",
						backgroundColor: "rgba(75,192,192,0.2)",
						fill: true,
					},
				],
			};
		} else if (selectedRange.range === "Last 6 months") {
			const today = new Date();
			const last6Months = Array.from({ length: 6 }, (_, i) => {
				return new Date(today.getFullYear(), today.getMonth() - i, 1);
			});

			const monthlyCounts = Array(6).fill(0);

			mergedArray.forEach((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				last6Months.forEach((month, index) => {
					if (
						invoiceDate.getMonth() === month.getMonth() &&
						invoiceDate.getFullYear() === month.getFullYear()
					) {
						monthlyCounts[index] += 1;
					}
				});
			});

			data = {
				labels: last6Months
					.reverse()
					?.map((month) =>
						month.toLocaleString("default", { month: "long", year: "numeric" })
					),
				datasets: [
					{
						label: "Invoice Count (Last 6 Months)",
						data: monthlyCounts.reverse(),
						borderColor: "rgba(75,192,192,1)",
						backgroundColor: "rgba(75,192,192,0.2)",
						fill: true,
					},
				],
			};
		} else if (selectedRange.range === "Last 12 months") {
			const today = new Date();
			const last12Months = Array.from({ length: 12 }, (_, i) => {
				return new Date(today.getFullYear(), today.getMonth() - i, 1);
			});

			const monthlyCounts = Array(12).fill(0);

			mergedArray.forEach((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				last12Months.forEach((month, index) => {
					if (
						invoiceDate.getMonth() === month.getMonth() &&
						invoiceDate.getFullYear() === month.getFullYear()
					) {
						monthlyCounts[index] += 1;
					}
				});
			});

			data = {
				labels: last12Months
					.reverse()
					?.map((month) =>
						month.toLocaleString("default", { month: "long", year: "numeric" })
					),
				datasets: [
					{
						label: "Invoice Count (Last 12 Months)",
						data: monthlyCounts.reverse(),
						borderColor: "rgba(75,192,192,1)",
						backgroundColor: "rgba(75,192,192,0.2)",
						fill: true,
					},
				],
			};
		} else if (selectedRange.range === "Last 16 months") {
			const today = new Date();
			const last16Months = Array.from({ length: 16 }, (_, i) => {
				return new Date(today.getFullYear(), today.getMonth() - i, 1);
			});

			const monthlyCounts = Array(16).fill(0);

			mergedArray.forEach((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				last16Months.forEach((month, index) => {
					if (
						invoiceDate.getMonth() === month.getMonth() &&
						invoiceDate.getFullYear() === month.getFullYear()
					) {
						monthlyCounts[index] += 1;
					}
				});
			});

			data = {
				labels: last16Months.reverse().map((month) => {
					const monthString = month.toLocaleString("default", {
						month: "short",
					});
					const yearString = month
						.toLocaleString("default", { year: "numeric" })
						.slice(-2);
					return `${monthString} '${yearString}`;
				}),
				datasets: [
					{
						label: "Invoice Count (Last 16 Months)",
						data: monthlyCounts.reverse(),
						borderColor: "rgba(75,192,192,1)",
						backgroundColor: "rgba(75,192,192,0.2)",
						fill: true,
					},
				],
			};
		} else if (
			selectedRange.range === "Custom" &&
			"startDate" in selectedRange &&
			"endDate" in selectedRange
		) {
			const startDate = new Date(
				(selectedRange as { startDate: string }).startDate
			);
			const endDate = new Date((selectedRange as { endDate: string }).endDate);
			endDate.setHours(23, 59, 59, 999);

			const dailyCounts: { [key: string]: number } = {};

			// Count invoices within the custom date range
			mergedArray.forEach((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				if (invoiceDate >= startDate && invoiceDate <= endDate) {
					const dateKey = invoiceDate.toISOString().split("T")[0];

					dailyCounts[dateKey] = (dailyCounts[dateKey] || 0) + 1;
				}
			});
			const labels: string[] = [];
			const counts: number[] = [];
			let currentDate = new Date(startDate);

			while (currentDate <= endDate) {
				const dateKey = currentDate.toISOString().split("T")[0];
				labels.push(dateKey);
				counts.push(dailyCounts[dateKey] || 0);
				currentDate.setDate(currentDate.getDate() + 1);
			}

			data = {
				labels: labels,
				datasets: [
					{
						label: "Invoice Count (Custom Date)",
						data: counts,
						borderColor: "rgba(75,192,192,1)",
						backgroundColor: "rgba(75,192,192,0.2)",
						fill: true,
					},
				],
			};
		}

		setGraphData(data);
	}, [selectedRange, mergedArray]);

	const options = {
		responsive: true,
		plugins: {
			legend: { display: true },
		},
		scales: {
			x: { beginAtZero: true },
			y: { beginAtZero: true },
		},
	};

	return (
		<div>
			<div className="chart-container col-span-2">
				<Line data={graphdata} options={options} />
			</div>
		</div>
	);
};

export default DashboardLineDia;
