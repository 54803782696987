export const SERIVICEUNITS_POST_REQUEST = 'SERIVICEUNITS_POST_REQUEST';
export const SERIVICEUNITS_POST_SUCCESS = 'SERIVICEUNITS_POST_SUCCESS';
export const SERIVICEUNITS_POST_FAIL = 'SERIVICEUNITS_POST_FAIL';
export const SERIVICEUNITS_POST_RESET = 'SERIVICEUNITS_POST_RESET';

export const SERIVICEUNITS_GET_REQUEST = 'SERIVICEUNITS_GET_REQUEST';
export const SERIVICEUNITS_GET_SUCCESS = 'SERIVICEUNITS_GET_SUCCESS';
export const SERIVICEUNITS_GET_FAIL = 'SERIVICEUNITS_GET_FAIL';
export const SERIVICEUNITS_GET_RESET = 'SERIVICEUNITS_GET_RESET';

export const SERIVICEUNITS_UPDATE_REQUEST = "SERIVICEUNITS_UPDATE_REQUEST";
export const SERIVICEUNITS_UPDATE_SUCCESS = "SERIVICEUNITS_UPDATE_SUCCESS";
export const SERIVICEUNITS_UPDATE_FAIL = "SERIVICEUNITS_UPDATE_FAIL";
export const SERIVICEUNITS_UPDATE_RESET = "SERIVICEUNITS_UPDATE_RESET";

export const SERIVICEUNITS_DELETE_REQUEST = "SERIVICEUNITS_DELETE_REQUEST";
export const SERIVICEUNITS_DELETE_SUCCESS = "SERIVICEUNITS_DELETE_SUCCESS";
export const SERIVICEUNITS_DELETE_FAIL = "SERIVICEUNITS_DELETE_FAIL";
export const SERIVICEUNITS_DELETE_RESET = "SERIVICEUNITS_DELETE_RESET";