import axios, { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { decryptData, encryptData } from "./cryptoConfig";


// Create an instance of Axios
const api = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
});

// Request Interceptor
api.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        if (config.method === "post" || config.method === "put") {
            if (config.data) {
                const encryptedData = encryptData(JSON.stringify(config.data));
                config.data = encryptedData;
            }
            return config;
        } else {
            return config;
        }
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Response Interceptor
api.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
        // if (response?.config?.method === "post" || response?.config?.method === "put") {
        if (response.data) {
            response.data = JSON.parse(decryptData(response.data));
        }
        return response;
        // } else {
        //     return response;
        // }

    },
    (error) => {
        return Promise.reject(error);
    },
);

export default api;
