export const UNIT_POST_REQUEST = 'UNIT_POST_REQUEST';
export const UNIT_POST_SUCCESS = 'UNIT_POST_SUCCESS';
export const UNIT_POST_FAIL = 'UNIT_POST_FAIL';
export const UNIT_POST_RESET = 'UNIT_POST_REST';

export const UNIT_GET_REQUEST = 'UNIT_VENKAT_GET_REQUEST';
export const UNIT_GET_SUCCESS = 'UNIT_GET_SUCCESS';
export const UNIT_GET_FAIL = 'UNIT_GET_FAIL';
export const UNIT_GET_RESET = 'UNIT_GET_REST';

export const UNIT_UPDATE_REQUEST = 'UNIT_UPDATE_REQUEST';
export const UNIT_UPDATE_SUCCESS = 'UNIT_UPDATE_SUCCESS';
export const UNIT_UPDATE_FAIL = 'UNIT_UPDATE_FAIL';
export const UNIT_UPDATE_RESET = 'UNIT_UPDATE_RESET';

export const UNIT_DELETE_REQUEST = 'UNIT_DELETE_REQUEST';
export const UNIT_DELETE_SUCCESS = 'UNIT_DELETE_SUCCESS';
export const UNIT_DELETE_FAIL = 'UNIT_DELETE_FAIL';
export const UNIT_DELETE_RESET = 'UNIT_DELETE_RESET';


export const UNIT_PAGE_POST_REQUEST = 'UNIT_PAGE_POST_REQUEST ';
export const UNIT_PAGE_POST_SUCCESS = 'UNIT_PAGE_POST_SUCCESS';
export const UNIT_PAGE_POST_FAIL = 'UNIT_PAGE_POST_FAIL';
export const UNIT_PAGE_POST_RESET = 'UNIT_PAGE_POST_RESET';









