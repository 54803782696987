import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  element: React.ReactNode;
  path: string;
  requiredRole: string; // Adjust based on your role system
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  path,
  requiredRole,
}) => {
  const usersignin = useSelector((state: any) => state.userSignin);
  const { userInfo } = usersignin;
  const isAuthenticated = !!userInfo?.token;
  const userRole = userInfo?.token?.userRoleName;

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (userRole !== requiredRole) {
    return <Navigate to="/app/unauthorized" />;
  }

  return <>{element}</>;
};

export default ProtectedRoute;
