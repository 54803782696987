import Axios from "axios";
import { EXTERNAL_INVOICE_FIND_ALL_FAIL, EXTERNAL_INVOICE_FIND_ALL_REQUEST, EXTERNAL_INVOICE_FIND_ALL_SUCCESS, EXTERNAL_PAGE_POST_FAIL, EXTERNAL_PAGE_POST_REQUEST, EXTERNAL_PAGE_POST_SUCCESS, EXTERNAL_POST_FAIL, EXTERNAL_POST_REQUEST, EXTERNAL_POST_SUCCESS } from "../constants/ExternalConstants";


export const ExternalPost = (externalInvoice: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: EXTERNAL_POST_REQUEST });
  try {
    const { data } = await Axios.post(`/api/externalcreat/save`, externalInvoice);
    return dispatch({ type: EXTERNAL_POST_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: EXTERNAL_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ExternalFindAll = () => async (dispatch: any, getState: any) => {
  dispatch({ type: EXTERNAL_INVOICE_FIND_ALL_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await Axios.get(`/api/external/find-all`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: EXTERNAL_INVOICE_FIND_ALL_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: EXTERNAL_INVOICE_FIND_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const pageInvoice = (externalInvoicePage: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: EXTERNAL_PAGE_POST_REQUEST });
  try {
    const { data } = await Axios.post(`/api/externalcreatepage/save`, externalInvoicePage);
    return dispatch({ type: EXTERNAL_PAGE_POST_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: EXTERNAL_PAGE_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
