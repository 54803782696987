import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { chequeModalSave } from '../../redux/actions/chequeAction';

const ChequeModal = ({ selectedRow, onClose }: any) => {
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paidTo: selectedRow?.Name || "",
      chequeAmount: selectedRow?.toatalAmount || "",
      withdrawFrom: "",
      chequeNo: selectedRow?.chequeNum || "",
      transferDate: "",
      description: "",
    },
  });
  const dispatch = useDispatch<any>();


  const onSubmit = (data: any) => {
    let chequeModalData = {
      paidTo: data?.paidTo,
      chequeAmount: data.chequeAmount,
      withdrawFrom: data?.withdrawFrom,
      chequeNo: data.chequeNo,
      transferDate: data.transferDate,
      description: data.description,
      user_id: token?._id,
      cheque_id: selectedRow._id.$oid
    };
    dispatch(chequeModalSave(chequeModalData) as any).then((res: any) => {
      if (res?.type === "CHEQUE_MODAL_POST_SUCCESS")
        onClose();
    })
    // Add save functionality here
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-6 max-w-md mx-auto bg-white rounded-md shadow-md">
      <h1 className="text-gray-700 font-medium text-lg">Transfer Cheque</h1>

      <label className="block mb-2">Paid To:</label>
      <input
        type="text"
        className="w-full p-2 mb-4 border rounded-md"
        {...register('paidTo', { required: true })}
        defaultValue={selectedRow?.Name}
      />
      {errors.paidTo && <p className="text-red-500">This field is required</p>}

      <label className="block mb-2">Cheque Amount:</label>
      <input
        type="number"
        className="w-full p-2 mb-4 border rounded-md"
        {...register('chequeAmount', { required: true, min: 1 })}
        defaultValue={selectedRow?.toatalAmount}
      />
      {errors.chequeAmount && <p className="text-red-500">This field is required and must be greater than 0</p>}

      <label className="block mb-2">Withdraw From:</label>
      <select
        className="w-full p-2 mb-4 border rounded-md"
        {...register('withdrawFrom', { required: true })}
      >
        <option value="Cash">Cash</option>
        {/* Add more options here as needed */}
      </select>
      {errors.withdrawFrom && <p className="text-red-500">This field is required</p>}

      <label className="block mb-2">Cheque Ref No:</label>
      <input
        type="text"
        className="w-full p-2 mb-4 border rounded-md"
        {...register('chequeNo', { required: true })}
        defaultValue={selectedRow?.chequeNum}
      />
      {errors.chequeNo && <p className="text-red-500">This field is required</p>}

      <label className="block mb-2">Transfer Date:</label>
      <input
        type="date"
        className="w-full p-2 mb-4 border rounded-md"
        value="2024-11-01"
        readOnly
        {...register('transferDate')}
      />

      <label className="block mb-2">Description (Optional):</label>
      <textarea
        className="w-full p-2 mb-4 border rounded-md"
        {...register('description')}
        placeholder="Enter description"
      />
      <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-white  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
        >
          SAVE
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        onClick={onClose}
        >
          Cancel
        </button>
      </div>
   
    </form>
  );
};

export default ChequeModal;
