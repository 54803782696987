import api from "../axiosConfig";
import { SERVICE_DEACTIVE_FAIL, SERVICE_DEACTIVE_REQUEST, SERVICE_DEACTIVE_SUCCESS, SERVICE_GET_FAIL, SERVICE_GET_REQUEST, SERVICE_GET_SUCCESS, SERVICE_POST_FAIL, SERVICE_POST_REQUEST, SERVICE_POST_SUCCESS, SERVICE_UPDATE_FAIL, SERVICE_UPDATE_REQUEST, SERVICE_UPDATE_SUCCESS } from "../constants/serviceConstants";



export const ServiceDetails = (service: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERVICE_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.post(`/api/service/save`, service, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: SERVICE_POST_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: SERVICE_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// // ------------------------------------Bulk add------------------------------------
// export const createBulkProducts = (prod: any) => async (dispatch: any, getState: any) => {
//   dispatch({ type: PRODUCT_POST_REQUEST });
//   try {
//     const {
//       userSignin: {
//         userInfo: { token },
//       },
//     } = getState();

//     const { data } = await api.post(`/api/bulkproduct/save`, prod, {
//       headers: { Authorization: `Bearer ${token?.tocken}` },
//     });
//     return dispatch({ type: PRODUCT_POST_SUCCESS, payload: data });

//   } catch (error: any) {
//     return dispatch({
//       type: PRODUCT_POST_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };


export const ServiceDetailsGet = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERVICE_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.get(`/api/service/serviceGet/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: SERVICE_GET_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: SERVICE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const serviceUpdate = (id: any, prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERVICE_UPDATE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.put(`/api/service/put/${id}`, prod, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: SERVICE_UPDATE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: SERVICE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ServicesDeactive = (id: any, prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERVICE_DEACTIVE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.put(`/api/service/serviceDeactive/${id}`, prod, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: SERVICE_DEACTIVE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: SERVICE_DEACTIVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// export const ProductsDelete = (id: any) => async (dispatch: any, getState: any) => {
//   dispatch({ type: PRODUCT_DELETE_REQUEST });
//   try {
//     const {
//       userSignin: {
//         userInfo: { token },
//       },
//     } = getState();

//     const { data } = await api.delete(`/api/products/delete/${id}`, {
//       headers: { Authorization: `Bearer ${token?.tocken}` },
//     });
//     return dispatch({ type: PRODUCT_DELETE_SUCCESS, payload: data });

//   } catch (error: any) {
//     dispatch({
//       type: PRODUCT_DELETE_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };