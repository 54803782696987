import api from "../axiosConfig";
import { PAYMENTOUT_DELETE_FAIL, PAYMENTOUT_DELETE_REQUEST, PAYMENTOUT_DELETE_SUCCESS, PAYMENTOUT_EDIT_FINDONE_FAIL, PAYMENTOUT_EDIT_FINDONE_REQUEST, PAYMENTOUT_EDIT_FINDONE_SUCCESS, PAYMENTOUT_FIND_ALL_FAIL, PAYMENTOUT_FIND_ALL_REQUEST, PAYMENTOUT_FIND_ALL_SUCCESS, PAYMENTOUT_LIST_FAIL, PAYMENTOUT_LIST_REQUEST, PAYMENTOUT_LIST_SUCCESS, PAYMENTOUT_POST_FAIL, PAYMENTOUT_POST_REQUEST, PAYMENTOUT_POST_SUCCESS, PAYMENTOUT_UPDATE_FAIL, PAYMENTOUT_UPDATE_REQUEST, PAYMENTOUT_UPDATE_SUCCESS } from "../constants/paymentOutConstants";

export const paymentOutGetList =
    () => async (dispatch: any, getState: any) => {
        dispatch({ type: PAYMENTOUT_LIST_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.get(`/api/paymentoutget/${token?._id}`);

            return dispatch({ type: PAYMENTOUT_LIST_SUCCESS, payload: data });
        } catch (error: any) {
            dispatch({
                type: PAYMENTOUT_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };


    export const paymentOutBillList = () => async (dispatch: any, getState: any) => {
        dispatch({ type: PAYMENTOUT_FIND_ALL_REQUEST });
      
        try {
          const {
            userSignin: {
              userInfo: { token },
            },
          } = getState();
          const { data } = await api.get(`/api/purchase/find-paymentoutbill-all/${token?._id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
          });
          return dispatch({ type: PAYMENTOUT_FIND_ALL_SUCCESS, payload: data });
        } catch (error: any) {
          dispatch({
            type: PAYMENTOUT_FIND_ALL_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          });
        }
      };

      export const paymentOutPost = (value: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: PAYMENTOUT_POST_REQUEST});
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.post(`/api/paymentOut/save`, value, {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            });
            return dispatch({ type: PAYMENTOUT_POST_SUCCESS, payload: data });
        } catch (error: any) {
    
            return dispatch({
                type: PAYMENTOUT_POST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };      



    export const paymentOutDelete = (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: PAYMENTOUT_DELETE_REQUEST });
      try {
          const {
              userSignin: {
                  userInfo: { token },
              },
          } = getState();
  
          const { data } = await api.delete(`/api/paymentOutDelete/${id}`, {
              headers: { Authorization: `Bearer ${token?.tocken}` },
          });
          return dispatch({ type: PAYMENTOUT_DELETE_SUCCESS, payload: data });
  
      } catch (error: any) {
          dispatch({
              type: PAYMENTOUT_DELETE_FAIL,
              payload:
                  error.response && error.response.data.message
                      ? error.response.data.message
                      : error.message,
          });
      }
  };
  
      export const  paymentOutEditFindOne =
    (id: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: PAYMENTOUT_EDIT_FINDONE_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.get(`/api/paymentOutEditfindone/${id}`, {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            });
            return dispatch({
                type: PAYMENTOUT_EDIT_FINDONE_SUCCESS,
                payload: data,
            });
        } catch (error: any) {
            dispatch({
                type: PAYMENTOUT_EDIT_FINDONE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

    export const paymentOutUpdate =
    (id: any, paymentReceiveUpdatedata: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: PAYMENTOUT_UPDATE_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.put(
                `/api/paymentOutUpdate/put/${id}`,
                paymentReceiveUpdatedata,
                {
                    headers: { Authorization: `Bearer ${token?.tocken}` },
                },
            );
            return dispatch({ type: PAYMENTOUT_UPDATE_SUCCESS, payload: data });
        } catch (error: any) {
            dispatch({
                type: PAYMENTOUT_UPDATE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };



