import api from "../axiosConfig";
import { USER_CHANGE_PASSWORD_POST_FAIL, USER_CHANGE_PASSWORD_POST_REQUSET, USER_CHANGE_PASSWORD_POST_SUCCESS, USER_CHANGE_PASSWORD_UPDATE_FAIL, USER_CHANGE_PASSWORD_UPDATE_REQUSET, USER_CHANGE_PASSWORD_UPDATE_SUCCESS } from "../constants/userChangePwdConstants";


export const userChangePwd = (pwdvalue: any) => async (dispatch: any) => {

    dispatch({ type: USER_CHANGE_PASSWORD_POST_REQUSET });
    try {
       const { data } = await api.post(`/api/changepwd`, pwdvalue);
       return dispatch({ type: USER_CHANGE_PASSWORD_POST_SUCCESS, payload: data });
    } catch (error: any) {
       return dispatch({
          type: USER_CHANGE_PASSWORD_POST_FAIL,
          payload:
             error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
       });
    }
 };

 export const userUpdatePwd = (regInfo: any) => async (dispatch: any) => {
   dispatch({ type: USER_CHANGE_PASSWORD_UPDATE_REQUSET });
   try {
      const { data } = await api.post(`/api/update-password`, regInfo);
      return dispatch({ type: USER_CHANGE_PASSWORD_UPDATE_SUCCESS, payload: data });
   } catch (error: any) {
      return dispatch({
         type: USER_CHANGE_PASSWORD_UPDATE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};