import api from "../axiosConfig";
import { PURCHASE_FIXED_ASSET_DELETE_FAIL, PURCHASE_FIXED_ASSET_DELETE_REQUEST, PURCHASE_FIXED_ASSET_DELETE_SUCCESS, PURCHASE_FIXED_ASSET_FIND_ALL_FAIL, PURCHASE_FIXED_ASSET_FIND_ALL_REQUEST, PURCHASE_FIXED_ASSET_FIND_ALL_SUCCESS, PURCHASE_FIXED_ASSET_FIND_ONE_FAIL, PURCHASE_FIXED_ASSET_FIND_ONE_REQUEST, PURCHASE_FIXED_ASSET_FIND_ONE_SUCCESS, PURCHASE_FIXED_ASSET_POST_FAIL, PURCHASE_FIXED_ASSET_POST_REQUEST, PURCHASE_FIXED_ASSET_POST_SUCCESS, PURCHASE_FIXED_ASSET_UPDATE_FAIL, PURCHASE_FIXED_ASSET_UPDATE_REQUEST, PURCHASE_FIXED_ASSET_UPDATE_SUCCESS } from "../constants/purchaseFixedAssetConstants";

export const PurchaseFixedAssetSave = (prod: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: PURCHASE_FIXED_ASSET_POST_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.post(
        `/api/purchaseFixedAsset/purchaseFixedAssetSave`,
        prod,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        }
      );
      return dispatch({ type: PURCHASE_FIXED_ASSET_POST_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: PURCHASE_FIXED_ASSET_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }


  export const PurchaseFixedAssetGetList =
    () => async (dispatch: any, getState: any) => {
        dispatch({ type: PURCHASE_FIXED_ASSET_FIND_ALL_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
            const { data } = await api.get(`/api/purchaseFixedget/${token?._id}`);

            return dispatch({ type: PURCHASE_FIXED_ASSET_FIND_ALL_SUCCESS, payload: data });
        } catch (error: any) {
            dispatch({
                type: PURCHASE_FIXED_ASSET_FIND_ALL_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

    export const purchaseFixedAssetDelete = (id: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: PURCHASE_FIXED_ASSET_DELETE_REQUEST });
        try {
            const {
                userSignin: {
                    userInfo: { token },
                },
            } = getState();
    
            const { data } = await api.delete(`/api/purchaseFixedAssetDelete/${id}`, {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            });
            return dispatch({ type: PURCHASE_FIXED_ASSET_DELETE_SUCCESS, payload: data });
    
        } catch (error: any) {
            dispatch({
                type: PURCHASE_FIXED_ASSET_DELETE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

    export const purchaseFixedAssetFindOne =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: PURCHASE_FIXED_ASSET_FIND_ONE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/purchaseFixedAsset/purchaseFixedAssetfind-one/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({
        type: PURCHASE_FIXED_ASSET_FIND_ONE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: PURCHASE_FIXED_ASSET_FIND_ONE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
    

  export const purchaseFixedAssetUpdate =
  (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: PURCHASE_FIXED_ASSET_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/purchaseFixedAsset/purchaseFixedAssetput/${id}`,
        externalInvoice,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        },
      );
      return dispatch({ type: PURCHASE_FIXED_ASSET_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: PURCHASE_FIXED_ASSET_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };