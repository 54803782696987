export const PAYMENTOUT_LIST_REQUEST = 'PAYMENTOUT_LIST_REQUEST';
export const PAYMENTOUT_LIST_SUCCESS = 'PAYMENTOUT_LIST_SUCCESS';
export const PAYMENTOUT_LIST_FAIL = 'PAYMENTOUT_LIST_FAIL';
export const PAYMENTOUT_LIST_RESET = 'PAYMENTOUT_LIST_RESET';

export const PAYMENTOUT_FIND_ALL_REQUEST = 'PAYMENTOUT_FIND_ALL_REQUEST';
export const PAYMENTOUT_FIND_ALL_SUCCESS = 'PAYMENTOUT_FIND_ALL_SUCCESS';
export const PAYMENTOUT_FIND_ALL_FAIL = 'PAYMENTOUT_FIND_ALL_FAIL';
export const PAYMENTOUT_FIND_ALL_RESET = 'PAYMENTOUT_FIND_ALL_RESET';

export const PAYMENTOUT_POST_REQUEST = 'PAYMENTOUT_POST_REQUEST';
export const PAYMENTOUT_POST_SUCCESS = 'PAYMENTOUT_POST_SUCCESS';
export const PAYMENTOUT_POST_FAIL = 'PAYMENTOUT_POST_FAIL';
export const PAYMENTOUT_POST_RESET = 'PAYMENTOUT_POST_RESET';

export const PAYMENTOUT_DELETE_REQUEST = 'PAYMENTOUT_DELETE_REQUEST';
export const PAYMENTOUT_DELETE_SUCCESS = 'PAYMENTOUT_DELETE_SUCCESS';
export const PAYMENTOUT_DELETE_FAIL = 'PAYMENTOUT_DELETE_FAIL';
export const PAYMENTOUT_DELETE_RESET = 'PAYMENTOUT_DELETE_RESET';


export const PAYMENTOUT_EDIT_FINDONE_REQUEST = 'PAYMENTOUT_EDIT_FINDONE_REQUEST';
export const PAYMENTOUT_EDIT_FINDONE_SUCCESS = 'PAYMENTOUT_EDIT_FINDONE_SUCCESS';
export const PAYMENTOUT_EDIT_FINDONE_FAIL = 'PAYMENTOUT_EDIT_FINDONE_FAIL';
export const PAYMENTOUT_EDIT_FINDONE_RESET = 'PAYMENTOUT_EDIT_FINDONE_RESET';

export const PAYMENTOUT_UPDATE_REQUEST = "PAYMENTOUT_UPDATE_REQUEST";
export const PAYMENTOUT_UPDATE_SUCCESS = "PAYMENTOUT_UPDATE_SUCCESS";
export const PAYMENTOUT_UPDATE_FAIL = "PAYMENTOUT_UPDATE_FAIL";
export const PAYMENTOUT_UPDATE_RESET = "PAYMENTOUT_UPDATE_RESET";