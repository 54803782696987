import { useEffect, useState } from 'react';
interface props {
    toggleSidebar: any
    isOpen: any
}

const SideBarHeader = ({ toggleSidebar, isOpen }: props) => {

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );


  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, [matches]);
  return (
    <div className='sticky top-0' style={{ zIndex: 1 }}>
       {/* <nav className='bg-[#F1F7F6]  shadow-lg p-[22px] sticky top-0' >
        <nav className='flex pb-4' aria-label='Breadcrumb'>
          {(matches && isOpen) ? (
          //   <motion.button
          //   className="focus:outline-none rounded-md"
          //   onClick={toggleSidebar}
          //   initial={{
          //     translateX: 0,
          //   }}
          //   transition={{
          //     type: "spring",
          //     damping: 25,
          //   }}
          // >
          //   <svg
          //     xmlns="http://www.w3.org/2000/svg"
          //     className="h-6 w-6 "
          //     fill="none"
          //     viewBox="0 0 24 24"
          //     stroke="currentColor"
          //     strokeWidth={2}
          //   >
          //     <path
          //       strokeLinecap="round"
          //       strokeLinejoin="round"
          //       d={false ? "M4 6h16M4 12h16M4 18h7" : "M4 6h16M4 12h16M4 18h7"}
          //     />
          //   </svg>
            //   </motion.button>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                onClick={toggleSidebar}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d={false ? "M4 6h16M4 12h16M4 18h7" : "M4 6h16M4 12h16M4 18h7"}
                />
              </svg>
          ) : <></>
            
         }
             

            </nav>
        </nav> */}
    </div>
  )
}

export default SideBarHeader
