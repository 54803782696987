import api from "../axiosConfig";
import { TERM_DELETE_FAIL, TERM_DELETE_REQUEST, TERM_DELETE_SUCCESS, TERM_GET_FAIL, TERM_GET_ONE_FAIL, TERM_GET_ONE_REQUEST, TERM_GET_ONE_SUCCESS, TERM_GET_REQUEST, TERM_GET_SUCCESS, TERM_POST_FAIL, TERM_POST_REQUEST, TERM_POST_SUCCESS, TERM_UPDATE_FAIL, TERM_UPDATE_REQUEST, TERM_UPDATE_SUCCESS } from "../constants/termConstants ";


export const TermSave = (prod: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: TERM_POST_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();

      const { data } = await api.post(
         `/api/term/termSave`,
         prod,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({ type: TERM_POST_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: TERM_POST_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const termsGet = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: TERM_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();

      const { data } = await api.get(
         `/api/term/all/${id}`,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({ type: TERM_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: TERM_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const findTermDetail = () => async (dispatch: any, getState: any) => {
   dispatch({ type: TERM_GET_ONE_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();

      const { data } = await api.get(
         `/api/term/findOne/${token?._id}`,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({ type: TERM_GET_ONE_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: TERM_GET_ONE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const termsUpdate = (id: any, TERM: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: TERM_UPDATE_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();

      const { data } = await api.put(`/api/term/termUpdate/${id}`, TERM, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: TERM_UPDATE_SUCCESS, payload: data });

   } catch (error: any) {
      dispatch({
         type: TERM_UPDATE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const termDelete = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: TERM_DELETE_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();

      const { data } = await api.delete(`/api/term/deleteTerm/${id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: TERM_DELETE_SUCCESS, payload: data });

   } catch (error: any) {
      dispatch({
         type: TERM_DELETE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};
