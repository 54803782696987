import React from "react";
import { useNavigate } from "react-router-dom";

const Unauthorized: React.FC = () => {
  const navigateTo = useNavigate();

  const signoutHandler = () => {
    sessionStorage.removeItem("userInfo");
    navigateTo("/login");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#E1E8E7] ">
      <div className="max-w-md w-full p-8 bg-white shadow-lg rounded-lg ">
        <div
          className="bg-red-500 text-xl font-semibold  max-w-md w-full p-8 text-white flex justify-center items-center h-16 rounded-lg"
          style={{ fontFamily: "Poppins" }}
        >
          Access Denied
        </div>

        <div className="mt-2 text-2xl font-bold text-gray-800 mb-6" style={{ fontFamily: "Poppins" }}>
          Unauthorized - You Do Not Have Access To This Page.
        </div>
        <p className="text-gray-600" style={{ fontFamily: "Poppins" }}>
          Please Contact Your Admin For Access Permisssions.
        </p>
        <div className="flex justify-between mt-8" style={{ fontFamily: "Poppins" }}>
          <button
            className=" bg-[#008065] rounded-md text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => navigateTo("/app/home")}
          >
            Go to Home
          </button>
          <button
            className=" bg-[#008065] rounded-md text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={signoutHandler}
            style={{ fontFamily: "Poppins" }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
