/* eslint-disable eqeqeq */
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { assetSave, fixedAssetAll, fixedAssetUpdate } from "../../redux/actions/fixedAssetsAction";

const FixedAssets = () => {
    const usersignin = useSelector((state: any) => state?.userSignin);
    const { userInfo } = usersignin;
    const fixedasset = useSelector((state: any) => state?.fixedAsset);
    const { fixedAssetlist } = fixedasset;
    const userid = userInfo.token._id;
    const {
        register,
        reset,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            asset_name: "",
            transactionType: "Opening Assets",
            qty: "",
            rate: "",
            date: "",
            user_id: userid,
        },
    });
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const onSubmit = (data: any) => {
        let obj = {
            asset_name: data.asset_name,
            transactionType: "Opening Assets",
            qty: data.qty,
            rate: data.rate,
            amount: parseFloat(data.qty) * parseFloat(data.rate),
            totalAmount:parseFloat(data.qty) * parseFloat(data.rate),
            date: data.date,
            user_id: userid,
        }
        if (id) {
            dispatch(fixedAssetUpdate(id, obj) as any).then((res: any) => {
                if (res.type == "FIXED_ASSETS_UPDATE_SUCCESS") {
                    reset();
                }
                toast.success("Update SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            });
        } else {
            dispatch(assetSave(obj) as any).then((res: any) => {
                if (res.type === "FIXED_ASSETS_POST_SUCCESS") {
                    reset();
                    toast.success("Saved Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    navigate(`/app/fixedassetsReport`)
                }
            });
        }

    };
    const handleDateChange = (data: any) => {
    }
    const fetchData = () => {
        if (id) {
            dispatch(fixedAssetAll() as any).then((res: any) => {
                let value = res.payload
                setValue('qty', value[0]?.qty)
                setValue('asset_name', value[0]?.asset_name)
                setValue('transactionType', value[0]?.tranactionType)
                setValue('rate', value[0]?.rate)
                setValue('date', value[0]?.date)
            })
        }
    }

    const isAssetNameUnique = (value: string) => {
        return !fixedAssetlist.some((asset: any) => asset.asset_name === value)
            || "Asset name already exists";
    };


    useEffect(() => {
        const today = new Date().toISOString().split("T")[0];
        setValue("date", today);
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue]);

    const handleCancelClick = () => {
        reset();
    }
    return (
        <div className="bg-[#E1E8E7]">
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <fieldset className="mx-3 mb-4 rounded-lg">
                    <legend className="p-4 font-bold text-[#008065] text-lg ml-2">
                        Fixed Assets
                    </legend>


                    <div className="flex flex-col lg:flex-row xl:flex-row gap-2 pl-4  sm:px-3  sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-4">
                        <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                }}
                                className="text-[#008065] required"
                            >
                                <span>Fixed Asset Name</span>
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    className={
                                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    }
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: errors.asset_name
                                            ? "#FF0000"
                                            : "#008065",
                                    }}
                                    placeholder=" Name..."
                                    {...register("asset_name", {
                                        required: "Name is required",
                                        validate: isAssetNameUnique
                                    })}

                                />
                                {errors.asset_name && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.asset_name.message}</p>}
                            </div>
                        </div>

                        <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                }}
                                className="text-[#008065] "
                            >
                                <span>Opening Quantity</span>
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    className={
                                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    }
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: "#008065",
                                    }}
                                    placeholder="Quantity..."
                                    {...register("qty", {
                                    })}

                                />
                            </div>
                        </div>

                        <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                }}
                                className="text-[#008065] "
                            >
                                <span>Price/Unit</span>
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    className={
                                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    }
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: "#008065",
                                    }}
                                    placeholder="Price..."
                                    {...register("rate", {
                                    })}

                                />
                            </div>
                        </div>

                        <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                }}
                                className="text-[#008065] "
                            >
                                <span>Date</span>
                            </label>
                            <div className="relative  cursor-pointer">
                                <input
                                    type="date"
                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: "#008065",
                                    }}
                                    {...register("date", {
                                        required: "ate  is required",
                                    })}
                                    {...register("date", {
                                        onChange: (e) => handleDateChange(e.target.value),
                                    })}

                                />
                            </div>
                        </div>

                    </div>
                    <div className="rounded-xl px-3 mb-3">
                        <div className="flex w-full flex-col sm:flex-row pl-5">
                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                                <div className="flex flex-col  mb-4 "></div>
                            </div>

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                                <div className="flex flex-col  mb-4 "> </div>
                            </div>

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2">
                                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                                    <button
                                        type="button"
                                        className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                        style={{
                                            backgroundColor: "#008065",
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                        }}
                                        onClick={handleCancelClick}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-1 px-2">
                                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                                    <button
                                        type="submit"
                                        className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                                        style={{
                                            backgroundColor: "#008065",
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {id ? "Update" : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>


        </div>
    )
}

export default FixedAssets
