export const BANK_POST_REQUEST = 'BANK_POST_REQUEST';
export const BANK_POST_SUCCESS = 'BANK_POST_SUCCESS';
export const BANK_POST_FAIL = 'BANK_POST_FAIL';
export const BANK_POST_REST = 'BANK_POST_REST';


export const BANK_GET_REQUEST = 'BANK_GET_REQUEST';
export const BANK_GET_SUCCESS = 'BANK_GET_SUCCESS';
export const BANK_GET_FAIL = 'BANK_GET_FAIL';
export const BANK_GET_REST = 'BANK_GET_REST';

export const BANK_GET_ONE_REQUEST = 'BANK_GET_ONE_REQUEST';
export const BANK_GET_ONE_SUCCESS = 'BANK_GET_ONE_SUCCESS';
export const BANK_GET_ONE_FAIL = 'BANK_GET_ONE_FAIL';
export const BANK_GET_ONE_REST = 'BANK_GET_ONE_REST';

export const BANK_UPDATE_REQUEST = 'BANK_UPDATE_REQUEST';
export const BANK_UPDATE_SUCCESS = 'BANK_UPDATE_SUCCESS';
export const BANK_UPDATE_FAIL = 'BANK_UPDATE_FAIL';
export const BANK_UPDATE_REST = 'BANK_UPDATE_REST';

export const BANK_DELETE_REQUEST = 'BANK_DELETE_REQUEST';
export const BANK_DELETE_SUCCESS = 'BANK_DELETE_SUCCESS';
export const BANK_DELETE_FAIL = 'BANK_DELETE_FAIL';
export const BANK_DELETE_REST = 'BANK_DELETE_REST';