export const EXPENSE_POST_REQUEST = 'EXPENSE_POST_REQUEST';
export const EXPENSE_POST_SUCCESS = 'EXPENSE_POST_SUCCESS';
export const EXPENSE_POST_FAIL = 'EXPENSE_POST_FAIL';
export const EXPENSE_POST_RESET = 'EXPENSE_POST_RESET';

export const EXPENSE_GET_REQUEST = 'EXPENSE_GET_REQUEST';
export const EXPENSE_GET_SUCCESS = 'EXPENSE_GET_SUCCESS';
export const EXPENSE_GET_FAIL = 'EXPENSE_GET_FAIL';
export const EXPENSE_GET_RESET = 'EXPENSE_GET_RESET';

export const EXPENSE_GET_ONE_REQUEST = 'EXPENSE_GET_ONE_REQUEST';
export const EXPENSE_GET_ONE_SUCCESS = 'EXPENSE_GET_ONE_SUCCESS';
export const EXPENSE_GET_ONE_FAIL = 'EXPENSE_GET_ONE_FAIL';
export const EXPENSE_GET_ONE_RESET = 'EXPENSE_GET_ONE_RESET';

export const EXPENSE_UPDATE_REQUEST = 'EXPENSE_UPDATE_REQUEST';
export const EXPENSE_UPDATE_SUCCESS = 'EXPENSE_UPDATE_SUCCESS';
export const EXPENSE_UPDATE_FAIL = 'EXPENSE_UPDATE_FAIL';
export const EXPENSE_UPDATE_RESET = 'EXPENSE_UPDATE_RESET';

export const EXPENSE_DELETE_REQUEST = 'EXPENSE_DELETE_REQUEST';
export const EXPENSE_DELETE_SUCCESS = 'EXPENSE_DELETE_SUCCESS';
export const EXPENSE_DELETE_FAIL = 'EXPENSE_DELETE_FAIL';
export const EXPENSE_DELETE_RESET = 'EXPENSE_DELETE_RESET';