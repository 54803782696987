import { QUOTATION_DELETE_FAIL, QUOTATION_DELETE_REQUEST, QUOTATION_DELETE_SUCCESS, QUOTATION_FIND_ONE_FAIL, QUOTATION_FIND_ONE_REQUEST, QUOTATION_FIND_ONE_SUCCESS, QUOTATION_PAGE_FIND_ALL_FAIL, QUOTATION_PAGE_FIND_ALL_REQUEST, QUOTATION_PAGE_FIND_ALL_SUCCESS, QUOTATION_PAGE_POST_FAIL, QUOTATION_PAGE_POST_REQUEST, QUOTATION_PAGE_POST_SUCCESS, QUOTATION_UPDATE_FAIL, QUOTATION_UPDATE_REQUEST, QUOTATION_UPDATE_SUCCESS } from "../constants/quoatationConstants";
import api from "../axiosConfig";
export const quotationPost =
  (externalInvoice: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: QUOTATION_PAGE_POST_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();

      const { data } = await api.post(`/api/quotation/save`, externalInvoice, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: QUOTATION_PAGE_POST_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: QUOTATION_PAGE_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const quotationFindAll = () => async (dispatch: any, getState: any) => {
    dispatch({ type: QUOTATION_PAGE_FIND_ALL_REQUEST });
  
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/quotation/find-all/${token?._id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: QUOTATION_PAGE_FIND_ALL_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: QUOTATION_PAGE_FIND_ALL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const quotationFindOne =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: QUOTATION_FIND_ONE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/quotation/find-one/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({
        type: QUOTATION_FIND_ONE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: QUOTATION_FIND_ONE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const quotationUpdate =
  (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: QUOTATION_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/quotation/put/${id}`,
        externalInvoice,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        },
      );
      return dispatch({ type: QUOTATION_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: QUOTATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const quotationDelete =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: QUOTATION_DELETE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.delete(`/api/quotation/delete/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: QUOTATION_DELETE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: QUOTATION_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

