import api from "../axiosConfig";
import {
   VENDORDETAILS_GET_FAIL,
   VENDORDETAILS_GET_REQUEST,
   VENDORDETAILS_GET_SUCCESS,
   VENDORPRODUCTLIST_GET_FAIL,
   VENDORPRODUCTLIST_GET_REQUEST,
   VENDORPRODUCTLIST_GET_SUCCESS,
   VENDORPRODUCT_DELETE_FAIL,
   VENDORPRODUCT_DELETE_REQUEST,
   VENDORPRODUCT_DELETE_SUCCESS,
   VENDORPRODUCT_GET_FAIL,
   VENDORPRODUCT_GET_REQUEST,
   VENDORPRODUCT_GET_SUCCESS,
   VENDORPRODUCT_POST_FAIL,
   VENDORPRODUCT_POST_REQUEST,
   VENDORPRODUCT_POST_SUCCESS,
   VENDORPRODUCT_UPDATE_FAIL,
   VENDORPRODUCT_UPDATE_REQUEST,
   VENDORPRODUCT_UPDATE_SUCCESS,
   VENDOR_DELETE_FAIL,
   VENDOR_DELETE_REQUEST,
   VENDOR_DELETE_SUCCESS,
   VENDOR_GET_FAIL,
   VENDOR_GET_FIND_ONE_FAIL,
   VENDOR_GET_FIND_ONE_REQUEST,
   VENDOR_GET_FIND_ONE_SUCCESS,
   VENDOR_GET_REQUEST,
   VENDOR_GET_SUCCESS,
   VENDOR_POST_FAIL,
   VENDOR_POST_REQUEST,
   VENDOR_POST_SUCCESS,
   VENDOR_UPDATE_FAIL,
   VENDOR_UPDATE_REQUEST,
   VENDOR_UPDATE_SUCCESS,
} from "../constants/vendorConstants";

export const VendorSave =
   (vendor: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: VENDOR_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.post(`/api/vendor/vendorSave`, vendor, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: VENDOR_POST_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: VENDOR_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const VendorUpdate =
   (id: any, prod: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: VENDOR_UPDATE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.put(
            `/api/vendor/vendorUpdate/${id}`,
            prod,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({ type: VENDOR_UPDATE_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: VENDOR_UPDATE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const VendorAll = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: VENDOR_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(`/api/vendor/all/${id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: VENDOR_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: VENDOR_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const VendorFindOne =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: VENDOR_GET_FIND_ONE_REQUEST });
      try {
         // const {
         //    userSignin: {
         //       userInfo: { token },
         //    },
         // } = getState();
         const { data } = await api.get(`/api/vendor/${id}`);
         return dispatch({ type: VENDOR_GET_FIND_ONE_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: VENDOR_GET_FIND_ONE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const VendorDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: VENDOR_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/vendor/Vendordelete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: VENDOR_DELETE_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: VENDOR_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const VendorProductSave =
   (vendor: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: VENDORPRODUCT_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.post(
            `/api/vendors/vendorProdSave`,
            vendor,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({ type: VENDORPRODUCT_POST_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: VENDORPRODUCT_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const VendorProductAll = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: VENDORPRODUCT_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(`/api/vendors/vendorProductGet/${id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: VENDORPRODUCT_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: VENDORPRODUCT_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const VendorProductExisting = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: VENDORPRODUCT_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(`/api/vendors/vendorProductGet/${id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: VENDORPRODUCT_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: VENDORPRODUCT_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const VendorDetails = (vendorId: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: VENDORDETAILS_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(`/api/vendor/vendorDetails/${vendorId}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: VENDORDETAILS_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: VENDORDETAILS_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};


export const VendorProductDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: VENDORPRODUCT_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/vendors/VendorProductdelete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: VENDORPRODUCT_DELETE_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: VENDORPRODUCT_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const VendorProductUpdate =
   (id: any, prod: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: VENDORPRODUCT_UPDATE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.put(
            `/api/vendors/vendorProductUpdate/${id}`,
            prod,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({ type: VENDORPRODUCT_UPDATE_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: VENDORPRODUCT_UPDATE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

   export const VendorProductList = (vendorId: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: VENDORPRODUCTLIST_GET_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.get(`/api/vendors/vendorProductList/${vendorId}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: VENDORPRODUCTLIST_GET_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: VENDORPRODUCTLIST_GET_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };