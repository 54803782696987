import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	BarElement,
	Title,
	Tooltip,
	ArcElement,
} from "chart.js";
import React, { useEffect, useMemo, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { salesReturnGet } from "../../redux/actions/salesReturnAction";
import { InternalFindAll } from "../../redux/actions/InternalInvoiceAction";
import { serviceFindAll } from "../../redux/actions/invoiceServiceActions";
import { expenseDetailAll } from "../../redux/actions/expensePageActions";
import { currencyGet } from "../../redux/actions/currencyAction";

// Register Chart.js components
ChartJS.register(
	CategoryScale,
	LinearScale,
	LineElement,
	PointElement,
	BarElement,
	ArcElement,
	Title,
	Tooltip,
	Legend
);

type SelectedRange =
	| { range: string }
	| { range: string; startDate: string; endDate: string };

interface DashboardPieDiaProps {
	selectedRange: SelectedRange;
}

const DashboardPieDia: React.FC<DashboardPieDiaProps> = ({ selectedRange }) => {
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;
	const dispatch = useDispatch();
	const [salesReturnDetails, setSalseDetails] = useState([] as any);
	const [invoiceTable, setInvoiceTable] = useState([] as any);
	const [serviceInvoiceTable, setserviceInvoiceTable] = useState([] as any);
	const [expenseDetails, setExpenseDetails] = useState([] as any);
	const [currencySymbol, setcurrencySymbol] = useState("₹");
	const [pieData, setPieData] = useState({
		labels: ["Sales", "Expenses", "Revenue", "Stock Value"],
		datasets: [
			{
				data: [0, 0, 0, 0], // Initial data values set to 0
				backgroundColor: [
					"rgba(75, 192, 192, 0.6)",
					"rgba(255, 99, 132, 0.6)",
					"rgba(153, 102, 255, 0.6)",
					"rgba(255, 159, 64, 0.6)",
				],
				hoverBackgroundColor: [
					"rgba(75, 192, 192, 0.8)",
					"rgba(255, 99, 132, 0.8)",
					"rgba(153, 102, 255, 0.8)",
					"rgba(255, 159, 64, 0.8)",
				],
			},
		],
	});

	const fetchSalesData = () => {
		dispatch(salesReturnGet() as any).then((response: any) =>
			setSalseDetails(response?.payload)
		);
		dispatch(InternalFindAll() as any).then((response: any) => {
			if (response && response.payload) setInvoiceTable(response.payload);
		});
		dispatch(serviceFindAll() as any).then((response: any) => {
			if (response && response.payload)
				setserviceInvoiceTable(response.payload);
		});
		dispatch(expenseDetailAll() as any).then((response: any) =>
			setExpenseDetails(response?.payload)
		);
	};

	useEffect(() => {
		fetchSalesData();
	}, []);

	const mergedArray = useMemo(
		() => invoiceTable.concat(serviceInvoiceTable),
		[invoiceTable, serviceInvoiceTable]
	);

	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	}, []);
	useEffect(() => {
		if (selectedRange.range === "Today") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setHours(23, 59, 59, 999);

			const totalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= todayStart && invoiceDate <= todayEnd;
			});

			let serviceprototal = 0;
			let collected = 0;

			let unpaid = 0;
			let parcollect = 0;
			let parout = 0;
			for (let j = 0; j < totalSales.length; j++) {
				serviceprototal += Number(totalSales[j].toatalAmount);

				const sales = totalSales[j];
				if (sales.paymentStatus === "PAID") {
					collected += Number(totalSales[j].toatalAmount);
				} else if (sales.paymentStatus === "PARTIALLY PAID") {
					parout += Number(totalSales[j].balanceAmount);
					parcollect += Number(totalSales[j].receivedAmount);
				} else if (sales.paymentStatus === "UNPAID") {
					unpaid += Number(totalSales[j].toatalAmount);
				}
			}
			const totcollect = collected + parcollect;

			const totoutstanding = unpaid + parout;
			setPieData({
				labels: ["Sales", "Collected", "Outstanding"],
				datasets: [
					{
						data: [serviceprototal, totcollect, totoutstanding],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
							"rgba(153, 102, 255, 0.8)",
							"rgba(255, 159, 64, 0.8)",
						],
					},
				],
			});
		} else if (selectedRange.range === "Last 7 days") {
			const today = new Date();

			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);

			const sevenDaysAgo = new Date(todayStart);
			sevenDaysAgo.setDate(todayStart.getDate() - 7);

			const totalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sevenDaysAgo && invoiceDate <= todayEnd;
			});

			let serviceprototal = 0;
			let collected = 0;

			let unpaid = 0;
			let parcollect = 0;
			let parout = 0;
			for (let j = 0; j < totalSales.length; j++) {
				serviceprototal += Number(totalSales[j].toatalAmount);

				const sales = totalSales[j];
				if (sales.paymentStatus === "PAID") {
					collected += Number(totalSales[j].toatalAmount);
				} else if (sales.paymentStatus === "PARTIALLY PAID") {
					parout += Number(totalSales[j].balanceAmount);
					parcollect += Number(totalSales[j].receivedAmount);
				} else if (sales.paymentStatus === "UNPAID") {
					unpaid += Number(totalSales[j].toatalAmount);
				}
			}
			const totcollect = collected + parcollect;

			const totoutstanding = unpaid + parout;
			setPieData({
				labels: ["Sales", "Collected", "Outstanding"],
				datasets: [
					{
						data: [serviceprototal, totcollect, totoutstanding],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
							"rgba(153, 102, 255, 0.8)",
							"rgba(255, 159, 64, 0.8)",
						],
					},
				],
			});
		} else if (selectedRange.range === "Last 1 month") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);

			const oneMonthAgo = new Date(today);
			oneMonthAgo.setMonth(today.getMonth() - 1);

			const totalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= oneMonthAgo && invoiceDate <= todayStart;
			});

			let serviceprototal = 0;
			let collected = 0;

			let unpaid = 0;
			let parcollect = 0;
			let parout = 0;
			for (let j = 0; j < totalSales.length; j++) {
				serviceprototal += Number(totalSales[j].toatalAmount);
				const sales = totalSales[j];
				if (sales.paymentStatus === "PAID") {
					collected += Number(totalSales[j].toatalAmount);
				} else if (sales.paymentStatus === "PARTIALLY PAID") {
					parout += Number(totalSales[j].balanceAmount);
					parcollect += Number(totalSales[j].receivedAmount);
				} else if (sales.paymentStatus === "UNPAID") {
					unpaid += Number(totalSales[j].toatalAmount);
				}
			}
			const totcollect = collected + parcollect;

			const totoutstanding = unpaid + parout;
			setPieData({
				labels: ["Sales", "Collected", "Outstanding"],
				datasets: [
					{
						data: [serviceprototal, totcollect, totoutstanding],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
							"rgba(153, 102, 255, 0.8)",
							"rgba(255, 159, 64, 0.8)",
						],
					},
				],
			});
		} else if (selectedRange.range === "Last 3 month") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const threeMonthAgo = new Date(today);
			threeMonthAgo.setMonth(today.getMonth() - 3);

			const totalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= threeMonthAgo && invoiceDate <= todayStart;
			});

			let serviceprototal = 0;
			let collected = 0;

			let unpaid = 0;
			let parcollect = 0;
			let parout = 0;
			for (let j = 0; j < totalSales.length; j++) {
				serviceprototal += Number(totalSales[j].toatalAmount);

				const sales = totalSales[j];
				if (sales.paymentStatus === "PAID") {
					collected += Number(totalSales[j].toatalAmount);
				} else if (sales.paymentStatus === "PARTIALLY PAID") {
					parout += Number(totalSales[j].balanceAmount);
					parcollect += Number(totalSales[j].receivedAmount);
				} else if (sales.paymentStatus === "UNPAID") {
					unpaid += Number(totalSales[j].toatalAmount);
				}
			}
			const totcollect = collected + parcollect;

			const totoutstanding = unpaid + parout;
			setPieData({
				labels: ["Sales", "Collected", "Outstanding"],
				datasets: [
					{
						data: [serviceprototal, totcollect, totoutstanding],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
							"rgba(153, 102, 255, 0.8)",
							"rgba(255, 159, 64, 0.8)",
						],
					},
				],
			});
		} else if (selectedRange.range === "Last 6 month") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);

			const sixMonthAgo = new Date(today);
			sixMonthAgo.setMonth(today.getMonth() - 6);

			const totalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sixMonthAgo && invoiceDate <= todayStart;
			});

			let serviceprototal = 0;
			let collected = 0;

			let unpaid = 0;
			let parcollect = 0;
			let parout = 0;
			for (let j = 0; j < totalSales.length; j++) {
				serviceprototal += Number(totalSales[j].toatalAmount);

				const sales = totalSales[j];
				if (sales.paymentStatus === "PAID") {
					collected += Number(totalSales[j].toatalAmount);
				} else if (sales.paymentStatus === "PARTIALLY PAID") {
					parout += Number(totalSales[j].balanceAmount);
					parcollect += Number(totalSales[j].receivedAmount);
				} else if (sales.paymentStatus === "UNPAID") {
					unpaid += Number(totalSales[j].toatalAmount);
				}
			}
			const totcollect = collected + parcollect;

			const totoutstanding = unpaid + parout;
			setPieData({
				labels: ["Sales", "Collected", "Outstanding"],
				datasets: [
					{
						data: [serviceprototal, totcollect, totoutstanding],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
							"rgba(153, 102, 255, 0.8)",
							"rgba(255, 159, 64, 0.8)",
						],
					},
				],
			});
		} else if (selectedRange.range === "Last 12 month") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const twelveMonthAgo = new Date(today);
			twelveMonthAgo.setMonth(today.getMonth() - 12);

			const totalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= twelveMonthAgo && invoiceDate <= todayStart;
			});

			let serviceprototal = 0;
			let collected = 0;

			let unpaid = 0;
			let parcollect = 0;
			let parout = 0;
			for (let j = 0; j < totalSales.length; j++) {
				serviceprototal += Number(totalSales[j].toatalAmount);

				const sales = totalSales[j];
				if (sales.paymentStatus === "PAID") {
					collected += Number(totalSales[j].toatalAmount);
				} else if (sales.paymentStatus === "PARTIALLY PAID") {
					parout += Number(totalSales[j].balanceAmount);
					parcollect += Number(totalSales[j].receivedAmount);
				} else if (sales.paymentStatus === "UNPAID") {
					unpaid += Number(totalSales[j].toatalAmount);
				}
			}
			const totcollect = collected + parcollect;
			const totoutstanding = unpaid + parout;
			setPieData({
				labels: ["Sales", "Collected", "Outstanding"],
				datasets: [
					{
						data: [serviceprototal, totcollect, totoutstanding],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
							"rgba(153, 102, 255, 0.8)",
							"rgba(255, 159, 64, 0.8)",
						],
					},
				],
			});
		} else if (selectedRange.range === "Last 16 month") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const sixteenMonthAgo = new Date(today);
			sixteenMonthAgo.setMonth(today.getMonth() - 16);

			const totalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sixteenMonthAgo && invoiceDate <= todayStart;
			});

			let serviceprototal = 0;
			let collected = 0;

			let unpaid = 0;
			let parcollect = 0;
			let parout = 0;
			for (let j = 0; j < totalSales.length; j++) {
				serviceprototal += Number(totalSales[j].toatalAmount);

				const sales = totalSales[j];
				if (sales.paymentStatus === "PAID") {
					collected += Number(totalSales[j].toatalAmount);
				} else if (sales.paymentStatus === "PARTIALLY PAID") {
					parout += Number(totalSales[j].balanceAmount);
					parcollect += Number(totalSales[j].receivedAmount);
				} else if (sales.paymentStatus === "UNPAID") {
					unpaid += Number(totalSales[j].toatalAmount);
				}
			}
			const totcollect = collected + parcollect;

			const totoutstanding = unpaid + parout;
			setPieData({
				labels: ["Sales", "Collected", "Outstanding"],
				datasets: [
					{
						data: [serviceprototal, totcollect, totoutstanding],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
							"rgba(153, 102, 255, 0.8)",
							"rgba(255, 159, 64, 0.8)",
						],
					},
				],
			});
		} else if (
			selectedRange.range === "Custom" &&
			"startDate" in selectedRange &&
			"endDate" in selectedRange
		) {
			const startDate = new Date(selectedRange.startDate);
			const endDate = new Date(selectedRange.endDate);
			endDate.setHours(23, 59, 59, 999);

			const totalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= startDate && invoiceDate <= endDate;
			});

			let serviceprototal = 0;
			let collected = 0;

			let unpaid = 0;
			let parcollect = 0;
			let parout = 0;
			for (let j = 0; j < totalSales.length; j++) {
				serviceprototal += Number(totalSales[j].toatalAmount);

				const sales = totalSales[j];
				if (sales.paymentStatus === "PAID") {
					collected += Number(totalSales[j].toatalAmount);
				} else if (sales.paymentStatus === "PARTIALLY PAID") {
					parout += Number(totalSales[j].balanceAmount);
					parcollect += Number(totalSales[j].receivedAmount);
				} else if (sales.paymentStatus === "UNPAID") {
					unpaid += Number(totalSales[j].toatalAmount);
				}
			}
			const totcollect = collected + parcollect;

			const totoutstanding = unpaid + parout;
			setPieData({
				labels: ["Sales", "Collected", "Outstanding"],
				datasets: [
					{
						data: [serviceprototal, totcollect, totoutstanding],
						backgroundColor: [
							"rgba(75, 192, 192, 0.6)",
							"rgba(255, 99, 132, 0.6)",
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
							"rgba(153, 102, 255, 0.8)",
							"rgba(255, 159, 64, 0.8)",
						],
					},
				],
			});
		}
	}, [selectedRange, mergedArray, expenseDetails]);

	const pieOptions = {
		responsive: true,
		plugins: {
			title: {
				display: true,
				text: "Pie Chart Example",
			},
			tooltip: {
				callbacks: {
					label: function (context: any) {
						return context.label + currencySymbol + context.raw.toFixed(2);
					},
				},
			},
		},
	};

	return (
		<div className="rounded-md col-span-2">
			<div
				className="chart-container"
				style={{ width: "350px", height: "350px", margin: "auto" }}
			>
				<Pie data={pieData} options={pieOptions} />
			</div>
		</div>
	);
};

export default DashboardPieDia;
