import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { userCode } from "../../redux/actions/userAction";

const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: any) => {
    // data.path = "forgetPassword";
    // data.fname = null;
    // data.lname = null;
    // data.phone = null;
    // data.taxType = null;
    // data.invoiceType = null;
    // data.billModule = null;
    // data.gstValue = null;
    // dispatch(registers(data) as any).then((res: any) => {
    //   console.log('registersdata=========.',data)
    //   if (res?.type === "USER_REGISTER_FAIL") {
    //     Swal.fire({
    //       position: "center",
    //       icon: "error",
    //       text: 'Enter Valid Email Address',
    //       timer: 3000,
    //       showConfirmButton: false,
    //     });
    //   } else {
    //     Swal.fire({
    //       position: "center",
    //       icon: "success",
    //       text: res?.payload.message,
    //       timer: 3000,
    //       showConfirmButton: false,
    //     });
    //     navigateTo("/verify", { state: data.email });
    //   }
    // });
    dispatch(userCode(data.email) as any).then((res: any) => {
      let code = res?.payload.code ? res?.payload.code : "";
      if (res?.type === "USER_CODE_FAIL") {
        Swal.fire({
          position: "center",
          icon: "error",
          text: "User Does Not Exist",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res?.payload.message,
          timer: 3000,
          showConfirmButton: false,
        });
        navigateTo("/verify", { state: { code: code, email: data.email } });
      }
    });
  };
  return (
    <section className=" flex items-center justify-center 2xl:mt-20 mb-10">
      <div className="  flex rounded-2xl  ">
        <div className="w-full lg:w-full  rounded-lg lg:rounded-l-none mt-8">
          <div className=" flex flex-col items-center justify-center relative">
            <div className="flex flex-col bg-[#008065] text-center   shadow-md px-4 sm:px-6 md:px-10 lg:px-10 py-16 h-full  w-full max-w-md rounded-t">
              <span
                style={{ fontFamily: "poppins" }}
                className="text-[#FFF] text-center text-xl font-bold mb-6 py-1 place-self-center"
              >
                
                Forget Password
              </span>
            </div>
            <div className="flex flex-col shadow-lg shadow-gray-400 px-4 sm:px-6 md:px-10 lg:px-10 py-8  w-full max-w-md rounded-b ">
              <div className="flex flex-col bg-white  -mt-28 shadow-lg shadow-gray-400  px-4 sm:px-6 md:px-10 lg:px-10 py-8 rounded-md w-full max-w-md ">
                <div className="pt-1  ">
                  <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="flex flex-col mb-6">
                      <label className="mb-1 text-xs sm:text-sm tracking-wide text-[#008065]">
                        Enter Your Email Address:
                      </label>
                      <div className="relative">
                        <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                          <svg
                            className="h-6 w-6"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                          </svg>
                        </div>

                        <input
                          type="text"
                          // name="email"
                          {...register("email", {
                            required: "Enter Email",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: errors?.email ? "" : "#008065",
                          }}
                          className={
                            `${errors?.email ? "border-red-600" : ""} ` +
                            "text-sm sm:text-base pl-10 pr-4 rounded-lg  py-2 border-2 focus:outline-none rounded-[7px] px-2 h-9 w-[100%]"
                          }
                          placeholder="Enter Your Email"
                        />
                      </div>
                      {errors?.email && (
                        <p className="text-red-400 text-xs">
                          {errors?.email?.message as any}
                        </p>
                      )}
                    </div>

                    <div className="flex w-full">
                      <button
                        type="submit"
                        // onClick={() => navigateTo('/OtpScreenPage')}
                        className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#008065] rounded py-2 w-full transition duration-150 ease-in"
                      >
                        <span className="mr-2 ">Next</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
