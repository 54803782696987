export const PAYMENTRECEIVE_LIST_REQUEST = 'PAYMENTRECEIVE_LIST_REQUEST';
export const PAYMENTRECEIVE_LIST_SUCCESS = 'PAYMENTRECEIVE_LIST_SUCCESS';
export const PAYMENTRECEIVE_LIST_FAIL = 'PAYMENTRECEIVE_LIST_FAIL';
export const PAYMENTRECEIVE_LIST_RESET = 'PAYMENTRECEIVE_LIST_RESET';

export const PAYMENTRECEIVE_DELETE_REQUEST = 'PAYMENTRECEIVE_DELETE_REQUEST';
export const PAYMENTRECEIVE_DELETE_SUCCESS = 'PAYMENTRECEIVE_DELETE_SUCCESS';
export const PAYMENTRECEIVE_DELETE_FAIL = 'PAYMENTRECEIVE_DELETE_FAIL';

export const PAYMENTRECEIVE_POST_REQUEST = "PAYMENTRECEIVE_POST_REQUEST";
export const PAYMENTRECEIVE_POST_SUCCESS = "PAYMENTRECEIVE_POST_SUCCESS";
export const PAYMENTRECEIVE_POST_FAIL = "PAYMENTRECEIVE_POST_FAIL";
export const PAYMENTRECEIVE_POST_RESET = "PAYMENTRECEIVE_POST_RESET";

export const PAYMENTRECEIVE_EDIT_FINDONE_REQUEST = "PAYMENTRECEIVE_EDIT_FINDONE_REQUEST";
export const PAYMENTRECEIVE_EDIT_FINDONE_SUCCESS = "PAYMENTRECEIVE_EDIT_FINDONE_SUCCESS";
export const PAYMENTRECEIVE_EDIT_FINDONE_FAIL = "PAYMENTRECEIVE_EDIT_FINDONE_FAIL";
export const PAYMENTRECEIVE_EDIT_FINDONE_RESET = "PAYMENTRECEIVE_EDIT_FINDONE_RESET";

export const PAYMENTRECEIVE_UPDATE_REQUEST = "PAYMENTRECEIVE_UPDATE_REQUEST";
export const PAYMENTRECEIVE_UPDATE_SUCCESS = "PAYMENTRECEIVE_UPDATE_SUCCESS";
export const PAYMENTRECEIVE_UPDATE_FAIL = "PAYMENTRECEIVE_UPDATE_FAIL";
export const PAYMENTRECEIVE_UPDATE_RESET = "PAYMENTRECEIVE_UPDATE_RESET";

