import api from "../axiosConfig";
import {
   BANK_DELETE_FAIL,
   BANK_DELETE_REQUEST,
   BANK_DELETE_SUCCESS,
   BANK_GET_FAIL,
   BANK_GET_ONE_FAIL,
   BANK_GET_ONE_REQUEST,
   BANK_GET_ONE_SUCCESS,
   BANK_GET_REQUEST,
   BANK_GET_SUCCESS,
   BANK_POST_FAIL,
   BANK_POST_REQUEST,
   BANK_POST_SUCCESS,
   BANK_UPDATE_FAIL,
   BANK_UPDATE_REQUEST,
   BANK_UPDATE_SUCCESS,
} from "../constants/bankConstants";

export const BankSave = (prod: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: BANK_POST_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.post(
         `/api/bank/bankSave`,
         prod,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({ type: BANK_POST_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: BANK_POST_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const BanksGet = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: BANK_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(
         `/api/bank/all/${id}`,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({ type: BANK_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: BANK_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const getBankDetail = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: BANK_GET_ONE_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(
         `/api/bank/findOne/${id}`,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({ type: BANK_GET_ONE_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: BANK_GET_ONE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const BanksUpdate = (id: any, bank: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: BANK_UPDATE_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.put(`/api/bank/bankUpdate/${id}`, bank, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: BANK_UPDATE_SUCCESS, payload: data });

   } catch (error: any) {
      dispatch({
         type: BANK_UPDATE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const BankDelete = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: BANK_DELETE_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.delete(`/api/bank/deleteBank/${id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: BANK_DELETE_SUCCESS, payload: data });

   } catch (error: any) {
      dispatch({
         type: BANK_DELETE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};
