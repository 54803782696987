export const NOTES_POST_REQUEST = 'NOTES_POST_REQUEST';
export const NOTES_POST_SUCCESS = 'NOTES_POST_SUCCESS';
export const NOTES_POST_FAIL = 'NOTES_POST_FAIL';
export const NOTES_POST_REST = 'NOTES_POST_REST';

export const NOTES_GET_REQUEST = 'NOTES_GET_REQUEST';
export const NOTES_GET_SUCCESS = 'NOTES_GET_SUCCESS';
export const NOTES_GET_FAIL = 'NOTES_GET_FAIL';
export const NOTES_GET_REST = 'NOTES_GET_REST';

export const NOTES_UPDATE_REQUEST = 'NOTES_UPDATE_REQUEST';
export const NOTES_UPDATE_SUCCESS = 'NOTES_UPDATE_SUCCESS';
export const NOTES_UPDATE_FAIL = 'NOTES_UPDATE_FAIL';
export const NOTES_UPDATE_REST = 'NOTES_UPDATE_REST';

export const NOTES_DELETE_REQUEST = 'NOTES_DELETE_REQUEST';
export const NOTES_DELETE_SUCCESS = 'NOTES_DELETE_SUCCESS';
export const NOTES_DELETE_FAIL = 'NOTES_DELETE_FAIL';
export const NOTES_DELETE_REST = 'NOTES_DELETE_REST';