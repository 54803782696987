import { useState } from 'react';
import NoteModel from '../../models/NoteModel';

const Notes = ({ formData, handleChange, setFormData, setIsNotesOpen, notesList, editValue, handleOpen }: any) => {
    const [isModalNote, setIsModalNote] = useState(false);
    const handleTerm = () => {
        setIsModalNote(true);
    };
    const closeModal = () => {
        setIsModalNote(false);
    }

    const handleSelect = (data: any) => {
        setFormData({
            ...formData,
            notes: data.notes,
        });
        setIsModalNote(false);
    };

 
    return (
        <div className='sm:pl-5'>
            <div
                className={`felx-col`}
            >
                <div className="flex justify-between">
                    <label className="block mb-2 mt-1 text-sm font-semibold text-black">
                        Notes:
                    </label>
                    <div className="block mb-2 mt-1 ">
                        <button
                            type="button"
                            className="mr-6 text-sm font-semibold text-[#008065]"
                            onClick={handleTerm}
                        >
                            Add Existing Notes
                        </button>
                    </div>
                </div>
                {isModalNote && <NoteModel closeModal={closeModal} handleSelect={handleSelect} />}
                <div className="flex flex-row">
                    <textarea
                        id="message"
                        className="block p-2.5 w-full  h-32 text-sm border-2 focus:outline-none rounded border-[#008065] border-solid "
                        placeholder="Write your thoughts here..."
                        name="notes"
                        value={formData.notes}
                        onChange={(e: any) => handleChange(e)}
                   
                    ></textarea>

                    <div>
                        <button onClick={() => handleOpen("addTruenotes")} type="button">
                            <svg
                                className="h-6  text-grey"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Notes
