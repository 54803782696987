import api from "../axiosConfig";
import { SALES_GET_FAIL, SALES_GET_REQUEST, SALES_GET_SUCCESS, SALES_POST_FAIL, SALES_POST_REQUEST, SALES_POST_SUCCESS } from "../constants/SalesConstants";

export const SalesPostMethod = (sales: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: SALES_POST_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();

        const { data } = await api.post(`/api/sales/save`, sales, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: SALES_POST_SUCCESS, payload: data });

    } catch (error: any) {
        dispatch({
            type: SALES_POST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const SalesgettMethod = () => async (dispatch: any, getState: any) => {
    dispatch({ type: SALES_GET_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();

        // const { data } = await Axios.get(`/api/Sales/all/${token?._id}`, {
        //     headers: { Authorization: `Bearer ${token?.tocken}` },
        // });
        // return dispatch({ type: SALES_GET_SUCCESS, payload: data });

        const { data } = await api.get(`/api/sales/getAllproduct/${token?._id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: SALES_GET_SUCCESS, payload: data.products });

    } catch (error: any) {
        dispatch({
            type: SALES_GET_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};