import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ExternalFindAll } from "../../redux/actions/externalInvoiceAction";

function ExternalInvoice() {
  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState("default");

  const externaluserdetails = useSelector((state: any) => state.externalGetAll);
  const { externalgetdata } = externaluserdetails;


  const handleDownload = (data: any) => {
    const queryParams = new URLSearchParams();
    queryParams.append("param1", "#000000");
    queryParams.append("param2", "#000000");
    queryParams.append("param3", "#000000");
    queryParams.append("param4", "#000000");
    fetch(`/api/generate_pdf/${data._id.$oid}?${queryParams.toString()}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link: any = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    let data = [...externalgetdata];
    data = data?.filter((value: any) =>
      value?.companyName?.toLowerCase().includes(query?.toLowerCase())
    );
    setExternalData(data);
  };

  const [ExternalData, setExternalData] = useState([] as any);

  useEffect(() => {
    dispatch(ExternalFindAll() as any).then((res: any) => {
      if (res && res.type === "EXTERNAL_INVOICE_FIND_ALL_SUCCESS") {
        setExternalData(res.payload);
      }
    });
  }, []);

  const handleSortChange = (newSortOrder: any) => {
    const sortedData = [...externalgetdata].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.companyName.localeCompare(b.companyName);
      } else {
        return b.companyName.localeCompare(a.companyName);
      }
    });
    setExternalData(sortedData);
    setSortOrder(newSortOrder);
  };

  const rows = ExternalData?.map((item: any, index: any) => ({
    id: uuidv4(),
    ...item,
    Sl_No: index + 1,
  }));

  const columns: GridColDef[] = [
    {
      field: "Sl_No",
      headerName: "S No",
      width: 270,
      editable: false,
    },
    {
      field: "companyName",
      headerName: "BILLED BY",
      editable: false,
      width: 300,
      renderCell: (params: any) => (
        <>{params?.row?.companyName ? params?.row?.companyName : "N/A"}</>
      ),
    },
    {
      field: "clientName",
      headerName: "SHIPPED BY",
      editable: false,
      width: 300,
      renderCell: (params: any) => (
        <>{params?.row?.clientName ? params?.row?.clientName : "N/A"}</>
      ),
    },
    {
      field: "unit_description",
      headerName: "DOWNLOAD",
      editable: false,
      width: 300,
      renderCell: (params: any) => (
        <>
          <IconButton onClick={() => handleDownload(params.row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#00A787"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div className="">
      <div className="p-4 text-2xl font-bold text-[#045545] mx-2">
        External Invoices
      </div>
      <div className="bg-[#F1F7F6]  rounded-xl px-3 py-3 mx-2">
        <div className="flex justify-between flex-col lg:flex-row md:flex-row xl:flex-row 2xl:flex-row ">
          <div className="flex items-center">
            <div className="relative flex mb-2">
              <input
                onChange={(e) => onSearch(e)}
                type="text"
                id="simple-search"
                className="w-48 bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                placeholder="Search"
                required
              />
              <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="block mb-2">
            <div className="relative flex items-center">
              <select
                defaultValue={""}
                id="sortDropdown"
                onChange={(e) => handleSortChange(e.target.value)}
                className="block  h-[2.6rem] w-48 p-2 text-sm  border border-[#00A787] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
              >
                <option value="" disabled  hidden>
                  Sort By
                </option>
                <option value="asc">Product Name A to Z</option>
                <option value="desc">Product Name Z to A</option>
              </select>
            </div>
          </div>
        </div>

        <Box
          sx={{
            height: 520,
            width: "100%",
            overflowX: "hidden !important",
            overflowY: "auto",
          }}
        >
          <DataGrid
autoHeight
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 500,
              ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                {
                  backgroundColor: "#fff",
                  color: "#008060 !important",
                  fontWeight: 600,
                  fontSize: "14px",
                },
              "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
              ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                fontWeight: 600,
                fontSize: "14px",
              },
              "@media (min-width: 2555px)": {
                ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
                  {
                    minWidth: "550px !important",
                  },
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </div>
    </div>
  );
}

export default ExternalInvoice;
