import { PURCHASE_INVOICE_FIND_ALL_FAIL, PURCHASE_INVOICE_FIND_ALL_REQUEST, PURCHASE_INVOICE_FIND_ALL_REST, PURCHASE_INVOICE_FIND_ALL_SUCCESS, PURCHASE_NEW_POST_FAIL, PURCHASE_NEW_POST_REQUEST, PURCHASE_NEW_POST_RESET, PURCHASE_NEW_POST_SUCCESS } from "../constants/purchasePageNewConstants";

export const purchaseNewPostReducer = (state = {}, action: any) => {
    switch (action.type) {
        case PURCHASE_NEW_POST_REQUEST:
            return { loading: true };
        case PURCHASE_NEW_POST_SUCCESS:
            return { loading: false,purchaseNewlist: action.payload };
        case PURCHASE_NEW_POST_FAIL:
            return { loading: false, error: action.payload };
        case PURCHASE_NEW_POST_RESET:
            return {};
        default:
            return state;
    }
};

export const purchaseNewGetReducer = (state = {}, action: any) => {
    switch (action.type) {
        case PURCHASE_INVOICE_FIND_ALL_REQUEST:
            return { loading: true };
        case PURCHASE_INVOICE_FIND_ALL_SUCCESS:
            return { loading: false, purchaseNewlist: action.payload };
        case PURCHASE_INVOICE_FIND_ALL_FAIL:
            return { loading: false, error: action.payload };
        case PURCHASE_INVOICE_FIND_ALL_REST:
            return {};
        default:
            return state;
    }
};