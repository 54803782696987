import api from "../axiosConfig";
import { PURCHASE_DELETE_FAIL, PURCHASE_DELETE_REQUEST, PURCHASE_DELETE_SUCCESS, PURCHASE_GET_FAIL, PURCHASE_GET_REQUEST, PURCHASE_GET_SUCCESS, PURCHASE_POST_FAIL, PURCHASE_POST_REQUEST, PURCHASE_POST_SUCCESS, PURCHASE_UPDATE_FAIL, PURCHASE_UPDATE_REQUEST, PURCHASE_UPDATE_SUCCESS } from "../constants/purchaseConstants";

export const purchaseSave = (purchase: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PURCHASE_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.post(`/api/purchase/save`, purchase, {
      headers: { Authorization: `Bearer ${token?.tocken}` }
    });
    return dispatch({ type: PURCHASE_POST_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PURCHASE_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const purchaseGet = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PURCHASE_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.get(`/api/purchase/all/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PURCHASE_GET_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PURCHASE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const purchaseUpdate = (id: any, purchase: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PURCHASE_UPDATE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

    const { data } = await api.put(`/api/purchase/put/${id}`, purchase, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PURCHASE_UPDATE_SUCCESS, payload: data });

  } catch (error: any) {
    return dispatch({
      type: PURCHASE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const purchaseDelete = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PURCHASE_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();

  const { data } = await api.delete(`/api/purchase/purchasedelete/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PURCHASE_DELETE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: PURCHASE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};