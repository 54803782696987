import React from 'react';
import DashboardLayout from '../layouts/Dashboard/Dashboard';
import { Outlet} from 'react-router-dom';

const Container = () => {
  
  sessionStorage.removeItem("userInfo");

  return (
    <div>
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </div>
  );
};

export default Container;
