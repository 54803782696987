import { } from "../constants/clientConstants";
import { PAYMENTRECEIVE_LIST_FAIL, PAYMENTRECEIVE_LIST_REQUEST, PAYMENTRECEIVE_LIST_RESET, PAYMENTRECEIVE_LIST_SUCCESS } from "../constants/paymentReceiveConstants";

export const paymentReceiveReducer = (state = {}, action: any) => {
    switch (action.type) {
        case PAYMENTRECEIVE_LIST_REQUEST:
            return { loading: true };
        case PAYMENTRECEIVE_LIST_SUCCESS:
            return { loading: false, paymentreceivegetlist: action.payload };
        case PAYMENTRECEIVE_LIST_FAIL:
            return { loading: false, error: action.payload };
        case PAYMENTRECEIVE_LIST_RESET:
            return {};
        default:
            return state;
    }
};