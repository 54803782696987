import { COMPANY_DELETE_FAIL, COMPANY_DELETE_REQUEST, COMPANY_DELETE_RESET, COMPANY_DELETE_SUCCESS, COMPANY_GET_FAIL, COMPANY_GET_REQUEST, COMPANY_GET_RESET, COMPANY_GET_SUCCESS, COMPANY_POST_FAIL, COMPANY_POST_REQUEST, COMPANY_POST_RESET, COMPANY_POST_SUCCESS } from "../constants/companyConstants";

const initialSaveState = {
    companySave: null,
};

const initialListsState = {
    companyLists: [],
};

const initialDeleteState = {
    companyLists: [],
};

export const companySaveReducer = (state = initialSaveState, action: any)=> {
    switch (action.type) {
        case COMPANY_POST_REQUEST:
            return { loading: true };
        case COMPANY_POST_SUCCESS:
            return { loading: false, company: action.payload };
        case COMPANY_POST_FAIL:
            return { loading: false, error: action.payload };
        case COMPANY_POST_RESET:
            return {};
        default:
            return state;
    }
}

export const companyListsReducer = (state = initialListsState, action: any) => {
    switch (action.type) {
        case COMPANY_GET_REQUEST:
            return { loading: true };
        case COMPANY_GET_SUCCESS:
            return { loading: false, company: action.payload };
        case COMPANY_GET_FAIL:
            return { loading: false, error: action.payload };
        case COMPANY_GET_RESET:
            return {};
        default:
            return state;
    }
}


export const companyDeleteReducer = (state = initialDeleteState, action: any) => {
    switch (action.type) {
        case COMPANY_DELETE_REQUEST:
            return { loading: true };
        case COMPANY_DELETE_SUCCESS:
            return { loading: false, company: action.payload };
        case COMPANY_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case COMPANY_DELETE_RESET:
            return {};
        default:
            return state;
    }
}