export const TERM_POST_REQUEST = 'TERM_POST_REQUEST';
export const TERM_POST_SUCCESS = 'TERM_POST_SUCCESS';
export const TERM_POST_FAIL = 'TERM_POST_FAIL';
export const TERM_POST_REST = 'TERM_POST_REST';


export const TERM_GET_REQUEST = 'TERM_GET_REQUEST';
export const TERM_GET_SUCCESS = 'TERM_GET_SUCCESS';
export const TERM_GET_FAIL = 'TERM_GET_FAIL';
export const TERM_GET_REST = 'TERM_GET_REST';

export const TERM_GET_ONE_REQUEST = 'TERM_GET_ONE_REQUEST';
export const TERM_GET_ONE_SUCCESS = 'TERM_GET_ONE_SUCCESS';
export const TERM_GET_ONE_FAIL = 'TERM_GET_ONE_FAIL';
export const TERM_GET_ONE_REST = 'TERM_GET_ONE_REST';

export const TERM_UPDATE_REQUEST = 'TERM_UPDATE_REQUEST';
export const TERM_UPDATE_SUCCESS = 'TERM_UPDATE_SUCCESS';
export const TERM_UPDATE_FAIL = 'TERM_UPDATE_FAIL';
export const TERM_UPDATE_REST = 'TERM_UPDATE_REST';

export const TERM_DELETE_REQUEST = 'TERM_DELETE_REQUEST';
export const TERM_DELETE_SUCCESS = 'TERM_DELETE_SUCCESS';
export const TERM_DELETE_FAIL = 'TERM_DELETE_FAIL';
export const TERM_DELETE_REST = 'TERM_DELETE_REST';