import { SALES_GET_FAIL, SALES_GET_REQUEST, SALES_GET_REST, SALES_GET_SUCCESS } from "../constants/SalesConstants";



export const SalesGetReducer = (state = {}, action: any) => {
    switch (action.type) {
        case SALES_GET_REQUEST:
            return { loading: true };
        case SALES_GET_SUCCESS:
            return { loading: false, saleslist: action.payload };
        case SALES_GET_FAIL:
            return { loading: false, error: action.payload };
        case SALES_GET_REST:
            return {};
        default:
            return state;
    }
};