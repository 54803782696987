import { useState } from 'react';
import ServiceSettings from './ServiceSettings';

const ServiceTab = () => {
  const [openTab, setOpenTab] = useState(1);

    return (
        <div>
            <div className="bg-[#E1E8E7] lg:h-[650px] xl:h-[650px] 2xl:h-[650px] h-screen  ">
                <div className=" ">
                    <ul className="flex flex-wrap text-sm font-medium text-center text-[#008065]  dark:text-gray-400 ">
                        <li className="-mb-px mr-1">
                            <button
                                style={{
                                    borderBottom: openTab === 1 ? "2px solid #008065" : "",
                                }}
                                onClick={() => setOpenTab(1)}
                                className={
                                    "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                                }
                            >
                                Service Details
                            </button>
                        </li>
                        {/* <li className="mr-1">
                            <button
                                onClick={() => setOpenTab(2)}
                                style={{
                                    borderBottom: openTab === 2 ? "2px solid #008065" : "",
                                }}
                                className={
                                    "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                                }
                            >
                            </button>
                        </li> */}


                    </ul>
                </div>
                <div className=" mt-6 ">
                    <div className={openTab === 1 ? "block  " : "hidden"}>
                        <ServiceSettings/>
                    </div>
                 

                </div>
            </div>
        </div>
    )
}

export default ServiceTab
