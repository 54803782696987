import api from "../axiosConfig";
import { PURCHASE_INVOICE_DELETE_FAIL, PURCHASE_INVOICE_DELETE_REQUEST, PURCHASE_INVOICE_DELETE_SUCCESS, PURCHASE_INVOICE_FIND_ALL_FAIL, PURCHASE_INVOICE_FIND_ALL_REQUEST, PURCHASE_INVOICE_FIND_ALL_SUCCESS, PURCHASE_INVOICE_GET_COUNT_FAIL, PURCHASE_INVOICE_GET_COUNT_REQUEST, PURCHASE_INVOICE_GET_COUNT_SUCCESS, PURCHASE_NEW_POST_FAIL, PURCHASE_NEW_POST_REQUEST, PURCHASE_NEW_POST_SUCCESS, PURCHASENEW_INVOICE_FIND_ONE_FAIL, PURCHASENEW_INVOICE_FIND_ONE_REQUEST, PURCHASENEW_INVOICE_FIND_ONE_SUCCESS, PURCHASENEW_INVOICE_UPDATE_FAIL, PURCHASENEW_INVOICE_UPDATE_REQUEST, PURCHASENEW_INVOICE_UPDATE_SUCCESS } from "../constants/purchasePageNewConstants";


export const PurchaseNewSave = (prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: PURCHASE_NEW_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.post(
      `/api/purchaseNew/purchaseNewSave`,
      prod,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: PURCHASE_NEW_POST_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: PURCHASE_NEW_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const PurchaseFindAll = () => async (dispatch: any, getState: any) => {
  dispatch({ type: PURCHASE_INVOICE_FIND_ALL_REQUEST });

  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(`/api/purchaseNew/find-all/${token?._id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: PURCHASE_INVOICE_FIND_ALL_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: PURCHASE_INVOICE_FIND_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const PurchaseGetCount = () => async (dispatch: any, getState: any) => {
  dispatch({ type: PURCHASE_INVOICE_GET_COUNT_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(`/api/purchaseget-count/${token?._id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({
      type: PURCHASE_INVOICE_GET_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: PURCHASE_INVOICE_GET_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const purchaseNewFindOne =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: PURCHASENEW_INVOICE_FIND_ONE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/purchaseNew/purchaseNewfind-one/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({
        type: PURCHASENEW_INVOICE_FIND_ONE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: PURCHASENEW_INVOICE_FIND_ONE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const purchaseNewUpdate =
  (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: PURCHASENEW_INVOICE_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/purchaseNew/purchaseNewput/${id}`,
        externalInvoice,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        },
      );
      return dispatch({ type: PURCHASENEW_INVOICE_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: PURCHASENEW_INVOICE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const purchaseDelete =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: PURCHASE_INVOICE_DELETE_REQUEST});
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.delete(`/api/purchaseinvoice/purchasedelete/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: PURCHASE_INVOICE_DELETE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: PURCHASE_INVOICE_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
