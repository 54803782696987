import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || "";

export const encryptData = (data: string): string => {
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(
        data,
        CryptoJS.enc.Utf8.parse(SECRET_KEY),
        {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        },
    );
    return iv.toString(CryptoJS.enc.Base64) + encrypted.toString();
};

export const decryptData = (data: string): string => {
    const iv = CryptoJS.enc.Base64.parse(data.slice(0, 24));
    const encrypted = data.slice(24);
    const decrypted = CryptoJS.AES.decrypt(
        encrypted,
        CryptoJS.enc.Utf8.parse(SECRET_KEY),
        {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        },
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
};