import NumberFormat from "../../commen/NumberFormat";

function ServiceTaxSection({ ServiceTaxAllList, formData }: any) {

  return (
    <>
      {ServiceTaxAllList?.length > 0 && (
        <>
          {(ServiceTaxAllList).map((tasx: any, index: number) => (
            <>
              {tasx.servicetaxStatus && (
                <div key={tasx._id || index}
                  className={
                    "flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center "
                  }
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                    }
                  >
                    Service Tax<span className="ml-2">@</span>
                    {tasx.servicetaxPercentage}%
                  </div>
                  <div
                    className={
                      "font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1"
                    }
                  >
                    <NumberFormat
                      value={
                        formData?.subtotal * (tasx.servicetaxPercentage / 100)
                          ? formData?.subtotal * (tasx.servicetaxPercentage / 100)
                          : 0
                      }
                    />
                  </div>
                </div>
              )}

              {tasx.servicediscountStatus && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center "
                  }
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "font-title w-1/2 sm:w-1/3 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                    }
                  >
                    DISCOUNT<span className="ml-2">@</span>
                    {tasx.servicediscountPercentage}%
                  </div>
                  <div
                    className={
                      "font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1"
                    }
                  >
                    <NumberFormat
                      value={
                        (Number(formData.subtotal) + (Number(formData.subtotal) * (Number(tasx.servicetaxPercentage) / 100))) 
                        * (Number(tasx.servicediscountPercentage) / 100)
                      }
                    />
                  </div>
                </div>
              )}
            </>
          ))}
        </>
      )}
    </>
  );
}

export default ServiceTaxSection;
