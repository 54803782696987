import { CURRENCY_GET_FAIL, CURRENCY_GET_REQUEST, CURRENCY_GET_RESET, CURRENCY_GET_SUCCESS, CURRENCY_POST_FAIL, CURRENCY_POST_REQUEST, CURRENCY_POST_RESET, CURRENCY_POST_SUCCESS } from "../constants/currencyConstants";

const initialSaveState = {
    CurrencyPost: null,
};

export const currencyPostReducer = (state = initialSaveState, action: any) => {
    switch (action.type) {
        case CURRENCY_POST_REQUEST:
            return { loading: true };
        case CURRENCY_POST_SUCCESS:
            return { loading: false, currency: action.payload };
        case CURRENCY_POST_FAIL:
            return { loading: false, error: action.payload };
        case CURRENCY_POST_RESET:
            return {};
        default:
            return state;
    }
}

export const GetCurrencyDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case CURRENCY_GET_REQUEST:
            return { loading: true };
        case CURRENCY_GET_SUCCESS:
            return { loading: false, currencyDetail: action.payload };
        case CURRENCY_GET_FAIL:
            return { loading: false, error: action.payload };
        case CURRENCY_GET_RESET:
            return {};
        default:
            return state;
    }
};