import api from "../axiosConfig";
import {
   EXPENSE_DELETE_FAIL,
   EXPENSE_DELETE_REQUEST,
   EXPENSE_DELETE_SUCCESS,
   EXPENSE_GET_FAIL,
   EXPENSE_GET_REQUEST,
   EXPENSE_GET_SUCCESS,
   EXPENSE_POST_FAIL,
   EXPENSE_POST_REQUEST,
   EXPENSE_POST_SUCCESS,
   EXPENSE_UPDATE_FAIL,
   EXPENSE_UPDATE_REQUEST,
   EXPENSE_UPDATE_SUCCESS,
} from "../constants/expenseConstants";

export const expenseSave =
   (company: any) => async (dispatch: any, getState: any) => {
      // company.user_id = environment.user_id;
      dispatch({ type: EXPENSE_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         company.user_id = token._id;
         const { data } = await api.post(`/api/expenseCategory/save`, company, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: EXPENSE_POST_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: EXPENSE_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const expenseAll = () => async (dispatch: any, getState: any) => {
   dispatch({ type: EXPENSE_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(`/api/expense/getall/${token?._id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: EXPENSE_GET_SUCCESS, payload: data });
   } catch (error: any) {
      return dispatch({
         type: EXPENSE_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

// export const findUpiDetail = () => async (dispatch: any, getState: any) => {
//    dispatch({ type: UPIPAYMENT_GET_ONE_REQUEST });
//    try {
//       const {
//          userSignin: {
//             userInfo: { token },
//          },
//       } = getState();
//       const { data } = await api.get(`/api/upiPayment/findOne/${token?._id}`, {
//          headers: { Authorization: `Bearer ${token?.tocken}` },
//       });
//       return dispatch({ type: UPIPAYMENT_GET_ONE_SUCCESS, payload: data });
//    } catch (error: any) {
//       return dispatch({
//          type: UPIPAYMENT_GET_ONE_FAIL,
//          payload:
//             error.response && error.response.data.message
//                ? error.response.data.message
//                : error.message,
//       });
//    }
// };

export const expenseDelete =
   (id: string) => async (dispatch: any, getState: any) => {
      dispatch({ type: EXPENSE_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/expense/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });

         return dispatch({ type: EXPENSE_DELETE_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: EXPENSE_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const expenseUpdate =
   (id: any, updatedet: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: EXPENSE_UPDATE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         updatedet.user_id = token._id;
         const { data } = await api.put(
            `/api/expense/update/${id}`,
            updatedet,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({ type: EXPENSE_UPDATE_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: EXPENSE_UPDATE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };
