
import api from "../axiosConfig";
import {
  COUNTRY_GET_FAIL,
  COUNTRY_GET_REQUEST,
  COUNTRY_GET_SUCCESS,
  DASHBOARD_INTERNAL_INVOICE_GET_COUNT_FAIL,
  DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REQUEST,
  DASHBOARD_INTERNAL_INVOICE_GET_COUNT_SUCCESS,
  INTERNAL_INVOICE_DELETE_FAIL,
  INTERNAL_INVOICE_DELETE_REQUEST,
  INTERNAL_INVOICE_DELETE_SUCCESS,
  INTERNAL_INVOICE_FIND_ALL_FAIL,
  INTERNAL_INVOICE_FIND_ALL_REQUEST,
  INTERNAL_INVOICE_FIND_ALL_SUCCESS,
  INTERNAL_INVOICE_FIND_ONE_FAIL,
  INTERNAL_INVOICE_FIND_ONE_REQUEST,
  INTERNAL_INVOICE_FIND_ONE_SUCCESS,
  INTERNAL_INVOICE_GET_COUNT_FAIL,
  INTERNAL_INVOICE_GET_COUNT_REQUEST,
  INTERNAL_INVOICE_GET_COUNT_SUCCESS,
  INTERNAL_INVOICE_POST_FAIL,
  INTERNAL_INVOICE_POST_REQUEST,
  INTERNAL_INVOICE_POST_SUCCESS,
  INTERNAL_INVOICE_UPDATE_FAIL,
  INTERNAL_INVOICE_UPDATE_REQUEST,
  INTERNAL_INVOICE_UPDATE_SUCCESS,
} from "../constants/InternalInvoiceConstants";

export const InternalPost =
  (externalInvoice: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: INTERNAL_INVOICE_POST_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();

      const { data } = await api.post(`/api/internal/save`, externalInvoice, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: INTERNAL_INVOICE_POST_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: INTERNAL_INVOICE_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const InternalInvoiceUpdate =
  (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: INTERNAL_INVOICE_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/internal/put/${id}`,
        externalInvoice,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        },
      );
      return dispatch({ type: INTERNAL_INVOICE_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: INTERNAL_INVOICE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const InternalGetCount = () => async (dispatch: any, getState: any) => {
  dispatch({ type: INTERNAL_INVOICE_GET_COUNT_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(`/api/get-count/${token?._id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({
      type: INTERNAL_INVOICE_GET_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: INTERNAL_INVOICE_GET_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const InternalDashboardGetCount =
  () => async (dispatch: any, getState: any) => {
    dispatch({ type: DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REQUEST });

    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/dashboard/get-count/${token?._id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({
        type: DASHBOARD_INTERNAL_INVOICE_GET_COUNT_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: DASHBOARD_INTERNAL_INVOICE_GET_COUNT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const InternalFindAll = () => async (dispatch: any, getState: any) => {
  dispatch({ type: INTERNAL_INVOICE_FIND_ALL_REQUEST });

  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(`/api/invoice/find-all/${token?._id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: INTERNAL_INVOICE_FIND_ALL_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: INTERNAL_INVOICE_FIND_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const paymentPendingBillList = () => async (dispatch: any, getState: any) => {
  dispatch({ type: INTERNAL_INVOICE_FIND_ALL_REQUEST });

  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(`/api/invoice/find-paymentpendingbill-all/${token?._id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: INTERNAL_INVOICE_FIND_ALL_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: INTERNAL_INVOICE_FIND_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const InternalFindOne =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: INTERNAL_INVOICE_FIND_ONE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/invoice/find-one/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({
        type: INTERNAL_INVOICE_FIND_ONE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: INTERNAL_INVOICE_FIND_ONE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const InvoiceDelete =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: INTERNAL_INVOICE_DELETE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.delete(`/api/invoice/delete/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: INTERNAL_INVOICE_DELETE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: INTERNAL_INVOICE_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const GetCountryData = () => async (dispatch: any, getState: any) => {
  dispatch({ type: COUNTRY_GET_REQUEST });
  try {
    const { data } = await api.get(`/api/country/getdetails`);
    return dispatch({ type: COUNTRY_GET_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: COUNTRY_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
