import { useNavigate } from "react-router-dom";

const Navbar = () => {
  
  const navigateTo = useNavigate();

  const homepageHandler = () => {
    navigateTo("/");
  };

  return (
    <nav
      className="bg-[#F1F1F1] shadow-md sticky top-0"
      style={{ zIndex: 1 }}
    >
      <nav className="flex h-[45px]" aria-label="Breadcrumb">
        
        <img
          src="/logoheader.webp"
          loading="lazy"
          alt="logo"
          style={{ height: "26px", paddingLeft: "1rem",marginTop: "8px", cursor: "pointer",}}
          onClick={homepageHandler}
        />
      </nav>
    </nav>
  );
};

export default Navbar;
