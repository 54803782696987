import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { currencyGet } from '../../redux/actions/currencyAction';
import { registerAll } from '../../redux/actions/userAction';
import { InternalFindAll } from '../../redux/actions/InternalInvoiceAction';
import { serviceFindAll } from '../../redux/actions/invoiceServiceActions';
import { salesReturnGet } from '../../redux/actions/salesReturnAction';
import { purchaseReturnFindAll } from '../../redux/actions/purchaseReturnActions';

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
type SelectedRange = 
  | { range: string }
  | { range: string; startDate: string; endDate: string };

interface DashboardBarDiaProps {
  selectedRange: SelectedRange;
}
const DashboardBarDia: React.FC<DashboardBarDiaProps> = ({ selectedRange }) => {
  const dispatch=useDispatch();
  const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;
  const [currencySymbol, setcurrencySymbol] = useState("₹");
  const [serviceInvoiceTable, setserviceInvoiceTable] = useState([] as any);
	const [invoiceTable, setInvoiceTable] = useState([] as any);
  const [salesReturnDetails, setSalseDetails] = useState([] as any);
  const [purchaseReturnDetails, setPurchaseReturn] = useState([] as any);
  const fetchData = useCallback(() => {
    dispatch(registerAll(token?._id) as any);
    dispatch(InternalFindAll() as any).then((response: any) => {
      if (response && response.payload) {
        setInvoiceTable(response.payload);
      }
    });
    dispatch(serviceFindAll() as any).then((response: any) => {
      if (response && response.payload) {
        setserviceInvoiceTable(response.payload);
      }
    });
    dispatch(salesReturnGet() as any).then((response: any) => {
      setSalseDetails(response?.payload);
    });
    dispatch(purchaseReturnFindAll() as any).then((response: any) => {
      setPurchaseReturn(response?.payload);
      
  });
  }, [dispatch, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	}, []);




  const mergedArray = useMemo(
		() => invoiceTable.concat(serviceInvoiceTable),
		[invoiceTable, serviceInvoiceTable]
	);


    const data = {
        labels: ['January', 'February', 'March', 'April', 'May'], 
        datasets: [
          {
            label: `Inflow (${currencySymbol})`,
            data: [1500, 2000, 2500, 3000, 1800], 
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
          {
            label: `Outflow (${currencySymbol})`,
            data: [1000, 1500, 2000, 2200, 1600], 
            backgroundColor: 'rgba(255, 99, 132, 0.5)', 
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      };
    
      // Options for the bar chart
      const options = {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Cash Inflow and Outflow',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                return currencySymbol + context.raw;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value: any) {
                return '$' + value; // Format Y-axis as currency
              },
            },
          },
        },
      };
  return (
    <div className="chart-container" >
      <Bar data={data} options={options} />
    </div>
    
  );
};

export default DashboardBarDia;
