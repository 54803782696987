import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  BarElement, // Import BarElement
  Title,
  Tooltip,
} from "chart.js";
import React, { useCallback, useEffect, useState } from "react";

import DashboardBarDia from "./DashboardBarDia";
import DashboardPieDia from "./DashboardPieDia";
import DashboardLineDia from "./DashboardLineDia";
import DashboardDonutDia from "./DashboardDonut";
import { useDispatch, useSelector } from "react-redux";
import { registerAll } from "../../redux/actions/userAction";
import { stockPurchaseGet } from "../../redux/actions/stockAction";


ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement, // Register BarElement
  Title,
  Tooltip,
  Legend
);
type SelectedRange = 
  | { range: string }
  | { range: string; startDate: string; endDate: string };
interface DashboardDiagramsProps {
  selectedRange: SelectedRange;
}
const DashboardDiagrams : React.FC<DashboardDiagramsProps> = ({ selectedRange }) => {
  const dispatch = useDispatch();
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const productdetails = useSelector((state: any) => state.stockPurchaseList);
  const { stocklist } = productdetails;
 
  const [stockvalue, setStockValue] = useState([] as any);
  useEffect(() => {
    setStockValue(stocklist);
  }, [stocklist]);
  const fetchData = useCallback(() => {
    dispatch(registerAll(token?._id) as any).then((res: any) => {});
  }, []);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    dispatch(stockPurchaseGet(token?._id) as any);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let lowstock = [];
  for (let i = 0; i < stockvalue?.length; i++) {
    if (stockvalue[i].qty <= 10) {
      lowstock.push(stockvalue[i]);
    }
  }
  

 
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-5 gap-4 mt-6 ml-2">
        <div className="chart-container col-span-2 bg-white">
          <DashboardLineDia selectedRange={selectedRange} />
        </div>

        <div className="rounded-lg col-span-2 bg-white">
          <div className="chart-container">
            <DashboardBarDia selectedRange={selectedRange}/>
          </div>
        </div>
        {/* <div className="relative flex items-center justify-center rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-600 dark:bg-navy-800 dark:text-white w-full lg:w-11/12 xl:w-11/12"> */}
        <div className="relative flex justify-center rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-600 dark:bg-navy-800 dark:text-white w-full lg:w-11/12 xl:w-11/12">
          <div className="flex flex-col items-start space-y-2 p-4 overflow-y-auto max-h-60">
            <p className="text-md font-semibold text-green-600">Low Stock</p>
            <ul className="space-y-1 text-sm text-navy-700 dark:text-white">
              {lowstock?.map((stock, index) => (
                <li
                  key={index}
                  className="flex items-center space-x-2 relative pl-2"
                >
                  <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-red-600 dark:text-orange-300">
                    •
                  </span>
                  <span className="text-red-600 dark:text-orange-300 ml-2">
                    {stock.productName} -
                  </span>
                  <span className="text-red-600 dark:text-orange-300">
                    {stock.qty}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-5 gap-4 mt-6 ml-2">
        <div className="chart-container col-span-2 bg-white">
          <DashboardPieDia selectedRange={selectedRange}/>
        </div>

        <div className="rounded-lg col-span-2 bg-white">
          <div className="chart-container">
            <DashboardDonutDia selectedRange={selectedRange} />
          </div>
        </div>

        <div className="relative flex  justify-center rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-600 dark:bg-navy-800 dark:text-white w-full lg:w-11/12 xl:w-11/12">
          <div className="flex flex-col items-center space-y-2 p-4">
            <p className="text-sm font-semibold text-green-600 dark:text-gray-300">
              Cash & Bank
            </p>
            <h4 className="text-md text-navy-700 dark:text-white">0.00</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDiagrams;
