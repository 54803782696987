import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetCountryData } from "../../redux/actions/currencyAction";

interface Country {
  name: string;
  countryCode: string;
  currencyCode: string;
  currencySymbol: string;
  currencyName: string;
}

const CurrencyPage = ({ setCurrentCountryName }: any) => {
  const dispatch = useDispatch();
  const [countryValue, setcountryValue] = useState([] as any);

  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  const fetchData = async () => {
    try {
      dispatch(GetCountryData() as any).then(async (res: any) => {
        if (res?.type === "COUNTRY_GET_SUCCESS") {
          let countries = JSON.parse(res?.payload?.countryDetails);
          const countrydata = await Promise.all(
            countries?.map(async (country: Country) => ({
              name: country.name,
              currencyCode: country.currencyCode,
              currencySymbol: country.currencySymbol,
              countryCode: country.countryCode,
              currencyName: country.currencyName,
            }))
          );

          setcountryValue(countrydata);
          // setCurrency(countrydata)
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [error, setError] = useState(false);

  return (
    <>
      <div>
        <label
          htmlFor="font-family-select"
          className="block mb-2 text-sm text-[black] font-semibold"
        >
          Currency
        </label>
        <select
          value={selectedCountry ? selectedCountry.name : ""}
          onChange={(event) => {
            const selectedCountryName = event.target.value;
            const country = countryValue.find(
              (country: any) => country.name === selectedCountryName
            );

            setCurrentCountryName(country?.name);

            setSelectedCountry(country || null);
            setError(false);
          }}
          className={`text-balance border-2 focus:outline-none border-[#008065] rounded-[7px] px-2 h-9 block lg:w-[10rem] md:w-[8rem] xl:w-[14rem] 2xl:w-[14rem] w-full 
              ${error ? "border-red-500" : "border-[#008065]"}`}
          style={{
            fontFamily: "poppins",
            fontSize: "13px",
            fontWeight: 400,
          }}
        >
          <option value="" disabled defaultValue=" " hidden>
            Currency
          </option>

          {countryValue.map((country: any) => (
            <option key={country.name} value={country.name}>
              {country.name}
              {country.currencySymbol ? `(${country.currencySymbol})` : ""}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
export default CurrencyPage;
