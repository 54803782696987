import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CurrencyTable from "../../Tables/CurrencyTable";
import { GetCountryData } from "../../redux/actions/InternalInvoiceAction";
import {
  currencyDelete,
  currencyGet,
  currencySave,
  currencyUpdate,
} from "../../redux/actions/currencyAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";

interface Country {
  name: string;
  countryCode: string;
  currencyCode: string;
  currencySymbol: string;
  currencyName: string;
}

const CountryPage = () => {
  const dispatch = useDispatch();

  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  const currencydetails = useSelector((state: any) => state.GetCurrencyDetails);
  const { currencyDetail } = currencydetails;
  const [countryValue, setcountryValue] = useState([] as any);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(GetCountryData() as any).then(async (res: any) => {
          let countries = JSON.parse(res.payload.countryDetails);

          const countrydata = await Promise.all(
            countries?.map(async (country: Country) => ({
              name: country.name,
              currencyCode: country.currencyCode,
              currencySymbol: country.currencySymbol,
              countryCode: country.countryCode,
              currencyName: country.currencyName,
            })),
          );

          setcountryValue(countrydata);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [defaultCurrencyInfo, setdefaultCurrencyInfo] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleDefaultCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setdefaultCurrencyInfo(e.target.checked);
  };

  const [currencyId, setcurrencyId] = useState(null);

  const [error, setError] = useState(false);

  const handleedit = (value: any) => {
    const country = countryValue.find(
      (country: any) => country.name === value?.currency,
    );
    setSelectedCountry(country || null);
    setdefaultCurrencyInfo(value?.defaultInfo);
    setcurrencyId(value._id?.$oid);
    setError(false);
  };

  const errorValidation = () => {
    if (!selectedCountry) {
      setError(true);
    } else {
      setError(false);
    }
    return true;
  }

  const resetCountry = () => {
    setcurrencyId(null);
    setSelectedCountry(null);
    setdefaultCurrencyInfo(false);
    setShowConfirmation(false);
    setError(false);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let obj = {
      currency: selectedCountry?.name,
      defaultInfo: defaultCurrencyInfo,
      currencySymbol: selectedCountry?.currencySymbol,
    };

    if (errorValidation() && selectedCountry) {
      if (currencyId) {
        dispatch(currencyUpdate(currencyId, obj) as any).then((res: any) => {
          if (res) {
            fetchData();
            resetCountry();
          }
          toast.success("Update SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        });
      } else {
        dispatch(currencySave(obj) as any).then((res: any) => {
          if (res) {
            fetchData();
            resetCountry();
          }
          toast.success("Saved SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        });
      }
    }
  };

  const onDelete = (value: any) => {
    setShowConfirmation(true);
    setcurrencyId(value._id?.$oid);
  };

  const handleConfirm = () => {
    dispatch(currencyDelete(currencyId) as any).then((res: any) => {
      if (res) {
        fetchData();
        resetCountry();
      }
    });
  };

  const handlePopupCancel = () => {
    setShowConfirmation(false);
  };

  const [currencyList, setcurrencyList] = useState([] as any);

  const fetchData = () => {
    dispatch(currencyGet(token?._id) as any).then((res: any) => {
      if (res) {
        setcurrencyList(res.payload);
        const data = res?.payload.find(
          (value: any) => value.defaultInfo === true
        );
        if (data) {
          sessionStorage.setItem("currency", JSON.stringify(data));
        }
      }
    });
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <form>
        <fieldset className="mx-2 mb-4 rounded-lg p-3">
          <legend className="p-4  font-bold text-[#045545]  text-lg   ml-2">
            Currency
          </legend>
          <div className="flex  gap-2 mb-3">
            <div>
              <input
                type="checkbox"
                name="defaultBankInfo"
                placeholder="defaultBankInfo"
                checked={defaultCurrencyInfo}
                onChange={handleDefaultCheckboxChange}
                className={
                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                }
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              />
            </div>
            <div className="text-[#008065] font-semibold">
              <label>Default Currency Info</label>
            </div>
          </div>
          <label
            htmlFor="font-family-select"
            className="block mb-2 text-sm text-[#008065] font-semibold"
          >
            Select Currency
          </label>
          <select
            value={selectedCountry ? selectedCountry.name : ""}
            onChange={(event) => {
              const selectedCountryName = event.target.value;
              const country = countryValue.find(
                (country: any) => country.name === selectedCountryName,
              );
              setSelectedCountry(country || null);
              setError(false);
            }}
            // className={
            //   "text-balance border-2 focus:outline-none  border-[#008065] rounded-[7px] px-2 h-9 block lg:w-[18rem] md:w-[17rem] xl:w-[17rem] 2xl:w-[17rem] w-full "
            // }
            className={`text-balance border-2 focus:outline-none  border-[#008065] rounded-[7px] px-2 h-9 block lg:w-[18rem] md:w-[17rem] xl:w-[17rem] 2xl:w-[17rem] w-full 
              ${error
                ? "border-red-500"
                : "border-[#008065]"
              }`}
            style={{
              fontFamily: "poppins",
              fontSize: "13px",
              fontWeight: 400,
            }}
          >
            <option value="" disabled  hidden>Please select</option>
            {countryValue.map((country: any) => (
              <option key={country.name} value={country.name} >
                {country.name} 
                {country.currencySymbol ? `(${country.currencySymbol})` : ""}
              </option>
            ))}
          </select>
          {error && <p style={{ color: "red", fontSize: '13px', marginLeft: '3px' }}>Currency is required</p>}
          {/* </div> */}
          <div className="flex w-full flex-col sm:flex-row px-4">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
              <div className="flex flex-col mb-6 "></div>
            </div>

            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
              <div className="flex flex-col  ">
                <div className="relative"></div>
              </div>
            </div>

            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
              <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                  onClick={resetCountry}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
              <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 ">
                <button
                  type="submit"
                  className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  {currencyId ? "Update" : " Submit"}
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <CurrencyTable
        currencyList={currencyList}
        setcurrencyList={setcurrencyList}
        currencyDetail={currencyDetail}
        edit={handleedit}
        deletecurrency={onDelete}
      />
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handlePopupCancel}
        />
      )}
    </div>
  );
};

export default CountryPage;
