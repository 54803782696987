import {
   FIXED_ASSETS_GET_FAIL,
   FIXED_ASSETS_GET_REQUEST,
   FIXED_ASSETS_GET_RESET,
   FIXED_ASSETS_GET_SUCCESS,
} from "../constants/fixedAssetsConstants";

export const fixedAssetGetReducer = (state = {}, action: any) => {
   switch (action.type) {
      case FIXED_ASSETS_GET_REQUEST:
         return { loading: true };
      case FIXED_ASSETS_GET_SUCCESS:
         return { loading: false, fixedAssetlist: action.payload };
      case FIXED_ASSETS_GET_FAIL:
         return { loading: false, error: action.payload };
      case FIXED_ASSETS_GET_RESET:
         return {};
      default:
         return state;
   }
};
