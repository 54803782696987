export const COMPANY_POST_REQUEST = 'COMPANY_POST_REQUEST';
export const COMPANY_POST_SUCCESS = 'COMPANY_POST_SUCCESS';
export const COMPANY_POST_FAIL = 'COMPANY_POST_FAIL';
export const COMPANY_POST_RESET = 'COMPANY_POST_RESET';

export const COMPANY_GET_REQUEST = 'COMPANY_GET_REQUEST';
export const COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS';
export const COMPANY_GET_FAIL = 'COMPANY_GET_FAIL';
export const COMPANY_GET_RESET = 'COMPANY_GET_RESET';

export const COMPANY_UPDATE_REQUEST = 'COMPANY_UPDATE_REQUEST';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_FAIL = 'COMPANY_UPDATE_FAIL';
export const COMPANY_UPDATE_RESET = 'COMPANY_UPDATE_RESET';

export const COMPANY_DELETE_REQUEST = 'COMPANY_DELETE_REQUEST';
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_FAIL = 'COMPANY_DELETE_FAIL';
export const COMPANY_DELETE_RESET = 'COMPANY_DELETE_RESET';