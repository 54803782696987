import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import {
  createBulkProducts,
  ProductsGet
} from "../../redux/actions/productAction";
import ConfirmationUploadProduct from "../commen/ConfirmationUploadProduct";

import { toast } from "react-toastify";
import UploadMessagePopUp from "../commen/UploadMessagePopUp";
import DownloadExcelSheet from "./DownloadExcelSheet";

interface IData {
  [key: string]: any;
}
const UploadProducts = () => {
  const [data, setData] = useState<IData[]>([]);
  const [Flag, setFlag] = useState<boolean>(false);

  const fileInputRef = useRef(null as any);
  const dispatch = useDispatch();
  const [productLists, setProductLists] = useState([] as any);
  const [uploadList, setUploadList] = useState([] as any);
  const [duplicateProduct, setDuplicateProduct] = useState([] as any);
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [ErrorMessagePopUp, setErrorMessagePopUp] = useState(false);

  const fetchData = () => {
    dispatch(ProductsGet(token?._id) as any).then((res: any) => {
      if (res) {
        setProductLists(res.payload);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) return;
    if (file) {
      setFileName(file.name);
      setErrorMessage("");
    } else {
      setFileName("");
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target?.result;
      if (!binaryString) return;

      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers: any = excelData[0];
      const parsedData = excelData.slice(1).map((row: any) => {
        const rowData: IData = {};
        headers.forEach((header: string, index: number) => {
          rowData[header] = row[index];
        });
        return rowData;
      });

      setData(parsedData);
    };
    reader.readAsBinaryString(file);
  };

  const setProductId = (index: any) => {
    if (productLists.length > 0) {
      const [ num] = productLists[0].product_id.split("-");
      let numeric_part = Number(num);
      let next_numeric_part = numeric_part + (index + 1);
      const paddedIndex = String(next_numeric_part).padStart(4, "0");
      return `prod-${paddedIndex}`;
    } else {
      const paddedIndex = String(index + 1).padStart(4, "0");
      return `prod-${paddedIndex}`;
    }
  };

  const calculateGstAmount = (price: any, gst: any) => {
    let Price = Number(price);
    let Gst = Number(gst);
    let GstAmount = (Price * Gst) / 100;
    return GstAmount;
  };

  const getExistValue = (values: any) => {
    let datas: any = [];
    let exist_value = values.filter((value: any) => {
      return productLists.some(
        (item: any) => item.product_name === value.product_name,
      );
    });

    for (let i = 0; i < exist_value?.length; i++) {
      let objects = {
        product_name: exist_value[i].product_name
          ? exist_value[i].product_name
          : null,
        hsn: exist_value[i].hsn ? Number(exist_value[i].hsn) : null,
        price: exist_value[i].price ? exist_value[i].price : 0,
        sellingPrice: exist_value[i].sellingPrice
          ? exist_value[i].sellingPrice
          : 0,
        units: exist_value[i].units ? exist_value[i].units : null,
        qty: 1,
        gst: exist_value[i].gst ? exist_value[i].gst : 0,
        subtotal: 1 * Number(exist_value[i].price ? exist_value[i].price : 0),
        GstAmount: exist_value[i].gst
          ? calculateGstAmount(exist_value[i].price, exist_value[i].gst)
          : 0,
        CgstPercentage: exist_value[i].gst ? exist_value[i].gst / 2 : 0,
        CgstAmount: exist_value[i].gst
          ? calculateGstAmount(exist_value[i].price, exist_value[i].gst / 2)
          : 0,
        SgstPercentage: exist_value[i].gst ? exist_value[i].gst / 2 : 0,
        SgstAmount: data[i].gst
          ? calculateGstAmount(exist_value[i].price, exist_value[i].gst / 2)
          : 0,
        igstTax: exist_value[i].igsttax ? exist_value[i].igsttax : 0,
        igstAmount: data[i].igsttax
          ? calculateGstAmount(exist_value[i].price, exist_value[i].igsttax)
          : 0,
        discount: exist_value[i].discount ? exist_value[i].discount : 0,
        user_id: token?._id,
        description: exist_value[i].description
          ? exist_value[i].description
          : "",
      };
      datas.push(objects);
    }
    setDuplicateProduct(datas);
  };

  const getNewValue = (values: any) => {
    const new_values = values.filter((value: any) => {
      return !productLists.some(
        (item: any) => item.product_name === value.product_name,
      );
    });

    return new_values;
  };
  const handleSubmit = (status: any) => {
    let values = [...uploadList];
    let newArray = getNewValue(values);
    let updateArray = [...duplicateProduct];
    let payload = { create_value: newArray, update_value: updateArray };

    dispatch(createBulkProducts(payload) as any).then((res: any) => {
      if (res?.type === "PRODUCT_POST_SUCCESS") {
        toast.success("Bulk Products Upload Saved SuccessFully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
        });
        fetchData();
      } else {
        toast.error(res?.payload, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
        });
      }
      setFlag(false);
      setData([]);
      fileInputRef.current.value = "";
      setFileName("");
      setErrorMessage("");
    });
  };


  const handleFileUploadFils = () => {
    setErrorMessage(""); // Reset the error message
    if (data?.length > 0) {
      let datas: any = [];
      let flag = false;
      // let exist_value = getExistValue(data);

      for (let i = 0; i < data?.length; i++) {
        if (
          !data[i].product_name ||
          !data[i].price ||
          !data[i].gst ||
          !data[i].igsttax
        ) {
          flag = true;
          setFlag(false);
          setErrorMessagePopUp(true);
          setUploadList([]);
          break;
        } else {
          let objects = {
            product_id: setProductId(i),
            product_name: data[i].product_name ? data[i].product_name : null,
            hsn: data[i].hsn ? Number(data[i].hsn) : null,
            price: data[i].price ? data[i].price : 0,
            sellingPrice: data[i].sellingPrice ? data[i].sellingPrice : 0,
            units: data[i].units ? data[i].units : null,
            qty: 1,
            gst: data[i].gst ? data[i].gst : 0,
            subtotal: 1 * Number(data[i].price ? data[i].price : 0),
            GstAmount: data[i].gst
              ? calculateGstAmount(data[i].price, data[i].gst)
              : 0,
            CgstPercentage: data[i].gst ? data[i].gst / 2 : 0,
            CgstAmount: data[i].gst
              ? calculateGstAmount(data[i].price, data[i].gst / 2)
              : 0,
            SgstPercentage: data[i].gst ? data[i].gst / 2 : 0,
            SgstAmount: data[i].gst
              ? calculateGstAmount(data[i].price, data[i].gst / 2)
              : 0,
            igstTax: data[i].igsttax ? data[i].igsttax : 0,
            igstAmount: data[i].igsttax
              ? calculateGstAmount(data[i].price, data[i].igsttax)
              : 0,
            discount: data[i].discount ? data[i].discount : 0,
            description: data[i]?.description ? data[i]?.description : "",
            user_id: token?._id,
          };
          datas.push(objects);
        }
      }
      setUploadList(datas);

      if (!flag) {
        setFlag(true);
      }
    } else {
      setErrorMessage("No file chosen. Please choose a file.");
    }
  };

  const handleCancelupload = () => {
    setFileName("");
    setErrorMessage("");
  };
  const handleCancelErrorPopUp = () => {
    setErrorMessagePopUp(false);
  };
  const handleCancel = () => {
    setFlag(false);
    setUploadList([]);
    setData([]);
    setFileName("");
    setErrorMessage("");
    fileInputRef.current.value = "";
  };

  return (
    <>
      <div className="rounded-xl px-3 mx-2">
        <div>
          <label
            className="block text-sm font-semibold text-[#008065] mt-4 mb-2"
            style={{ fontFamily: "poppins", fontSize: "14px" }}
          >
            * Please Download (Refer) The Sample Products Excel
          </label>
          <DownloadExcelSheet />
        </div>
        <label
          className="block text-sm font-semibold text-[#008065] mt-4 mb-2"
          style={{ fontFamily: "poppins", fontSize: "14px" }}
        >
          Please Upload Your File :
        </label>
        <div className="file-upload">
          <label
            title="Click to upload"
            htmlFor="button2"
            className="w-full md:w-2/4 lg:w-2/6 xl:w-1/6 cursor-pointer flex items-center justify-center gap-4 px-4 py-4 border border-[#008065] border-11px rounded-2xl"
          >
            <input
              type="file"
              id="button2"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileUpload}
            />
            <div className="w-max relative">
              <svg
                className="w-6 h-6"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"></path>
              </svg>
            </div>
            <div className="relative">
              <span
                className="block text-base font-semibold relative text-[#008065] group-hover:text-[#008065] whitespace-nowrap"
                style={{ fontFamily: "poppins", fontSize: "14px" }}
              >
                Upload a File
              </span>
            </div>
          </label>
          <div className="mt-2">
            {fileName && (
              <span
                className="block text-sm text-[#008065]"
                style={{ fontFamily: "poppins", fontSize: "14px" }}
              >
                File: {fileName}
              </span>
            )}
            {errorMessage && (
              <div
                className="text-red-500 font-semibold mt-2"
                style={{ fontFamily: "poppins", fontSize: "14px" }}
              >
                {errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full flex-col py-1 sm:flex-row">
          <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 mt-5">
            <div className="flex flex-col w-40 rounded">
              <button
                className="rounded-xl font-bold text-white flex flex-row items-center bg-[#008065] justify-center text-sm h-8 block"
                style={{ fontFamily: "poppins", fontSize: "14px" }}
                onClick={handleCancelupload}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2 mt-5">
            <div className="flex flex-col w-40">
              <button
                className="rounded-xl font-bold text-white flex flex-row items-center bg-[#008065] justify-center text-sm h-8 block"
                style={{ fontFamily: "poppins", fontSize: "14px" }}
                onClick={handleFileUploadFils}
              >
                Submit
              </button>
            </div>
          </div>
          {Flag && uploadList && (
            <div>
              <ConfirmationUploadProduct
                duplicateArray={duplicateProduct}
                productList={uploadList}
                onConfirm={handleSubmit}
                onCancel={handleCancel}
              />
            </div>
          )}
          {ErrorMessagePopUp && (
            <UploadMessagePopUp
              message="Upload File Is Not in the format."
              onCancel={handleCancelErrorPopUp}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UploadProducts;
