import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import UnitTable from '../../Tables/UnitTable';
import { unitDelete, unitGet, unitSave, unitUpdate } from '../../redux/actions/unitActions';
import { UNIT_POST_SUCCESS } from '../../redux/constants/unitConstants';
import ConfirmationPopup from "../commen/ConfirmationPopup";

const Unit = () => {
    const dispatch = useDispatch()
    const [unitList, setUnitList] = useState([] as any)
    const [updateId, setUpdateId] = useState(null as any);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [rowPerPageUser, setrowPerPageUser] = useState(10);
    const [userPage, setUserPage] = useState(0);
    const [totalCountForUser, settotalCountForUser] = useState(0);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            unit_no: "",
            unit_description: "",
        },
    });

    const handleEdit = (item: any) => {
        setUpdateId(item?._id?.$oid)
        let value = {
            unit_no: item.unit_no,
            unit_description: item.unit_description,
        };
        reset({
            ...value,
        });
    }

    const fetchData = (data: any) => {
        dispatch(unitGet() as any).then((res: any) => {
            if (res && res.type === "UNIT_GET_SUCCESS") {
                setUnitList(res.payload);
            }
          });
    };

    const onSubmit = (data: any) => {
        let unitdata = {
            unit_no: data.unit_no,
            unit_description: data.unit_description
        }
        if (updateId) {
            dispatch(unitUpdate(updateId, unitdata) as any).then((res: any) => {
                if (res) {
                    let obj = {
                        unit_no: "",
                        unit_description: "",
                    };
                    reset({ ...obj });
                    setUpdateId(null);
                    let currentdata = {
                        pageNumber: userPage + 1,
                        pageSize: rowPerPageUser,
                    }
                    fetchData(currentdata);
                    setUserPage(userPage);
                    toast.success("Update SuccessFully !", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });

                }
            })
        }
        else {
            dispatch(unitSave(unitdata) as any).then(() => {
                if ({ type: UNIT_POST_SUCCESS }) {
                    reset()
                }
                let currentdata = {
                    pageNumber: 1,
                    pageSize: rowPerPageUser,
                }
                fetchData(currentdata);
                setUserPage(0);
                toast.success("Saved SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            })
        }

    }

    useEffect(() => {
        let currentdata = {
            pageNumber: 1,
            pageSize: 10,
        }
        fetchData(currentdata);
    }, []);

    const resetProduct = () => {
        setShowConfirmation(false);
        setUpdateId(null);
        let value = {
            unit_no: "",
            unit_description: "",
        };
        reset({ ...value });
    };

    const handledelete = (data: any) => {
        setShowConfirmation(true);
        setUpdateId(data?._id?.$oid)
    };

    const handleConfirmdelete = () => {
        dispatch(unitDelete(updateId) as any).then((res: any) => {
            if (res) {
                setUpdateId(null);
                let currentdata = {
                    pageNumber: userPage + 1,
                    pageSize: rowPerPageUser,
                }
                fetchData(currentdata);
                setUserPage(userPage);
                setUpdateId(null)
                setShowConfirmation(false);
            }
        })
    }

    const handleCancel = () => {
        setShowConfirmation(false);

    };

    return (
        <>
            <div className=''>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className="mx-3 mb-4 rounded-lg">
                        <legend className="p-4  font-bold text-[#045545]  text-lg mb-2 ml-2">
                            Units
                        </legend>
                        <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row mb-8 xl:flex-row gap-4 px-2">
                            <div className="required flex flex-col mb-2 lg:w-1/3 xl:w-1/4">
                                <label
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                        color: "#008065",
                                    }}
                                >
                                    <span>
                                        Units
                                    </span>

                                </label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder='Units'
                                        {...register("unit_no", {
                                            required: "Unit is required",
                                        })}
                                        className={
                                            `${errors?.unit_no ? "border-rose-600" : ""} ` + "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-40 md:w-full lg:w-full xl:w-72 2xl:w-72"
                                        }
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                        }}
                                    // {...register("amountReceived")}
                                    />
                                    {errors?.unit_no && (
                                    <p 
                                        style={{
                                          fontFamily: "poppins",
                                          fontWeight: 700,
                                          color: "#ff0000",
                                        }}
                                        className="text-red-400 text-xs"
                                    >
                                            {errors?.unit_no?.message}
                                    </p>
                                    )}
                                </div>
                            </div>
                            <div className="required flex flex-col mb-2 lg:w-1/3 xl:w-1/4">
                                <label
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                        color: "#008065",
                                    }}
                                >
                                    <span>
                                        Description
                                    </span>

                                </label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder='Description'
                                        {...register("unit_description", {
                                            required: "Description is required",
                                        })}
                                        className={
                                            `${errors?.unit_description ? "border-rose-600" : ""} ` + "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-40 md:w-full lg:w-full xl:w-72 2xl:w-72"
                                        }
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                        }}
                                    // {...register("amountReceived")}
                                    />
                                    {errors?.unit_description && (
                                    <p 
                                        style={{
                                          fontFamily: "poppins",
                                          fontWeight: 700,
                                          color: "#ff0000",
                                        }}
                                        className="text-red-400 text-xs"
                                    >
                                            {errors?.unit_description?.message}
                                    </p>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col mb-2  mt-0 sm:mt-6 md:mt-6 lg:mt-6 xl:mt-6 2xl:mt-6 ">
                                <div className="pr-3">
                                    <button
                                        type="button"
                                        className="rounded-xl  font-bold text-white  text-sm h-8 w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 bg-[#008065] "
                                        onClick={resetProduct}
                                        style={{ fontFamily: "poppins", fontSize: "14px" }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-col mb-2 mt-0 sm:mt-6 md:mt-6 lg:mt-6 xl:mt-6 2xl:mt-6">
                                <div className="pr-3">
                                    <button
                                        type="submit"
                                        className="rounded-xl  font-bold text-white  text-sm h-8 w-48 sm:w-40 md:w-40 xl:w-40 2xl:w-40 lg:w-40 bg-[#008065] "
                                        style={{ fontFamily: "poppins", fontSize: "14px" }}
                                    >
                                        {updateId ? "Update" : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </div> 
                    </fieldset>
                </form>



            </div>
            <UnitTable
                unitList={unitList}
                setUnitList={setUnitList}
                handleEdit={handleEdit}
                handledelete={handledelete}
                userPage={userPage}
                setUserPage={setUserPage}
                rowPerPageUser={rowPerPageUser}
                setrowPerPageUser={setrowPerPageUser}
                totalCountForUser={totalCountForUser}
                settotalCountForUser={settotalCountForUser}
            />
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirmdelete}
                    onCancel={handleCancel}
                />
            )}
        </>
    )
}

export default Unit