import { NOTIFICATION_FIND_FAIL, NOTIFICATION_FIND_REQUEST, NOTIFICATION_FIND_RESET, NOTIFICATION_FIND_SUCCESS } from "../constants/NotificationConstants";

interface NotificationState {
  loading: boolean;
  notificationcount: {
    total_count: number;
    invoices: any[];
  };
  error: string | null;
}

interface NotificationAction {
  type: string;
  payload?: any;
}

const initialState: NotificationState = {
  loading: false,
  notificationcount: {
    total_count: 0,
    invoices: []
  },
  error: null
};

export const NotificationReducer = (state = initialState, action: NotificationAction): NotificationState => {
   
  switch (action.type) {
    case NOTIFICATION_FIND_REQUEST:
      return { ...state, loading: true };
    case NOTIFICATION_FIND_SUCCESS:
      return { loading: false, notificationcount: action.payload, error: null };
    case NOTIFICATION_FIND_FAIL:
      return { loading: false, error: action.payload, notificationcount: { total_count: 0, invoices: [] }};
    case NOTIFICATION_FIND_RESET:
      return {
        ...state,
        notificationcount: {
          ...state.notificationcount,
          total_count: 0
        }
      };
    default:
      return state;
  }
};
