// src/context/FontContext.tsx

import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from "react";

interface FontContextProps {
  selectedFont: string;
  setSelectedFont: (font: string) => void;
}

const FontContext = createContext<FontContextProps | undefined>(undefined);

export const useFont = () => {
  const context = useContext(FontContext);
  if (!context) {
    throw new Error("useFont must be used within a FontProvider");
  }
  return context;
};

export const FontProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedFont, setSelectedFont] = useState<string>("poppins");
  return (
    <FontContext.Provider value={{ selectedFont, setSelectedFont }}>
      {children}
    </FontContext.Provider>
  );
};
