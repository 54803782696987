import api from "../axiosConfig";
import { FONTFAMILY_DELETE_FAIL, FONTFAMILY_DELETE_REQUEST, FONTFAMILY_DELETE_SUCCESS, FONTFAMILY_GET_FAIL, FONTFAMILY_GET_REQUEST, FONTFAMILY_GET_SUCCESS, FONTFAMILY_POST_FAIL, FONTFAMILY_POST_REQUEST, FONTFAMILY_POST_SUCCESS, FONTFAMILY_UPDATE_FAIL, FONTFAMILY_UPDATE_REQUEST, FONTFAMILY_UPDATE_SUCCESS } from "../constants/FontFamilyConstants";

export const fontFamilySave = (font: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: FONTFAMILY_POST_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();
        font.user_id = token._id;
        const { data } = await api.post(
            `/api/fontfamily/save`,
            font,
            {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            }
        );
        return dispatch({ type: FONTFAMILY_POST_SUCCESS, payload: data });
    } catch (error: any) {
        return dispatch({
            type: FONTFAMILY_POST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const fontFamilyGet = (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: FONTFAMILY_GET_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();

        const { data } = await api.get(
            `/api/fontfamily/all/${id}`,
            {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            }
        );
        return dispatch({ type: FONTFAMILY_GET_SUCCESS, payload: data });
    } catch (error: any) {
        dispatch({
            type: FONTFAMILY_GET_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const fontFamilyUpdate = (id: any, font: any) => async (dispatch: any, getState: any) => {

    dispatch({ type: FONTFAMILY_UPDATE_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();
        font.user_id = token._id;
        const { data } = await api.put(`/api/fontfamily/update/${id}`, font, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: FONTFAMILY_UPDATE_SUCCESS, payload: data });

    } catch (error: any) {
        dispatch({
            type: FONTFAMILY_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const fontFamilyDelete = (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: FONTFAMILY_DELETE_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();

        const { data } = await api.delete(`/api/fontfamily/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: FONTFAMILY_DELETE_SUCCESS, payload: data });

    } catch (error: any) {
        dispatch({
            type: FONTFAMILY_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};