import {
  PAYMENT_GET_FAIL,
  PAYMENT_GET_REQUEST,
  PAYMENT_GET_RESET,
  PAYMENT_GET_SUCCESS,
  PAYMENT_PAGE_POST_FAIL,
  PAYMENT_PAGE_POST_REQUEST,
  PAYMENT_PAGE_POST_RESET,
  PAYMENT_PAGE_POST_SUCCESS,
} from "../constants/PaymentConstants";

export const paymentGetReducer = (
  state = { loading: true, paymentlist: [] },
  action: any
) => {
  switch (action.type) {
    case PAYMENT_GET_REQUEST:
      return { loading: true };
    case PAYMENT_GET_SUCCESS:
      return { loading: false, paymentlist: action.payload };
    case PAYMENT_GET_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_GET_RESET:
      return {};
    default:
      return state;
  }
};

export const paymentGetpageReducer = (
  state = { loading: true, paymentpagelist: [] },
  action: any
) => {
  switch (action.type) {
    case PAYMENT_PAGE_POST_REQUEST:
      return { loading: true };
    case PAYMENT_PAGE_POST_SUCCESS:
      return { loading: false, paymentpagelist: action.payload };
    case PAYMENT_PAGE_POST_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_PAGE_POST_RESET:
      return {};
    default:
      return state;
  }
};
