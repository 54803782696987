export const PRODUCT_POST_REQUEST = 'PRODUCT_POST_REQUEST';
export const PRODUCT_POST_SUCCESS = 'PRODUCT_POST_SUCCESS';
export const PRODUCT_POST_FAIL = 'PRODUCT_POST_FAIL';
export const PRODUCT_POST_REST = 'PRODUCT_POST_REST';


export const PRODUCT_GET_REQUEST = 'PRODUCT_GET_REQUEST';
export const PRODUCT_GET_SUCCESS = 'PRODUCT_GET_SUCCESS';
export const PRODUCT_GET_FAIL = 'PRODUCT_GET_FAIL';
export const PRODUCT_GET_REST = 'PRODUCT_GET_REST';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_REST = 'PRODUCT_UPDATE_REST';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_REST = 'PRODUCT_DELETE_REST';

export const PRODUCT_DEACTIVE_REQUEST = 'PRODUCT_DEACTIVE_REQUEST';
export const PRODUCT_DEACTIVE_SUCCESS = 'PRODUCT_DEACTIVE_SUCCESS';
export const PRODUCT_DEACTIVE_FAIL = 'PRODUCT_DEACTIVE_FAIL';
export const PRODUCT_DEACTIVE_REST = 'PRODUCT_DEACTIVE_REST';