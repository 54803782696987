import React from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const OtpScreenPage = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({});
      const navigateTo = useNavigate()

      const onSubmit = async (e:any) => {

      };

  return (
    <section className=" flex items-center justify-center 2xl:mt-20 mb-10">
    <div className="  flex rounded-2xl  ">

      <div className="w-full lg:w-full  rounded-lg lg:rounded-l-none mt-8">
        <div className=" flex flex-col items-center justify-center relative">
          <div className="flex flex-col bg-[#00A787] text-center   shadow-md px-4 sm:px-6 md:px-10 lg:px-10 py-16 h-full  w-full max-w-md rounded-t">
            <span
              style={{ fontFamily: "poppins" }}
              className="text-[#FFF] text-center text-xl font-bold mb-6 py-1 place-self-center"
            >
              
              OTP
            </span>
          </div>
          <div className="flex flex-col shadow-lg shadow-gray-400 px-4 sm:px-6 md:px-10 lg:px-10 py-8  w-full max-w-md rounded-b ">
            <div className="flex flex-col bg-white  -mt-28 shadow-lg shadow-gray-400  px-4 sm:px-6 md:px-10 lg:px-10 py-8 rounded-md w-full max-w-md">
              <div className="pt-1  ">
                <form
                  action="#"
                  method="POST"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="flex flex-col mb-6">
                    <label
                      className="mb-1 text-xs sm:text-sm tracking-wide text-[#00A787]"
                    >
                      Enter Your OTP:
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        // name="email"
                        {...register("otp", {
                          required: "Enter Your Otp",

                        })}
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: errors?.otp ? "" : "#00A787",
                        }}
                        className={
                          `${errors?.otp ? "border-red-600" : ""} ` +
                          "text-sm sm:text-base pr-4 rounded-lg  py-2 border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                        }
                        placeholder="Enter Your OTP"
                      />
                    </div>
                    {errors?.otp && (
                      <p className="text-red-400 text-xs">
                        {errors?.otp?.message as any}
                      </p>
                    )}
                  </div>

                  <div className="flex w-full">
                    <button
                      type="button"
                      onClick={()=>navigateTo('/changePassword')}
                      className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#00A787] rounded py-2 w-full transition duration-150 ease-in"
                    >
                      <span className="mr-2 ">Next</span>
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default OtpScreenPage
