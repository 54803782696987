export const UPIPAYMENT_POST_REQUEST = 'UPIPAYMENT_POST_REQUEST';
export const UPIPAYMENT_POST_SUCCESS = 'UPIPAYMENT_POST_SUCCESS';
export const UPIPAYMENT_POST_FAIL = 'UPIPAYMENT_POST_FAIL';
export const UPIPAYMENT_POST_RESET = 'UPIPAYMENT_POST_RESET';

export const UPIPAYMENT_GET_REQUEST = 'UPIPAYMENT_GET_REQUEST';
export const UPIPAYMENT_GET_SUCCESS = 'UPIPAYMENT_GET_SUCCESS';
export const UPIPAYMENT_GET_FAIL = 'UPIPAYMENT_GET_FAIL';
export const UPIPAYMENT_GET_RESET = 'UPIPAYMENT_GET_RESET';

export const UPIPAYMENT_GET_ONE_REQUEST = 'UPIPAYMENT_GET_ONE_REQUEST';
export const UPIPAYMENT_GET_ONE_SUCCESS = 'UPIPAYMENT_GET_ONE_SUCCESS';
export const UPIPAYMENT_GET_ONE_FAIL = 'UPIPAYMENT_GET_ONE_FAIL';
export const UPIPAYMENT_GET_ONE_RESET = 'UPIPAYMENT_GET_ONE_RESET';

export const UPIPAYMENT_UPDATE_REQUEST = 'UPIPAYMENT_UPDATE_REQUEST';
export const UPIPAYMENT_UPDATE_SUCCESS = 'UPIPAYMENT_UPDATE_SUCCESS';
export const UPIPAYMENT_UPDATE_FAIL = 'UPIPAYMENT_UPDATE_FAIL';
export const UPIPAYMENT_UPDATE_RESET = 'UPIPAYMENT_UPDATE_RESET';

export const UPIPAYMENT_DELETE_REQUEST = 'UPIPAYMENT_DELETE_REQUEST';
export const UPIPAYMENT_DELETE_SUCCESS = 'UPIPAYMENT_DELETE_SUCCESS';
export const UPIPAYMENT_DELETE_FAIL = 'UPIPAYMENT_DELETE_FAIL';
export const UPIPAYMENT_DELETE_RESET = 'UPIPAYMENT_DELETE_RESET';