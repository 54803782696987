import { UNIT_GET_FAIL, UNIT_GET_REQUEST, UNIT_GET_RESET, UNIT_GET_SUCCESS, UNIT_PAGE_POST_FAIL, UNIT_PAGE_POST_REQUEST, UNIT_PAGE_POST_RESET, UNIT_PAGE_POST_SUCCESS, UNIT_POST_FAIL, UNIT_POST_REQUEST, UNIT_POST_RESET, UNIT_POST_SUCCESS } from "../constants/unitConstants";


export const unitPostReducer = (state = {}, action: any) => {
    switch (action.type) {
        case UNIT_POST_REQUEST:
            return { loading: true };
        case UNIT_POST_SUCCESS:
            return { loading: false, unit: action.payload };
        case UNIT_POST_FAIL:
            return { loading: false, error: action.payload };
        case UNIT_POST_RESET:
            return {};
        default:
            return state;
    }
};

export const unitGetReducer = (state = {}, action: any) => {
    switch (action.type) {
        case UNIT_GET_REQUEST:
            return { loading: true };
        case UNIT_GET_SUCCESS:
            return { loading: false, purchaselist: action.payload };
        case UNIT_GET_FAIL:
            return { loading: false, error: action.payload };
        case UNIT_GET_RESET
            :
            return {};
        default:
            return state;
    }
};

export const unitGetPageReducer = (state = {}, action: any) => {
    switch (action.type) {
        case UNIT_PAGE_POST_REQUEST:
            return { loading: true };
        case UNIT_PAGE_POST_SUCCESS:
            return { loading: false, unitpagelist: action.payload };
        case UNIT_PAGE_POST_FAIL:
            return { loading: false, error: action.payload };
        case UNIT_PAGE_POST_RESET
            :
            return {};
        default:
            return state;
    }
};

// export const unitDeleteReducer = (state = {}, action: any) => {
//     switch (action.type) {
//         case PURCHASE_DELETE_REQUEST:
//             return { loading: true };
//         case PURCHASE_DELETE_SUCCESS:
//             return { loading: false, purchase: action.payload };
//         case PURCHASE_DELETE_FAIL:
//             return { loading: false, error: action.payload };
//         case PURCHASE_DELETE_RESET:
//             return {};
//         default:
//             return state;
//     }
// }