import { Box, IconButton } from "@mui/material";
// import { MenuButton, menuButton } from '@szhsin/react-menu';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  serviceUnitsAll,
  serviceUnitsDelete,
  serviceUnitsSave,
  serviceUnitsUpdate,
} from "../../redux/actions/serviceUnitsActions";
import ConfirmationPopup from "../commen/ConfirmationPopup";

const ServiceUnitSettingtabpage = () => {
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      service_units: "",
    },
  });
  const [unitid, setUnitid] = useState(null);
  const [serviceUnit, SetServiceUnit] = useState([] as any);
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const fetchData = () => {
    dispatch(serviceUnitsAll(userId?.token?._id) as any).then((res: any) => {
      SetServiceUnit(res.payload);
    });
  };

  const onSubmit = (data: any) => {
    let obj = {
      service_units: data.service_units,
      user_id: userId?.token?._id,
    };

    if (unitid) {
      dispatch(serviceUnitsUpdate(unitid, data) as any).then((res: any) => {
        // eslint-disable-next-line eqeqeq
        if (res.type == "SERIVICEUNITS_UPDATE_SUCCESS") {
          fetchData();
          let value = {
            service_units: "",
          };
          reset({
            ...value,
          });
        }
        setUnitid(null);
        toast.success("Update SuccessFully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
      });
    } else {
      dispatch(serviceUnitsSave(obj) as any).then((res: any) => {
        if (res.type === "SERIVICEUNITS_POST_SUCCESS") {
          fetchData();
          resetUnit();
        }
        toast.success("Saved Successfully!", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
      });
    }
  };

  const OnEdit = (data: any) => {
    setUnitid(data._id.$oid);

    let value = {
      service_units: data.service_units,
    };

    reset({
      ...value,
    });
  };

  const deleteUnit = (data: any) => {
    setShowConfirmation(true);
    setUnitid(data._id?.$oid);
  };

  const handleConfirm = () => {
    dispatch(serviceUnitsDelete(unitid) as any).then((res: any) => {
      if (res) {
        fetchData();
        setUnitid(null);
        setShowConfirmation(false);
        let value = {
          service_units: "",
        };
        reset({
          ...value,
        });
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetUnit = () => {
    reset({
      service_units: "",
    });
    setUnitid(null);
  };

  // Add unique ids to each row
  const rows = serviceUnit?.map((item: any, index: any) => ({
    id: uuidv4(),
    sl_No: index + 1,
    ...item,
  }));

  const columns: GridColDef[] = [
    {
      field: "sl_No",
      headerName: "Sl No",
      width: 400,
      editable: false,
    },
    {
      field: "service_units",
      headerName: "Units",
      width: 370,
      editable: false,
      renderCell: (params: any) => (
        <>{params?.row?.service_units ? params?.row?.service_units : "N/A"}</>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      editable: false,
      width: 380,
      renderCell: (params: any) => (
        <>
          <IconButton aria-label="edit" onClick={() => OnEdit(params.row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => deleteUnit(params.row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5  cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="mx-3 mb-4 rounded-lg">
          <legend className="p-4 font-bold text-[#045545] text-lg ml-2">
            Service Units Settings
          </legend>

          <div className="rounded-xl py-3 mb-3 bg-[#E1E8E7] w-60 sm:w-80">
            <div className="flex flex-col mb-6 ml-12">
              <label
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#008065",
                }}
                className="required"
              >
                <span>Add Service Units</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  {...register("service_units", {
                    required: "Service Units is required",
                  })}
                  placeholder="Ex: Per Day"
                  className={
                    "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full" +
                    (errors.service_units
                      ? " border-red-500"
                      : " border-[#008065]")
                  }
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    if (input.value.startsWith(" ")) {
                      input.value = input.value.trimStart();
                    }
                  }}
                />
                {errors.service_units && (
                  <span className="text-red-500 text-sm">
                    {errors.service_units.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="rounded-xl px-3 mb-3">
            <div className="flex w-full flex-col sm:flex-row pl-5">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col  mb-4 "></div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col  mb-4 "> </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2">
                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                  <button
                    type="button"
                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                    style={{
                      backgroundColor: "#008065",
                      fontFamily: "poppins",
                      fontSize: "14px",
                    }}
                    onClick={resetUnit}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                  <button
                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                    style={{
                      backgroundColor: "#008065",
                      fontFamily: "poppins",
                      fontSize: "14px",
                    }}
                  >
                    {unitid ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>

      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
          <div className="flex items-center mb-2">
            <div className="relative flex">
              <input
                type="text"
                id="simple-search"
                className="w-44 bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                placeholder="Search"
                required
              />
              <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="mb-2">
            <div className="relative flex items-center">
              <select
                id="sortDropdown"
                className="block w-[11rem] h-10 p-2 text-sm border border-[#008065] rounded-lg bg-gray-50"
              >
                <option value="" disabled  hidden>
                  Sort By
                </option>
                <option value="asc">Units Name A to Z</option>
                <option value="desc">Units Name Z to A</option>
              </select>
            </div>
          </div>
        </div>
        <Box
          sx={{
            height: 400,
            width: "100%",
            // maxHeight: "60vh",
            overflowX: "hidden !important",
            overflowY: "auto",
          }}
        >
          <DataGrid
autoHeight
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 500,
              ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                {
                  backgroundColor: "#fff",
                  color: "#008060 !important",
                  fontWeight: 600,
                  fontSize: "14px",
                },
              "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
              ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                fontWeight: 600,
                fontSize: "14px",
              },
              "@media (min-width: 2555px)": {
                ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
                  {
                    minWidth: "700px !important",
                  },
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </div>
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default ServiceUnitSettingtabpage;
