import React from "react";

const DownloadExcelSheet = () => {
  const downloadExcel = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/product_bulk_upload.xlsx`; // Path to your file
    link.setAttribute("download", "product_bulk_upload.xlsx"); // Suggested download file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after the click
  };

  return (
    <div className="flex justify-start">
      <div className="p-6 text-center sm:p-4">
        <button
          onClick={downloadExcel}
          className="rounded-xl bg-[#008065] text-white font-bold py-2 px-4 sm:py-1 sm:px-2 sm:text-xs md:text-sm lg:text-base focus:outline-none focus:shadow-outline"
        >
          Download Sample Excel
        </button>
      </div>
    </div>

  );
};

export default DownloadExcelSheet;
