import React, { useState } from "react";

// Define the props type
interface DateFormatProps {
  // eslint-disable-next-line no-unused-vars
  setDateFormat: (format: string) => void;
}

const DateFormat: React.FC<DateFormatProps> = ({ setDateFormat }) => {
  const [selectedFormat, setSelectedFormat] = useState<string>("DD/MM/YYYY");

  const dateFormats: string[] = [
    "DD-MM-YYYY",
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "YYYY-MM-DD",
  ];

  return (
    <>
      <label
        htmlFor="font-family-select"
        className="block mb-2 text-sm text-[black] font-semibold"
      >
        Date Format
      </label>
      <select
        value={selectedFormat}
        className=" border-2 border-[#008065] focus:outline-none rounded-[7px]  h-9 lg:w-[10rem] md:w-[8rem] xl:w-[14rem] 2xl:w-[14rem] w-full  bg-[white]"
        onChange={(event) => {
          const format = event.target.value as string;
          setDateFormat(format);
          setSelectedFormat(format);
        }}
        style={{
          fontFamily: "poppins",
          fontSize: "13px",
          fontWeight: 400,
        }}
      >
        <option value="" disabled hidden>
          Font
        </option>
        {dateFormats.map((dateFormat) => (
          <option key={dateFormat} value={dateFormat}>
            {dateFormat}
          </option>
        ))}
      </select>
    </>
  );
};

export default DateFormat;
