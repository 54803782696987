import React from 'react'

const EmailIcon = ({props}:any) => {
    return (
        <div>
            <svg width="18" height="19" className='pr-1 font-bold mr-3' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.28571L10.6441 12.2976C11.4652 12.8095 12.5348 12.8095 13.3559 12.2976L23 6.28571M1 6.28571C1 5.02335 2.09441 4 3.44444 4H20.5556C21.9056 4 23 5.02335 23 6.28571M1 6.28571V17.7143C1 18.9767 2.09441 20 3.44444 20H20.5556C21.9056 20 23 18.9767 23 17.7143V6.28571" stroke="#00A787" strokeLinecap="round" strokeLinejoin="round">
                </path>
            </svg>
        </div>
    )
}

export default EmailIcon
