import { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SalesgettMethod } from "../redux/actions/SalesAction";
import PaymentReceiveTable from "./PaymentReceiveTable";

const PaymentReceiveTab = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stockdetails = useSelector((state: any) => state.SalesList);
    const { saleslist } = stockdetails;

    const [salseDetails, setSalseDetails] = useState([] as any);

    useEffect(() => {
        const fetchData = () => {
            dispatch(SalesgettMethod() as any).then((response: any) => {
                setSalseDetails(response?.payload.reverse());
            });
        };
        fetchData();
    }, []);
    // *******************sort*********************
    const [sortOrder, setSortOrder] = useState<string>("asc");
    const handleSortChange = (newSortOrder: string) => {
        const sortedData = [...salseDetails].sort((a: any, b: any) => {
            if (newSortOrder === "asc") {
                return a.prodcut.localeCompare(b.prodcut);
            } else {
                return b.prodcut.localeCompare(a.prodcut);
            }
        });
        setSalseDetails(sortedData);
        setSortOrder(newSortOrder);
    };

    const handleAddPaymentReceive = () => {
        navigate("/app/paymentsReceive");
    };

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        let data = [...saleslist];
        data = data?.filter((value: any) =>
            value?.prodcut?.toLowerCase().includes(query?.toLowerCase())
        );
        setSalseDetails(data);
    };

    // Add unique ids to each row
    const rows = salseDetails?.map((item: any, index: any) => ({
        id: uuidv4(),
        sl_No: index + 1,
        ...item,
    }));
    return (
        <div>
            <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                <div className="p-4  font-bold text-[#008065]  ml-2">Payment In - Report</div>
                <div className="p-4  font-bold text-[#008065]  ml-2">
                    <button
                        type="button"
                        className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                        style={{ backgroundColor: "#008065" }}
                        onClick={handleAddPaymentReceive}
                    >
                        <FiPlusCircle className="h-5 w-5" />
                        <span className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`} >Add Payment In </span>
                    </button></div>


            </div>
            <div className="mt-1">
                <PaymentReceiveTable />
            </div>


        </div>
    );
};

export default PaymentReceiveTab;
