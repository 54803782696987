import { useState } from "react";
import InvoiceProductSetting from "./InvoiceProductSetting";
import InvoiceServiceSettings from "./InvoiceServiceSettings";


const InvoiceSettings = () => {
 
    const [openTabValue, setOpenTabValue] = useState(1);
    return (
        
        <div>
        <div className="bg-[#E1E8E7] lg:h-[650px] xl:h-[650px] 2xl:h-[650px] h-screen  ">
          <div className=" ">
            <ul className="flex flex-wrap text-sm font-medium text-center text-[#008065]  dark:text-gray-400 ">
              <li className="-mb-px mr-1">
                <button
                  style={{
                    borderBottom: openTabValue === 1 ? "2px solid #008065" : "",
                  }}
                  onClick={() => setOpenTabValue(1)}
                  className={
                    "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                  }
                >
                  Invoice Product Settings
                </button>
              </li>
              <li className="mr-1">
                <button
                  onClick={() => setOpenTabValue(2)}
                  style={{
                    borderBottom: openTabValue === 2 ? "2px solid #008065" : "",
                  }}
                  className={
                    "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                  }
                >
                   Invoice Service Settings
                </button>
              </li>
            </ul>
          </div>
          <div className=" mt-6 ">
            <div className={openTabValue === 1 ? "block  " : "hidden"}>
            <InvoiceProductSetting/>
             
            </div>
            {openTabValue === 2 && (
              <div className={openTabValue === 2 ? "block" : "hidden"}>
                {/* <CountryPage /> */}
                {/* <GstSettings /> */}
                <InvoiceServiceSettings/>
              </div>
            )}
           
          </div>
        </div>
      </div>
    )
}

export default InvoiceSettings
