export const SERVICE_POST_REQUEST = 'SERVICE_POST_REQUEST';
export const SERVICE_POST_SUCCESS = 'SERVICE_POST_SUCCESS';
export const SERVICE_POST_FAIL = 'SERVICE_POST_FAIL';
export const SERVICE_POST_REST = 'SERVICE_POST_REST';


export const SERVICE_GET_REQUEST = 'SERVICE_GET_REQUEST';
export const SERVICE_GET_SUCCESS = 'SERVICE_GET_SUCCESS';
export const SERVICE_GET_FAIL = 'SERVICE_GET_FAIL';
export const SERVICE_GET_REST = 'SERVICE_GET_REST';

export const SERVICE_UPDATE_REQUEST = 'SERVICE_UPDATE_REQUEST';
export const SERVICE_UPDATE_SUCCESS = 'SERVICE_UPDATE_SUCCESS';
export const SERVICE_UPDATE_FAIL = 'SERVICE_UPDATE_FAIL';
export const SERVICE_UPDATE_REST = 'SERVICE_UPDATE_REST';

export const SERVICE_DEACTIVE_REQUEST = 'SERVICE_DEACTIVE_REQUEST';
export const SERVICE_DEACTIVE_SUCCESS = 'SERVICE_DEACTIVE_SUCCESS';
export const SERVICE_DEACTIVE_FAIL = 'SERVICE_DEACTIVE_FAIL';
export const SERVICE_DEACTIVE_REST = 'SERVICE_DEACTIVE_REST';

export const SERVICE_DELETE_REQUEST = 'SERVICE_DELETE_REQUEST';
export const SERVICE_DELETE_SUCCESS = 'SERVICE_DELETE_SUCCESS';
export const SERVICE_DELETE_FAIL = 'SERVICE_DELETE_FAIL';
export const SERVICE_DELETE_REST = 'SERVICE_DELETE_REST';


export const INTERNAL_SERVICEINVOICE_POST_REQUEST = 'INTERNAL_SERVICEINVOICE_POST_REQUEST';
export const INTERNAL_SERVICEINVOICE_POST_SUCCESS = 'INTERNAL_SERVICEINVOICE_POST_SUCCESS';
export const INTERNAL_SERVICEINVOICE_POST_FAIL = 'INTERNAL_SERVICEINVOICE_POST_FAIL';
export const INTERNAL_SERVICEINVOICE_POST_REST = 'INTERNAL_SERVICEINVOICE_POST_REST';

export const INTERNAL_SERVICEINVOICE_FIND_ALL_REQUEST = 'INTERNAL_SERVICEINVOICE_FIND_ALL_REQUEST';
export const INTERNAL_SERVICEINVOICE_FIND_ALL_SUCCESS = 'INTERNAL_SERVICEINVOICE_FIND_ALL_SUCCESS';
export const INTERNAL_SERVICEINVOICE_FIND_ALL_FAIL = 'INTERNAL_SERVICEINVOICE_FIND_ALL_FAIL';
export const INTERNAL_SERVICEINVOICE_FIND_ALL_REST = 'INTERNAL_SERVICEINVOICE_FIND_ALL_REST';

export const INTERNAL_SERVICEINVOICE_FIND_ONE_REQUEST = 'INTERNAL_SERVICEINVOICE_FIND_ONE_REQUEST';
export const INTERNAL_SERVICEINVOICE_FIND_ONE_SUCCESS = 'INTERNAL_SERVICEINVOICE_FIND_ONE_SUCCESS';
export const INTERNAL_SERVICEINVOICE_FIND_ONE_FAIL = 'INTERNAL_SERVICEINVOICE_FIND_ONE_FAIL';
export const INTERNAL_SERVICEINVOICE_FIND_ONE_REST = 'INTERNAL_SERVICEINVOICE_FIND_ONE_REST';

export const INTERNAL_SERVICEINVOICE_UPDATE_REQUEST = 'INTERNAL_SERVICEINVOICE_UPDATE_REQUEST';
export const INTERNAL_SERVICEINVOICE_UPDATE_SUCCESS = 'INTERNAL_SERVICEINVOICE_UPDATE_SUCCESS';
export const INTERNAL_SERVICEINVOICE_UPDATE_FAIL = 'INTERNAL_SERVICEINVOICE_UPDATE_FAIL';
export const INTERNAL_SERVICEINVOICE_UPDATE_REST = 'INTERNAL_INVOICE_UPDATE_REST';

export const INTERNAL_SERVICEINVOICE_DELETE_REQUEST = 'INTERNAL_SERVICEINVOICE_DELETE_REQUEST';
export const INTERNAL_SERVICEINVOICE_DELETE_SUCCESS = 'INTERNAL_SERVICEINVOICE_DELETE_SUCCESS';
export const INTERNAL_SERVICEINVOICE_DELETE_FAIL = 'INTERNAL_SERVICEINVOICE_DELETE_FAIL';
export const INTERNAL_SERVICEINVOICE_DELETE_REST = 'INTERNAL_SERVICEINVOICE_DELETE_REST';