/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import VendorsTable from "../../Tables/VendorsTable";
import {
  VendorDelete,
  VendorSave,
  VendorUpdate,
} from "../../redux/actions/vendorAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";

const VendorsPage = ({ vendorList, fetchData }: any) => {
  // console.log("vendorList", vendorList);
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);
  const [taxtype, settaxtype] = useState<string>("IGST");
  // console.log("taxtype", taxtype);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    vendor_name: "",
    vendor_address: "",
    city: "",
    gst: "",
    pan_no: "",
    postal_code: "",
    state: "",
    vendor_email: "",
    vendor_mobile: "",
    tax_type: "",
    user_id: userId?.token?._id,
  } as any);
  // console.log("formData", formData);
  const [vendorid, setVendorid] = useState(null as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);


  useEffect(() => {
    setFormData((prevData : any) => ({
      ...prevData,
      tax_type: taxtype, 
    }));
  }, [taxtype]);

  // ***************************Value Enter Sction*****************************

  const [vendorPageErrors, setVendorPageErrors] = useState<{
    vendor_name?: string;
    vendor_mobile?: string;
    gst?: string;
    vendor_address?: string;
  }>({});

  const validateProdForm = () => {
    let isValid = true;
    const errors: any = {};

    if (formData.vendor_name.trim() === "") {
      errors.vendor_name = "Name is Required.";
      isValid = false;
    }
    if (formData.vendor_mobile.trim() === "") {
      errors.vendor_mobile = "Mobile No is Required.";
      isValid = false;
    }
    if (formData.gst.trim() === "") {
      errors.gst = "GST is Required.";
      isValid = false;
    }
    if (formData.vendor_address.trim() === "") {
      errors.vendor_address = "Address is Required.";
      isValid = false;
    }

    const duplicate = vendorList.some((vendor: any) => {
      return (
        (formData.vendor_mobile &&
          vendor.vendor_mobile === formData.vendor_mobile &&
          vendor._id.$oid !== vendorid) ||
        (formData.gst &&
          vendor.gst === formData.gst &&
          vendor._id.$oid !== vendorid) ||
        (formData.vendor_email &&
          vendor.vendor_email === formData.vendor_email &&
          vendor._id.$oid !== vendorid)
      );
    });

    if (duplicate) {
      if (
        vendorList.some(
          (vendor: any) =>
            vendor.vendor_mobile === formData.vendor_mobile &&
            vendor._id.$oid !== vendorid
        )
      ) {
        errors.vendor_mobile = "Mobile Number is Already Exist";
      }
      if (
        formData.gst &&
        vendorList.some(
          (vendor: any) =>
            vendor.gst === formData.gst && vendor._id.$oid !== vendorid
        )
      ) {
        errors.gst = "GST No is Already Exist";
      }
      if (
        formData.pan_no &&
        vendorList.some(
          (vendor: any) =>
            vendor.pan_no === formData.pan_no && vendor._id.$oid !== vendorid
        )
      ) {
        errors.pan_no = "PAN No is Already Exist";
      }
      if (
        formData.client_email &&
        vendorList.some(
          (vendor: any) =>
            vendor.vendor_email === formData.vendor_email &&
            vendor._id.$oid !== vendorid
        )
      ) {
        errors.vendor_email = "Email is Already Exist";
      }
      isValid = false;
    }

    setVendorPageErrors(errors);
    return isValid;
  };

  //   setErrors(validationErrors);
  const postalcodeRegex = /^\d{6}(?:-\d{4})?$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("ee------------------>", e.target.value);

    const { name, value } = e.target;
    let newValue = value.trimStart(); // Trim only leading spaces

    if (name === "gst") {
        settaxtype(newValue.startsWith("33") ? "GST" : "IGST");
    }


    setVendorPageErrors({ ...vendorPageErrors, [name]: "" });
    let errorMessage = "";
    let isValidPostalCode = true;
    let isValidEmail = true;
    let isValidPan = true;
    let isValidMobile = true;
    let isValidGst = true;

    // Validate based on field name
    if (name === "postal_code" && newValue) {
      isValidPostalCode = postalcodeRegex.test(newValue);
      if (!isValidPostalCode) {
        errorMessage = "Invalid Postal Code";
      }
    }
    if (name === "pan_no" && newValue) {
      newValue = newValue.toUpperCase();
      isValidPan = panRegex.test(newValue);
      errorMessage = isValidPan ? "" : "Invalid PAN Number";
    }
    if (name === "vendor_email" && newValue) {
      isValidEmail = emailRegex.test(newValue);
      errorMessage = isValidEmail ? "" : "Invalid Email Address";
    }
    if (name === "vendor_mobile" && newValue) {
      isValidMobile = newValue.length === 10;
      errorMessage = isValidMobile ? "" : "Invalid Mobile Number";
    }
    if (name === "gst" && newValue) {
      newValue = newValue.toUpperCase();
      isValidGst = gstRegex.test(newValue);
      errorMessage = isValidGst ? "" : "Invalid GST Number";
    }

    setFormData({
      ...formData,
      [name]: newValue,
      errors: {
        ...formData.errors,
        [`${name}ErrorMessage`]: errorMessage,
      },
      isValidPostalCode:
        name === "postal_code" ? isValidPostalCode : formData.isValidPostalCode,
      isValidEmail:
        name === "vendor_email" ? isValidEmail : formData.isValidEmail,
      isValidPan: name === "pan_no" ? isValidPan : formData.isValidPan,
      isValidGst: name === "gst" ? isValidGst : formData.isValidGst,
      isValidMobile:
        name === "vendor_mobile" ? isValidMobile : formData.isValidMobile,
    });
  };

  const onEdit = (data: any) => {
    // console.log("data=========================>", data);
    setVendorPageErrors({
      ...vendorPageErrors,
      vendor_name: "",
      vendor_mobile: "",
      gst: "",
      vendor_address: "",
    });
    setVendorid(data._id?.$oid);
    setFormData({
      vendor_name: data.vendor_name,
      vendor_address: data.vendor_address,
      city: data.city,
      gst: data.gst,
      postal_code: data.postal_code,
      state: data.state,
      vendor_email: data.vendor_email,
      vendor_mobile: data.vendor_mobile,
      tax_type: data.taxtype,
      user_id: userId.token._id,
    });
  };
  const onDelete = (data: any) => {
    setShowConfirmation(true);
    setVendorid(data._id?.$oid);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const isFormValid = validateProdForm();
    const vendorNameValid = formData.vendor_name.trim().length > 0;
    let optionalFieldsValid = true;

    // Check optional fields
    const optionalFields = ["vendor_address", "city", "state"];
    optionalFields.forEach((field) => {
      if (formData[field] && formData[field].trim().length === 0) {
        optionalFieldsValid = false;
      }
    });

    if (
      isFormValid &&
      vendorNameValid &&
      optionalFieldsValid &&
      !formData?.errors?.postal_codeErrorMessage &&
      !formData?.errors?.vendor_mobileErrorMessage &&
      !formData?.errors?.pan_noErrorMessage &&
      !formData.errors?.vendor_emailErrorMessage &&
      !formData.errors?.gstErrorMessage
    ) {
      if (vendorid) {
        dispatch(VendorUpdate(vendorid, formData) as any).then((res: any) => {
          // console.log("formData", formData);
          if (res) {
            setFormData({
              vendor_name: "",
              vendor_address: "",
              city: "",
              gst: "",
              pan_no: "",
              postal_code: "",
              state: "",
              vendor_email: "",
              vendor_mobile: "",
              tax_type: "",
              user_id: userId.token._id,
            });
            setVendorid(null);
            fetchData();
            toast.success("Update Successfully!", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            resetProduct();
          }
        });
      } else {
        dispatch(VendorSave(formData) as any).then((res: any) => {
          // console.log("savechecking++++++++++++++++++++++++>", formData);
          if (res) {
            setFormData({
              vendor_name: "",
              vendor_address: "",
              city: "",
              gst: "",
              pan_no: "",
              postal_code: "",
              state: "",
              vendor_email: "",
              vendor_mobile: "",
              tax_type: "",
              user_id: userId.token._id,
            });
            fetchData();
            setVendorid(null);
            toast.success("Saved Successfully!", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            resetProduct();
          }
        });
      }
    } else {
      if (!vendorNameValid) {
        setVendorPageErrors((prev) => ({
          ...prev,
          vendor_name: "Vendor Name cannot be empty or spaces only",
        }));
      }
      validateProdForm();
    }
  };

  const handleConfirm = () => {
    dispatch(VendorDelete(vendorid) as any).then((res: any) => {
      if (res) {
        fetchData();
        setVendorid(null);
        setShowConfirmation(false);
        resetProduct();
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  // *********************cancel button section*************************
  const resetProduct = () => {
    setVendorPageErrors({
      ...vendorPageErrors,
      vendor_name: "",
      vendor_mobile: "",
      gst: "",
      vendor_address: "",
    });
    setFormData({
      vendor_name: "",
      vendor_address: "",
      city: "",
      gst: "",
      pan_no: "",
      postal_code: "",
      state: "",
      vendor_email: "",
      vendor_mobile: "",
      // tax_type: taxtype,
      user_id: userId.token._id,
    });
    setVendorid(null);
    // Reset the form using reset() from react-hook-form
  };

  return (
    <div className="w-full">
      <form>
        <fieldset className="mx-3 mb-4 rounded-lg">
          <legend className="p-4  font-bold text-[#045545]  text-lg    ml-2">
            Vendor
          </legend>

          <div className="rounded-xl  py-3 mb-3  bg-[#E1E8E7]">
            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span>Vendor Name</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="vendor_name"
                      placeholder="Vendor Name"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: vendorPageErrors.vendor_name
                          ? "red"
                          : "#008065",
                      }}
                      value={formData.vendor_name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {vendorPageErrors.vendor_name && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {vendorPageErrors.vendor_name}
                    </span>
                  )}
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span> Vendor Address</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder=" Address"
                      name="vendor_address"
                      className={
                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: vendorPageErrors.vendor_address
                          ? "#FF0000"
                          : "#008065",
                      }}
                      value={formData.vendor_address}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {vendorPageErrors.vendor_address && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {vendorPageErrors.vendor_address}
                    </span>
                  )}
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    City
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="City (Optional)"
                      name="city"
                      className={
                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      value={formData.city}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    State
                  </label>
                  <div className="relative">
                    <input
                      placeholder="State (Optional)"
                      type="text"
                      name="state"
                      className={
                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      value={formData.state}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    Postal Code
                  </label>
                  <div className="relative">
                    <input
                      type=""
                      name="postal_code"
                      placeholder="PostalCode (Optional)"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: formData.errors?.postal_codeErrorMessage
                          ? "#FF0000"
                          : "#008065",
                      }}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        // eslint-disable-next-line eqeqeq
                        if (re.test(e.target.value) || e.target.value == "") {
                          handleChange(e);
                        }
                      }}
                      value={formData.postal_code}
                    />
                  </div>
                  {formData.errors?.postal_codeErrorMessage && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {formData.errors.postal_codeErrorMessage}
                    </span>
                  )}
                </div>
              </div>
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    Vendor Email
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="vendor_email"
                      placeholder="VendorEmail (Optional)"
                      className={
                        "border-2  focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: formData.errors?.vendor_emailErrorMessage
                          ? "#FF0000"
                          : "#008065",
                      }}
                      value={formData.vendor_email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {formData.errors?.vendor_emailErrorMessage && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {formData.errors.vendor_emailErrorMessage}
                    </span>
                  )}
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span> Vendor Mobile</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="vendor_mobile"
                      maxLength={10}
                      className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      placeholder="Vendor Mobile"
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor:
                          vendorPageErrors.vendor_mobile ||
                          formData.errors?.vendor_mobileErrorMessage
                            ? "red"
                            : "#008065",
                      }}
                      value={formData.vendor_mobile}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (re.test(e.target.value) || e.target.value == "") {
                          handleChange(e);
                        }
                      }}
                    />
                  </div>
                  {(vendorPageErrors?.vendor_mobile ||
                    formData.errors?.vendor_mobileErrorMessage) && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {vendorPageErrors?.vendor_mobile ||
                        formData.errors?.vendor_mobileErrorMessage}
                    </span>
                  )}
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col mb-6 ">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span> GSTIN</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="GSTIN"
                      name="gst"
                      className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor:
                          formData.errors?.gstErrorMessage ||
                          vendorPageErrors?.gst
                            ? "red"
                            : "#008065",
                      }}
                      value={formData.gst}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {(vendorPageErrors?.gst ||
                    formData.errors?.gstErrorMessage) && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {vendorPageErrors?.gst ||
                        formData.errors?.gstErrorMessage}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col sm:flex-row px-4">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  "></div>
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col  ">
                  <div className="relative"></div>
                </div>
              </div>

              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded">
                  <button
                    type="button"
                    onClick={resetProduct}
                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                    style={{
                      backgroundColor: "#008065",
                      fontSize: "14px",
                      fontFamily: "poppins",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 ">
                  <button
                    type="submit"
                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                    style={{
                      backgroundColor: "#008065",
                      fontSize: "14px",
                      fontFamily: "poppins",
                    }}
                    onClick={(e: any) => handleSubmit(e)}
                  >
                    {vendorid ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <VendorsTable vendorList={vendorList} edit={onEdit} deleteId={onDelete} />

      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default VendorsPage;
