import React from 'react'

const Revenue = () => {
    return (
        <div>
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 864.000000 738.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    transform="translate(0.000000,738.000000) scale(0.100000,-0.100000)"
                    fill="#FAAF6E"
                    stroke="none"
                >
                    <path
                        d="M6435 7165 c-292 -46 -544 -162 -759 -351 -274 -239 -442 -552 -497
                        -924 -28 -191 6 -506 76 -700 105 -291 327 -574 579 -737 331 -214 718 -288
                        1123 -214 172 32 434 148 583 258 155 115 327 313 428 493 49 88 119 276 142
                        385 31 140 37 363 16 517 -80 576 -462 1037 -1006 1216 -203 67 -478 90 -685
                        57z m358 -407 c13 -11 17 -34 19 -123 l3 -109 33 -7 c106 -22 257 -139 315
                        -243 36 -64 59 -146 55 -193 l-3 -38 -140 0 -140 0 -25 52 c-35 73 -66 101
                        -129 120 -76 22 -220 14 -278 -15 -101 -52 -133 -176 -69 -272 42 -64 79 -76
                        246 -84 165 -7 199 -15 295 -71 273 -160 327 -541 107 -760 -63 -64 -124 -101
                        -212 -131 l-65 -21 -3 -109 c-2 -71 -7 -111 -15 -116 -7 -4 -69 -8 -139 -8
                        -124 0 -128 1 -144 25 -12 19 -15 45 -13 116 l3 92 -67 22 c-119 40 -200 102
                        -266 201 -60 91 -94 220 -65 248 20 21 267 24 284 4 5 -7 23 -38 38 -68 40
                        -78 87 -103 207 -108 131 -6 207 21 258 90 25 33 34 122 18 171 -15 45 -78
                        102 -128 116 -21 6 -88 11 -149 11 -131 0 -202 19 -306 80 -82 49 -138 112
                        -185 210 -33 67 -37 84 -41 175 -7 155 29 256 127 358 65 69 117 103 202 131
                        l74 25 5 105 c3 57 7 106 10 109 34 29 249 40 283 15z"
                    />
                    <path
                        d="M3591 5084 c-128 -64 -149 -230 -42 -327 36 -33 94 -58 383 -167 49
                        -19 87 -34 85 -36 -1 -1 -600 -271 -1332 -599 -1467 -658 -1391 -619 -1418
                        -724 -18 -69 -7 -120 34 -173 43 -53 82 -72 154 -72 58 -1 98 16 1390 598 732
                        329 1333 600 1337 603 8 4 -6 -34 -97 -267 -69 -177 -76 -226 -45 -298 53
                        -123 209 -160 311 -75 16 14 36 37 43 52 23 43 355 915 362 948 13 65 -36 167
                        -95 197 -77 40 -953 366 -983 366 -19 0 -58 -12 -87 -26z"
                    />
                    <path
                        d="M6090 2382 c0 -1415 1 -1550 16 -1571 35 -50 49 -51 549 -51 497 0
                        506 1 550 50 20 22 20 38 23 1563 1 847 -1 1544 -6 1549 -5 5 -53 -2 -107 -16
                        -137 -35 -365 -66 -481 -66 -105 0 -324 34 -449 70 -39 11 -76 20 -82 20 -10
                        0 -13 -314 -13 -1548z"
                    />
                    <path
                        d="M4492 3136 l-37 -34 -3 -1124 c-2 -1054 -1 -1125 15 -1158 36 -71 31
                        -70 563 -70 532 0 527 -1 562 70 17 33 18 108 18 1143 0 1217 3 1153 -59 1190
                        -26 15 -76 17 -525 17 l-496 0 -38 -34z"
                    />
                    <path
                        d="M2901 2437 c-13 -6 -34 -25 -45 -40 -21 -28 -21 -35 -24 -785 -2
                        -705 -1 -759 15 -792 36 -71 32 -70 561 -70 522 0 524 0 562 62 20 32 20 48
                        20 783 l0 750 -23 40 c-12 22 -34 45 -47 52 -36 18 -984 18 -1019 0z"
                    />
                    <path
                        d="M1257 1699 c-46 -35 -48 -66 -45 -481 l3 -388 33 -32 32 -33 510 0
                        510 0 32 33 33 32 0 410 0 409 -34 35 -35 36 -507 0 c-496 0 -507 0 -532 -21z"
                    />
                </g>
            </svg>
        </div>
    )
}

export default Revenue
