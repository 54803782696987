import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  newchangePassword
} from "../../redux/actions/userAction";

const ChangePassword = ({ getMail }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigateTo = useNavigate();
  const dispatch = useDispatch();


  const [errorPassword, seterrorPassword] = useState(false);

  const onSubmit = async (data: any) => {
    const { newPassword, confirmPassword } = data;
    if (newPassword === confirmPassword) {
      const postdata = {
        email: getMail,
        newPassword: data.newPassword,
      };
      dispatch(newchangePassword(postdata) as any).then((res: any) => {
        if (res.type === 'USER_NEW_PASSWORD_SUCCESS') {
          navigateTo('/login')
        }
      })
    } else {
      seterrorPassword(true);
    }
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setconfirmPasswordVisible] = useState(false);
  const [newPasswordVisible, setnewPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const togglechangePasswordVisibility = () => {
    setconfirmPasswordVisible((prevState) => !prevState);
  };

  const toggleNewPasswordVisibility = () => {
    setnewPasswordVisible((prevState) => !prevState);
  };
  return (
    <section className=" flex items-center justify-center">
      <div className="  flex rounded-2xl  ">
        <div className="w-full lg:w-full  rounded-lg lg:rounded-l-none">
          <div className=" flex flex-col items-center justify-center relative">
            <div className="flex flex-col text-center  px-4 sm:px-6 md:px-10 lg:px-10  h-full  w-full max-w-md ">
              <span
                style={{ fontFamily: "poppins" }}
                className="text-[#00000] text-center text-xl font-bold place-self-center"
              >

                Change Password
              </span>
            </div>
            <div className="flex flex-col  px-4 sm:px-6 md:px-10 lg:px-10  w-full max-w-md pt-2 ">
              <div className="flex flex-col    px-4 sm:px-6 md:px-10 lg:px-10   w-full max-w-md">
                <div className="pt-1  ">
                  <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {/* <div className="flex flex-col mb-6">
                      <label className="mb-1 text-xs sm:text-sm tracking-wide text-[#008065]">
                        Email:
                      </label>
                      <div className="relative">
                        <input
                          // name="password"
                          {...register("email", {
                            required: "Email is required .",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: errors?.password ? "" : "#008065",
                          }}
                          className={
                            `${errors?.email ? "border-red-600" : ""} ` +
                            "text-sm sm:text-base  pr-4 rounded-lg  py-2 border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                          }
                          placeholder="Password"
                        />
                      </div>
                      {errors?.oldPassword && (
                        <p className="text-red-400 text-xs">
                          {errors?.email?.message as any}
                        </p>
                      )}
                    </div> */}
                    {/* <div className="flex flex-col mb-6">
                      <label className="mb-1 text-xs sm:text-sm tracking-wide text-[#008065]">
                        Old Password:
                      </label>
                      <div className="relative">
                        <input
                          // name="password"
                          {...register("oldPassword", {
                            required: "Enter old password",
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: errors?.password ? "" : "#008065",
                          }}
                          type={newPasswordVisible ? "text" : "password"}
                          className={
                            `${errors?.oldPassword ? "border-red-600" : ""} ` +
                            "text-sm sm:text-base  pr-4 rounded-lg  py-2 border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                          }
                          placeholder="Password"
                        />
                        <span
                          onClick={toggleNewPasswordVisibility}
                          className="absolute inset-y-0 end-0 grid cursor-pointer place-content-center px-4"
                        >
                          {newPasswordVisible ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 text-gray-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              className="h-4 w-4 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      {errors?.oldPassword && (
                        <p className="text-red-400 text-xs">
                          {errors?.oldPassword?.message as any}
                        </p>
                      )}
                    </div> */}
                    <div className="flex flex-col mb-6">
                      <label className="mb-1 text-xs sm:text-sm tracking-wide  font-semibold text-[#008065]">
                        New Password:
                      </label>
                      <div className="relative">
                        <input
                          // name="password"
                          {...register("newPassword", {
                            required: "Enter password",
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: errors?.password ? "" : "#008065",
                          }}
                          type={isPasswordVisible ? "text" : "password"}
                          className={
                            `${errors?.newPassword ? "border-red-600" : ""} ` +
                            "text-sm sm:text-base  pr-4 rounded-lg  py-2 border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                          }
                          placeholder="Password"
                        />
                        <span
                          onClick={togglePasswordVisibility}
                          className="absolute inset-y-0 end-0 grid cursor-pointer place-content-center px-4"
                        >
                          {isPasswordVisible ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 text-gray-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              className="h-4 w-4 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      {errors?.newPassword && (
                        <p className="text-red-400 text-xs">
                          {errors?.newPassword?.message as any}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col mb-6">
                      <label className="mb-1 text-xs sm:text-sm tracking-wide  font-semibold text-[#008065]">
                        Confirm Password:
                      </label>
                      <div className="relative">
                        <input
                          type={confirmPasswordVisible ? "text" : "password"}
                          // name="password"
                          {...register("confirmPassword", {
                            required: "confirm password",
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: errors?.confirmPassword
                              ? ""
                              : "#008065",
                          }}
                          className={
                            `${errors?.confirmPassword ? "border-red-600" : ""
                            } ` +
                            "text-sm sm:text-base pr-4 rounded-lg  py-2 border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                          }
                          placeholder="Confirm Password"
                        />
                        <span
                          onClick={togglechangePasswordVisibility}
                          className=" absolute inset-y-0 end-0 grid cursor-pointer place-content-center px-4"
                        >
                          {confirmPasswordVisible ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 text-gray-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              className="h-4 w-4 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      {errors?.confirmPassword && (
                        <p className="text-red-400 text-xs">
                          {errors?.confirmPassword?.message as any}
                        </p>
                      )}
                      {errorPassword && (
                        <p className="text-red-400 text-xs">
                          {"password mismatch"}
                        </p>
                      )}
                    </div>

                    <div className="flex w-full">
                      <button
                        type="submit"
                        className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#008065] rounded py-2 w-full transition duration-150 ease-in"
                      >
                        <span className="mr-2 ">Next</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
