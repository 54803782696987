import api from "../axiosConfig";
import { SERIVICEUNITS_DELETE_FAIL, SERIVICEUNITS_DELETE_REQUEST, SERIVICEUNITS_DELETE_SUCCESS, SERIVICEUNITS_GET_FAIL, SERIVICEUNITS_GET_REQUEST, SERIVICEUNITS_GET_SUCCESS, SERIVICEUNITS_POST_FAIL, SERIVICEUNITS_POST_SUCCESS, SERIVICEUNITS_UPDATE_FAIL, SERIVICEUNITS_UPDATE_REQUEST, SERIVICEUNITS_UPDATE_SUCCESS } from "../constants/serviceUnitsConstants";

export const serviceUnitsSave = (company: any) => async (dispatch: any, getState: any) => {

    // company.user_id = environment.user_id;
    dispatch({ type: SERIVICEUNITS_POST_SUCCESS });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      company.user_id = token._id;
      const { data } = await api.post(
        `/api/serviceUnits/save`,
        company,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        }
      );
      return dispatch({ type: SERIVICEUNITS_POST_SUCCESS, payload: data });
    } catch (error: any) {
      return dispatch({
        type: SERIVICEUNITS_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
};


export const serviceUnitsAll = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERIVICEUNITS_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(
      `/api/serviceUnits/all/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: SERIVICEUNITS_GET_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: SERIVICEUNITS_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const serviceUnitsUpdate = (id: any, prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERIVICEUNITS_UPDATE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.put(`/api/serviceUnits/UnitUpdate/${id}`, prod, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: SERIVICEUNITS_UPDATE_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: SERIVICEUNITS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const serviceUnitsDelete = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERIVICEUNITS_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.delete(`/api/serviceUnits/UnitDelete/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: SERIVICEUNITS_DELETE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: SERIVICEUNITS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};