import React from "react";
import { BrowserHistory } from "history";
import store from "../../redux/store";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import DataProvider from "../shared/DataProvider";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "../../pages/errors/ErrrorPage";
import routes from "../../routes";
import { ToastContainer } from "react-toastify";
import { FontProvider } from "../../context/FontContext";
import '../../fontawesome';

interface AppProps {
  history: any | BrowserHistory;
}

function App({ history }: AppProps) {
  return (
    <Provider store={store}>
      <FontProvider>
        <DataProvider>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <RouterProvider router={routes} />
          </ErrorBoundary>
        </DataProvider>
      </FontProvider>
      <ToastContainer />
    </Provider>
  );
}

export default App;
