/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { registerAll, updateRegister } from '../../redux/actions/userAction';
import ChangePassword from './ChangePassword';

const MailVerification = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checkMail, setCheckMail] = useState(false)
    const [getMail, setaMail] = useState(null as any)
    useEffect(() => {
        dispatch(registerAll(params.id) as any).then((res: any) => {
            if (res && res.type === "REGISTER_GET_SUCCESS") {
                if (res?.payload?.active === false) {
                    setaMail(res.payload.email)
                    setCheckMail(false)
                    let obj = {
                        active: true
                    }
                    dispatch(updateRegister(params.id, obj) as any)
                } else {
                    setCheckMail(true)
                }
            }
        })
    }, [dispatch])

    const login = (data: any) => {
        if (data === true) {
            navigate("/login");
        }
        // else {
        //     navigate("/changePassword", { state: getMail });
        // }
    }


    return (
        <div className="flex items-center justify-center p-5 min-w-screen">
            <div className="max-w-xl p-8 text-center text-gray-800 bg-white shadow-xl lg:max-w-3xl rounded-3xl lg:p-12">
                <h3 className="text-2xl">{checkMail === false ? "Thanks for verifying your Email Address!" : "Your Email has been Already Verified!!"}</h3>
                <div className="flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-24 h-24 text-green-400" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                            d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                    </svg>
                </div>
                {checkMail !== true && (<p>Your Email has been Successfully Verified!!</p>)}
                {checkMail == true && <button type="button" className="mt-3 text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" onClick={(e) => login(checkMail)}> Go To Login</button>}
                {checkMail !== true && <ChangePassword getMail={getMail} />}

            </div>

        </div>
    )
}

export default MailVerification
