export const FIXED_ASSETS_POST_REQUEST = 'FIXED_ASSETS_POST_REQUEST';
export const FIXED_ASSETS_POST_SUCCESS = 'FIXED_ASSETS_POST_SUCCESS';
export const FIXED_ASSETS_POST_FAIL = 'FIXED_ASSETS_POST_FAIL';
export const FIXED_ASSETS_POST_RESET = 'FIXED_ASSETS_POST_RESET';

export const FIXED_ASSETS_GET_REQUEST = 'FIXED_ASSETS_GET_REQUEST';
export const FIXED_ASSETS_GET_SUCCESS = 'FIXED_ASSETS_GET_SUCCESS';
export const FIXED_ASSETS_GET_FAIL = 'FIXED_ASSETS_GET_FAIL';
export const FIXED_ASSETS_GET_RESET = 'FIXED_ASSETS_GET_RESET';

export const FIXED_ASSETS_GET_ONE_REQUEST = 'FIXED_ASSETS_GET_ONE_REQUEST';
export const FIXED_ASSETS_GET_ONE_SUCCESS = 'FIXED_ASSETS_GET_ONE_SUCCESS';
export const FIXED_ASSETS_GET_ONE_FAIL = 'FIXED_ASSETS_GET_ONE_FAIL';
export const FIXED_ASSETS_GET_ONE_RESET = 'FIXED_ASSETS_GET_ONE_RESET';

export const FIXED_ASSETS_UPDATE_REQUEST = 'FIXED_ASSETS_UPDATE_REQUEST';
export const FIXED_ASSETS_UPDATE_SUCCESS = 'FIXED_ASSETS_UPDATE_SUCCESS';
export const FIXED_ASSETS_UPDATE_FAIL = 'FIXED_ASSETS_UPDATE_FAIL';
export const FIXED_ASSETS_UPDATE_RESET = 'FIXED_ASSETS_UPDATE_RESET';

export const FIXED_ASSETS_DELETE_REQUEST = 'FIXED_ASSETS_DELETE_REQUEST';
export const FIXED_ASSETS_DELETE_SUCCESS = 'FIXED_ASSETS_DELETE_SUCCESS';
export const FIXED_ASSETS_DELETE_FAIL = 'FIXED_ASSETS_DELETE_FAIL';
export const FIXED_ASSETS_DELETE_RESET = 'FIXED_ASSETS_DELETE_RESET';