import api from "../axiosConfig";
import {
  APPSETTING_DELETE_FAIL,
  APPSETTING_DELETE_REQUEST,
  APPSETTING_DELETE_SUCCESS,
  APPSETTING_GET_FAIL,
  APPSETTING_GET_REQUEST,
  APPSETTING_GET_SUCCESS,
  APPSETTING_POST_FAIL,
  APPSETTING_POST_REQUEST,
  APPSETTING_POST_SUCCESS,
  APPSETTING_UPDATE_FAIL,
  APPSETTING_UPDATE_REQUEST,
  APPSETTING_UPDATE_SUCCESS,
  SIGNATURE_DELETE_FAIL,
  SIGNATURE_DELETE_REQUEST,
  SIGNATURE_DELETE_SUCCESS,
  SIGNATURE_GET_FAIL,
  SIGNATURE_GET_REQUEST,
  SIGNATURE_GET_SUCCESS,
  SIGNATURE_SAVE_FAIL,
  SIGNATURE_SAVE_REQUEST,
  SIGNATURE_SAVE_SUCCESS,
  SIGNATURE_UPDATE_FAIL,
  SIGNATURE_UPDATE_REQUEST,
  SIGNATURE_UPDATE_SUCCESS,
} from "../constants/appSettingConstants";


export const AppSettingSave = (appSetting: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: APPSETTING_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.post(
      `/api/appsetting/save`,
      appSetting,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: APPSETTING_POST_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: APPSETTING_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AppSettingGet = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: APPSETTING_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(
      `/api/appsetting/all/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: APPSETTING_GET_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: APPSETTING_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AppSettingUpdate =
  (id: any, appSetting: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: APPSETTING_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/appsetting/put/${id}`,
        appSetting,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        }
      );
      return dispatch({ type: APPSETTING_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: APPSETTING_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const AppSettingDelete = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: APPSETTING_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.delete(
      `/api/appsetting/delete/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: APPSETTING_DELETE_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: APPSETTING_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const AppSignSave = (appSetting: any) => async (dispatch: any, getState: any) => {

  dispatch({ type: SIGNATURE_SAVE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.post(
      `/api/appsign/sign`,
      appSetting,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: SIGNATURE_SAVE_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: SIGNATURE_SAVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AppSignGet = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SIGNATURE_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(
      `/api/appsign/getsign/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: SIGNATURE_GET_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: SIGNATURE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const SignatureDelete = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SIGNATURE_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.delete(
      `/api/appsign/delete/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: SIGNATURE_DELETE_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: SIGNATURE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AppSignUpdate =
  (id: any, appSetting: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: SIGNATURE_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/appsign/signupdate/${id}`,
        appSetting,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        }
      );
      return dispatch({ type: SIGNATURE_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: SIGNATURE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
