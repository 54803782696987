import api from "../axiosConfig";
import {
   EXPENSE_DETAIL_DELETE_FAIL,
   EXPENSE_DETAIL_DELETE_REQUEST,
   EXPENSE_DETAIL_DELETE_SUCCESS,
   EXPENSE_DETAIL_GET_FAIL,
   EXPENSE_DETAIL_GET_ONE_FAIL,
   EXPENSE_DETAIL_GET_ONE_REQUEST,
   EXPENSE_DETAIL_GET_ONE_SUCCESS,
   EXPENSE_DETAIL_GET_REQUEST,
   EXPENSE_DETAIL_GET_SUCCESS,
   EXPENSE_DETAIL_POST_FAIL,
   EXPENSE_DETAIL_POST_REQUEST,
   EXPENSE_DETAIL_POST_SUCCESS,
   EXPENSE_DETAIL_UPDATE_FAIL,
   EXPENSE_DETAIL_UPDATE_REQUEST,
   EXPENSE_DETAIL_UPDATE_SUCCESS,
} from "../constants/expensePageConstants";

export const expenseDetailsSave =
   (company: any) => async (dispatch: any, getState: any) => {
      // company.user_id = environment.user_id;
      dispatch({ type: EXPENSE_DETAIL_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         company.user_id = token._id;
         const { data } = await api.post(`/api/expenseDetail/save`, company, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: EXPENSE_DETAIL_POST_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: EXPENSE_DETAIL_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const expenseDetailAll = () => async (dispatch: any, getState: any) => {
   dispatch({ type: EXPENSE_DETAIL_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(
         `/api/expenseDetail/getall/${token?._id}`,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({ type: EXPENSE_DETAIL_GET_SUCCESS, payload: data });
   } catch (error: any) {
      return dispatch({
         type: EXPENSE_DETAIL_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const expenseDetailFindOne =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: EXPENSE_DETAIL_GET_ONE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.get(`/api/expenseDetail/findOne/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: EXPENSE_DETAIL_GET_ONE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         return dispatch({
            type: EXPENSE_DETAIL_GET_ONE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const expenseDetailDelete =
   (id: string) => async (dispatch: any, getState: any) => {
      dispatch({ type: EXPENSE_DETAIL_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/expenseDetails/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });

         return dispatch({ type: EXPENSE_DETAIL_DELETE_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: EXPENSE_DETAIL_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const expenseDetailUpdate =
   (id: any, updatedet: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: EXPENSE_DETAIL_UPDATE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         updatedet.user_id = token._id;
         const { data } = await api.put(
            `/api/expenseDetail/update/${id}`,
            updatedet,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: EXPENSE_DETAIL_UPDATE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: EXPENSE_DETAIL_UPDATE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };
