import api from "../axiosConfig";
import {
   FIXED_ASSETS_DELETE_FAIL,
   FIXED_ASSETS_DELETE_REQUEST,
   FIXED_ASSETS_DELETE_SUCCESS,
   FIXED_ASSETS_GET_FAIL,
   FIXED_ASSETS_GET_REQUEST,
   FIXED_ASSETS_GET_SUCCESS,
   FIXED_ASSETS_POST_FAIL,
   FIXED_ASSETS_POST_REQUEST,
   FIXED_ASSETS_POST_SUCCESS,
   FIXED_ASSETS_UPDATE_FAIL,
   FIXED_ASSETS_UPDATE_REQUEST,
   FIXED_ASSETS_UPDATE_SUCCESS,
} from "../constants/fixedAssetsConstants";

export const assetSave =
   (fixedAsset: any) => async (dispatch: any, getState: any) => {
      // company.user_id = environment.user_id;
      dispatch({ type: FIXED_ASSETS_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         fixedAsset.user_id = token._id;
         const { data } = await api.post(`/api/fixedAsset/save`, fixedAsset, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({ type: FIXED_ASSETS_POST_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: FIXED_ASSETS_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const fixedAssetAll = () => async (dispatch: any, getState: any) => {
   dispatch({ type: FIXED_ASSETS_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(`/api/fixedAsset/getall/${token?._id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: FIXED_ASSETS_GET_SUCCESS, payload: data });
   } catch (error: any) {
      return dispatch({
         type: FIXED_ASSETS_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const getPurchaseFAandFixedAsset =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: FIXED_ASSETS_GET_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.get(
            `/api/fixedAsset/purchaseFAandFixedAsset/${id}`,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({ type: FIXED_ASSETS_GET_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: FIXED_ASSETS_GET_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

// export const findUpiDetail = () => async (dispatch: any, getState: any) => {
//    dispatch({ type: UPIPAYMENT_GET_ONE_REQUEST });
//    try {
//       const {
//          userSignin: {
//             userInfo: { token },
//          },
//       } = getState();
//       const { data } = await api.get(`/api/upiPayment/findOne/${token?._id}`, {
//          headers: { Authorization: `Bearer ${token?.tocken}` },
//       });
//       return dispatch({ type: UPIPAYMENT_GET_ONE_SUCCESS, payload: data });
//    } catch (error: any) {
//       return dispatch({
//          type: UPIPAYMENT_GET_ONE_FAIL,
//          payload:
//             error.response && error.response.data.message
//                ? error.response.data.message
//                : error.message,
//       });
//    }
// };

export const fixedAssetDelete =
   (id: string) => async (dispatch: any, getState: any) => {
      dispatch({ type: FIXED_ASSETS_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/fixedAsset/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });

         return dispatch({ type: FIXED_ASSETS_DELETE_SUCCESS, payload: data });
      } catch (error: any) {
         return dispatch({
            type: FIXED_ASSETS_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const fixedAssetUpdate =
   (id: any, updatedet: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: FIXED_ASSETS_UPDATE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         updatedet.user_id = token._id;
         const { data } = await api.put(
            `/api/fixedAsset/update/${id}`,
            updatedet,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({ type: FIXED_ASSETS_UPDATE_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: FIXED_ASSETS_UPDATE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };
