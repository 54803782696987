export const CLIENT_POST_REQUEST = "CLIENT_POST_REQUEST";
export const CLIENT_POST_SUCCESS = "CLIENT_POST_SUCCESS";
export const CLIENT_POST_FAIL = "CLIENT_POST_FAIL";
export const CLIENT_POST_REST = "CLIENT_POST_REST";

export const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_REQUEST";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_UPDATE_FAIL = "CLIENT_UPDATE_FAIL";
export const CLIENT_UPDATE_REST = "CLIENT_UPDATE_REST";

export const CLIENT_GET_REQUEST = "CLIENT_GET_REQUEST";
export const CLIENT_GET_SUCCESS = "CLIENT_GET_SUCCESS";
export const CLIENT_GET_FAIL = "CLIENT_GET_FAIL";
export const CLIENT_GET_REST = "CLIENT_GET_REST";

export const CLIENT_GET_FIND_ONE_REQUEST = "CLIENT_GET_FIND_ONE_REQUEST";
export const CLIENT_GET_FIND_ONE_SUCCESS = "CLIENT_GET_FIND_ONE_SUCCESS";
export const CLIENT_GET_FIND_ONE_FAIL = "CLIENT_GET_FIND_ONE_FAIL";
export const CLIENT_GET_FIND_ONE_REST = "CLIENT_GET_FIND_ONE_REST";

export const CLIENT_DELETE_REQUEST = "CLIENT_DELETE_REQUEST";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_FAIL = "CLIENT_DELETE_FAIL";
export const CLIENT_DELETE_REST = "CLIENT_DELETE_REST";
