import React from 'react'

const ExpensesSvg = () => {
    return (
        <div>
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 505.000000 426.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    transform="translate(0.000000,426.000000) scale(0.100000,-0.100000)"
                    fill="#a78bfa"
                    stroke="none"
                >
                    <path
                        d="M3379 3641 l-23 -18 18 -67 c10 -37 17 -68 15 -69 -2 -1 -29 -11 -59
                        -21 -68 -23 -151 -75 -208 -130 -89 -84 -175 -228 -203 -337 -12 -46 -15 -49
                        -46 -49 -33 0 -33 1 -33 46 0 25 -7 77 -16 117 -62 275 -301 467 -581 467
                        -310 0 -568 -239 -590 -547 l-6 -82 -241 -3 c-226 -3 -244 -4 -282 -25 -51
                        -27 -87 -69 -108 -125 -15 -39 -16 -127 -14 -893 l3 -850 24 -49 c33 -66 91
                        -122 160 -155 l56 -26 1060 0 1060 0 57 28 c69 34 140 109 164 175 13 36 18
                        89 22 226 l4 178 43 42 c54 52 65 90 65 231 0 140 -11 179 -66 234 l-44 44 0
                        148 c0 165 -11 234 -45 295 -45 78 -152 153 -217 154 l-28 0 0 149 c0 145 -1
                        150 -25 179 -14 17 -41 34 -63 39 l-37 9 68 33 c70 33 192 64 236 59 22 -3 27
                        -11 44 -78 22 -88 31 -102 66 -98 20 2 56 45 173 203 211 285 221 300 214 323
                        -7 21 -54 43 -361 167 -252 103 -231 97 -256 76z m354 -196 c82 -34 148 -66
                        148 -71 0 -13 -231 -324 -240 -324 -5 0 -11 18 -15 39 -10 67 -26 75 -135 67
                        -171 -12 -309 -73 -423 -186 -63 -63 -67 -65 -61 -36 3 17 17 62 31 101 67
                        190 225 341 394 376 74 16 81 24 75 81 l-6 47 42 -16 c23 -9 109 -45 190 -78z
                        m-1322 37 c167 -55 293 -192 334 -363 39 -167 -10 -342 -132 -466 l-51 -53
                        -305 0 -305 0 -59 63 c-94 101 -135 204 -135 342 0 134 39 237 125 332 79 86
                        171 139 282 163 58 13 181 4 246 -18z m-141 -972 c795 -4 1102 -8 1121 -17 42
                        -18 89 -63 109 -104 18 -33 20 -59 20 -208 l0 -170 -282 -3 c-311 -3 -318 -5
                        -372 -68 -40 -48 -48 -92 -44 -235 4 -155 17 -192 87 -236 l45 -29 284 0 283
                        0 -3 -184 -3 -184 -31 -44 c-19 -25 -52 -53 -80 -68 l-48 -25 -1036 0 c-806 0
                        -1043 3 -1070 13 -45 15 -107 75 -126 120 -12 29 -14 150 -14 757 l0 723 33
                        -16 c29 -16 129 -17 1127 -22z"
                    />
                    <path
                        d="M2226 3374 c-9 -8 -16 -32 -16 -52 0 -32 -5 -40 -39 -59 -93 -53
                        -107 -202 -25 -260 16 -11 58 -31 92 -43 71 -26 97 -54 86 -94 -7 -31 -31 -46
                        -72 -46 -34 0 -57 23 -67 68 -5 23 -14 34 -32 38 -40 10 -58 -7 -57 -54 1 -49
                        36 -104 82 -129 27 -15 32 -23 32 -55 0 -35 20 -68 41 -68 32 0 49 22 49 64 0
                        39 3 45 31 56 50 21 82 73 86 138 5 77 -17 111 -94 146 -130 59 -128 57 -128
                        100 0 31 6 43 27 59 26 20 30 20 62 4 25 -12 36 -24 41 -49 9 -43 22 -58 50
                        -58 79 0 43 154 -44 190 -28 11 -31 17 -31 56 0 33 -5 46 -19 54 -27 14 -37
                        13 -55 -6z"
                    />
                </g>
            </svg>
        </div>
    )
}

export default ExpensesSvg
