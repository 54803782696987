import React from "react";

interface HeadingWithBorderProps {
  title: string;
}

export const HeadingWithBorder: React.FC<HeadingWithBorderProps> = ({
  title,
}) => {
  return (
    <div className="flex items-center mx-8">
      <div className="md:w-6 sm:w-6 border-t border-gray-400"></div>
      <span
        className="md:mx-4 sm:mx-4 text-base sm:text-lg font-bold text-[#008065]"
        style={{
          fontFamily: "poppins",
        }}
      >
        {title}
      </span>
      <div className="flex-grow border-t border-gray-400"></div>
    </div>
  );
};
export default HeadingWithBorder;
