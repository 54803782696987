import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import App from "./layouts/App/App";
import * as serviceWorker from "./serviceWorker";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import store from "./redux/store";
import "@szhsin/react-menu/dist/index.css";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App history={store} />
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
