import { EXTERNAL_INVOICE_FIND_ALL_FAIL, EXTERNAL_INVOICE_FIND_ALL_REQUEST, EXTERNAL_INVOICE_FIND_ALL_SUCCESS, EXTERNAL_INVOICE_POST_REST, EXTERNAL_PAGE_POST_FAIL, EXTERNAL_PAGE_POST_REQUEST, EXTERNAL_PAGE_POST_RESET, EXTERNAL_PAGE_POST_SUCCESS } from "../constants/ExternalConstants";


export const externalGetReducer = (state = {}, action: any) => {
    switch (action.type) {
        case EXTERNAL_PAGE_POST_REQUEST:
            return { loading: true };
        case EXTERNAL_PAGE_POST_SUCCESS:
            return { loading: false, externaldata: action.payload };
        case EXTERNAL_PAGE_POST_FAIL:
            return { loading: false, error: action.payload };
        case EXTERNAL_PAGE_POST_RESET:
            return {};
        default:
            return state;
    }
};


export const externalGetAllReducer = (state = {}, action: any) => {
    switch (action.type) {
        case EXTERNAL_INVOICE_FIND_ALL_REQUEST:
            return { loading: true };
        case EXTERNAL_INVOICE_FIND_ALL_SUCCESS:
            return { loading: false, externalgetdata: action.payload };
        case EXTERNAL_INVOICE_FIND_ALL_FAIL:
            return { loading: false, error: action.payload };
        case EXTERNAL_INVOICE_POST_REST:
            return {};
        default:
            return state;
    }
};