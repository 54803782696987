export const VENDOR_POST_REQUEST = "VENDOR_POST_REQUEST";
export const VENDOR_POST_SUCCESS = "VENDOR_POST_SUCCESS";
export const VENDOR_POST_FAIL = "VENDOR_POST_FAIL";
export const VENDOR_POST_REST = "VENDOR_POST_REST";

export const VENDOR_UPDATE_REQUEST = "VENDOR_UPDATE_REQUEST";
export const VENDOR_UPDATE_SUCCESS = "VENDOR_UPDATE_SUCCESS";
export const VENDOR_UPDATE_FAIL = "VENDOR_UPDATE_FAIL";
export const VENDOR_UPDATE_REST = "VENDOR_UPDATE_REST";

export const VENDOR_GET_REQUEST = "VENDOR_GET_REQUEST";
export const VENDOR_GET_SUCCESS = "VENDOR_GET_SUCCESS";
export const VENDOR_GET_FAIL = "VENDOR_GET_FAIL";
export const VENDOR_GET_REST = "VENDOR_GET_REST";

export const VENDOR_GET_FIND_ONE_REQUEST = "VENDOR_GET_FIND_ONE_REQUEST";
export const VENDOR_GET_FIND_ONE_SUCCESS = "VENDOR_GET_FIND_ONE_SUCCESS";
export const VENDOR_GET_FIND_ONE_FAIL = "VENDOR_GET_FIND_ONE_FAIL";
export const VENDOR_GET_FIND_ONE_REST = "VENDOR_GET_FIND_ONE_REST";

export const VENDOR_DELETE_REQUEST = "VENDOR_DELETE_REQUEST";
export const VENDOR_DELETE_SUCCESS = "VENDOR_DELETE_SUCCESS";
export const VENDOR_DELETE_FAIL = "VENDOR_DELETE_FAIL";
export const VENDOR_DELETE_REST = "VENDOR_DELETE_REST";

export const VENDORPRODUCT_POST_REQUEST = "VENDORPRODUCT_POST_REQUEST";
export const VENDORPRODUCT_POST_SUCCESS = "VENDORPRODUCT_POST_SUCCESS";
export const VENDORPRODUCT_POST_FAIL = "VENDORPRODUCT_POST_FAIL";
export const VENDORPRODUCT_POST_REST = "VENDORPRODUCT_POST_REST";

export const VENDORPRODUCT_UPDATE_REQUEST = "VENDORPRODUCT_UPDATE_REQUEST";
export const VENDORPRODUCT_UPDATE_SUCCESS = "VENDORPRODUCT_UPDATE_SUCCESS";
export const VENDORPRODUCT_UPDATE_FAIL = "VENDORPRODUCT_UPDATE_FAIL";
export const VENDORPRODUCT_UPDATE_REST = "VENDORPRODUCT_UPDATE_REST";

export const VENDORPRODUCT_GET_REQUEST = "VENDORPRODUCT_GET_REQUEST";
export const VENDORPRODUCT_GET_SUCCESS = "VENDORPRODUCT_GET_SUCCESS";
export const VENDORPRODUCT_GET_FAIL = "VENDORPRODUCT_GET_FAIL";
export const VENDORPRODUCT_GET_REST = "VENDORPRODUCT_GET_REST";

export const VENDORPRODUCT_DELETE_REQUEST = "VENDORPRODUCT_DELETE_REQUEST";
export const VENDORPRODUCT_DELETE_SUCCESS = "VENDORPRODUCT_DELETE_SUCCESS";
export const VENDORPRODUCT_DELETE_FAIL = "VENDORPRODUCT_DELETE_FAIL";
export const VENDORPRODUCT_DELETE_REST = "VENDORPRODUCT_DELETE_REST";

export const VENDORDETAILS_GET_REQUEST = "VENDORDETAILS_GET_REQUEST";
export const VENDORDETAILS_GET_SUCCESS = "VENDORDETAILS_GET_SUCCESS";
export const VENDORDETAILS_GET_FAIL = "VENDORDETAILS_GET_FAIL";
export const VENDORDETAILS_GET_REST = "VENDORDETAILS_GET_REST";

export const VENDORPRODUCTLIST_GET_REQUEST = "VENDORPRODUCTLIST_GET_REQUEST";
export const VENDORPRODUCTLIST_GET_SUCCESS = "VENDORPRODUCTLIST_GET_SUCCESS";
export const VENDORPRODUCTLIST_GET_FAIL = "VENDORPRODUCTLIST_GET_FAIL";
export const VENDORPRODUCTLIST_GET_REST = "VENDORPRODUCTLIST_GET_REST";
