import api from "../axiosConfig";
import {
  SERVICESETTING_DELETE_FAIL,
  SERVICESETTING_DELETE_REQUEST,
  SERVICESETTING_DELETE_SUCCESS,
  SERVICESETTING_GET_FAIL,
  SERVICESETTING_GET_REQUEST,
  SERVICESETTING_GET_SUCCESS,
  SERVICESETTING_POST_FAIL,
  SERVICESETTING_POST_REQUEST,
  SERVICESETTING_POST_SUCCESS,
  SERVICESETTING_UPDATE_FAIL,
  SERVICESETTING_UPDATE_REQUEST,
  SERVICESETTING_UPDATE_SUCCESS,
} from "../constants/serviceSettingConstants";


export const ServiceSettingSave = (appSetting: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERVICESETTING_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.post(
      `/api/servicesetting/save`,
      appSetting,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    ); 
    return dispatch({ type: SERVICESETTING_POST_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: SERVICESETTING_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ServiceSettingGet = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERVICESETTING_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(
      `/api/servicesetting/all/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: SERVICESETTING_GET_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: SERVICESETTING_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ServiceSettingUpdate =
  (id: any, appSetting: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: SERVICESETTING_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/servicesetting/put/${id}`,
        appSetting,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        }
      );
      return dispatch({ type: SERVICESETTING_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: SERVICESETTING_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const ServiceSettingDelete = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: SERVICESETTING_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.delete(
      `/api/servicesetting/delete/${id}`,
      {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      }
    );
    return dispatch({ type: SERVICESETTING_DELETE_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: SERVICESETTING_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
