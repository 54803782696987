export const CASHINHAND_GET_REQUEST = 'CASHINHAND_GET_REQUEST';
export const CASHINHAND_GET_SUCCESS = 'CASHINHAND_GET_SUCCESS';
export const CASHINHAND_GET_FAIL = 'CASHINHAND_GET_FAIL';
export const CASHINHAND_GET_RESET = 'CASHINHAND_GET_RESET';

export const CASHINHAND_DELETE_REQUEST = 'CASHINHAND_DELETE_REQUEST';
export const CASHINHAND_DELETE_SUCCESS = 'CASHINHAND_DELETE_SUCCESS';
export const CASHINHAND_DELETE_FAIL = 'CASHINHAND_DELETE_FAIL';

export const CASHINHAND_POST_REQUEST = "CASHINHAND_POST_REQUEST";
export const CASHINHAND_POST_SUCCESS = "CASHINHAND_POST_SUCCESS";
export const CASHINHAND_POST_FAIL = "CASHINHAND_POST_FAIL";
export const CASHINHAND_POST_RESET = "CASHINHAND_POST_RESET";

export const CASHINHAND_EDIT_FINDONE_REQUEST = "CASHINHAND_EDIT_FINDONE_REQUEST";
export const CASHINHAND_EDIT_FINDONE_SUCCESS = "CASHINHAND_EDIT_FINDONE_SUCCESS";
export const CASHINHAND_EDIT_FINDONE_FAIL = "CASHINHAND_EDIT_FINDONE_FAIL";
export const CASHINHAND_EDIT_FINDONE_RESET = "CASHINHAND_EDIT_FINDONE_RESET";

export const CASHINHAND_UPDATE_REQUEST = "CASHINHAND_UPDATE_REQUEST";
export const CASHINHAND_UPDATE_SUCCESS = "CASHINHAND_UPDATE_SUCCESS";
export const CASHINHAND_UPDATE_FAIL = "CASHINHAND_UPDATE_FAIL";
export const CASHINHAND_UPDATE_RESET = "CASHINHAND_UPDATE_RESET";

