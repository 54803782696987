import React, { useState } from "react";

// Define your font families
const fontFamilies = [
  "emoji",
  "cursive",
  "Arial, sans-serif",
  "Courier New, monospace",
  "Georgia, serif",
  "Verdana, sans-serif",
  "Times New Roman, serif",
  "Trebuchet MS, sans-serif",
  "Times, serif",
];

// FontPage component
const FontPage = ({ setSelectedFontFamily }: any) => {
  const [selectedFont, setSelectedFont] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState<any>({}); // Initialize formData state

  // Handle font change
  const handleFontChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFont = event.target.value;
    setSelectedFont(selectedFont);
    setSelectedFontFamily(selectedFont);

    // Update formData with the selected font
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      frontFamily: selectedFont,
    }));

    setError(false);
  };

  return (
    <>
      <label
        htmlFor="font-family-select"
        className="block mb-2 text-sm text-[black] font-semibold"
      >
        Font
      </label>
      <select
        id="font-family-select"
        value={selectedFont}
        onChange={handleFontChange}
        className={`text-balance border-2 focus:outline-none border-[#008065] rounded-[7px] px-2 h-9 block lg:w-[10rem] md:w-[8rem] xl:w-[14rem] 2xl:w-[14rem] w-full ${
          error ? "border-red-500" : "border-[#008065]"
        }`}
        style={{
          fontFamily: "poppins",
          fontSize: "13px",
          fontWeight: 400,
        }}
      >
        <option value="" disabled hidden>
          Font
        </option>
        {fontFamilies.map((font) => (
          <option key={font} value={font}>
            {font}
          </option>
        ))}
      </select>
    </>
  );
};

export default FontPage;
