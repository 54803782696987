import { TERM_GET_REQUEST, TERM_GET_SUCCESS, TERM_GET_FAIL, TERM_GET_REST, TERM_GET_ONE_REQUEST, TERM_GET_ONE_SUCCESS, TERM_GET_ONE_FAIL, TERM_GET_ONE_REST } from "../constants/termConstants ";

export const GetTermListReducer = (state = {}, action: any) => {
    switch (action.type) {
        case TERM_GET_REQUEST:
            return { loading: true };
        case TERM_GET_SUCCESS:
            return { loading: false, termList: action.payload };
        case TERM_GET_FAIL:
            return { loading: false, error: action.payload };
        case TERM_GET_REST:
            return {};
        default:
            return state;
    }
};

export const GetOneTermDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case TERM_GET_ONE_REQUEST:
            return { loading: true };
        case TERM_GET_ONE_SUCCESS:
            return { loading: false, termDetail: action.payload };
        case TERM_GET_ONE_FAIL:
            return { loading: false, error: action.payload };
        case TERM_GET_ONE_REST:
            return {};
        default:
            return state;
    }
};