import { BANK_GET_FAIL, BANK_GET_ONE_FAIL, BANK_GET_ONE_REQUEST, BANK_GET_ONE_REST, BANK_GET_ONE_SUCCESS, BANK_GET_REQUEST, BANK_GET_REST, BANK_GET_SUCCESS } from "../constants/bankConstants";

export const GetBankListReducer = (state = {}, action: any) => {
    switch (action.type) {
        case BANK_GET_REQUEST:
            return { loading: true };
        case BANK_GET_SUCCESS:
            return { loading: false, bankList: action.payload };
        case BANK_GET_FAIL:
            return { loading: false, error: action.payload };
        case BANK_GET_REST:
            return {};
        default:
            return state;
    }
};

export const GetOneBankDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case BANK_GET_ONE_REQUEST:
            return { loading: true };
        case BANK_GET_ONE_SUCCESS:
            return { loading: false, bankDetail: action.payload };
        case BANK_GET_ONE_FAIL:
            return { loading: false, error: action.payload };
        case BANK_GET_ONE_REST:
            return {};
        default:
            return state;
    }
};