export const DELIVERYCHALLAN_POST_REQUEST = 'DELIVERYCHALLAN_POST_REQUEST';
export const DELIVERYCHALLAN_POST_SUCCESS = 'DELIVERYCHALLAN_POST_SUCCESS';
export const DELIVERYCHALLAN_POST_FAIL = 'DELIVERYCHALLAN_POST_FAIL';
export const DELIVERYCHALLAN_POST_REST = 'DELIVERYCHALLAN_POST_REST';

export const DELIVERYCHALLAN_GET_COUNT_REQUEST = 'DELIVERYCHALLAN_GET_COUNT_REQUEST';
export const DELIVERYCHALLAN_GET_COUNT_SUCCESS = 'DELIVERYCHALLAN_GET_COUNT_SUCCESS';
export const DELIVERYCHALLAN_GET_COUNT_FAIL = 'DELIVERYCHALLAN_GET_COUNT_FAIL';
export const DELIVERYCHALLAN_GET_COUNT_REST = 'DELIVERYCHALLAN_GET_COUNT_REST';

export const DELIVERY_FIND_ONE_REQUEST = 'DELIVERY_FIND_ONE_REQUEST';
export const DELIVERY_FIND_ONE_SUCCESS = 'DELIVERY_FIND_ONE_SUCCESS';
export const DELIVERY_FIND_ONE_FAIL = 'DELIVERY_FIND_ONE_FAIL';
export const DELIVERY_FIND_ONE_REST = 'DELIVERY_FIND_ONE_REST';

export const DELIVERYCHALLAN_UPDATE_REQUEST = 'DELIVERYCHALLAN_UPDATE_REQUEST';
export const DELIVERYCHALLAN_UPDATE_SUCCESS = 'DELIVERYCHALLAN_UPDATE_SUCCESS';
export const DELIVERYCHALLAN_UPDATE_FAIL = 'DELIVERYCHALLAN_UPDATE_FAIL';
export const DELIVERYCHALLAN_UPDATE_REST = 'DELIVERYCHALLAN_UPDATE_REST';

export const DELIVERYCHALLAN_DELETE_REQUEST = 'DELIVERYCHALLAN_DELETE_REQUEST';
export const DELIVERYCHALLAN_DELETE_SUCCESS = 'DELIVERYCHALLAN_DELETE_SUCCESS';
export const DELIVERYCHALLAN_DELETE_FAIL = 'DELIVERYCHALLAN_DELETE_FAIL';
export const DELIVERYCHALLAN_DELETE_REST = 'DELIVERYCHALLAN_DELETE_REST';