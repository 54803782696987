import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { userDelete, UsersGet } from "../../redux/actions/userAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";
export const User = () => {
  
  const dispatch = useDispatch();

  const usergetlist = useSelector((state: any) => state.userGet);
  const { userGetdata } = usergetlist;

  const [userList, setuserList] = useState([] as any);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [updateId, setUpdateId] = useState(null as any);
  
  const fetchData=()=>{
    dispatch(UsersGet() as any).then((res: any) => {
      setuserList(res.payload);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    let data = [...userGetdata];
    data = data?.filter((value: any) =>
      value?.email?.toLowerCase().includes(query?.toLowerCase())
    );
    setuserList(data);
  };

  const handleSortChange = (newSortOrder: string) => {
    const sortedData = [...userGetdata].sort((a: any, b: any) => {
      if (newSortOrder === "asc") {
        return a.email.localeCompare(b.email);
      } else {
        return b.email.localeCompare(a.email);
      }
    });
    setuserList(sortedData);
  };

  const rows = userList.map((user: any, index: any) => ({
    id: uuidv4(),
    sno: index + 1,
    ...user,
  }));

  const columns: GridColDef[] = [
    { field: "sno", headerName: "S No", width: 100 },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 2,
      minWidth: 260,
    },
    {
      field: "userRoleName",
      headerName: "ROLE NAME",
      flex: 2,
      minWidth: 100,
      renderCell: (params: any) => (
        <>{params.row.userRoleName ? params.row.userRoleName : "N/A"}</>
      ),
    },
    {
      field: "total",
      headerName: "TOTAL INVOICES",
      flex: 2,
      minWidth: 120,
      renderCell: () => <>-</>,
    },
    {
      field: "creation_date",
      headerName: "CREATED AT",
      flex: 2,
      minWidth: 190,
      renderCell: (params: any) => (
        <>
          {params?.row?.creation_date?.$date
            ? moment(params?.row?.creation_date?.$date).format("DD/MM/YYYY")
            : "N/A"}
        </>
      ),
    },
    {
      field: "UpdateAt",
      headerName: "	UPDATED AT",
      flex: 2,
      minWidth: 190,
      renderCell: (params: any) => (
        <>
          {params?.row?.UpdateAt?.$date
            ? moment(params?.row?.UpdateAt?.$date).format("DD/MM/YYYY")
            : "N/A"}
        </>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      editable: false,
      flex: 2,
      width: 150,
      renderCell: (params: any) => (
        <>
          <IconButton
            aria-label="delete"
            onClick={() => handledelete(params.row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5  cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];

  const handledelete = (data: any) => {
   
    
    setShowConfirmation(true);
    setUpdateId(data?._id?.$oid)
};

const handleConfirmdelete = () => {
  dispatch(userDelete(updateId) as any).then((res: any) => {
      if (res) {
          setUpdateId(null);
          fetchData();
          setShowConfirmation(false);
      }
  })
}

const handleCancel = () => {
  setShowConfirmation(false);

};

  return (
    <>
      <div className="p-4 text-2xl font-bold text-[#045545] mx-2">Users</div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
          <div className="flex items-center">
            <div className="relative flex  mb-2 ">
              <input
                onChange={(e) => onSearch(e)}
                type="text"
                id="simple-search"
                className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                placeholder="Search"
                required
              />
              <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]  ">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="block mb-2">
            <div className="relative flex items-center ">
              <select
                defaultValue={""}
                id="sortDropdown"
                className="block w-[11rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600  "
                onChange={(e) => handleSortChange(e.target.value)}
              >
                <option value="" disabled  hidden>
                  Sort By
                </option>
                <option value="asc">Client Name A to Z</option>
                <option value="desc">Client Name Z to A</option>
              </select>
            </div>
          </div>
        </div>
        <div
          className="bg-[#F1F7F6]"
          style={{ borderRadius: "8px" }}
        >
          <Box sx={{ height: 520, width: "100%" }}>
            <DataGrid
autoHeight
              sx={{
                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                  {
                    color: "rgb(0, 128, 101)",
                    fontWeight: 600,
                    fontSize: "14px",
                  },
                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                  fontWeight: 600,
                  fontSize: "14px",
                },
                "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
              }}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
      {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirmdelete}
                    onCancel={handleCancel}
                />
            )}
    </>
  );
};
export default User;
