import { useEffect, useState } from "react";
import TermsTable from "../../Tables/TermsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  TermSave,
  termDelete,
  termsGet,
  termsUpdate,
} from "../../redux/actions/termActions";
import ConfirmationPopup from "../commen/ConfirmationPopup";
import { toast } from "react-toastify";

const TermsPage = () => {
  const dispatch = useDispatch();

  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;
  const [errors, setErrors] = useState({} as any);
  const [termlist, setTermList] = useState([] as any);
  const [termid, setTermid] = useState(null as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    terms_Conditions: "",
    user_id: token?._id,
    defaultTermsInfo: false
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settermsConditionPageErrors({
      ...termsConditionPageErrors,
      [e.target.name]: "",
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
  // ***************************cancel button section*****************************
  const resetProduct = () => {
    setFormData({
      terms_Conditions: "",
      user_id: token?._id,
      defaultTermsInfo: false
    });
  };

  const handleCancelClick = () => {
    settermsConditionPageErrors({
      ...termsConditionPageErrors,
      terms_Conditions: "",
    });
    resetProduct();
    setTermid(null);
  };

  const [termsConditionPageErrors, settermsConditionPageErrors] = useState<{
    terms_Conditions?: string;
  }>({});
  const validateProdForm = () => {
    if (formData.terms_Conditions.trim() === "") {
      settermsConditionPageErrors({
        ...termsConditionPageErrors,
        terms_Conditions: "Terms and Condition Required.",
      });
    }
  };

  // ***************************Value Submit Sction*****************************
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

    const trimmedFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value,]));

    if (trimmedFormData.terms_Conditions) {
      if (termid) {
        dispatch(termsUpdate(termid, trimmedFormData) as any).then((res: any) => {
          if (res) {
            resetProduct();
            fetchData();
            setTermid(null);
          }
          toast.success("Update SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        });
      } else {
        dispatch(TermSave(trimmedFormData) as any).then((res: any) => {
          if (res) {
            resetProduct();
            fetchData();
          }
          toast.success("Saved SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        });
      }
    } else {
      validateProdForm();
    }
  };

  const onEdit = (data: any) => {
    settermsConditionPageErrors({
      ...termsConditionPageErrors,
      terms_Conditions: "",
    });
    setFormData({
      terms_Conditions: data.terms_Conditions,
      user_id: token?._id,
      defaultTermsInfo: data.defaultTermsInfo
    });
    setTermid(data._id?.$oid);
  };

  // **********************************Get Call Section**************************************
  const fetchData = () => {
    dispatch(termsGet(token?._id) as any).then((res: any) => {
      if (res) {
        setTermList(res.payload);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onDelete = (data: any) => {
    setShowConfirmation(true);
    setTermid(data._id?.$oid);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, defaultTermsInfo: e.target.checked });
  };

  const handleConfirm = () => {
    dispatch(termDelete(termid) as any).then((res: any) => {
      if (res) {
        fetchData();
        setTermid(null);
        setShowConfirmation(false);
        resetProduct();
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <div>
      <fieldset className="mx-3 mb-4 rounded-lg">
        <legend className="p-4  font-bold text-[#045545]  text-lg      ml-2">
          Terms & Condition
        </legend>
        <div className="rounded-xl px-2 ">
          <div className="flex ml-2 gap-2">
            <div>
              <input
                type="checkbox"
                name="defaultBankInfo"
                placeholder="defaultBankInfo"
                checked={formData.defaultTermsInfo}
                onChange={handleCheckboxChange}
                className={
                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                }
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              />
            </div>
            <div className="text-[#008065] font-semibold">
              <label>Default Terms & Conditions Info</label>
            </div>
          </div>
        </div>
        <div className=" rounded-xl px-3  mx-2">
          <div>
            <label className="block mb-2 text-sm font-semibold text-[#008065] mt-2">
              Terms and Conditions:
            </label>
            <textarea
              name="terms_Conditions"
              id="message"
              rows={4}
              className={`block p-2.5 w-full mx-1 text-sm border-2 focus:outline-none rounded border-[#008065] border-solid${termsConditionPageErrors.terms_Conditions
                ? " border-2  border-[red] w-full"
                : ""
                }`}
              placeholder="Write your thoughts here..."
              onChange={(e: any) => handleChange(e)}
              value={formData.terms_Conditions ? formData.terms_Conditions : ""}
            >
              {/* {text.length > 0 && text.split("\n").map((l, i) => <p key={i}>{l}</p>)} */}
            </textarea>
            {termsConditionPageErrors.terms_Conditions && (
              <span
                style={{
                  fontFamily: "poppins",
                  fontWeight: 700,
                  color: "#ff0000",
                }}
                className="text-red-400 text-xs"
              >
                {termsConditionPageErrors.terms_Conditions}
              </span>
            )}
          </div>
          <div className="flex w-full flex-col py-1 sm:flex-row">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>

            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
              <div className="flex flex-col  ">
                <div className="relative"></div>
              </div>
            </div>

            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
              <div className="flex flex-col w-40  rounded">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-sm h-8   block    bg-[#008065] "
                  style={{ fontFamily: "poppins", fontSize: "14px" }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
              <div className="flex flex-col w-40 ">
                <button
                  onClick={(e: any) => handleSubmit(e)}
                  className="rounded-xl font-bold text-white flex flex-row items-center bg-[#008065] justify-center  text-sm h-8 block  "
                  style={{ fontFamily: "poppins", fontSize: "14px" }}
                >
                  {termid ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <TermsTable termlist={termlist} edit={onEdit} deleteterms={onDelete} />
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default TermsPage;
