import { SERVICE_GET_FAIL, SERVICE_GET_REQUEST, SERVICE_GET_REST, SERVICE_GET_SUCCESS } from "../constants/serviceConstants";



export const serviceGetReducer = (state = {}, action: any) => {
    switch (action.type) {
       case SERVICE_GET_REQUEST:
          return { loading: true };
       case SERVICE_GET_SUCCESS:
          return { loading: false, serviceGetdata: action.payload };
       case SERVICE_GET_FAIL:
          return { loading: false, error: action.payload };
       case SERVICE_GET_REST:
          return {};
       default:
          return state;
    }
 };