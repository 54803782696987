import { PRODUCT_GET_FAIL, PRODUCT_GET_REQUEST, PRODUCT_GET_REST, PRODUCT_GET_SUCCESS, PRODUCT_POST_FAIL, PRODUCT_POST_REQUEST, PRODUCT_POST_REST, PRODUCT_POST_SUCCESS } from "../constants/productConstants";

export const userProductReducer = (state = {}, action: any) => {
    switch (action.type) {
        case PRODUCT_POST_REQUEST:
            return { loading: true };
        case PRODUCT_POST_SUCCESS:
            return { loading: false, prods: action.payload };
        case PRODUCT_POST_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_POST_REST:
            return {};
        default:
            return state;
    }
};

export const userProductgetReducer = (state = {}, action: any) => {
    switch (action.type) {
        case PRODUCT_GET_REQUEST:
            return { loading: true };
        case PRODUCT_GET_SUCCESS:
            return { loading: false, productlist: action.payload };
        case PRODUCT_GET_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_GET_REST:
            return {};
        default:
            return state;
    }
};