export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';
export const USER_SIGNOUT = 'USER_SIGNOUT';

export const USER_NEW_PASSWORD_REQUEST = 'USER_NEW_PASSWORD_REQUEST';
export const USER_NEW_PASSWORD_SUCCESS = 'USER_NEW_PASSWORD_SUCCESS';
export const USER_NEW_PASSWORD_FAIL = 'USER_NEW_PASSWORD_FAIL';
export const USERNEW_PASSWORD_RESET = 'USERNEW_PASSWORD_RESET';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTEROUT = 'USER_REGISTEROUT';

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAIL = 'USER_CHANGE_PASSWORD_FAIL';
export const USER_CHANGE_PASSWORD_RESET = 'USER_CHANGE_PASSWORD_RESET';

export const USERS_GET_REQUEST = 'USERS_GET_REQUEST';
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
export const USERS_GET_FAIL = 'USERS_GET_FAIL';
export const USERS_GET_RESET = 'USERS_GET_RESET';

export const USERS_PAGES_POST_REQUEST = 'USERS_PAGES_POST_REQUEST ';
export const USERS_PAGES_POST_SUCCESS = 'USERS_PAGES_POST_SUCCESS';
export const USERS_PAGES_POST_FAIL = 'USERS_PAGES_POST_FAIL';
export const USERS_PAGES_POST_RESET = 'USERS_PAGES_POST_RESET';

export const REGISTER_GET_REQUEST = 'REGISTER_GET_REQUEST';
export const REGISTER_GET_SUCCESS = 'REGISTER_GET_SUCCESS';
export const REGISTER_GET_FAIL = 'REGISTER_GET_FAIL';
export const REGISTER_GET_RESET = 'REGISTER_GET_RESET';

export const REGISTER_UPDATE_REQUEST = 'REGISTER_UPDATE_REQUEST';
export const REGISTER_UPDATE_SUCCESS = 'REGISTER_UPDATE_SUCCESS';
export const REGISTER_UPDATE_FAIL = 'REGISTER_UPDATE_FAIL';
export const REGISTER_UPDATE_RESET = 'REGISTER_UPDATE_RESET';


export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS ';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const ROLL_GET_REQUEST = 'ROLL_GET_REQUEST';
export const ROLL_GET_SUCCESS = 'ROLL_GET_SUCCESS';
export const ROLL_GET_FAIL = 'ROLL_GET_FAIL';
export const ROLL_GET_RESET = 'ROLL_GET_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS ';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_DELETE_RESET = 'USER_DELETE_RESET';

export const USER_CODE_REQUEST = 'USER_CODE_REQUEST';
export const USER_CODE_SUCCESS = 'USER_CODE_SUCCESS';
export const USER_CODE_FAIL = 'USER_CODE_FAIL';
