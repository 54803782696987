import api from "../axiosConfig";
import { FOOTERNOTES_DELETE_FAIL, FOOTERNOTES_DELETE_REQUEST, FOOTERNOTES_DELETE_SUCCESS, FOOTERNOTES_GET_FAIL, FOOTERNOTES_GET_REQUEST, FOOTERNOTES_GET_SUCCESS, FOOTERNOTES_POST_FAIL, FOOTERNOTES_POST_REQUEST, FOOTERNOTES_POST_SUCCESS, FOOTERNOTES_UPDATE_FAIL, FOOTERNOTES_UPDATE_REQUEST, FOOTERNOTES_UPDATE_SUCCESS } from "../constants/footernotesConstants";

export const FooterNoteSave = (prod: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: FOOTERNOTES_POST_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();

      const { data } = await api.post(
         `/api/footernote/footernotesSave`,
         prod,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({ type: FOOTERNOTES_POST_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: FOOTERNOTES_POST_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const FooterNoteGet = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: FOOTERNOTES_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();

      const { data } = await api.get(
         `/api/footernote/all/${id}`,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({ type: FOOTERNOTES_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: FOOTERNOTES_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const FooternotesUpdate = (id: any, TERM: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: FOOTERNOTES_UPDATE_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();

      const { data } = await api.put(`/api/footernote/footernoteUpdate/${id}`, TERM, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: FOOTERNOTES_UPDATE_SUCCESS, payload: data });

   } catch (error: any) {
      dispatch({
         type: FOOTERNOTES_UPDATE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const FooternoteDelete = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: FOOTERNOTES_DELETE_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();

      const { data } = await api.delete(`/api/footernote/deleteFooterNote/${id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: FOOTERNOTES_DELETE_SUCCESS, payload: data });

   } catch (error: any) {
      dispatch({
         type: FOOTERNOTES_DELETE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};