export const defaultStyle =
  " text-md px-2 block w-full border-solid border-2 rounded-[7px] p-x2 focus:outline-none ";
export const defaultInputStyle = defaultStyle + "border-indigo-400 h-12 flex-1";
export const defaultSearchStyle =
  defaultStyle + "border-[#00A787] h-12 flex-1 text-sm";
export const defaultInputInvalidStyle =
  defaultStyle + "border-red-400 h-12 flex-1";
export const defaultInputLargeStyle = defaultStyle + "border-indigo-400 h-14";
export const defaultInputLargeInvalidStyle =
  defaultStyle + "border-red-400 h-14";
export const defaultSkeletonStyle =
  "px-2 block w-full rounded-2xl p-x2 focus:outline-none skeleton-input-radius ";
export const defaultSkeletonLargeStyle = defaultSkeletonStyle + " h-12";
export const defaultSkeletonNormalStyle = defaultSkeletonStyle + " h-10";
export const defaultInputSmStyle = defaultStyle + " h-10 text-sm  flex-1";
export const defaultInputSmInvalidStyle =
  defaultStyle + "border-red-400 h-8 text-sm flex-1";

// Default Table Styles
export const defaultTdStyle =
  "text-sm sm:text-left text-default-color font-title flex flex-wrap flex-row flex-1 whitespace-nowrap text-ellipsis overflow-hidden mb-1";
export const defaultTdActionStyle =
  "text-sm sm:text-left text-default-color font-title flex flex-row sm:w-11";
export const defaultTdWrapperStyle =
  "w-full flex flex-wrap flex-col sm:flex-row my-2 bg-white rounded-xl py-2 px-3 sm:px-0 sm:py-0";
export const defaultTdContent =
  "sm:w-full w-1/2 flex flex-wrap flex-row items-center";
export const defaultTdContentTitleStyle =
  "sm:hidden w-1/2 flex flex-row items-center";

export const IconStyle = { position: "relative", marginRight: 2 };

// Default Details Styles

export const defaultheadStyle =
"text-left text-lg  mt-5 flex font-semibold text-[#008065]";


export const defaulparaStyle =
"text-base font-medium mt-3 ";


export const defaultinformationStyle =
"mt-2 font-semibold text-[#008065] text-sm";

export const defaulcardparaStyle =
"text-sm font-light p-1 ";

export const defaulcardpheadStyle =
"text-xl font-normal text-[#008065] text-center  ";
