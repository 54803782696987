export const INTERNAL_INVOICE_POST_REQUEST = 'INTERNAL_INVOICE_POST_REQUEST';
export const INTERNAL_INVOICE_POST_SUCCESS = 'INTERNAL_INVOICE_POST_SUCCESS';
export const INTERNAL_INVOICE_POST_FAIL = 'INTERNAL_INVOICE_POST_FAIL';
export const INTERNAL_INVOICE_POST_REST = 'INTERNAL_INVOICE_POST_REST';

export const INTERNAL_INVOICE_GET_COUNT_REQUEST = 'INTERNAL_INVOICE_GET_COUNT_REQUEST';
export const INTERNAL_INVOICE_GET_COUNT_SUCCESS = 'INTERNAL_INVOICE_GET_COUNT_SUCCESS';
export const INTERNAL_INVOICE_GET_COUNT_FAIL = 'INTERNAL_INVOICE_GET_COUNT_FAIL';
export const INTERNAL_INVOICE_GET_COUNT_REST = 'INTERNAL_INVOICE_GET_COUNT_REST';

export const DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REQUEST = 'DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REQUEST';
export const DASHBOARD_INTERNAL_INVOICE_GET_COUNT_SUCCESS = 'DASHBOARD_INTERNAL_INVOICE_GET_COUNT_SUCCESS';
export const DASHBOARD_INTERNAL_INVOICE_GET_COUNT_FAIL = 'DASHBOARD_INTERNAL_INVOICE_GET_COUNT_FAIL';
export const DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REST = 'DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REST';


export const INTERNAL_INVOICE_FIND_ALL_REQUEST = 'INTERNAL_INVOICE_FIND_ALL_REQUEST';
export const INTERNAL_INVOICE_FIND_ALL_SUCCESS = 'INTERNAL_INVOICE_FIND_ALL_SUCCESS';
export const INTERNAL_INVOICE_FIND_ALL_FAIL = 'INTERNAL_INVOICE_FIND_ALL_FAIL';
export const INTERNAL_INVOICE_FIND_ALL_REST = 'INTERNAL_INVOICE_FIND_ALL_REST';

export const INTERNAL_INVOICE_FIND_ONE_REQUEST = 'INTERNAL_INVOICE_FIND_ONE_REQUEST';
export const INTERNAL_INVOICE_FIND_ONE_SUCCESS = 'INTERNAL_INVOICE_FIND_ONE_SUCCESS';
export const INTERNAL_INVOICE_FIND_ONE_FAIL = 'INTERNAL_INVOICE_FIND_ONE_FAIL';
export const INTERNAL_INVOICE_FIND_ONE_REST = 'INTERNAL_INVOICE_FIND_ONE_REST';


export const INTERNAL_INVOICE_UPDATE_REQUEST = 'INTERNAL_INVOICE_UPDATE_REQUEST';
export const INTERNAL_INVOICE_UPDATE_SUCCESS = 'INTERNAL_INVOICE_UPDATE_SUCCESS';
export const INTERNAL_INVOICE_UPDATE_FAIL = 'INTERNAL_INVOICE_UPDATE_FAIL';
export const INTERNAL_INVOICE_UPDATE_REST = 'INTERNAL_INVOICE_UPDATE_REST';

export const INTERNAL_INVOICE_DELETE_REQUEST = 'INTERNAL_INVOICE_DELETE_REQUEST';
export const INTERNAL_INVOICE_DELETE_SUCCESS = 'INTERNAL_INVOICE_DELETE_SUCCESS';
export const INTERNAL_INVOICE_DELETE_FAIL = 'INTERNAL_INVOICE_DELETE_FAIL';
export const INTERNAL_INVOICE_DELETE_REST = 'INTERNAL_INVOICE_DELETE_REST';

export const COUNTRY_GET_REQUEST = "COUNTRY_GET_REQUEST";
export const COUNTRY_GET_SUCCESS = "COUNTRY_GET_SUCCESS";
export const COUNTRY_GET_FAIL = "COUNTRY_GET_FAIL";
export const COUNTRY_GET_RESET = "COUNTRY_GET_RESET";