export const PURCHASE_RETURN_POST_REQUEST = 'PURCHASE_RETURN_POST_REQUEST';
export const PURCHASE_RETURN_POST_SUCCESS = 'PURCHASE_RETURN_POST_SUCCESS';
export const PURCHASE_RETURN_POST_FAIL = 'PURCHASE_RETURN_POST_FAIL';
export const PURCHASE_RETURN_POST_REST = 'PURCHASE_RETURN_POST_REST';

export const PURCHASE_RETURN_GET_COUNT_REQUEST = 'PURCHASE_RETURN_GET_COUNT_REQUEST';
export const PURCHASE_RETURN_GET_COUNT_SUCCESS = 'PURCHASE_RETURN_GET_COUNT_SUCCESS';
export const PURCHASE_RETURN_GET_COUNT_FAIL = 'PURCHASE_RETURN_GET_COUNT_FAIL';
export const PURCHASE_RETURN_GET_COUNT_REST = 'PURCHASE_RETURN_GET_COUNT_REST';

export const DASHBOARD_PURCHASE_RETURN_GET_COUNT_REQUEST = 'DASHBOARD_PURCHASE_RETURN_GET_COUNT_REQUEST';
export const DASHBOARD_PURCHASE_RETURN_GET_COUNT_SUCCESS = 'DASHBOARD_PURCHASE_RETURN_GET_COUNT_SUCCESS';
export const DASHBOARD_PURCHASE_RETURN_GET_COUNT_FAIL = 'DASHBOARD_PURCHASE_RETURN_GET_COUNT_FAIL';
export const DASHBOARD_PURCHASE_RETURN_GET_COUNT_REST = 'DASHBOARD_PURCHASE_RETURN_GET_COUNT_REST';


export const PURCHASE_RETURN_FIND_ALL_REQUEST = 'PURCHASE_RETURN_FIND_ALL_REQUEST';
export const PURCHASE_RETURN_FIND_ALL_SUCCESS = 'PURCHASE_RETURN_FIND_ALL_SUCCESS';
export const PURCHASE_RETURN_FIND_ALL_FAIL = 'PURCHASE_RETURN_FIND_ALL_FAIL';
export const PURCHASE_RETURN_FIND_ALL_REST = 'PURCHASE_RETURN_FIND_ALL_REST';

export const PURCHASE_RETURN_FIND_ONE_REQUEST = 'PURCHASE_RETURN_FIND_ONE_REQUEST';
export const PURCHASE_RETURN_FIND_ONE_SUCCESS = 'PURCHASE_RETURN_FIND_ONE_SUCCESS';
export const PURCHASE_RETURN_FIND_ONE_FAIL = 'PURCHASE_RETURN_FIND_ONE_FAIL';
export const PURCHASE_RETURN_FIND_ONE_REST = 'PURCHASE_RETURN_FIND_ONE_REST';

export const PURCHASE_RETURN_EDIT_REQUEST = 'PURCHASE_RETURN_EDIT_REQUEST';
export const PURCHASE_RETURN_EDIT_SUCCESS = 'PURCHASE_RETURN_EDIT_SUCCESS';
export const PURCHASE_RETURN_EDIT_FAIL = 'PURCHASE_RETURN_EDIT_FAIL';
export const PURCHASE_RETURN_EDIT_REST = 'PURCHASE_RETURN_EDIT_REST';

export const PURCHASE_RETURN_UPDATE_REQUEST = 'PURCHASE_RETURN_UPDATE_REQUEST';
export const PURCHASE_RETURN_UPDATE_SUCCESS = 'PURCHASE_RETURN_UPDATE_SUCCESS';
export const PURCHASE_RETURN_UPDATE_FAIL = 'PURCHASE_RETURN_UPDATE_FAIL';
export const PURCHASE_RETURN_UPDATE_REST = 'PURCHASE_RETURN_UPDATE_REST';

export const PURCHASE_RETURN_DELETE_REQUEST = 'PURCHASE_RETURN_DELETE_REQUEST';
export const PURCHASE_RETURN_DELETE_SUCCESS = 'PURCHASE_RETURN_DELETE_SUCCESS';
export const PURCHASE_RETURN_DELETE_FAIL = 'PURCHASE_RETURN_DELETE_FAIL';
export const PURCHASE_RETURN_DELETE_REST = 'PURCHASE_RETURN_DELETE_REST';

export const COUNTRY_GET_REQUEST = "COUNTRY_GET_REQUEST";
export const COUNTRY_GET_SUCCESS = "COUNTRY_GET_SUCCESS";
export const COUNTRY_GET_FAIL = "COUNTRY_GET_FAIL";
export const COUNTRY_GET_RESET = "COUNTRY_GET_RESET";