// import ClearIcon from "@mui/icons-material/Clear";
import React, { useRef } from "react";
// import Image from "next/image";
import ClearIcon from "@mui/icons-material/Clear";
const Signature = ({ formData, setFormData, handleOpen, sign }: any) => {
  const inputRef2 = useRef(null as any);
  const inputRef3 = useRef(null as any);

  const handleSign = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        setFormData({ ...formData, signature: base64Data });
        if (inputRef2.current) {
          inputRef2.current.value = "";
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSeal = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        setFormData({ ...formData, seal: base64Data });
        if (inputRef3.current) {
          inputRef3.current.value = "";
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const ClearSign = (e: any) => {
    e.preventDefault();
    formData.signature = "";
    setFormData({ ...formData });
    inputRef2.current.value = "";
  };

  const ClearSeal = (e: any) => {
    e.preventDefault();
    formData.seal = "";
    setFormData({ ...formData });
    inputRef3.current.value = "";
  };

  return (
    <div className="sm:pl-5">
      <div className="flex-col">
        <div className="flex justify-between">
          <div className="flex flex-col sm:flex-row justify-between w-full">
            <p
              className={
                sign?.signPostion === "R"
                  ? "order-2 text-sm font-semibold text-black my-2"
                  : "text-sm font-semibold text-black my-2"
              }
            >
              Signature:
            </p>
            <p
              className={
                sign?.sealPostion === "L"
                  ? "order-1 text-sm font-semibold text-black my-2"
                  : "text-sm font-semibold text-black my-2 xl:mr-[33%] md:mr-[46%]"
              }
            >
              Seal:
            </p>
          </div>
        </div>

        <div className={"flex"}>
          <div className="sm:p-2.5 w-full  text-sm border-2 focus:outline-none rounded border-[#008065] border-solid mt-2 ">
            <div className=" 2xl:flex xl:flex lg:flex md:flex sm:flex  justify-between  ">
              <div
                className={
                  sign?.signPostion === "R"
                    ? "order-2 flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12  2xl:w-1/3 items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 mt-2  hover:bg-gray-100"
                    : "flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 sm:w-1/2 2xl:w-1/3 items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 mt-2  hover:bg-gray-100"
                }
              >
                <div className="relative">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="object-cover  pb-2 flex flex-col items-center    cursor-pointer "
                  >
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputRef2}
                      className="hidden"
                      name="signature"
                      onChange={(e) => handleSign(e)}
                    />
                    {formData?.signature ? (
                      <>
                        <ClearIcon
                          sx={{ marginLeft: "16rem" }}
                          onClick={(e) => ClearSign(e)}
                        />
                        <img
                          className="sm:w-[200px] sm:h-[80px] w-[150px]  inline-block shrink-0 w  "
                          alt="sign"
                          width={200}
                          height={230}
                          src={
                            formData?.signature
                              ? formData?.signature
                              : sign?.signature
                          }
                        />
                      </>
                    ) : (
                      <>
                        <svg
                          className="w-8 h-8 mt-1"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <span className="mt-2 text-base leading-normal">
                          Upload a Signature
                        </span>
                      </>
                    )}
                  </label>
                </div>
              </div>
              <div
                className={
                  sign?.sealPostion === "L"
                    ? "order-1 flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 mt-2 hover:bg-gray-100 "
                    : " flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 mt-2 hover:bg-gray-100 "
                }
              >
                <div className="relative">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="object-cover  pb-2 flex flex-col items-center    cursor-pointer hover:bg-blue "
                  >
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputRef3}
                      className="hidden"
                      name="seal"
                      onChange={(e) => handleSeal(e)}
                    />
                    {formData?.seal ? (
                      <>
                        <ClearIcon
                          sx={{ marginLeft: "15rem" }}
                          onClick={(e) => ClearSeal(e)}
                        />
                        <img
                          className="sm:w-[200px] sm:h-[80px] w-[150px] inline-block shrink-0  "
                          alt="sign"
                          width={200}
                          height={230}
                          src={formData?.seal ? formData?.seal : sign?.seal}
                        />
                      </>
                    ) : (
                      <>
                        <svg
                          className="w-8 h-8 mt-1"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <span className="mt-2 text-base leading-normal">
                          Upload a Seal
                        </span>
                      </>
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="relative ">
            <button onClick={() => handleOpen("addSigntuare")} type="button">
              <svg
                className="sm:h-6 h-5  text-grey"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signature;
