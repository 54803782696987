import React, { useState, Suspense } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintBrush } from "@fortawesome/free-solid-svg-icons";
import { HexColorPicker } from "react-colorful";

interface ColorSchema {
  // eslint-disable-next-line no-unused-vars
  setSelectedColor: (color: string) => void;
}

const ColorScheme: React.FC<ColorSchema> = ({ setSelectedColor }) => {
  const [color, setColor] = useState("black");
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleBoxClick = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  const isValidHexColor = (color: string) => {
    const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;
    return hexColorRegex.test(color);
  };

  const handleColorChange = (newColor: string) => {
    if (isValidHexColor(newColor)) {
      setColor(newColor);
      setSelectedColor(newColor);
      setIsPickerOpen(false);
    } else {
      setColor("black");
      setSelectedColor("black");
    }
  };

  return (
    <>
      <div className="relative ml-1">
        <label
          htmlFor="color-picker"
          className="block mb-2 text-sm text-black font-semibold"
        >
          Color Picker
        </label>
        <div
          className="inline-block cursor-pointer ml-5"
          onClick={handleBoxClick}
        >
          <FontAwesomeIcon icon={faPaintBrush} size="1x" style={{ color }} />
        </div>
        {isPickerOpen && (
          <div className="absolute z-50 ">
            <Suspense fallback={<div>Loading...</div>}>
              <HexColorPicker color={color} onChange={handleColorChange} />
            </Suspense>
          </div>
        )}
      </div>
    </>
  );
};

export default ColorScheme;
