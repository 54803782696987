import api from "../axiosConfig";
import { DELIVERY_FIND_ONE_FAIL, DELIVERY_FIND_ONE_REQUEST, DELIVERY_FIND_ONE_SUCCESS, DELIVERYCHALLAN_DELETE_FAIL, DELIVERYCHALLAN_DELETE_REQUEST, DELIVERYCHALLAN_DELETE_SUCCESS, DELIVERYCHALLAN_GET_COUNT_FAIL, DELIVERYCHALLAN_GET_COUNT_REQUEST, DELIVERYCHALLAN_GET_COUNT_SUCCESS, DELIVERYCHALLAN_POST_FAIL, DELIVERYCHALLAN_POST_REQUEST, DELIVERYCHALLAN_POST_SUCCESS, DELIVERYCHALLAN_UPDATE_FAIL, DELIVERYCHALLAN_UPDATE_REQUEST, DELIVERYCHALLAN_UPDATE_SUCCESS } from "../constants/DeliveryChallanContants";

export const DeliveryChallanPost =
  (externalInvoice: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: DELIVERYCHALLAN_POST_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();

      const { data } = await api.post(`/api/deliverychallan/save`, externalInvoice, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: DELIVERYCHALLAN_POST_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: DELIVERYCHALLAN_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const deliveryChallanGet = () => async (dispatch: any, getState: any) => {
    dispatch({ type: DELIVERYCHALLAN_GET_COUNT_REQUEST });
    try {
       const {
          userSignin: {
             userInfo: { token },
          },
       } = getState();
       const { data } = await api.get(`/api/deliveryChallan/find-all/${token?._id}`, {
          headers: { Authorization: `Bearer ${token?.tocken}` },
       });
       return dispatch({
          type: DELIVERYCHALLAN_GET_COUNT_SUCCESS,
          payload: data,
       });
    } catch (error: any) {
       dispatch({
          type: DELIVERYCHALLAN_GET_COUNT_FAIL,
          payload:
             error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
       });
    }
 };

 export const ChallanFindOne =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: DELIVERY_FIND_ONE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/deliveryChallan/find-one/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({
        type: DELIVERY_FIND_ONE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: DELIVERY_FIND_ONE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const DeliveryChallanUpdate =
  (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: DELIVERYCHALLAN_UPDATE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.put(
        `/api/deliveryChallanUpdate/put/${id}`,
        externalInvoice,
        {
          headers: { Authorization: `Bearer ${token?.tocken}` },
        },
      );
      return dispatch({ type: DELIVERYCHALLAN_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: DELIVERYCHALLAN_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const DeliveryChallanDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: DELIVERYCHALLAN_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/deliverychallan/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: DELIVERYCHALLAN_DELETE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: DELIVERYCHALLAN_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };