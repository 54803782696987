import React from "react";

const Header = () => {
  return (
    <>
      <div className="grid xl:grid-cols-12 sticky top-0 z-30 w-full">
        <div className="xl:col-start-1 xl:col-span-12 ">
          <div
            className={"bg-[#f1f6f7]   lg:mx-4  xl:mx-0 xl:ml-20 xl:mr-20"}
            id="InvoiceWrapper"
          >
            <nav className="bg-[#f1f6f7]-800 pl-2 md:px-4 ">
              <div className="  flex justify-between items-center h-[50px]">
                <div className="text-black font-bold pl-0 md:pl-4">
                  <img
                    src="/logoheader.webp"
                    loading="lazy"
                    alt="logo"
                    className="UniqHeaderLogoImg"
                    style={{ height: "32px"}}
                  />
                </div>
                <div className="  font-title text-right whitespace-nowrap">
                <a
                    href="/support"
                    style={{ 
                      color: "#1A9D81",
                      fontFamily: "poppins",
                      fontWeight: "700",
                      fontSize: "14px", 
                    }}
                    className="rounded-xl font-title text-white  text-lg h-8 pr-2 md:px-2 HeaderNavBtn"
                  >
                    Support
                    <span
                    className="ml-1"
                    style={{
                      fontSize: "12px",
                    }
                    }
                    >&#x2197;</span>
                  </a>
                  <a
                    href="/register"
                    style={{ 
                      color: "#1A9D81",
                      fontFamily: "poppins",
                      fontWeight: "700",
                      fontSize: "14px", 
                    }}
                    className="rounded-xl font-title text-white  text-lg h-8 pr-2 md:px-2 HeaderNavBtn"
                  >
                    Sign Up
                    <span
                    className="ml-1"
                    style={{
                      fontSize: "12px",
                    }
                    }
                    >&#x2197;</span>
                  </a>
                  <a
                    href="/login"
                    style={{ 
                      color: "#1A9D81",
                      fontFamily: "poppins",
                      fontWeight: "700",
                      fontSize: "14px", 
                    }}
                    className="rounded-xl font-title text-white  text-lg h-8 pr-1 md:px-2 HeaderNavBtn"
                  >
                    Login
                    <span
                    className="ml-1"
                    style={{
                      fontSize: "12px",
                    }
                    }
                    >&#x2197;</span>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
