import api from "../axiosConfig";
import {
   INTERNAL_SERVICEINVOICE_DELETE_FAIL,
   INTERNAL_SERVICEINVOICE_DELETE_REQUEST,
   INTERNAL_SERVICEINVOICE_DELETE_SUCCESS,
   INTERNAL_SERVICEINVOICE_FIND_ALL_FAIL,
   INTERNAL_SERVICEINVOICE_FIND_ALL_REQUEST,
   INTERNAL_SERVICEINVOICE_FIND_ALL_SUCCESS,
   INTERNAL_SERVICEINVOICE_FIND_ONE_FAIL,
   INTERNAL_SERVICEINVOICE_FIND_ONE_REQUEST,
   INTERNAL_SERVICEINVOICE_FIND_ONE_SUCCESS,
   INTERNAL_SERVICEINVOICE_POST_FAIL,
   INTERNAL_SERVICEINVOICE_POST_REQUEST,
   INTERNAL_SERVICEINVOICE_POST_SUCCESS,
   INTERNAL_SERVICEINVOICE_UPDATE_FAIL,
   INTERNAL_SERVICEINVOICE_UPDATE_REQUEST,
   INTERNAL_SERVICEINVOICE_UPDATE_SUCCESS,
} from "../constants/serviceConstants";

export const ServicePost =
   (externalInvoice: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: INTERNAL_SERVICEINVOICE_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();

         const { data } = await api.post(
            `/api/service/invoice/save`,
            externalInvoice,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: INTERNAL_SERVICEINVOICE_POST_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: INTERNAL_SERVICEINVOICE_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const serviceFindAll = () => async (dispatch: any, getState: any) => {
   dispatch({ type: INTERNAL_SERVICEINVOICE_FIND_ALL_REQUEST });

   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(
         `/api/serviceinvoice/find-all/${token?._id}`,
         {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         }
      );
      return dispatch({
         type: INTERNAL_SERVICEINVOICE_FIND_ALL_SUCCESS,
         payload: data,
      });
   } catch (error: any) {
      dispatch({
         type: INTERNAL_SERVICEINVOICE_FIND_ALL_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const ServiceFindOne =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: INTERNAL_SERVICEINVOICE_FIND_ONE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.get(`/api/serviceinvoice/find-one/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: INTERNAL_SERVICEINVOICE_FIND_ONE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: INTERNAL_SERVICEINVOICE_FIND_ONE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const serviceInvoiceUpdate =
   (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: INTERNAL_SERVICEINVOICE_UPDATE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.put(
            `/api/internalService/put/${id}`,
            externalInvoice,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: INTERNAL_SERVICEINVOICE_UPDATE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: INTERNAL_SERVICEINVOICE_UPDATE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const serviceInvoiceDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: INTERNAL_SERVICEINVOICE_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/servieInvoice/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: INTERNAL_SERVICEINVOICE_DELETE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: INTERNAL_SERVICEINVOICE_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };
