import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import "react-toastify/dist/ReactToastify.css";

const ConfirmationUploadProduct: React.FC<{
  duplicateArray: any;
  productList: any;
  onConfirm: (value: any) => void;
  onCancel: () => void;
}> = ({ duplicateArray, productList, onConfirm, onCancel }) => {
  const StyledTableRow = styled(TableRow)<{ highlight: boolean }>(
    ({ theme, highlight }) => ({
      backgroundColor: highlight ? "#ffcccb" : theme.palette.background.default,
      "&:nth-of-type(odd)": {
        backgroundColor: highlight ? "#ffcccb" : "#F1F7F6",
      },
      "&:nth-of-type(even)": {
        backgroundColor: highlight ? "#ffcccb" : "#F1F1F1",
      },
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }),
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleConfirm = (value: any) => {
    onConfirm(value);
  };

  const checkIfDuplicate = (prod: any) => {
    return duplicateArray.some(
      (duplicate: any) => duplicate.product_name === prod.product_name,
    );
  };

  return (
    <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-35">
      <div className="md:text-sm 2xl:text-lg relative  my-6 mx-auto w-11/12 px-4 ">
        <div className="md:text-sm 2xl:text-lg border-solid border-2 border-green-700  rounded-md shadow-lg  bg-[#e1e8e7] relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="items-start justify-between p-2 border-solid border-slate-200 rounded-t bg-[#008065]">
            <div className="flex p-1 border-solid border-slate-200 rounded-b justify-between  ">
              <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">
                Confirmation
              </h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="cursor-pointer mt-1.5"
                viewBox="0 0 384 512"
                fill="#fff"
                onClick={onCancel}
              >
                <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
              </svg>
            </div>
          </div>
          <>
            <TableContainer
              sx={{ maxHeight: 345, overflowX: "auto", scrollbarWidth: "thin" }}
            >
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      S No
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Product ID
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Product Name
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      HSN/SAC
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                      }}
                    >
                      MRP
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Selling Price
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                      }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                      }}
                    >
                      Types
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                      }}
                    >
                      GST
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      GST Amount
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      CGST Tax
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      CGST Amount
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      SGST Tax
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      SGST Amount
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      IGST Tax
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      IGST Amount
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#008065",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Discount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productList.length > 0 &&
                    productList.map((prod: any, index: any) => (
                      <StyledTableRow
                        key={index || prod?._id?.$oid}
                        highlight={checkIfDuplicate(prod)}
                      >
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.product_id}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.product_name}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.hsn ? prod?.hsn : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {Number(prod?.price).toFixed(2)}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.sellingPrice}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.qty}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.units}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.gst}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.GstAmount}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.CgstPercentage}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.CgstAmount}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.SgstPercentage}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.SgstAmount}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.igstTax}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.igstAmount}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {prod?.discount}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          <div className="flex justify-between flex-col lg:flex-row p-2 border-solid border-slate-200 rounded-b mt-2">
            <div className="lg:flex lg:justify-around flex justify-around mt-2 mr-2 font-semibold">
              <div
                className="w-4 h-4 mt-2"
                style={{
                  backgroundColor: "#ffcccb",
                  borderRadius: "5px",
                  marginRight: "10px",
                  border: "1px solid black",
                }}
              ></div>
              * Already Existing Products.
            </div>
            <div className="lg:flex flex-col lg:flex-row lg:justify-end sm:justify-center mt-2">
              <button
                className="2xl:text-lg xl:text-lg lg:text-lg md:text-base xs:text-lg bg-[#008065] text-white font-semibold justify-center items-center mr-2 rounded-lg px-2 py-1 text-xs inline-flex whitespace-nowrap mb-2 lg:mb-0 w-[9rem]"
                type="button"
                onClick={(e) => handleConfirm("create")}
              >
                Save
              </button>
              <button
                type="button"
                className="2xl:text-lg lg:text-lg xl:text-lg md:text-base xs:text-lg bg-white-900 font-semibold border-[#008065] text-sm items-center justify-center text-gray-900 mr-3 dark:text-white border-2 rounded-lg font-bold px-2 py-1 inline-flex w-[9rem]"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationUploadProduct;
