export const FOOTERNOTES_POST_REQUEST = 'FOOTERNOTES_POST_REQUEST';
export const FOOTERNOTES_POST_SUCCESS = 'FOOTERNOTES_POST_SUCCESS';
export const FOOTERNOTES_POST_FAIL = 'FOOTERNOTES_POST_FAIL';
export const FOOTERNOTES_POST_REST = 'FOOTERNOTES_POST_REST';

export const FOOTERNOTES_GET_REQUEST = 'FOOTERNOTES_GET_REQUEST';
export const FOOTERNOTES_GET_SUCCESS = 'FOOTERNOTES_GET_SUCCESS';
export const FOOTERNOTES_GET_FAIL = 'FOOTERNOTES_GET_FAIL';
export const FOOTERNOTES_GET_REST = 'FOOTERNOTES_GET_REST';

export const FOOTERNOTES_UPDATE_REQUEST = 'FOOTERNOTES_UPDATE_REQUEST';
export const FOOTERNOTES_UPDATE_SUCCESS = 'FOOTERNOTES_UPDATE_SUCCESS';
export const FOOTERNOTES_UPDATE_FAIL = 'FOOTERNOTES_UPDATE_FAIL';
export const FOOTERNOTES_UPDATE_REST = 'FOOTERNOTES_UPDATE_REST';

export const FOOTERNOTES_DELETE_REQUEST = 'FOOTERNOTES_DELETE_REQUEST';
export const FOOTERNOTES_DELETE_SUCCESS = 'FOOTERNOTES_DELETE_SUCCESS';
export const FOOTERNOTES_DELETE_FAIL = 'FOOTERNOTES_DELETE_FAIL';
export const FOOTERNOTES_DELETE_REST = 'FOOTERNOTES_DELETE_REST';