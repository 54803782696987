import React, { useState } from "react";
import { Switch, FormControlLabel } from "@mui/material";

const InvoiceType = ({ setbillModule }: any) => {
  const [billModules, setBillModules] = useState("PRODUCT");
  const [isToggled, setIsToggled] = useState(true);

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
    if (billModules === "PRODUCT") {
      setBillModules("SERVICE");
      setbillModule("SERVICE");
    } else {
      setBillModules("PRODUCT");
      setbillModule("PRODUCT");
    }
  };

  return (
    <>
      <div>
        <label
          htmlFor="font-family-select"
          className="block mb-2 text-sm text-[black] font-semibold"
        >
          Invoice Type
        </label>
        <span
          style={{ fontFamily: "Poppins", fontWeight: 700, color: "#008065" }}
          className="mb-2 sm:mb-0"
        >
          {billModules === "PRODUCT" ? "PRODUCT" : "SERVICE"}
        </span>
        <FormControlLabel
          className="text-md font-bold"
          label=""
          labelPlacement="start"
          sx={{ fontFamily: "Poppins", fontWeight: 700 }}
          control={
            <Switch
              //   disabled={!!invoiceModule}
              checked={isToggled}
              onChange={handleToggleChange}
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: "#008065",
                },
                "& .MuiSwitch-thumb": {
                  color: "#008065",
                },
              }}
            />
          }
        />
      </div>
    </>
  );
};
export default InvoiceType;
