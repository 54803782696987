import api from "../axiosConfig";
import { UNIT_DELETE_FAIL, UNIT_DELETE_REQUEST, UNIT_DELETE_SUCCESS, UNIT_GET_FAIL, UNIT_GET_REQUEST, UNIT_GET_SUCCESS, UNIT_PAGE_POST_FAIL, UNIT_PAGE_POST_REQUEST, UNIT_PAGE_POST_SUCCESS, UNIT_POST_FAIL, UNIT_POST_REQUEST, UNIT_POST_SUCCESS, UNIT_UPDATE_FAIL, UNIT_UPDATE_REQUEST, UNIT_UPDATE_SUCCESS } from "../constants/unitConstants";

// export const unitSave = (unit: any) => async (dispatch: any) => {

//     dispatch({ type: UNIT_POST_REQUEST });
//     try {
//         const { data } = await api.post(`${environment.baseUrl}/api/unit/save`, unit, {
//             headers: { Authorization: `Bearer ${environment.bearerToken}` }
//         });
//         return dispatch({ type: UNIT_POST_SUCCESS, payload: data });
//     } catch (error: any) {
//         dispatch({
//             type: UNIT_POST_FAIL,
//             payload:
//                 error.response && error.response.data.message
//                     ? error.response.data.message
//                     : error.message,
//         });
//     }


// };

export const unitSave = (unit: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: UNIT_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.post(`/api/unit/save`, unit, {
      headers: { Authorization: `Bearer ${token?.tocken}` }
    });
    return dispatch({ type: UNIT_POST_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: UNIT_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const unitGet = () => async (dispatch: any, getState: any) => {
  dispatch({ type: UNIT_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(`/api/unit/all`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: UNIT_GET_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: UNIT_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const unitUpdate = (id: any, unit: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: UNIT_UPDATE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.put(`/api/unit/put/${id}`, unit, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: UNIT_UPDATE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: UNIT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const unitDelete = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: UNIT_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.delete(`/api/unit/delete/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: UNIT_DELETE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: UNIT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const unitpageInvoice = (unitPage: any) => async (dispatch: any) => {
  dispatch({ type: UNIT_PAGE_POST_REQUEST });
  try {
    const { data } = await api.post(`/api/unitpage/save`, unitPage);
    return dispatch({ type: UNIT_PAGE_POST_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: UNIT_PAGE_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};