import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	BarElement,
	Title,
	Tooltip,
	ArcElement,
} from "chart.js";
import React, { useEffect, useMemo, useState } from "react";
import { Pie } from "react-chartjs-2";
import { ChartData } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { SalesgettMethod } from "../../redux/actions/SalesAction";
import { salesReturnGet } from "../../redux/actions/salesReturnAction";
import { InternalFindAll } from "../../redux/actions/InternalInvoiceAction";
import { serviceFindAll } from "../../redux/actions/invoiceServiceActions";
import { expenseDetailAll } from "../../redux/actions/expensePageActions";
import { currencyGet } from "../../redux/actions/currencyAction";

// Register all necessary Chart.js components
ChartJS.register(
	CategoryScale,
	LinearScale,
	LineElement,
	PointElement,
	BarElement,
	ArcElement,
	Title,
	Tooltip,
	Legend
);

type SelectedRange =
	| { range: string }
	| { range: string; startDate: string; endDate: string };

interface DashboardDonutDiaProps {
	selectedRange: SelectedRange;
}

const DashboardDonutDia: React.FC<DashboardDonutDiaProps> = ({
	selectedRange,
}) => {
	const dispatch = useDispatch();

	const [salesReturnDetails, setSalseDetails] = useState([] as any);
	const [invoiceTable, setInvoiceTable] = useState([] as any);
	const [serviceInvoiceTable, setserviceInvoiceTable] = useState([] as any);
	const [expenseDetails, setExpenseDetails] = useState([] as any);

	const [donutData, setDonutData] = useState<
		ChartData<"pie", number[], string>
	>({
		labels: [],
		datasets: [],
	});

	const fetchSalesData = () => {
		dispatch(salesReturnGet() as any).then((response: any) =>
			setSalseDetails(response?.payload)
		);
		dispatch(InternalFindAll() as any).then((response: any) => {
			if (response && response.payload) setInvoiceTable(response.payload);
		});
		dispatch(serviceFindAll() as any).then((response: any) => {
			if (response && response.payload)
				setserviceInvoiceTable(response.payload);
		});
		dispatch(expenseDetailAll() as any).then((response: any) =>
			setExpenseDetails(response?.payload)
		);
	};

	useEffect(() => {
		fetchSalesData();
	}, []);

	const mergedArray = useMemo(
		() => invoiceTable.concat(serviceInvoiceTable),
		[invoiceTable, serviceInvoiceTable]
	);

	useEffect(() => {
		if (selectedRange.range === "Today") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= todayStart && invoiceDate <= todayEnd;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;
			let invoiceTotalProductt = 0;
			for (let i = 0; i < recentInvoice?.length; i++) {
				const invoice = recentInvoice[i];

				if (invoice.billModule === "SERVICE") {
					for (let j = 0; j < invoice.product?.length; j++) {
						const product = invoice.product[j];
						const revenue = product.qty * product.rate;
						if (invoice.tax && invoice.tax.length > 0) {
							for (let k = 0; k < invoice.tax.length; k++) {
								const tax = invoice.tax[0] || 0;

								const taxAmount = (revenue * (tax.taxPersent || 0)) / 100;
								invoiceTotalProductt = revenue - taxAmount || 0;
							}
						} else {
							invoiceTotalProductt = revenue;
						}
						invoiceTotalser += invoiceTotalProductt;
					}
				} else {
					for (let j = 0; j < invoice.product?.length; j++) {
						const product = invoice.product[j];
						const revenue = product.qty * product.rate;
						const discount = (revenue * product.discount || 0) / 100;
						let invoiceTotalProduct = revenue - discount;
						if (invoice.tax && invoice.tax.length > 0) {
							for (let k = 0; k < invoice.tax.length; k++) {
								const tax = invoice.tax[k];
								const taxAmount =
									(invoiceTotalProduct * (tax.taxPersent || 0)) / 100;
								invoiceTotalProduct += taxAmount;
							}
						}
						invoiceTotalproduct += invoiceTotalProduct;
					}
				}
			}

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);

				return saleDate >= todayStart && saleDate <= todayEnd;
			});

			let revenuee = 0;
			let discountt = 0;
			let returntotal = 0;
			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];
					revenuee = product.qty * product.rate;
					discountt = product.discount
						? (revenuee * product.discount) / 100
						: 0;
					returntotal += revenuee - discountt;
				}
			}

			let overallrevenue = torevwithdis - returntotal;
			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= todayStart && expenseDate <= todayEnd;
			});
			const recentExpenseTotal = recentExpenses.reduce(
				(total: any, expense: any) => total + Number(expense.toatalAmount),
				0
			);
			const updatedData = {
				labels: ["Revenue", "Expenses"],
				datasets: [
					{
						data: [overallrevenue, recentExpenseTotal],
						backgroundColor: [
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
						],
					},
				],
			};

			setDonutData(updatedData);
		} else if (selectedRange.range === "Last 7 days") {
			const today = new Date();

			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);

			const sevenDaysAgo = new Date(todayStart);
			sevenDaysAgo.setDate(todayStart.getDate() - 7);

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sevenDaysAgo && invoiceDate < todayEnd;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= sevenDaysAgo && saleDate < todayEnd;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;

			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= sevenDaysAgo && expenseDate < todayEnd;
			});

			const recentExpenseTotal = recentExpenses.reduce(
				(total: number, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const updatedData = {
				labels: ["Revenue", "Expenses"],
				datasets: [
					{
						data: [overallRevenue, recentExpenseTotal],
						backgroundColor: [
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
						],
					},
				],
			};

			setDonutData(updatedData);
		} else if (selectedRange.range === "Last 3 months") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const threeMonthAgo = new Date(today);
			threeMonthAgo.setMonth(today.getMonth() - 3);

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= threeMonthAgo && invoiceDate < todayStart;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= threeMonthAgo && saleDate < todayStart;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;

			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);

				return expenseDate >= threeMonthAgo && expenseDate < todayStart;
			});

			const recentExpenseTotal = recentExpenses.reduce(
				(total: number, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const updatedData = {
				labels: ["Revenue", "Expenses"],
				datasets: [
					{
						data: [overallRevenue, recentExpenseTotal],
						backgroundColor: [
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
						],
					},
				],
			};

			setDonutData(updatedData);
		} else if (selectedRange.range === "Last 1 month") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);

			const oneMonthAgo = new Date(today);
			oneMonthAgo.setMonth(today.getMonth() - 1);
			// oneMonthAgo.setHours(0, 0, 0, 0);

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= oneMonthAgo && invoiceDate < todayStart;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= oneMonthAgo && saleDate < todayStart;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;

			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);

				return expenseDate >= oneMonthAgo && expenseDate < todayStart;
			});

			const recentExpenseTotal = recentExpenses.reduce(
				(total: number, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const updatedData = {
				labels: ["Revenue", "Expenses"],
				datasets: [
					{
						data: [overallRevenue, recentExpenseTotal],
						backgroundColor: [
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
						],
					},
				],
			};

			setDonutData(updatedData);
		} else if (selectedRange.range === "Last 6 months") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);

			const sixMonthAgo = new Date(today);
			sixMonthAgo.setMonth(today.getMonth() - 6);
			// sixMonthAgo.setHours(0, 0, 0, 0);

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sixMonthAgo && invoiceDate < todayStart;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= sixMonthAgo && saleDate < todayStart;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;

			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);

				return expenseDate >= sixMonthAgo && expenseDate < todayStart;
			});

			const recentExpenseTotal = recentExpenses.reduce(
				(total: number, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const updatedData = {
				labels: ["Revenue", "Expenses"],
				datasets: [
					{
						data: [overallRevenue, recentExpenseTotal],
						backgroundColor: [
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
						],
					},
				],
			};

			setDonutData(updatedData);
		} else if (selectedRange.range === "Last 12 months") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const twelveMonthAgo = new Date(today);
			twelveMonthAgo.setMonth(today.getMonth() - 12);

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= twelveMonthAgo && invoiceDate < todayStart;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= twelveMonthAgo && saleDate < todayStart;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;

			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);

				return expenseDate >= twelveMonthAgo && expenseDate < todayStart;
			});

			const recentExpenseTotal = recentExpenses.reduce(
				(total: number, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const updatedData = {
				labels: ["Revenue", "Expenses"],
				datasets: [
					{
						data: [overallRevenue, recentExpenseTotal],
						backgroundColor: [
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
						],
					},
				],
			};

			setDonutData(updatedData);
		} else if (selectedRange.range === "Last 16 months") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const sixteenMonthAgo = new Date(today);
			sixteenMonthAgo.setMonth(today.getMonth() - 16);

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sixteenMonthAgo && invoiceDate < todayStart;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= sixteenMonthAgo && saleDate < todayStart;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;

			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);

				return expenseDate >= sixteenMonthAgo && expenseDate < todayStart;
			});

			const recentExpenseTotal = recentExpenses.reduce(
				(total: number, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const updatedData = {
				labels: ["Revenue", "Expenses"],
				datasets: [
					{
						data: [overallRevenue, recentExpenseTotal],
						backgroundColor: [
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
						],
					},
				],
			};

			setDonutData(updatedData);
		} else if (
			selectedRange.range === "Custom" &&
			"startDate" in selectedRange &&
			"endDate" in selectedRange
		) {
			const startDate = new Date(selectedRange.startDate);
			const endDate = new Date(selectedRange.endDate);
			endDate.setHours(23, 59, 59, 999);
			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= startDate && invoiceDate <= endDate;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= startDate && saleDate < endDate;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;
			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= startDate && expenseDate < endDate;
			});

			const recentExpenseTotal = recentExpenses.reduce(
				(total: number, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const updatedData = {
				labels: ["Revenue", "Expenses"],
				datasets: [
					{
						data: [overallRevenue, recentExpenseTotal],
						backgroundColor: [
							"rgba(153, 102, 255, 0.6)",
							"rgba(255, 159, 64, 0.6)",
						],
						hoverBackgroundColor: [
							"rgba(75, 192, 192, 0.8)",
							"rgba(255, 99, 132, 0.8)",
						],
					},
				],
			};

			setDonutData(updatedData);
		}
	}, [
		selectedRange,
		salesReturnDetails,
		invoiceTable,
		serviceInvoiceTable,
		expenseDetails,
		mergedArray,
	]);
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;
	const [currencySymbol, setcurrencySymbol] = useState("₹");
	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	}, []);
	const donutOptions = {
		responsive: true,
		cutout: "50%",
		plugins: {
			title: { display: true, text: "Donut Chart for Revenue and expenses" },
			tooltip: {
				callbacks: {
					label: (context: any) =>
						`${context.label}: ${currencySymbol} ${context.raw}`,
				},
			},
		},
	};

	return (
		<div className="rounded-md col-span-2">
			<div
				className="chart-container"
				style={{ width: "350px", height: "350px", margin: "auto" }}
			>
				<Pie data={donutData} options={donutOptions} />
			</div>
		</div>
	);
};

export default DashboardDonutDia;
