/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ServiceSettingDelete, ServiceSettingGet, ServiceSettingSave, ServiceSettingUpdate } from '../../redux/actions/serviceSettingsAction';
import ServiceSettingTable from '../../Tables/ServiceSettingTable';
import ConfirmationPopup from '../commen/ConfirmationPopup';

const ServiceSettingstabpage = () => {
  const dispatch = useDispatch();
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;

  const { register, handleSubmit, reset,
    setValue,
    formState: { errors }, } = useForm({
      defaultValues: {
        servicetaxPercentage: "",
        servicediscountPercentage: "",
      },
    });

  const [ServiceSettingList, setServiceSettingList] = useState([] as any);
  const [updateId, setUpdateId] = useState(null as any);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [ServiceTaxChecked, setServiceTaxChecked] = useState(false);
  const [ServiceDiscountChecked, setServiceDiscountChecked] = useState(false);

  const handleCheckboxChange = () => {
    setServiceTaxChecked(!ServiceTaxChecked);
    setValue(`servicetaxPercentage`, "0");

  };
  const handleCheckboxChange2 = () => {
    setServiceDiscountChecked(!ServiceDiscountChecked);
    setValue(`servicediscountPercentage`, "0");
  };

  // ***************************cancel button section*****************************
  const resetServiceSetting = () => {
    let defaultValues = {
      servicetaxPercentage: "0",
      servicediscountPercentage: "0",

    };
    setUpdateId(null);
    setServiceTaxChecked(true)
    setServiceDiscountChecked(true)
    reset({
      ...defaultValues,
    });
  };

  // const [servicesettingPageErrors, setServiceSettingPageErrors] = useState<{
  //   client_name?: string;
  //   client_mobile?: string;

  // }>({});

  const onSubmit = (data: any) => {
    let serviceSettingData = {
      servicetaxPercentage: data?.servicetaxPercentage,
      servicetaxStatus: !data.servicetaxPercentage ? false : true,
      servicediscountPercentage: data?.servicediscountPercentage,
      servicediscountStatus: !data.servicediscountPercentage ? false : true,
      user_id: token?._id,


    };

    if (ServiceSettingList?.length > 0) {

      dispatch(
        ServiceSettingUpdate(ServiceSettingList[0]._id?.$oid, serviceSettingData) as any,
      ).then((res: any) => {
        if (res?.payload) {
          fetchData();
          reset();
          setValue(`servicetaxPercentage`, "0");
          setValue(`servicediscountPercentage`, "0");

          setUpdateId(null);
          toast.success("Update SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        }

      });
    } else {

      dispatch(ServiceSettingSave(serviceSettingData) as any).then((res: any) => {
        if (res) {
          fetchData();
          reset();
          toast.success("Saved SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        }
      });
      // }

    }
    setServiceTaxChecked(false);
    setServiceDiscountChecked(false)
  };

  const handleEdit = (item: any) => {
    setUpdateId(item?._id?.$oid);
    setServiceTaxChecked(item?.servicetaxStatus);
    setServiceDiscountChecked(item?.servicediscountStatus);

    let value = {
      servicetaxPercentage: item?.servicetaxPercentage,
      servicediscountPercentage: item?.servicediscountPercentage,
    };
    reset({
      ...value,
    });

  };

  const handleDelete = (data: any) => {
    setShowConfirmation(true);
    setUpdateId(data._id?.$oid);
  };

  const handleConfirm = () => {
    dispatch(ServiceSettingDelete(updateId) as any).then((res: any) => {
      if (res?.type == 'SERVICESETTING_DELETE_SUCCESS') {
        resetServiceSetting();
        setShowConfirmation(false);
        reset();
        fetchData();
        setUpdateId(null);
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const fetchData = () => {
    dispatch(ServiceSettingGet(token?._id) as any).then((res: any) => {
      if (res) {
        setServiceSettingList(res.payload);
      }
    });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ServiceSettingList) {
      setServiceTaxChecked(
        ServiceSettingList[0]?.servicetaxStatus == undefined
          ? ServiceTaxChecked
          : ServiceSettingList[0]?.servicetaxStatus,
      );
      setServiceDiscountChecked(
        ServiceSettingList[0]?.servicediscountStatus == undefined
          ? ServiceDiscountChecked
          : ServiceSettingList[0]?.servicediscountStatus,
      );

    }
  }, [ServiceSettingList]);


  return (
    <div>
      <div>

        <fieldset className="mx-2 mb-4 rounded-lg">
          <legend className="p-4  font-bold text-[#008065]  ml-2">
            Service Settings
          </legend>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rounded-xl  py-3 mb-3  bg-[#E1E8E7]">
              <div className="flex w-full flex-col px-4 sm:flex-row">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 ">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      Service Tax(%)
                    </label>
                    <div className="relative flex">
                      <div className="relative m-2">
                        <input
                          type="checkbox"
                          name="serviceTax"
                          checked={ServiceTaxChecked}
                          onChange={handleCheckboxChange}
                          className={
                            "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        />
                      </div>

                      <div className="relative">
                        <input
                          type="text"
                          placeholder="0"
                          readOnly={!ServiceTaxChecked}
                          {...register("servicetaxPercentage", {
                            pattern: {
                              value: /^(?:\d*\.\d+|\d+)$/,
                              message:
                                "Enter a valid Service Tax Percentage (only numbers allowed).",
                            },
                            onChange: (e) => {
                              let { value } = e.target;
                              value = value.replace(/[^0-9.]/g, '');
                              if (parseFloat(value) > 100) {
                                value = '0';
                              }
                              setValue("servicetaxPercentage", value);
                            },
                          })}
                          className={
                            "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: "#008065",
                          }}
                        />
                        {errors?.servicetaxPercentage && (
                          <p className="text-red-400 text-xs">
                            {errors?.servicetaxPercentage?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 ">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      Service Discount(%)
                    </label>
                    <div className="relative flex">
                      <div className="relative m-2">
                        <input
                          type="checkbox"
                          name="serviceDiscount"
                          checked={ServiceDiscountChecked}
                          onChange={handleCheckboxChange2}
                          placeholder="GST"
                          className={
                            "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        />
                      </div>
                      <div className="relative">
                        <input
                          type="text"
                          readOnly={!ServiceDiscountChecked}
                          {...register("servicediscountPercentage", {
                            pattern: {
                              value: /^(?:\d*\.\d+|\d+)$/,
                              message:
                                "Enter a valid Service Discount Percentage (only numbers allowed).",
                            },
                            onChange: (e) => {
                              let { value } = e.target;
                              value = value.replace(/[^0-9.]/g, '');
                              if (parseFloat(value) > 100) {
                                value = "0"
                              }
                              setValue("servicediscountPercentage", value);
                            }
                          })}
                          placeholder="0"
                          className={
                            "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: "#008065",
                          }}
                        />
                        {errors?.servicediscountPercentage && (
                          <p className="text-red-400 text-xs">
                            {errors?.servicediscountPercentage?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col sm:flex-row pl-4">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                  <div className="flex flex-col w-44 rounded">
                    <button
                      type="reset"
                      className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "poppins",
                      }}

                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                  <div className="flex flex-col w-44 ">
                    <button
                      type="submit"
                      // className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "poppins",
                      }}
                      className={`${ServiceSettingList?.length > 0 && !updateId
                        ? "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] opacity-50  cursor-not-allowed disabled"
                        : "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                        }`}
                      disabled={ServiceSettingList?.length > 0 && !updateId ? true : false}
                    >
                      {updateId ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </fieldset>
        <ServiceSettingTable
          ServiceSettingList={ServiceSettingList}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
        {showConfirmation && (
          <ConfirmationPopup
            message="Are you sure you want to proceed?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </div>
    </div>
  )
}

export default ServiceSettingstabpage
