import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  userChangePwd,
  userUpdatePwd,
} from "../../../redux/actions/userChangePwdAction";
import { toast } from "react-toastify";

function UserChangePassword() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
    },
  });

  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  const dispatch = useDispatch();

  const [responseStatus, setresponseStatus] = useState(false);
  const [newpwd, setnewpwd] = useState("" as any);
  const [confirmpwd, setconfirmpwd] = useState("" as any);

  const [newpwdStatus, setnewpwdStatus] = useState(false);

  useEffect(() => {
    setnewpwdStatus(true);
  }, []);

  const handlenewpwdchange = (value: any) => {
    if (confirmpwd === value) {
      setnewpwdStatus(true);
    } else {
      setnewpwdStatus(false);
    }
    setnewpwd(value);
  };

  const handleconfirmpwdchange = (value: any) => {
    if (newpwd === value) {
      setnewpwdStatus(true);
    } else {
      setnewpwdStatus(false);
    }
    setconfirmpwd(value);
  };

  const onSubmit = (data: any) => {
    let payloaddata = {
      currentpassword: data.currentpassword,
      email: token.email,
    };
      dispatch(userChangePwd(payloaddata) as any).then((res: any) => {
        if (res && res.type === "USER_CHANGE_PASSWORD_POST_FAIL") {
          setresponseStatus(true);
        } else if (newpwd === confirmpwd) {
          setresponseStatus(false);
          let payloaddata = {
            newpassword: data.newpassword,
            email: token.email,
          };
          dispatch(userUpdatePwd(payloaddata) as any).then((res: any) => {

            setnewpwdStatus(true);
            reset();
            toast.success("New Password Updated SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            setconfirmpwd(null);
          });
        }
      });
  };

  const [currentVisibilityPassword, setcurrentVisibilityPassword] =
    useState(false);

  const [newVisibilityPassword, setnewVisibilityPassword] = useState(false);

  const [confirmVisibilitypassword, setconfirmVisibilitypassword] =
    useState(false);

  const togglePasswordVisibility = () => {
    setcurrentVisibilityPassword((prevState: any) => !prevState);
  };

  const toggleNewPasswordVisibility = () => {
    setnewVisibilityPassword((prevState: any) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setconfirmVisibilitypassword((prevState: any) => !prevState);
  };

  const handleCancel = () => {
    reset();
    setnewpwdStatus(true);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="mx-3 mb-4 rounded-lg p-2">
          <legend className="p-4 font-bold text-[#045545] text-lg ml-2">
            User Change Password
          </legend>
          <div className="rounded-xl px-3 mx-2">
            <div>
              <div className="max-w-xs">
                <label className="block mb-2 text-sm font-semibold text-[#008065] mt-2">
                  Current Password:
                </label>
                <div className="relative">
                  <input
                    type={currentVisibilityPassword ? "text" : "password"}
                    {...register("currentpassword", {
                      required: "Current Password is required",
                    })}
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full py-3 ps-2 pe-10 block w-full text-sm"
                    placeholder="Enter Current password"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      borderColor:
                        errors?.currentpassword || responseStatus
                          ? "red"
                          : "#008065",
                    }}
                  />
                  <button
                    onClick={togglePasswordVisibility}
                    type="button"
                    className={
                      `${errors?.currentpassword ? "border-red-600" : ""} ` +
                      "absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-sm"
                    }
                  >
                    {currentVisibilityPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="h-4 w-4 text-gray-400"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                {(errors?.currentpassword || responseStatus) && (
                  <p className="text-red-600 text-sm">
                    {"Current Password is required"}
                  </p>
                )}
              </div>
              <div className="max-w-xs">
                <label className="block mb-2 text-sm font-semibold text-[#008065] mt-2">
                  New Password:
                </label>
                <div className="relative">
                  <input
                    type={newVisibilityPassword ? "text" : "password"}
                    placeholder="Enter New Password"
                    {...register("newpassword", {
                      required: "New Password is required",
                      onChange: (e) => handlenewpwdchange(e.target.value),
                    })}
                    // onChange={(e) => handlenewpwdchange(e.target.value)}
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full py-3 ps-2 pe-10 block w-full text-sm"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      borderColor: errors?.newpassword ? "red" : "#008065",
                    }}
                  />
                  <button
                    onClick={toggleNewPasswordVisibility}
                    type="button"
                    className={
                      `${errors?.newpassword ? "border-red-600" : ""} ` +
                      "absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-sm"
                    }
                  >
                    {newVisibilityPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="h-4 w-4 text-gray-400"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                {errors?.newpassword && (
                  <p className="text-red-600 text-sm">
                    {errors?.newpassword?.message as any}
                  </p>
                )}
              </div>
              <div className="max-w-xs">
                <label className="block mb-2 text-sm font-semibold text-[#008065] mt-2">
                  Confirm New Password:
                </label>
                <div className="relative">
                  <input
                    type={confirmVisibilitypassword ? "text" : "password"}
                    placeholder="Enter Confirm New Password"
                    {...register("confirmpassword", {
                      required: "Confirm Password is required",
                      onChange: (e) => handleconfirmpwdchange(e.target.value),
                    })}
                    // onChange={(e) => handleconfirmpwdchange(e.target.value)}
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full py-3 ps-2 pe-10 block w-full text-sm"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      borderColor:
                        errors?.confirmpassword || !newpwdStatus
                          ? "red"
                          : "#008065",
                    }}
                  />
                  <button
                    onClick={toggleConfirmPasswordVisibility}
                    type="button"
                    className={
                      `${errors?.confirmpassword ? "border-red-600" : ""} ` +
                      "absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-sm"
                    }
                  >
                    {confirmVisibilitypassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="h-4 w-4 text-gray-400"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                {errors?.confirmpassword ? (
                  <p className="text-red-600 text-sm">
                    {errors?.confirmpassword?.message as any}
                  </p>
                ) : !newpwdStatus ? (
                  <p className="text-red-600 text-sm">
                    {"Confirm password does not match"}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex w-full flex-col sm:flex-row pl-4">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                <div className="flex flex-col w-44 rounded">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                    style={{
                      backgroundColor: "#008065",
                      fontSize: "14px",
                      fontFamily: "poppins",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                <div className="flex flex-col w-44 ">
                  <button
                    type="submit"
                    className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                    style={{
                      backgroundColor: "#008065",
                      fontSize: "14px",
                      fontFamily: "poppins",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default UserChangePassword;
