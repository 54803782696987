/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
// import NumberFormat from "../Common/NumberFormat";
// import Image from "next/image";
import CurrenyFormat from "../commen/CurrenyFormat";

const HomePrevieww = ({
  Invoice,
  componentRef,
  closeModal,
  invoice,
  setCurrency,
  billModules,
  selectedFontFamily,
  currentCountryName,
}: any) => {
  const [preview, setPreview] = useState(null as any);

  const handleCancelClick = () => {
    setPreview(null);
    closeModal();
  };

  useEffect(() => {
    setPreview(Invoice);

    // setCurrency(setCurrency)
  }, [Invoice]);

  return (
    <div ref={componentRef} style={{ fontFamily: selectedFontFamily }}>
      <div style={{ breakBefore: "page" }}></div>
      {/*   image and client information   */}

      <div className="grid grid-cols-6">
        <div className="col-start-1 col-end-2">
          {Invoice?.imageData ? (
            <div className="font-title sm:text-left">
              <label
                style={{ fontFamily: selectedFontFamily }}
                className="items-start text-blue"
              >
                <img
                  className="pl-2 sm:pr-8 md:pr-8 pt-7  2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4"
                  src={Invoice?.imageData}
                  alt="logonew"
                  width={100}
                  height={100}
                />
              </label>
            </div>
          ) : null}
        </div>

        <div className="col-start-2 col-span-4 ">
          {Invoice?.companyName ? (
            <div className=" text-center sm:font-normal font-semibold pt-7 text-md sm:text-4xl ">
              {preview?.companyName}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="text-center">
          <div className="mt-1">
            <span>
              {Invoice?.companyAddress ? (
                <span>Address: {preview?.companyAddress},</span>
              ) : null}
              {Invoice?.companyCity ? (
                <span>{preview?.companyCity},</span>
              ) : null}

              {Invoice?.companyState ? (
                <span className="pl-1">{preview?.companyState}-</span>
              ) : null}
              {Invoice?.companyPostalCode ? (
                <span className="pl-1">{preview?.companyPostalCode},</span>
              ) : null}
              {Invoice?.companygst ? (
                <span>
                  
                  Gstn:
                  {preview?.companygst}
                </span>
              ) : null}
            </span>
          </div>
          <div>
            <span>
              {Invoice?.companyPan ? (
                <span>PAN: {preview?.companyPan},</span>
              ) : null}
              {Invoice?.companyEmail ? (
                <>
                  Email:
                  <span className="lowercase"> {preview?.companyEmail},</span>
                </>
              ) : null}
              {preview?.companyMobile ? (
                <span>Mobile: {preview?.companyMobile},</span>
              ) : null}
            </span>
          </div>
        </div>
      </div>
      <div className="border "></div>
      {/******************** invoice name ********************/}

      <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
        <div className="justify-center flex flex-row relative ">
          <span
            className="w-60 md:w-11/12 lg:w-11/12 2xl:w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none"
            style={{
              fontFamily: selectedFontFamily,
              // fontSize: "34px",
              fontWeight: 700,
            }}
          >
            {invoice ? invoice : preview?.invoicehead}
          </span>
          {/* <input
            readOnly
            className="w-full rounded-[7px] text-md  sm:text-2xl  text-center xs:w-1/4 focus:outline-none "
            autoComplete="nope"
            style={{
              fontFamily: "poppins",
              // fontSize: "34px",
              fontWeight: 700,
            }}
            id="input_focus"
            value={invoice ? invoice : preview?.invoicehead}
          /> */}
          {/* </h1> */}
        </div>
        {/*     client details           */}
        <div className=" px-4 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
          <div className="flex-1 md:mx-2 column-reverse">
            <div className="flex flex-col sm:flex-row pt-3  justify-between pre-reverse">
              <div className="">
                <div className="flex flex-row  items-center mb-2 ">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      color: "#008065",
                      fontFamily: selectedFontFamily,

                      fontWeight: 700,
                    }}
                  >
                    Client Details (Shipped To)
                  </div>
                </div>
                {Invoice?.clientName ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFontFamily,
                          marginRight: "66px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Name
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none w-[90%] sm:w-[150%] sm:w-[150%]  text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                          readOnly
                          value={Invoice?.clientName}
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice?.clientAddress ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFontFamily,
                          marginRight: "53px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Address
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none w-[90%] sm:w-[150%] text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice?.clientAddress}
                          style={{
                            fontFamily: selectedFontFamily,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice?.clientCity ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFontFamily,
                          marginRight: "79px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        City
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none w-[90%] sm:w-[150%] text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice?.clientCity}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice?.clientState ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFontFamily,
                          marginRight: "70px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        State
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none w-[90%] sm:w-[150%] text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice?.clientState}
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice?.clientPostalCode ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFontFamily,
                          marginRight: "28px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left whitespace-nowrap text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Postal Code
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs w-[90%] sm:w-[150%] lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice?.clientPostalCode}
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice?.clientMobile ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFontFamily,
                          marginRight: "35px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-[30%]"
                      >
                        Mobile Number
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none w-[90%] sm:w-[150%] text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice?.clientMobile}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice?.clientEmail ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFontFamily,
                          marginRight: "69px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Email
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none w-[90%] sm:w-[165%] text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                          readOnly
                          value={Invoice?.clientEmail}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice?.clientPan ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFontFamily,
                          marginRight: "27px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        PAN Number
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none w-[90%] sm:w-[150%] text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice?.clientPan}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice?.clientgst ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFontFamily,
                          marginRight: "80px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        GST
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none w-[90%] sm:w-[150%] text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice?.clientgst}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="sm:ml-[100px]">
                <div className="flex flex-row items-center justify-between mb-2 mr-8">
                  <div
                    style={{
                      color: "#008065",
                      fontFamily: selectedFontFamily,

                      fontWeight: 700,
                    }}
                    className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    INVOICE #
                  </div>
                  <div className="font-title flex-1 text-right ">
                    <input
                      readOnly
                      value={Invoice?.invoiceNo}
                      style={{
                        fontFamily: "poppins",
                      }}
                      className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-2 sm:ml-auto block focus:outline-none "
                    />
                  </div>
                  <div></div>
                </div>
                {Invoice?.invoicedate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFontFamily,

                          fontWeight: 700,
                        }}
                        className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        Creation Date
                      </div>
                      <div className="font-title flex-1 text-right">
                        <input
                          readOnly
                          value={Invoice?.invoicedate}
                          style={{
                            fontFamily: "poppins",
                          }}
                          className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full ml-2 sm:ml-auto  focus:outline-none "
                        ></input>
                      </div>
                      <div></div>
                    </div>
                  </>
                ) : null}
                {Invoice?.duedate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-6 mr-8">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFontFamily,

                          fontWeight: 700,
                        }}
                        className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Due Date
                      </div>
                      <div className="font-title flex-1 text-right ">
                        <div>
                          <input
                            readOnly
                            value={Invoice?.duedate}
                            style={{
                              fontFamily: "poppins",
                            }}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md ml-2 sm:ml-auto block w-full  focus:outline-none  "
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* product details */}
        <div className="px-8 mt-3">
          <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white bg-[#008065]">
            <div
              style={{
                fontFamily: selectedFontFamily,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" sm:w-1/4 text-left sm:pr-11 "
            >
              <span className="inline-block">
                {billModules === "PRODUCT" ? "Product" : "Service"}
              </span>
            </div>
            {/* <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="w-40 sm:w-1/4 text-left  "
            >
              <span className="inline-block ">Hsn</span>
            </div> */}
            <div
              style={{
                fontFamily: selectedFontFamily,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4 2xl:text-left  2xl:pl-16  xl:text-center xl:mr-16 lg:text-center    md:text-center md:mr-14   sm:text-left     "
            >
              Price
            </div>

            <div
              style={{
                fontFamily: selectedFontFamily,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="w-full sm:w-1/4 text-center sm:text-center   "
            >
              Quantity
            </div>
            <div
              style={{
                fontFamily: selectedFontFamily,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4 text-right 2xl:text-right   "
            >
              Total
            </div>
          </div>
        </div>
        {Invoice?.product?.map((product: any, index: number) => {
          return (
            <div
              key={`${index}_${product.id}`}
              className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row  xl:flex-row flex-col rounded-lg w-full  2xl:px-6 xl:px-6  md:px-7 px-4  py-1 items-center relative text-sm"
            >
              <div
                style={{
                  fontFamily: selectedFontFamily,
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-right   flex flex-row sm:block "
              >
                <span
                  style={{
                    marginBottom: "5px",
                  }}
                  className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  Product
                </span>
                <span className="inline-block w-full  product   mb-1 sm:mb-0   2xl:px-3  xl:px-3 lg:px-3 md:px-3">
                  <input
                    readOnly
                    value={product.prodcut}
                    style={{
                      fontFamily: "poppins",
                      fontWeight: 400,
                    }}
                    className="font-title  block w-full 2xl:text-left  2xl:ml-3 xl:ml-2 md:ml-3  xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
              </div>

              {/* <div
                style={{
                  fontFamily: "poppins",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
              >
                <span
                  style={{
                    marginBottom: "5px",
                  }}
                  className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  hsnNo
                </span>
                {Invoice?.product.length > 0 ?
                  (<span className="inline-block w-full  sm:w-full mb-1 sm:mb-0 ">
                    <input
                      readOnly
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 400,
                      }}
                      value={product?.hsn ? product.hsn : "N/A"}
                      className="font-title  block w-full 2xl:mr-5 xl:ml-4  md:ml-4  2xl:text-left  xl:text-left  lg:text-left  md:text-left    sm:text-left  sm:ml-7    text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>) : (
                    <></>
                  )}
              </div> */}

              <div
                style={{
                  fontFamily: selectedFontFamily,
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-left   flex flex-row sm:block "
              >
                <span
                  style={{
                    marginBottom: "5px",
                  }}
                  className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  Price
                </span>
                <span className="inline-block  w-full   mb-1 sm:mb-0 ">
                  <input
                    style={{
                      fontFamily: selectedFontFamily,
                      marginTop: "",
                      fontWeight: 400,
                    }}
                    readOnly
                    value={product?.rate}
                    type={"number"}
                    className="font-title text-right block w-full   2xl:text-right   2xl:ml-4   xl:text-right  xl:ml-4 lg:text-right lg:r-0  lg:mr-10   md:text-right md:mr-7   sm:text-right sm:mr-6 sm:w-20 focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
              </div>

              <>
                <div
                  style={{
                    fontFamily: selectedFontFamily,
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Quantity
                  </span>
                  <span className="inline-block  w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product.qty}
                      type={"number"}
                      style={{
                        fontFamily: "poppins",
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full 2xl:text-center    xl:text-center xl:pl-4        lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
              </>

              <>
                <div
                  style={{
                    fontFamily: selectedFontFamily,
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right  flex flex-row sm:block"
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Total
                  </span>
                  <span className="font-block w-full sm:w-full  md:w-full  lg:w-full lg:pr-4 md:pr-4  sm:pr-7  lg:pr-4  xl:pr-5 text-xs sm:text-sm">
                    <CurrenyFormat
                      value={product?.amount}
                      setCurrency={setCurrency}
                      root={"Preview"}
                      currentCountryName={currentCountryName}
                    />
                  </span>
                </div>
              </>
            </div>
          );
        })}
        {/********************************** * subtotal *******************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFontFamily,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Subtotal
          </div>

          <div
            style={{
              fontFamily: selectedFontFamily,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-block w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
          >
            <CurrenyFormat
              value={preview?.subtotal}
              setCurrency={setCurrency}
              root={"Preview"}
              currentCountryName={currentCountryName}
            />
          </div>
        </div>
        {Invoice?.discount ? (
          <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
            <div
              style={{
                fontFamily: selectedFontFamily,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
            >
              <span>
                Discount
                <span className="ml-2">{preview?.discountprecentage}% </span>
              </span>
            </div>

            <div
              style={{
                fontFamily: selectedFontFamily,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-block w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
            >
              <CurrenyFormat
                value={preview?.discount}
                setCurrency={setCurrency}
                root={"Preview"}
                currentCountryName={currentCountryName}
              />
            </div>
          </div>
        ) : null}
        {(Invoice?.tax || []).map((tax: any, index: number) => (
          <div key={index}>
            {/* Render each tax item with specific details */}
            {tax.taxPersent && (
              <>
                <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                  >
                    {tax.taxPersent && (
                      <>
                        {tax.taxName}
                        <span className="ml-2">{tax.taxPersent}% </span>
                      </>
                    )}
                  </div>

                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                  >
                    <CurrenyFormat
                      value={tax.taxAmount}
                      setCurrency={undefined}
                      root={undefined}
                      currentCountryName={undefined} // displayType={"text"}
                      // thousandSeparator={true}
                    />
                  </div>
                </div>
              </>
            )}

            {/* Similarly render other tax details */}
            {/* Example: SCGST, IGST, vat, OTHERS, extrafees, DISCOUNT */}
          </div>
        ))}
        {Invoice?.extrafeesRows?.map((extrafeesRows: any, index: number) => {
          return (
            <div key={index}>
              {extrafeesRows.examount != "0.00" && (
                <>
                  <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                    <div
                      style={{
                        fontFamily: selectedFontFamily,
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                    >
                      <span>Extrafees</span>
                    </div>

                    <div
                      style={{
                        fontFamily: selectedFontFamily,
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                    >
                      <CurrenyFormat
                        value={extrafeesRows.examount}
                        setCurrency={setCurrency}
                        root={"Preview"}
                        currentCountryName={currentCountryName}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}

        {/*************************total****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Total
          </div>

          <div
            style={{
              fontFamily: selectedFontFamily,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
          >
            <CurrenyFormat
              value={preview?.toatalAmount}
              setCurrency={setCurrency}
              root={"Preview"}
              currentCountryName={currentCountryName}
              //   displayType={"text"}
              //   thousandSeparator={true}
            />
          </div>
        </div>

        {/************************************* bank details *********************/}
        {Invoice?.bankNme ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5"
              style={{
                color: "#008065",
                fontFamily: selectedFontFamily,

                fontWeight: 700,
              }}
            >
              <label className="block my-2 2xl:ml-3 xl:ml-3 md:ml-5  text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md font-semibold text-(#008065)">
                BANK DETAILS:
              </label>
            </div>
            <div className=" 2xl:ml-5   2xl:mr-5  xl:ml-5   xl:mr-5  lg:ml-5   lg:mr-5 md:ml-5   md:mr-5">
              <div className="text md:flex  md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                  <label
                    style={{
                      fontFamily: selectedFontFamily,
                      fontWeight: 600,
                    }}
                  >
                    BankName:
                  </label>
                </div>

                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md focus:outline-none "
                    type="text"
                    value={Invoice?.bankNme}
                  />
                </div>
              </div>

              <div className="text md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                  <label
                    style={{
                      fontFamily: selectedFontFamily,
                      fontWeight: 600,
                    }}
                  >
                    Account HolderName:
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md focus:outline-none "
                    type="text"
                    value={Invoice?.accounHolder}
                  />
                </div>
              </div>

              <div className="text md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                  <label
                    style={{
                      fontFamily: selectedFontFamily,
                      fontWeight: 600,
                    }}
                  >
                    Account Number:
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow ">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md  focus:outline-none"
                    type="text"
                    value={Invoice?.accounNumber}
                  />
                </div>
              </div>
              <div className="text md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                  <label
                    style={{
                      fontFamily: selectedFontFamily,
                      fontWeight: 600,
                    }}
                  >
                    IFSC code:
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md   focus:outline-none "
                    type="text"
                    value={Invoice?.ifscCode}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {/***********************************UPI PAY*****************************************/}
        {Invoice?.upiType ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5 mt-6"
              style={{
                color: "#008065",
                fontFamily: selectedFontFamily,

                fontWeight: 700,
              }}
            >
              <label className="block my-2 2xl:ml-3 xl:ml-3 md:ml-5  text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md font-semibold text-(#008065) ">
                UPI PAYMENT:
              </label>
            </div>
            <div className="2xl:ml-5   2xl:mr-5  xl:ml-5   xl:mr-5  lg:ml-5   lg:mr-5 md:ml-5   md:mr-5 ">
              <div className="text md:flex md:items-center mx-6  ">
                <div className="mb-1 md:mb-0 md:w-1/3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                  <label
                    style={{
                      fontFamily: selectedFontFamily,
                      fontWeight: 600,
                    }}
                  >
                    UPI PAY
                  </label>
                </div>

                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 focus:outline-none text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md"
                    value={Invoice?.upiType}
                  />
                </div>
              </div>

              <div className=" md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                  <label
                    style={{
                      fontFamily: selectedFontFamily,
                      fontWeight: 600,
                    }}
                  >
                    UPI NUMBER
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 focus:outline-none text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md"
                    value={Invoice?.upiNumber}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {/**************************  term and condition ******************/}
        {Invoice?.termsanddcon ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5 mt-6"
              style={{
                color: "#008065",
                fontFamily: selectedFontFamily,

                fontWeight: 700,
              }}
            >
              <label className=" 2xl:ml-2 xl:ml-3 lg:ml-3 md:ml-3 text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md text-#008065 font-semibold">
                Terms and Conditions:
              </label>
            </div>
            <div className="2xl:ml-8 xl:ml-8 lg:ml-8 md:ml-8 ml-8 ">
              <p className="sm:text-wrap lg:text-wrap md:text-wrap text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md break-words break-all mr-4">
                {Invoice?.termsanddcon}
              </p>
            </div>
          </>
        ) : null}

        {/********************* notes  ***************************** */}

        {Invoice?.notes ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5 mt-6"
              style={{
                color: "#008065",
                fontFamily: selectedFontFamily,

                fontWeight: 700,
              }}
            >
              <label className=" 2xl:ml-2 xl:ml-3 lg:ml-3 md:ml-3 text-base text-#008065 font-semibold">
                Notes:
              </label>
            </div>
            <div className="2xl:ml-8 xl:ml-8 lg:ml-8 md:ml-8 ml-8 ">
              <p className="sm:text-wrap lg:text-wrap md:text-wrap text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md break-words break-all mr-4">
                {Invoice?.notes}
              </p>
            </div>
          </>
        ) : null}

        {/********************************signature*******************************/}
        {Invoice?.signature || Invoice?.seal ? (
          <div className="flex flex-row-reverse justify-between">
            {Invoice?.seal ? (
              <div className="pt-20">
                <div className="flex justify-between  2xl:mx-8 xl:mx-8 lg:mx-8  md:mx-8 mx-2 text-right space-y-4 . ">
                  <div>
                    <img
                      className="h-16 w-[100px]"
                      width={16}
                      height={16}
                      src={Invoice?.seal}
                      alt=""
                    />
                    <span className="font-bold text-xs">Authority Seal</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="pt-20">
                <div className="flex justify-between  mx-20   text-right space-y-4 . "></div>
              </div>
            )}

            {Invoice?.signature ? (
              <div className="pt-20">
                <div className="flex justify-between  2xl:mx-8 xl:mx-8  lg:mx-8 md:mx-8 mx-2  text-right space-y-4 . ">
                  <div>
                    <img
                      className="h-16 w-[100px]"
                      width={16}
                      height={16}
                      src={Invoice?.signature}
                      alt=""
                    />
                    <span className="font-bold text-xs">
                      Authority Signature
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="pt-20">
                <div className="flex justify-between  mx-20   text-right space-y-4 . "></div>
              </div>
            )}
          </div>
        ) : null}

        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            // onClick={() => setOpenView(!viewOpen)}
            // ref={cancelButtonRef}
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default HomePrevieww;
